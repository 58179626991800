import {GetSessionStorage, GetUrlParameter, RemoveSessionStorage, SetSessionStorage} from '@hmteamhub/core';
import Axios, {AxiosInstance, AxiosRequestConfig} from 'axios';
import {attach} from 'retry-axios';

declare module 'axios' {
    // eslint-disable-next-line no-shadow
    export interface AxiosRequestConfig {
        anonymous?: boolean;
    }
}

export interface ConfigurationOptions {
    baseURL?: string;
    getIdToken: () => Promise<string | null>;
}

function TransformResponse(data: any): any {
    if (typeof data === 'string') {
        try {
            const dateReviver = (_key: string, value: any): any => {
                if (typeof value === 'string' && value.substring(0, 5) === 'Date(') {
                    return new Date(Date.parse(value.substring(5, value.length - 1)));
                }

                return value;
            };

            // eslint-disable-next-line no-param-reassign
            data = data.indexOf('Date(') > -1 ? JSON.parse(data, dateReviver) : JSON.parse(data);
        } catch (e) {
            /* Just return data. Something was mis-formatted. */
        }
    }

    return data;
}

function AddTokenInterceptor(instance: AxiosInstance, getIdToken: () => Promise<string | null>) {
    const fulfilled = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
        if (config.anonymous) {
            return config;
        }

        const userToken = await getIdToken?.();
        // eslint-disable-next-line no-param-reassign
        config.headers['X-FBT'] = userToken || 'null';
        return config;
    };

    instance.interceptors.request.use(fulfilled);
}

function GetUserMimic() {
    let userMimic = GetUrlParameter('UserMimic', 'string', null);

    if (userMimic != null) {
        if (userMimic && userMimic !== 'null') {
            SetSessionStorage('UserMimic', userMimic);
        } else {
            // Empty string
            RemoveSessionStorage('UserMimic');
        }
    } else {
        userMimic = GetSessionStorage<string>('UserMimic', null);
    }

    return userMimic;
}

/**
 * Setup axios headers and interceptors for app requests.
 * @param options The configuration options.
 * @public
 */
export function ConfigureAxios(options: ConfigurationOptions): AxiosInstance {
    const {baseURL, getIdToken} = options;

    const config: AxiosRequestConfig = {
        baseURL,
        maxContentLength: 64000000,
        transformResponse: TransformResponse,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-FBT': 'null',
        },
    };

    const dst = process.env.DST;
    const mimic = GetUserMimic();

    if (dst) {
        config.headers['X-DST'] = dst;
    }

    if (mimic) {
        config.headers['X-UserMimic'] = mimic;
    }

    const instance = Axios.create(config);
    AddTokenInterceptor(instance, getIdToken);
    attach(instance);
    return instance;
}

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {useMediaQuery, Menu, IconButton, List, ListItemButton, ListItemText, Box, Divider, Button, Tooltip, Typography, ListItem, Badge, TextField} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { GridInitialState } from '@mui/x-data-grid-pro';
import React, {useCallback, useMemo, useState, useEffect, SyntheticEvent} from 'react';
import {Link} from 'react-router-dom';
import {useStoreState, useStoreActions} from '~/store/storeHooks';
import styles from '~/styles/NavBar.module.scss';

let scrollPos = 0;

const getFilterTitle: (
        gridState: GridInitialState, 
        filterOn: boolean, 
        filterHeaderLookup: {[field: string]: string}
    ) => JSX.Element = (gridState, filterOn, filterHeaderLookup) => {
        if (gridState?.filter?.filterModel?.items) {
            const {items, linkOperator} = gridState.filter.filterModel;
            const title: JSX.Element[] = [];
            items.forEach((item, index)=>{
                const {columnField, operatorValue, value} = item;
                title.push(
                    <ListItem key={`li${index+1}`}>
                        <ListItemText>
                            {`${filterHeaderLookup && filterHeaderLookup[columnField] ? filterHeaderLookup[columnField] : columnField} ${operatorValue} ${value}`}
                        </ListItemText>
                    </ListItem>
                );
                if (linkOperator && index < (items.length-1)) title.push(<Divider key={`ld${index+1}`}>{linkOperator}</Divider>)
            })
            return (
                <List>
                    <ListItem disableGutters key='li0'>
                        <ListItemText>
                            <Typography variant='subtitle1'>{`${items.length} ${filterOn ? 'active' : 'inactive'} filter${items.length > 1 ? 's' : ''}`}</Typography>
                            <Typography variant='subtitle1'>{`Click icon to ${filterOn ? 'de' : ''}activate filter`}</Typography>
                        </ListItemText>
                    </ListItem>
                    <Divider key='d1'/>
                    {title}
                </List>
            )
        } 
        return <></>;
}

export function ProjectsNav() {
    const {projectLogTableInitialState, filterProjectListOn, defaultOpenInNewTab, defaultOpenToSummary, includeArchived} = useStoreState((state) => state.shared.userOptions.projectTableOptions);
    const {setUserOptions} = useStoreActions((actions) => actions.shared);
    const validSubsiteLabelWidth = useMediaQuery('(min-width: 675px)');
    const project = useStoreState((state) => state.subsite.project);
    const {projectsList, projectsFilteredList, filterHeaderLookup} = useStoreState((state) => state.project);
    const getProjectsList = useStoreActions((actions) => actions.project.getProjectsList);
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [toolTipOpen, setToolTipOpen] = useState(false);


    const open = Boolean(anchorEl);

    // if user comes directly to project link and not from dashboard
    // need to get intial data to get project list for project dropdown
    useEffect(() => {
        if (!projectsList.length) {
            getProjectsList({includeArchived});
        }
    }, [getProjectsList,projectsList,includeArchived]);

    const handleIconClick = useCallback((e: SyntheticEvent) => {
        setAnchorEl(e.currentTarget);
        scrollPos = window.pageYOffset;
        document.body.style.position = 'fixed';
        document.body.style.top = `-${scrollPos}px`;
        document.getElementById('root').style.opacity = '.7';
        setTimeout(()=>{document.body.style.overflow = 'visible'},0);
    },[setAnchorEl]);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
        document.body.style.position = '';
        document.body.style.top = '';
        document.getElementById('root').style.opacity = '1';
        window.scrollTo(0,scrollPos);
        setTimeout(()=>setSearchText(''),1);
    },[setAnchorEl]);

    const filterTitle: JSX.Element = useMemo(
        () => getFilterTitle(projectLogTableInitialState,filterProjectListOn,filterHeaderLookup),
        [projectLogTableInitialState,filterProjectListOn,filterHeaderLookup]
    );

    const filterCount = projectLogTableInitialState?.filter?.filterModel?.items?.length;

    const handleProjectClick = useCallback((href: string, e: SyntheticEvent): void => {
        if (defaultOpenInNewTab) {
            e.stopPropagation();
            e.preventDefault();
            handleClose();
            const win = window.open(href, '_blank');
            win.focus();
        } else {
            handleClose();
        }
    },
    [defaultOpenInNewTab, handleClose]) 

    const handleFilterListOn: () => void = useCallback(()=> {
        setToolTipOpen(false);  
        setUserOptions({ 
            option: 'projectTableOptions',
            key: 'filterProjectListOn', 
            value: !filterProjectListOn
        });
    },[setUserOptions,setToolTipOpen,filterProjectListOn])

    const getFilterOnOffOption: (title:string) => JSX.Element = useCallback((title: string): JSX.Element => (
            <Tooltip    title={filterTitle} 
                        key='tt2'  
                        open={toolTipOpen} 
                        onClose={()=>setToolTipOpen(false)} 
                        onOpen={()=>setToolTipOpen(true)}>
                <Box>
                    <Button key='b1' onClick={handleFilterListOn}>
                        {
                            title === 'Enable Filter'
                            ?
                            <FilterListIcon fontSize='small'/>
                            :
                            <FilterListOffIcon fontSize='small'/>
                        }
                        &nbsp;{title}
                    </Button> 
                </Box>
            </Tooltip>
        ), [filterTitle,toolTipOpen,setToolTipOpen,handleFilterListOn]) 

    const currentHref: string = window.location.pathname;

    const listItems: JSX.Element[] = useMemo(
        (): Array<any> => {
            const items: JSX.Element[] = [];
            const list: Array<Object> = filterProjectListOn && projectsFilteredList.length ? projectsFilteredList : projectsList;
            const st = searchText.toLowerCase();
            list.sort((a: any,b: any)=>a.title>b.title?1:-1).filter((p: any)=> !st?.length || p.project?.toLowerCase().indexOf(st) > -1 || p.title?.toLowerCase().indexOf(st) > -1).forEach((p: any)=>{
                let href: string = '';
                if (p?.surveyAvailable || p?.id === 536) {
                    href = `/project/${p.id}/closeout`;
                } else if (defaultOpenToSummary) {
                    href = `/project/${p.id}/summary`;
                } else {
                    href = currentHref.replace(/^(.*?\/)\d+?(\/.+)$/,`$1${  p.id  }$2`).replace('closeout','summary');
                }
                items.push(
                    <div className={styles.listOptions} key={p.id} >
                        <ListItemButton selected={project?.id === p.id} className={styles.listItemButton}>
                            <Link to={href} onClick={(e: React.UIEvent): void => {handleProjectClick(href,e)}}>
                                <ListItemText className={styles.listItemText} primary={<span className={styles.optionTitle}>{p.project}</span>} secondary={<span className={styles.option}>{p.title}</span>} />
                            </Link>
                        </ListItemButton>
                    </div>
                )
            })
            return items;
        },
        [projectsList, handleProjectClick, currentHref, project, projectsFilteredList, filterProjectListOn,defaultOpenToSummary,searchText]
    )
    
    const handleDefaultNewTab: () => void = useCallback(()=> {
        setUserOptions({ 
            option: 'projectTableOptions',
            key: 'defaultOpenInNewTab', 
            value: !defaultOpenInNewTab
        });
    },[setUserOptions,defaultOpenInNewTab]);

    const handleDefaultToSummary: () => void = useCallback(()=> {
        setUserOptions({ 
            option: 'projectTableOptions',
            key: 'defaultOpenToSummary', 
            value: !defaultOpenToSummary
        });
    },[setUserOptions,defaultOpenToSummary]);

    const handleIncludeArchived = useCallback(()=> {
        setUserOptions({ 
          option: 'projectTableOptions',
          key: 'includeArchived', 
          value: !includeArchived
        }); 
      },[setUserOptions,includeArchived]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    }

    const dashboardFiltering: boolean = projectsFilteredList.length && projectsFilteredList.length < projectsList.length ;

    if (!project) return (
        <div className={styles.projectsMenuContainer}>
            <div className={styles.subsiteLabel}><CircularProgress color="info" size={16}/></div>
        </div>
    )

    return (
        <div className={styles.projectsMenuContainer}>
            {
                validSubsiteLabelWidth && (
                    <div className={styles.subsiteLabel}>
                        <p className={styles.title}>{project.clientProjectCode ? `${project.clientProjectCode} / ${project.code}` : project.code}</p>
                        <p>{project.title}</p>
                    </div>
                ) 
            }
            { 
                listItems?.length  || searchText?.length
                ?
                (
                    <>
                        <IconButton
                            className={styles.vertIcon}
                            aria-label="more"
                            id="prjListButton"
                            aria-controls={open ? 'prjList' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleIconClick}>
                            <MoreVertIcon/> 
                        </IconButton>
                        <Menu
                            id="prjList"
                            MenuListProps={{
                            'aria-labelledby': 'prjListButton',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}>             
                            <Box className={styles.projectsListContainer}>
                                <Box className={styles.topDivider}>
                                    <Box className={styles.startDivider}/>
                                    {
                                        projectsList?.length > 5
                                        ?
                                        <Box className={styles.searchRow}>
                                            <TextField  variant="filled" 
                                                        placeholder='Search projects' 
                                                        type='search' 
                                                        value={searchText} 
                                                        className={styles.searchContainer} 
                                                        autoFocus 
                                                        inputProps={{className:styles.searchBar}} 
                                                        size='small' 
                                                        color='primary'
                                                        onChange={handleSearch}/>
                                             <span className={styles.searchSpan}>~{/* Temporarily using this hack to prevent letter L keydown from highlighting list instead of starting search for L */}</span>
                                        </Box>
                                        :
                                        <></>
                                    }
                                    {
                                        dashboardFiltering
                                        ?
                                        
                                            <Box onClick={handleFilterListOn} className={styles.filterIcon}>
                                                <Tooltip title={filterTitle} key='tt1'>
                                                    <Badge badgeContent={filterCount} color={filterProjectListOn ? 'primary' : 'default'}>
                                                        {
                                                            filterProjectListOn
                                                            ?
                                                            <FilterListIcon color='primary'/>
                                                            :
                                                            <FilterListOffIcon color='disabled'/>
                                                        }
                                                    </Badge>
                                                </Tooltip>
                                            </Box>
                                        
                                        :
                                        <></>
                                    }
                                    <Box className={styles.endDivider}/>
                                </Box>
                                <Box className={styles.mainList}>
                                    <List key='l1' onKeyDown={(e)=>e.preventDefault()}>
                                        {listItems?.length ? listItems : <Box className={styles.noMatch}>&nbsp;&nbsp;No projects match search</Box>}
                                    </List>
                                </Box>
                                <Divider className={styles.settingsIcon} textAlign='left'>Settings</Divider>
                                { 
                                    filterProjectListOn && dashboardFiltering
                                    ?
                                    getFilterOnOffOption('Disable Filter')
                                    :
                                    <></>
                                }
                                {   
                                    !filterProjectListOn && dashboardFiltering
                                    ?
                                    getFilterOnOffOption('Enable Filter')
                                    :
                                    <></>
                                }                                
                                <Box>
                                    <Button key='b3' onClick={handleDefaultNewTab}>
                                        {
                                            defaultOpenInNewTab 
                                            ? 
                                            <CheckBoxIcon fontSize='small'/> 
                                            : 
                                            <CheckBoxOutlineBlankIcon fontSize='small'/> 
                                        }&nbsp;Default Open In New Tab
                                    </Button>
                                </Box>
                                <Box>
                                    <Button key='b4' onClick={handleDefaultToSummary}>
                                        {
                                            defaultOpenToSummary 
                                            ? 
                                            <CheckBoxIcon fontSize='small'/> 
                                            : 
                                            <CheckBoxOutlineBlankIcon fontSize='small'/> 
                                        }&nbsp;Default Open To Summary
                                    </Button>
                                </Box>
                                <Box>
                                    <Button key='b4' onClick={handleIncludeArchived}>
                                        {
                                            includeArchived 
                                            ? 
                                            <CheckBoxIcon fontSize='small'/> 
                                            : 
                                            <CheckBoxOutlineBlankIcon fontSize='small'/> 
                                        }&nbsp;Include Archived Records
                                    </Button>
                                </Box>
                            </Box>                   
                        </Menu>                 
                    </>
                )
                :
                <div><CircularProgress color="info" size={16}/></div>
            }
        </div>
    );
}



import {Divider} from '@mui/material';
import Masonry from 'react-masonry-css';
import scannerImage from '../../../assets/img/leica-scanner.jpg';
import pressureVessel from '../../../assets/img/Pressure-Vessel.jpg';
import { useTrackPage } from '~/api';
import LibraryCard from '~/components/app/Resources/LibraryCard';
import styles from '~/styles/LibraryPage.module.scss';

export const DEFAULT_LIBRARY = {
    sample: {
        title: '3D Laser Scanning',
        description: 'Leica Point Cloud Scans - Access archive of project point cloud scans',
    },
    sample2: {
        title: 'Pressure Vessel Fabrication & Repair',
        description: '',
    },
};

export default function LibraryPage(): JSX.Element {
    useTrackPage('Library');
    
    return (
        <>
            <div id="resources-libary-page-root" className={styles.root}>
                <div id="resources-library-content" className={styles.content}>
                    <h4 className={styles.header}>Featured Contents</h4>
                    <Divider variant="middle" />
                    <Masonry
                        breakpointCols={{
                            default: 4,
                            2000: 4,
                            1530: 3,
                            1040: 2,
                            700: 1,
                        }}
                        className="my-masonry-grid"
                        columnClassName="my-masonry-grid_column"
                    >
                        <LibraryCard title={DEFAULT_LIBRARY.sample.title} description={DEFAULT_LIBRARY.sample.description} image={scannerImage} />
                        <LibraryCard title={DEFAULT_LIBRARY.sample2.title} description={DEFAULT_LIBRARY.sample2.description} image={pressureVessel} />
                    </Masonry>
                </div>
            </div>
        </>
    );
}

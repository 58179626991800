/* eslint-disable react-hooks/exhaustive-deps */
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography} from '@mui/material';
import {Box} from '@mui/system';
import {useEffect, useState} from 'react';
import {axios} from '~/api';
import {useStoreActions} from '~/store/storeHooks';

interface CPPSpecialistProps {
    open: boolean;
}

export default function CPPSpecialist({open}: CPPSpecialistProps): JSX.Element {
    const {setUserOptions} = useStoreActions((actions) => actions.shared);
    const [status, setStatus] = useState('default');
    const [isOpen, setIsOpen] = useState(open);
    const [countdown, setCountdown] = useState(3);

    useEffect(() => {
        let intervalId: NodeJS.Timeout;

        if (status !== 'default' && countdown > 0) {
            intervalId = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
        } else if (status !== 'default' && countdown === 0) {
            handleClose();
        }

        return () => clearInterval(intervalId);
    }, [status, countdown]);

    const handleClose = () => {
        setStatus('default');
        setIsOpen(false);
        setCountdown(3);
    };

    const handleAgree = async () => {
        await axios.get('/RequestCPPSpecialist').catch((e) => {});
        setStatus('agree');
        setUserOptions({
            option: 'feedbackOptions',
            key: 'defaultCPPSpecialistFeedback',
            value: false,
        });
    };

    const handleDisagree = () => {
        setStatus('disagree');
        setUserOptions({
            option: 'feedbackOptions',
            key: 'defaultCPPSpecialistFeedback',
            value: false,
        });
    };

    const defaultStatus = (
        <Box component="div">
            <DialogContent
                dividers
                style={{width: '90%', height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'left'}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            CPP was built for you and your opinion matters to us. We want to build the things that will help your team and you.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            Would you be willing to participate in a quick User Feedback Session with a CPP Specialist?
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            By clicking Yes, you are agreeing to scheduling a quick call with an H+M Team Member to discuss CPP.
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleAgree} color="info" autoFocus>
                    Yes, I Agree
                </Button>
                <Button onClick={handleDisagree} color="inherit" autoFocus sx={{fontSize: 10, fontWeight: 100, color: '#b2bec3'}}>
                    No, not at this time
                </Button>
            </DialogActions>
        </Box>
    );

    const agreeStatus = (
        <Box component="div">
            <DialogContent
                dividers
                style={{width: '90%', height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="body1">Great! We will have a CPP Specialist reach out to you shortly.</Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="info" autoFocus>
                    Close ({countdown}
                    <span style={{textTransform: 'lowercase'}}>s</span>)
                </Button>
            </DialogActions>
        </Box>
    );

    const disagreeStatus = (
        <Box component="div">
            <DialogContent
                dividers
                style={{width: '90%', height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="body1">Thank you, we understand your decision. </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="info" autoFocus>
                    Close ({countdown}
                    <span style={{textTransform: 'lowercase'}}>s</span>)
                </Button>
            </DialogActions>
        </Box>
    );

    return (
        <>
            <Dialog open={isOpen} fullWidth onClose={handleClose} aria-labelledby="CPP Specialist Feedback">
                <DialogTitle id="cppSpecialistFeedback">Talk to a CPP Specialist</DialogTitle>
                {status === 'default' && defaultStatus}
                {status === 'agree' && agreeStatus}
                {status === 'disagree' && disagreeStatus}
            </Dialog>
        </>
    );
}

import {action, Action, thunk, Thunk} from 'easy-peasy';
import {axios} from '~/api';
import {AxiosTryCatchBlock} from '~/helpers';
import {Comment, NewComment, TempComment, Person} from '~/types';

const defaultTimeout = 30000;
export interface CommentModel {
    comments: Comment[];
    setComments: Action<CommentModel, Comment[]>;
    addCommentBeforePost: Action<CommentModel, {currentUser: Person, newComment: NewComment, selectedComment: Comment}>;
    getProjectComments: Thunk<CommentModel, number>;
    postComment: Thunk<CommentModel, NewComment>;
    reactToComment: Thunk<CommentModel, Comment>;
    addReactionBeforePost: Action<CommentModel, Comment>;
    updateCommentsRead: Thunk<CommentModel, {projectId: number; docId: number | null}>;
}

const commentModel: CommentModel = {
    comments: [],
    setComments: action((draftState, comments) => {
        draftState.comments = comments;
    }),
    addCommentBeforePost: action ((draftState,props) => {
        // This will add comment to the store comments array with a temporary -1 id while the 
        // postcomment action is busy saving the comment so the user experiences immediate response
        // when they hit enter on a message.
        const updatedNewComment: TempComment = {
            ...props.newComment,
            commentReactions: [],
            id: -1,
            created: new Date(),
            createdBy: props.currentUser,
            read: false,
            parent: props.selectedComment
        }
        draftState.comments.push(updatedNewComment);
    }),
    getProjectComments: thunk((actions, projectId) =>
        AxiosTryCatchBlock(async () => {
            const {data} = await axios.get(`/ProjectComments?projectId=${projectId}`, {
                timeout: defaultTimeout,
            });
            actions.setComments(data);
        }),
    ),
    postComment: thunk((actions, newComment) =>
        AxiosTryCatchBlock(async () => {
            const {data} = await axios.post('/NewComment', newComment, {
                timeout: defaultTimeout,
            });
            actions.setComments(data);
            return data;
        }),
    ),
    addReactionBeforePost: action((draftState,comment) => {
        // immediatley adds the reaction to the store state so user gets immediate response to their reaction
        // even while the data is still posting to the database.
        const { comments } = draftState;
        for (let i=0; i<comments.length; i++) {
            if (comments[i].id === comment.id) {
                draftState.comments[i] = comment;
                break;
            }
        }
    }),
    reactToComment: thunk((actions, comment) =>
        AxiosTryCatchBlock(async () => {
            actions.addReactionBeforePost(comment);
            // Update or Insert comment reaction
            const {data} = await axios.post('/UpsertCommentReaction', comment, {
                timeout: defaultTimeout,
            });
            actions.setComments(data);
        }),
    ),
    updateCommentsRead: thunk(async (actions, payload) => {
        try {
            const {data} = await axios.post('/UpdateCommentsRead', payload, {
                timeout: defaultTimeout,
            });
            actions.setComments(data);
            return {
                result: 'success',
                message: 'Successfully updated comments read',
            };
        } catch (e) {
            return {
                result: 'error',
                message: 'Error while attempting to update comments read',
            };
        }
    }),
};

export default commentModel;

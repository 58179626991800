import {IconButton} from '@mui/material';
import {useSnackbar, VariantType} from 'notistack';
import {useCallback, useMemo, useState} from 'react';
import * as React from 'react';
import {trackEvent} from '~/api';
import DeleteIcon from '~/assets/img/delete-icon.svg';
import ConfirmationDialog from '~/components/generics/ConfirmationDialog';
import {OnEnterPressWrapper} from '~/helpers';
import {useStoreActions} from '~/store/storeHooks';
import styles from '~/styles/AttachmentListItem.module.scss';
import {Attachment} from '~/types';


interface AttachmentListItemProps {
    file: Attachment;
    editable?: boolean;
    onDeleteCallback?: (id: number) => Promise<{result: string; message: string}>;
}

AttachmentListItem.defaultProps = {
    editable: true,
    onDeleteCallback: (id: number): {result: string; message: string} => {
        // eslint-disable-next-line no-console
        console.log(`Attempting to delete ${id}`);

        return {
            result: null,
            message: null,
        };
    },
};

export default function AttachmentListItem({file, editable, onDeleteCallback}: AttachmentListItemProps): JSX.Element {
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [busy, setBusy] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const downloadFile = useStoreActions((actions) => actions.shared.downloadFile);

    const handleDelete = useCallback(async () => {
        setBusy(true);
        trackEvent('delete attachemnt', {id: file.id, file: file.fileName});
        const {result, message} = await onDeleteCallback(file.id);
        setBusy(false);
        if (result) {
            enqueueSnackbar(message, {variant: result as VariantType});
        }
    }, [enqueueSnackbar, file.id, file.fileName, onDeleteCallback]);

    const handleClick = useCallback(
        async (e: React.UIEvent) => {
            if (file.url.includes('hm.hosted-by-files.com')) {
                window.open(file.url, '_blank');
            } else {
                setBusy(true);
                await downloadFile(file.url);
                setBusy(false);
                e.stopPropagation();
            }
        },
        [file.url, downloadFile],
    );

    const handleDeleteClick = (e: React.MouseEvent): void => {
        e.stopPropagation();
        setConfirmDialogOpen(true);
    };

    // Ignore timestamp part of file name
    const fileName = file.fileName?.replace(/^(?:[0-9]+_)*(.+)$/,'$1'); 
    const fileDate = useMemo(()=>{
        if (file?.created) {
            return new Date(file.created);
        }
        if (file?.fileName.search(/^([0-9]+)*_(?:.+)$/) > -1) {
            return new Date(Number(file.fileName?.replace(/^([0-9]+)*_(?:.+)$/,'$1')));
        }
        return new Date(Number(file?.url?.replace(/^.+?\/(\d+).+$/,"$1") ?? "0"));
    },[file]);

    return (
        <div id={`attachment-${file.id}-list-item-root`} className={styles.root}>
            <div onClick={handleClick} onKeyDown={OnEnterPressWrapper(handleClick)} role="button" tabIndex={0}>
                <h4  style={{padding: 0, margin: 0}}>{fileName}</h4>
                <span  style={{fontSize: 11}}>{fileDate.toLocaleString()}</span>
            </div>
            {editable && (
                <>
                    <IconButton onClick={handleDeleteClick} disabled={busy} className="delete-button">
                        <img src={DeleteIcon} alt="delete-document-icon-button" />
                    </IconButton>
                    <ConfirmationDialog
                        open={confirmDialogOpen}
                        title="Confirm Delete Request"
                        message={`Please confirm you wish to delete ${file.fileName}, this process cannot be undone`}
                        onConfirm={handleDelete}
                        onCancel={(): void => setConfirmDialogOpen(false)}
                    />
                </>
            )}
        </div>
    );
}

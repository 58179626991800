import {useContext, useEffect, useState} from 'react';
import CostCard from '~/components/dashboard/project-log/CostCard';
import ProgressCard from '~/components/dashboard/project-log/ProgressCard';
import ProjectContext from '~/components/dashboard/project-log/ProjectContext';
import ProjectInfoCard from '~/components/dashboard/project-log/ProjectInfoCard';
import ProjectScopeCard from '~/components/dashboard/summary-stack/ProjectScopeCard';
import SwipeStack from '~/components/generics/SwipeStack';
import SwipeStackCard from '~/components/generics/SwipeStackCard';
import MilestoneList from '~/components/subsite/summary/milestone/MilestoneList';
import LoadingComponent from '~/components/visualizations/LoadingComponent';
import {useStoreActions} from '~/store/storeHooks';
import styles from '~/styles/ProjectSummaryDesktop.module.scss';

export default function ProjectSummaryStack(): JSX.Element {
    const {project} = useContext(ProjectContext);
    const {id} = project;
    const getProjectProgress = useStoreActions((actions) => actions.project.getProjectProgress);
    const getStandardHealthCriteria = useStoreActions((actions) => actions.project.getStandardHealthCriteria);
    const [fetchingProgressRecords, setFetchingProgressRecords] = useState(false);
    const [fetchingHealthCriteria, setFetchingHealthCriteria] = useState(false);

    useEffect(() => {
        if (project) {
            (async function fetchData(): Promise<void> {
                setFetchingProgressRecords(true);
                await getProjectProgress(id);
                setFetchingProgressRecords(false);

                setFetchingHealthCriteria(true);
                await getStandardHealthCriteria();
                setFetchingHealthCriteria(false);
            })();
        }
        return (): void => {
            setFetchingProgressRecords(false);
            setFetchingHealthCriteria(false);
        };
    }, [getProjectProgress, getStandardHealthCriteria, id, project]);

    return (
        <SwipeStack id="project-stack-root">
            <SwipeStackCard cardIndex={0}>
                {fetchingHealthCriteria ? <LoadingComponent title="Fetching Project Data" /> : <ProjectInfoCard />}
            </SwipeStackCard>
            <SwipeStackCard cardIndex={1}>
                {fetchingProgressRecords ? <LoadingComponent title="Fetching Progress Records" /> : <ProgressCard />}
            </SwipeStackCard>
            <SwipeStackCard cardIndex={2}>
                <CostCard />
            </SwipeStackCard>
            <SwipeStackCard cardIndex={3}>
                <ProjectScopeCard />
            </SwipeStackCard>
            <SwipeStackCard cardIndex={4}>
                <h3 className={styles.title}>Milestones</h3>
                <MilestoneList />
            </SwipeStackCard>
        </SwipeStack>
    );
}

import {Typography} from '@mui/material';
import {trackEvent} from '~/api';
import styles from '~/styles/CustomerService.module.scss';

export const DEFAULT_CUSTOMER_SERVICE = {
    customerService: {
        title: 'Customer Service Team',
        email: 'CPPCustService@hm-ec.com',
        subject: 'CPP Customer Inquiry',
        description: 'For all non-urgent inquiries',
    },
    management: {
        title: 'Management Team',
        email: 'CPPExecMgmt@hm-ec.com',
        subject: 'CPP Urgent Customer Inquiry',
        description: 'For urgent or critical inquiries',
    },
};

export default function CustomerServiceCard(): JSX.Element {
    const {customerService, management} = DEFAULT_CUSTOMER_SERVICE;

    const onClick = (email: string) => {
        trackEvent('contact customer service', {email});
    };

    return (
        <div className={styles.root}>
            <div id="customer-service-team" className={styles.contactContainer}>
                <Typography align="left" variant="subtitle1">
                    {customerService.title}
                </Typography>
                <a href={`mailto:${customerService.email}?subject=${customerService.subject}`} onClick={() => onClick(customerService.email)}>
                    {customerService.email}
                </a>
                <Typography align="left" variant="subtitle2">
                    {customerService.description}
                </Typography>
            </div>
            <div id="management-team" className={styles.contactContainer}>
                <Typography align="left" variant="subtitle1">
                    {management.title}
                </Typography>
                <a href={`mailto:${management.email}?subject=${management.subject}`} onClick={() => onClick(management.email)}>
                    {management.email}
                </a>
                <Typography align="left" variant="subtitle2">
                    {management.description}
                </Typography>
            </div>
        </div>
    );
}

import Skeleton from '@mui/material/Skeleton';
import styles from '~/styles/LoadingComponent.module.scss';

interface LoadingComponentProps {
    title?: string;
    contained?: boolean;
}

LoadingComponent.defaultProps = {
    title: 'Loading',
    contained: false
};

export default function LoadingComponent({title,contained}: LoadingComponentProps): JSX.Element {
    // todo: move loading component to root and use suspense to help mimimize flashing
    return (
        <div className={contained ? styles.container : ''}>
            <div className={styles.root}>
                <h4>{title}</h4>
                <div className={styles.circles}>
                    <Skeleton variant="circular" />
                    <Skeleton variant="circular" />
                    <Skeleton variant="circular" />
                </div>
            </div>
        </div>
    );
}

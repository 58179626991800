import {createContext} from 'react';
import {Document} from '~/types';

type DocumentTableContextType = {
    document?: Document;
    validCategories: number | number[];
    handleClose?: () => void;
};

const DocumentTableContext = createContext<DocumentTableContextType>({
    document: null,
    validCategories: [],
    handleClose: () => true,
});

export default DocumentTableContext;

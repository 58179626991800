import {Dialog, Zoom} from '@mui/material';
import moment from 'moment';
import {useContext} from 'react';
import DeleteDocumentButton from '~/components/subsite/documentTable/DeleteDocumentButton';
import DocumentTableContext from '~/components/subsite/documentTable/DocumentTableContext';
import styles from '~/styles/PhotoGrid.module.scss';

export default function PhotoDetail(): JSX.Element {
    const {document: photo, handleClose} = useContext(DocumentTableContext);
    // console.log(photo)
    return photo ? (
        <Dialog
            open={photo?.url?.length > 0}
            onClose={handleClose}
            TransitionComponent={Zoom}
            className={styles.photoDetailDialog}
            onClick={handleClose}
        >
            {photo?.url && <img src={photo.url} alt={photo?.title ?? 'missing-photo'} />}
            <div className={styles.captionContainer}>
                <div>
                    <h3>{photo?.title}</h3>
                    <p className={styles.description}>{photo?.description}</p>
                    <p>{moment(photo?.modifiedOn).calendar()}</p>
                </div>
                <div className={styles.deleteButtonContainer}>
                    <DeleteDocumentButton />
                </div>
            </div>
        </Dialog>
    ) : null;
}

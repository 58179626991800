import { Add } from '@mui/icons-material';
import {Button} from '@mui/material';
import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import NewTeamMemberDialog from '~/components/subsite/summary/team/NewTeamMemberDialog';
import TeamTable from '~/components/subsite/summary/team/TeamTable';
import LoadingComponent from '~/components/visualizations/LoadingComponent';
import {useStoreActions} from '~/store/storeHooks';
import styles from '~/styles/ProjectTeamPanel.module.scss';

export default function ProjectTeamPanel(): JSX.Element {
    const getProjectTeam = useStoreActions((actions) => actions.subsite.getProjectTeam);
    const [newTeamMemberDialogOpen, setNewTeamMemberDialogOpen] = useState(false);
    const {projectId} = useParams<{projectId: string}>();
    const [busy, setBusy] = useState(false);

    useEffect(() => {
        setBusy(true);
        (async function dataRequest(): Promise<void> {
            await getProjectTeam(parseInt(projectId, 10));
        })();
        setBusy(false);

        return (): void => {
            setBusy(false);
        };
    }, [getProjectTeam, projectId]);

    const handleClose = (): void => {
        setNewTeamMemberDialogOpen(false);
    };

    if (busy) {
        return <LoadingComponent title="Loading Team Data" contained />;
    }

    const addButton = (
        <Button onClick={(): void => setNewTeamMemberDialogOpen(true)} id="add-new-team-member-button">
            <Add/> Add Member
        </Button>
    )

    return (
        <div id="project-team-panel-root" className={styles.root}>
            <div className={styles.teamTableContainer}>
                <h4 className={styles.header}>
                    My Team
                    
                </h4>
                <TeamTable isHM={false} addButton={addButton} />
                <NewTeamMemberDialog open={newTeamMemberDialogOpen} handleClose={handleClose} />
            </div>
            <div className={styles.teamTableContainer}>
                <h4 className={styles.header}>H+M Team</h4>
                <TeamTable isHM addButton={null}/>
            </div>
        </div>
    );
}

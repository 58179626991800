import {createContext} from 'react';

type NavBarContextType = {
    expanded: string;
    setExpanded: (menu?: string) => void;
};

const NavBarContext = createContext<NavBarContextType>({
    expanded: '',
    setExpanded: () => true,
});

export default NavBarContext;
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Accordion, AccordionDetails, AccordionSummary, Divider} from '@mui/material';
import {useContext} from 'react';
import {BlockMath} from 'react-katex';
import HealthReportCardContext from '~/components/subsite/health/HealthReportCardContext';
import useProjectHealth from '~/hooks/useProjectHealth';
import {useStoreState} from '~/store/storeHooks';
import styles from '~/styles/HealthReportCard.module.scss';

export default function HSEScore(): JSX.Element {
    const {projectHealth, foundationJob} = useStoreState((state) => state.subsite.project);
    const {
        t0Incidents = 0,
        t1Incidents = 0,
        t2Incidents = 0,
        t3Incidents = 0,
        t0UnwantedEvents = 0,
        t1UnwantedEvents = 0,
        t2UnwantedEvents = 0,
        t3UnwantedEvents = 0,
        goodCatches = 0,
    } = projectHealth ?? {};
    const {healthCriteria} = useContext(HealthReportCardContext);
    const {calculateHSEGrade} = useProjectHealth();

    const hseGrade = calculateHSEGrade(projectHealth, foundationJob, healthCriteria);

    return (
        <div className={styles.scoreRoot}>
            <div className={styles.accordionContainer}>
                <Accordion>
                    <AccordionSummary expandIcon={<FontAwesomeIcon icon={['fad', 'caret-down']} />}>
                        <span className={styles.summaryTitle}>Tier 0 Incidents</span>
                        <span>{`Current Number : ${t0Incidents}`}</span>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>Low Severity | Weighting: 0.02</p>
                        <p>Minor first aids or any incidents resulting in ≤ $3,000 property or equipment damage</p>
                        <ul>
                            <li>First aid</li>
                            <li>Damage ≤ $3,000</li>
                            <li>Environmental issues</li>
                        </ul>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<FontAwesomeIcon icon={['fad', 'caret-down']} />}>
                        <span className={styles.summaryTitle}>Tier I Incidents</span>
                        <span>{`Current Number : ${t1Incidents}`}</span>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>Minor Severity | Weighting: 0.05</p>
                        <p>{`First Aids that require follow up, minor motor vehicle accidents, or any incident resulting in property or equipment damage: $3,000 < x < $10,000`}</p>
                        <ul>
                            <li>Minor first aid</li>
                            <li>Minor Damage</li>
                            <li>Environmental issues</li>
                        </ul>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<FontAwesomeIcon icon={['fad', 'caret-down']} />}>
                        <span className={styles.summaryTitle}>Tier II Incidents</span>
                        <span>{`Current Number : ${t2Incidents}`}</span>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>Moderate Severity | Weighting: 0.50</p>
                        <p>{`Incidents that will not result in formal OSHA Reporting but could be listed as a recordable injury on the 300 logs (i.e. Treatment beyond first aid) or damages that result in > $10000`}</p>
                        <ul>
                            <li>Medical treatment</li>
                            <li>{`Damages > $10,000`}</li>
                        </ul>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<FontAwesomeIcon icon={['fad', 'caret-down']} />}>
                        <span className={styles.summaryTitle}>Tier III Incidents</span>
                        <span>{`Current Number : ${t3Incidents}`}</span>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>High Severity | Weighting: 1.00</p>
                        <p>
                            An incident that will result in formal OSHA Reporting. Items such as amputation, loss of an eye, overnight
                            hospitalization, etc.
                        </p>
                        <ul>
                            <li>Reportable (amputation, loss of eye, death)</li>
                        </ul>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<FontAwesomeIcon icon={['fad', 'caret-down']} />}>
                        <span className={styles.summaryTitle}>Tier 0 Unwanted Events</span>
                        <span>{`Current Number : ${t0UnwantedEvents}`}</span>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>Low Severity | Weighting: 0.01</p>
                        <p>{`Unplanned Events that could have led to a Tier 0 Injury, or any unplanned event that had the potential to cause < $3000 property or equipment damage`}</p>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<FontAwesomeIcon icon={['fad', 'caret-down']} />}>
                        <span className={styles.summaryTitle}>Tier I Unwanted Events</span>
                        <span>{`Current Number : ${t1UnwantedEvents}`}</span>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>Minor Severity | Weighting: 0.025</p>
                        <p>{`Unplanned Events that could have led to a Tier I Injury,  or any unplanned event that had the potential to cause property or equipment damage: $3000 < x < $10000`}</p>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<FontAwesomeIcon icon={['fad', 'caret-down']} />}>
                        <span className={styles.summaryTitle}>Tier II Unwanted Events</span>
                        <span>{`Current Number : ${t2UnwantedEvents}`}</span>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>Moderate Severity | Weighting: 0.25</p>
                        <p>{`Unplanned Events that could have led to a Tier II Injury or any unplanned event that had the potential to cause property or equipment damage > $10000`}</p>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<FontAwesomeIcon icon={['fad', 'caret-down']} />}>
                        <span className={styles.summaryTitle}>Tier III Unwanted Events</span>
                        <span>{`Current Number : ${t3UnwantedEvents}`}</span>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>High Severity | Weighting: 0.5</p>
                        <p>
                            Unplanned Events that could have resulted in formal OSHA Reporting. Items such as death, amputation, loss of an eye,
                            overnight hospitalization, etc.
                        </p>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<FontAwesomeIcon icon={['fad', 'caret-down']} />}>
                        <span className={styles.summaryTitle}>Good Catches</span>
                        <span>{`Current Number : ${goodCatches}`}</span>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>Weighting: 0.005</p>
                        <p>Any Good Catch submitted</p>
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className={styles.calculationContainer}>
                <p>Score Calculation</p>
                <BlockMath math="\frac{0.02TI_{0} + 0.05TI_{1} + 0.50TI_{2} + TI_{3} + 0.01TU_{0} + 0.025TU_{1} + 0.25TU_{2} + 0.5TU_{3} - 0.005(\sum{\text{Incidents}} + \sum{\text{Unwanted Events}} + \sum{\text{Good Catches}})}{\text{Actual Hours / 100,000}}" />
            </div>
            <div className={styles.resultContainer}>
                <div>
                    <p>Score</p>
                    <p className={styles.score}>{Number(hseGrade.score) < 0 ? 0 : hseGrade.score}</p>
                </div>
                <Divider orientation="vertical" flexItem />
                <div>
                    <p>Grade</p>
                    <FontAwesomeIcon icon={['fas', 'circle']} color={hseGrade.indicator.color} />
                </div>
            </div>
        </div>
    );
}

import { Box, useMediaQuery } from "@mui/material";
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid-pro";
import { DocumentTableSearchBar } from "./DocumentTableSearchBar";
import { DocumentTableSettingsMenu } from "./DocumentTableSettingsMenu";

export function DocumentTableToolbar(props: any) {
    const {csvOptions,addButton,getDefaultGridState,optionKey,showSearchBar} = props;
    const mobile = useMediaQuery('(max-width: 674px)');
    return (
      <GridToolbarContainer>
        <Box sx={{width: '100%'}}>
          {addButton}
          {!mobile && <GridToolbarColumnsButton />}
          {(!mobile || !addButton) && <GridToolbarFilterButton />}
          {!mobile && <GridToolbarDensitySelector />}
          <GridToolbarExport csvOptions={csvOptions}/>
          <DocumentTableSettingsMenu getDefaultGridState={getDefaultGridState} optionKey={optionKey}/>
          {!mobile && showSearchBar && <DocumentTableSearchBar optionKey={optionKey}/>}
        </Box>
      </GridToolbarContainer>
    );
  }
import {useEffect, useState} from 'react';
import {useStoreState} from '~/store/storeHooks';

export function usePermissions() {
    const [ability, setAbility] = useState(undefined);
    const {currentUser} = useStoreState((state) => state.shared);

    useEffect(() => {
        setAbility(currentUser.viewCommercial === true);
    }, [ability, currentUser]);

    return ability;
}

import {action, Action, thunk, Thunk} from 'easy-peasy';
import {axios} from '~/api';
import {AxiosTryCatchBlock} from '~/helpers';
import {ActionItem, Hold, Need, RFI, TDR, TQ, UploadedFile} from '~/types';

const defaultTimeout = 30000;
const requests: any = {}; // object to track API requests to prevent cases where later requests return faster than earlier requests and the earlier outdated request data is then incorrectly used,
// for example first request data without filtering and then 2nd request with filtering the first request which returns more data and takes longer comes in after the last request
export interface CorrespondenceModel {
    rfis: RFI[];
    tqs: TQ[];
    tdrs: TDR[];
    holds: Hold[];
    needs: Need[];
    actionItems: ActionItem[];
    setRFIs: Action<CorrespondenceModel, RFI[]>;
    getRFIs: Thunk<CorrespondenceModel, number>;
    setTQs: Action<CorrespondenceModel, TQ[]>;
    getTQs: Thunk<CorrespondenceModel, number>;
    setTDRs: Action<CorrespondenceModel, TDR[]>;
    getTDRs: Thunk<CorrespondenceModel, number>;
    setHolds: Action<CorrespondenceModel, Hold[]>;
    setNeeds: Action<CorrespondenceModel, Need[]>;
    getHolds: Thunk<CorrespondenceModel, number>;
    getNeeds: Thunk<CorrespondenceModel, number>;
    setActionItems: Action<CorrespondenceModel, ActionItem[]>;
    getActionItems: Thunk<CorrespondenceModel, number>;
    updateActionItem: Thunk<CorrespondenceModel, ActionItem>;
    updateNeed: Thunk<CorrespondenceModel, Need>;
    insertNeed: Thunk<CorrespondenceModel, Need>;
    deleteNeed: Thunk<CorrespondenceModel, {id: number; project: number}>;
    updateRFI: Thunk<CorrespondenceModel, {rfi: RFI; uploadedFiles: UploadedFile[]}>;
    updateTQ: Thunk<CorrespondenceModel, {tq: TQ; uploadedFiles: UploadedFile[]}>;
    updateTDR: Thunk<CorrespondenceModel, {tdr: TDR}>;
    toggleAssignmentCompleted: Thunk<CorrespondenceModel, number>;
    getProjectSetUnresolvedCorrespondence: Thunk<CorrespondenceModel, number[]>;
    getActionItemsExcelExport: Thunk<CorrespondenceModel, {projectId: number; projectCode: string}>;
    getClientNeedsExcelExport: Thunk<CorrespondenceModel, {projectId: number; projectCode: string}>;
    getRFIsExcelExport: Thunk<CorrespondenceModel, {projectId: number; projectCode: string}>;
    getTQsExcelExport: Thunk<CorrespondenceModel, {projectId: number; projectCode: string}>;
}

const correspondenceModel: CorrespondenceModel = {
    rfis: [],
    tqs: [],
    tdrs: [],
    holds: [],
    needs: [],
    actionItems: [],
    setRFIs: action((draftState, rfis) => {
        draftState.rfis = rfis;
    }),
    setTQs: action((draftState, tqs) => {
        draftState.tqs = tqs;
    }),
    setTDRs: action((draftState, tdrs) => {
        draftState.tdrs = tdrs;
    }),
    setHolds: action((draftState, holds) => {
        draftState.holds = holds;
    }),
    setNeeds: action((draftState, needs) => {
        draftState.needs = needs;
    }),
    setActionItems: action((draftState, actionItems) => {
        draftState.actionItems = actionItems;
    }),
    getRFIs: thunk((actions, projectId) =>
        AxiosTryCatchBlock(async () => {
            const {data} = await axios.get(`/ProjectRFIs?projectId=${projectId}`, {
                timeout: defaultTimeout,
            });
            actions.setRFIs(data);
        }),
    ),
    getTQs: thunk((actions, projectId) =>
        AxiosTryCatchBlock(async () => {
            const {data} = await axios.get(`/ProjectTQs?projectId=${projectId}`, {
                timeout: defaultTimeout,
            });
            actions.setTQs(data);
        }),
    ),
    getTDRs: thunk((actions, projectId) =>
        AxiosTryCatchBlock(async () => {
            const {data} = await axios.get(`/ProjectTDRs?projectId=${projectId}`, {
                timeout: defaultTimeout,
            });
            actions.setTDRs(data);
        }),
    ),
    getHolds: thunk((actions, projectId) =>
        AxiosTryCatchBlock(async () => {
            const {data} = await axios.get(`/ProjectHolds?projectId=${projectId}`, {
                timeout: defaultTimeout,
            });
            actions.setHolds(data);
        }),
    ),
    getNeeds: thunk((actions, projectId) =>
        AxiosTryCatchBlock(async () => {
            const {data} = await axios.get(`/ProjectNeeds?projectId=${projectId}`, {
                timeout: defaultTimeout,
            });
            actions.setNeeds(data);
        }),
    ),
    getActionItems: thunk((actions, projectId) =>
        AxiosTryCatchBlock(async () => {
            const {data} = await axios.get(`/ProjectActionItems?projectId=${projectId}`, {
                timeout: defaultTimeout,
            });
            actions.setActionItems(data);
        }),
    ),
    updateActionItem: thunk((actions, actionItem) =>
        AxiosTryCatchBlock(async () => {
            const postData = {
                actionId: actionItem.id,
                comments: actionItem.comments,
                clientAssignee: actionItem.clientAssignee,
                completed: actionItem.completed,
                completedBy: actionItem.completedBy?.id,
            };
            const {data} = await axios.post('/UpdateProjectActionItem', postData, {
                timeout: defaultTimeout,
            });
            const {actionItems} = data;
            if (actionItems) {
                if (actionItems?.length > 0) {
                    actions.setActionItems(actionItems);
                } else {
                    actions.setActionItems([]);
                }
            }
            return data;
        }).catch((e) => ({result: 'error', message: `Failed to save project action item - Please contact Tech Support${e.toString()}`})),
    ),
    updateNeed: thunk((actions, need) =>
        AxiosTryCatchBlock(async () => {
            const postData = {
                ...need,
                completedBy: need.completedBy?.id,
            };
            const {data} = await axios.post('/UpsertNeed', postData, {
                timeout: defaultTimeout,
            });
            const {needs} = data;
            if (needs) {
                if (needs?.length > 0) {
                    actions.setNeeds(needs);
                } else {
                    actions.setNeeds([]);
                }
            }
            return data;
        }).catch((e) => ({result: 'error', message: `Failed to save project need - Please contact Tech Support${e.toString()}`})),
    ),
    insertNeed: thunk((actions, need) =>
        AxiosTryCatchBlock(async () => {
            const {data} = await axios.post('/UpsertNeed', need, {
                timeout: defaultTimeout,
            });
            const {needs} = data;
            if (needs) {
                if (needs?.length > 0) {
                    actions.setNeeds(needs);
                } else {
                    actions.setNeeds([]);
                }
            }
            return data;
        }).catch((e) => ({result: 'error', message: `Failed to save project need - Please contact Tech Support${e.toString()}`})),
    ),
    deleteNeed: thunk((actions, need) =>
        AxiosTryCatchBlock(async () => {
            const {data} = await axios.post('/DeleteNeed', need, {
                timeout: defaultTimeout,
            });
            const {needs} = data;
            if (needs) {
                if (needs?.length > 0) {
                    actions.setNeeds(needs);
                } else {
                    actions.setNeeds([]);
                }
            }
            return data;
        }).catch((e) => ({result: 'error', message: `Failed to delete project need- Please contact Tech Support${e.toString()}`})),
    ),
    updateRFI: thunk((actions, {rfi, uploadedFiles}) =>
        AxiosTryCatchBlock(async () => {
            const {data} = await axios.post(
                '/UpdateRFI',
                {rfi, uploadedFiles},
                {
                    timeout: defaultTimeout,
                },
            );
            const {rfis} = data;
            if (rfis?.length > 0) {
                actions.setRFIs(rfis);
            } else {
                actions.setRFIs([]);
            }
            return data;
        }),
    ),
    updateTQ: thunk((actions, {tq, uploadedFiles}) =>
        AxiosTryCatchBlock(async () => {
            const {data} = await axios.post(
                '/UpdateTQ',
                {tq, uploadedFiles},
                {
                    timeout: defaultTimeout,
                },
            );
            const {tqs} = data;
            if (tqs?.length > 0) {
                actions.setTQs(tqs);
            }
            return data;
        }),
    ),
    updateTDR: thunk((actions, {tdr}) =>
        AxiosTryCatchBlock(async () => {
            const {data} = await axios.post(
                '/UpdateTDR',
                {tdr},
                {
                    timeout: defaultTimeout,
                },
            );
            const {tdrs} = data;
            if (tdrs?.length > 0) {
                actions.setTDRs(tdrs);
            }
            return data;
        }),
    ),
    toggleAssignmentCompleted: thunk((actions, assignmentId) =>
        AxiosTryCatchBlock(async () => {
            const {data} = await axios.post(
                '/ToggleAssignmentComplete',
                {assignmentId},
                {
                    timeout: defaultTimeout,
                },
            );
            const {actionItems} = data;
            if (actionItems.length > 0) {
                actions.setActionItems(actionItems);
            }
            return data;
        }),
    ),
    getProjectSetUnresolvedCorrespondence: thunk((actions, projectIdSet) =>
        AxiosTryCatchBlock(async () => {
            if (!requests.unresolvedCorrespondence) requests.unresolvedCorrespondence = 0;
            const myRequest = requests.unresolvedCorrespondence + 1;
            requests.unresolvedCorrespondence++;
            const {data} = await axios.post(
                '/ProjectSetUnresolvedCorrespondence',
                {projectIdSet},
                {
                    timeout: defaultTimeout,
                },
            );
            // we only want to use this data if it is the very latest request for data
            if (requests.unresolvedCorrespondence !== myRequest) {
                return;
            }
            const {rfis, tqs, tdrs, holds, actionItems, needs} = data;
            if (rfis) {
                actions.setRFIs(rfis);
            } else {
                actions.setRFIs([]);
            }
            if (tqs) {
                actions.setTQs(tqs);
            } else {
                actions.setTQs([]);
            }
            if (tdrs) {
                actions.setTDRs(tdrs);
            } else {
                actions.setTDRs([]);
            }
            if (actionItems) {
                actions.setActionItems(actionItems);
            } else {
                actions.setActionItems([]);
            }
            if (needs) {
                actions.setNeeds(needs);
            } else {
                actions.setNeeds([]);
            }
            if (holds) {
                actions.setHolds(holds);
            } else {
                actions.setHolds([]);
            }
        }),
    ),
    getActionItemsExcelExport: thunk((actions, {projectId, projectCode}) =>
        AxiosTryCatchBlock(async () => {
            const success = await axios({
                url: `/DownloadExcelActionItems?id=${projectId}`,
                method: 'GET',
                responseType: 'blob',
            });

            if (success.status === 200) {
                const blob = new Blob([success.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});

                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');

                link.href = url;
                link.download = `${projectCode}_Action-Items.xlsx`;
                link.click();
                window.URL.revokeObjectURL(url);
            }
        }),
    ),
    getClientNeedsExcelExport: thunk((actions, {projectId, projectCode}) =>
        AxiosTryCatchBlock(async () => {
            const success = await axios({
                url: `/DownloadExcelClientNeeds?id=${projectId}`,
                method: 'GET',
                responseType: 'blob',
            });

            if (success.status === 200) {
                const blob = new Blob([success.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});

                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');

                link.href = url;
                link.download = `${projectCode}_Needs.xlsx`;
                link.click();
                window.URL.revokeObjectURL(url);
            }
        }),
    ),
    getRFIsExcelExport: thunk((actions, {projectId, projectCode}) =>
        AxiosTryCatchBlock(async () => {
            const success = await axios({
                url: `/DownloadExcelRFIs?id=${projectId}`,
                method: 'GET',
                responseType: 'blob',
            });

            if (success.status === 200) {
                const blob = new Blob([success.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});

                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');

                link.href = url;
                link.download = `${projectCode}_RFIs.xlsx`;
                link.click();
                window.URL.revokeObjectURL(url);
            }
        }),
    ),
    getTQsExcelExport: thunk((actions, {projectId, projectCode}) =>
        AxiosTryCatchBlock(async () => {
            const success = await axios({
                url: `/DownloadExcelTechnicalQueries?id=${projectId}`,
                method: 'GET',
                responseType: 'blob',
            });

            if (success.status === 200) {
                const blob = new Blob([success.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});

                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');

                link.href = url;
                link.download = `${projectCode}_TQs.xlsx`;
                link.click();
                window.URL.revokeObjectURL(url);
            }
        }),
    ),
};

export default correspondenceModel;

import {IconButton} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useCallback, useEffect, useState} from 'react';
import * as React from 'react';
import { trackEvent } from '~/api';
import DeleteIcon from '~/assets/img/delete-icon.svg';
import ConfirmationDialog from '~/components/generics/ConfirmationDialog';
import {useStoreActions} from '~/store/storeHooks';
import {ProjectTeamMember} from '~/types';


interface DeleteTeamMemberButtonProps {
    selectedMember: ProjectTeamMember;
    handleCancel: () => void;
    isHM: boolean;
}

export default function DeleteTeamMemberButton({selectedMember, handleCancel, isHM}: DeleteTeamMemberButtonProps): JSX.Element {
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [busy, setBusy] = useState(false);
    const deleteMember = useStoreActions((actions) => actions.subsite.deleteTeamMember);
    const {enqueueSnackbar} = useSnackbar();

    const handleDelete = useCallback(async () => {
        setBusy(true);
        trackEvent('delete team member', {id: selectedMember.id, name: selectedMember.person?.fullName});
        const {result, message} = await deleteMember({id: selectedMember.id, projectId: selectedMember.projectId});
        setBusy(false);
        enqueueSnackbar(message, {variant: result});
        if (result !== 'error') {
            handleCancel();
        }
    }, [deleteMember, selectedMember, enqueueSnackbar, handleCancel]);

    useEffect(
        () => (): void => {
            setBusy(false);
        },
        [],
    );

    const handleClick = (e: React.MouseEvent): void => {
        e.stopPropagation();
        setConfirmDialogOpen(true);
    };

    return (
        !isHM && selectedMember?.role?.title !== 'Client Project Manager' && (
            <>
                <IconButton onClick={handleClick} disabled={busy} className="delete-button">
                    <img src={DeleteIcon} alt="delete-team-member-icon-button" />
                </IconButton>
                <ConfirmationDialog
                    open={confirmDialogOpen}
                    title="Confirm Delete Request"
                    message={`Please confirm you wish to remove ${selectedMember.role.title} : ${selectedMember.person.firstName} ${selectedMember.person.lastName}, this process cannot be undone`}
                    onConfirm={handleDelete}
                    onCancel={(): void => setConfirmDialogOpen(false)}
                />
            </>
        )
    );
}

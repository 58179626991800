import {User, updateEmail, reauthenticateWithCredential, EmailAuthProvider} from 'firebase/auth';

export class FBUserAdapter {
    private user: User | null;

    static equals(user1: FBUserAdapter | null, user2: FBUserAdapter | null): boolean {
        if (!user1 || !user2) {
            return false;
        }

        return Object.is(user1.user, user2.user);
    }

    constructor(user: User | null) {
        this.user = user;
    }

    public get displayName(): string | null {
        return this.user.displayName;
    }

    public get email(): string | null {
        return this.user?.email;
    }

    public getIdToken(): Promise<string | null> {
        return this.user?.getIdToken() ?? null;
    }

    public updateEmail(email: string): Promise<void> {
        if (this.user) {
            return updateEmail(this.user, email);
        }

        throw new Error('User is not found.');
    }

    public async reauthenticateWithCredential(email: string, password: string): Promise<boolean> {
        const cred = await reauthenticateWithCredential(this.user, EmailAuthProvider.credential(email, password));
        const verified = this.user.email === cred.user?.email;
        if (verified) {
            this.user = cred.user;
        }

        return verified;
    }
}

import {Box, useMediaQuery} from '@mui/material';
import {
    GridCsvExportOptions,
    GridInitialState,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import {ReactElement} from 'react';
import {DataGridProCustomExport} from './DataGridProCustomExport';
import {DataGridProSearchBar} from './DataGridProSearchBar';
import {DataGridProSettings} from './DataGridProSettings';

DataGridProTableToolbar.defaultProps = {
    csvOptions: null,
    excelOptions: null,
    addButton: null,
    getDefaultGridState: null,
    optionKey: '',
    toolbarHide: {},
    searchBar: false,
};

export function DataGridProTableToolbar(props: {
    csvOptions?: GridCsvExportOptions;
    excelOptions?: GridCsvExportOptions;
    addButton?: ReactElement;
    getDefaultGridState?: () => GridInitialState;
    optionKey?: string;
    toolbarHide?: {[key: string]: boolean};
    searchBar?: boolean;
}) {
    const {csvOptions, excelOptions, addButton, getDefaultGridState, optionKey, toolbarHide, searchBar} = props;
    const mobile = useMediaQuery('(max-width: 674px)');
    return (
        <GridToolbarContainer>
            <Box sx={{width: '100%'}}>
                {addButton}
                {!toolbarHide?.columns && !mobile && <GridToolbarColumnsButton />}
                {!toolbarHide?.filter && <GridToolbarFilterButton />}
                {!toolbarHide?.density && !mobile && <GridToolbarDensitySelector />}
                {/* {!toolbarHide?.export && (!mobile || !addButton) && <GridToolbarExport csvOptions={csvOptions} />} */}
                {!toolbarHide?.export && (!mobile || !addButton) && <DataGridProCustomExport csvOptions={csvOptions} excelOptions={excelOptions} />}
                {!toolbarHide?.settings && <DataGridProSettings getDefaultGridState={getDefaultGridState} optionKey={optionKey} />}
                {searchBar && !mobile && <DataGridProSearchBar optionKey={optionKey} />}
            </Box>
        </GridToolbarContainer>
    );
}

import {FormControlLabel, Radio, RadioGroup, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import {useEffect, useState} from 'react';
import * as React from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useParams} from 'react-router-dom';
import LoadingComponent from '../visualizations/LoadingComponent';
import BusyButton from '~/components/generics/BusyButton';
import RHFController from '~/components/generics/RHFController';
import {useStoreActions, useStoreState} from '~/store/storeHooks';
import styles from '~/styles/Feedback.module.scss';
import {Feedback} from '~/types';

const marks = [
    {
        value: 1,
        label: '1',
    },
    {
        value: 2,
        label: '2',
    },
    {
        value: 3,
        label: '3',
    },
    {
        value: 4,
        label: '4',
    },
    {
        value: 5,
        label: '5',
    },
    {
        value: 6,
        label: '6',
    },
    {
        value: 7,
        label: '7',
    },
    {
        value: 8,
        label: '8',
    },
    {
        value: 9,
        label: '9',
    },
    {
        value: 10,
        label: '10',
    },
];

function valuetext(value: number): string {
    return `${value}`;
}

function valueLabelFormat(value: number): number {
    return marks.findIndex((mark) => mark.value === value) + 1;
}

export default function FeedBackSurvey(): JSX.Element {
    const [statusState, setStatusState] = useState<string>('Loading');
    const {guid} = useParams();
    const [busy, setBusy] = useState(true);
    const [clientSatisfied, setClientSatisfied] = useState('Yes');
    const {getFeedback, postFeedback} = useStoreActions((actions) => actions.feedback);
    const feedback = useStoreState((state) => state.feedback.feedback);

    useEffect(() => {
        if (guid) {
            setStatusState('Loading');
            setBusy(true);
            (async function fetchData(): Promise<void> {
                await getFeedback(guid);
            })();
            setStatusState('Idle');
            setBusy(false);
        }
        return (): void => {
            setBusy(false);
        };
    }, [getFeedback, guid]);

    const methods = useForm<Feedback>({
        defaultValues: feedback,
    });

    const {setValue, handleSubmit, reset, watch} = methods;

    const formId = watch('id');
    const formDisabled = formId > 0;

    useEffect(() => {
        reset(feedback);
    }, [reset, feedback]);

    const onSubmit = handleSubmit(async (data): Promise<void> => {
        if (!guid) {
            return;
        }

        setBusy(true);
        const {result} = await postFeedback({...data, linkNumber: guid});

        if (result === 'error') {
            setBusy(false);
            setStatusState('Error');
        } else {
            setBusy(false);
            setStatusState('Success');
        }
    });

    const handleChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const {value} = event.target as HTMLInputElement;
            setValue('clientSatisfied', value === 'Yes');
            setClientSatisfied(value);
        },
        [setClientSatisfied, setValue],
    );

    const handleRatingChange = (event: Event, newValue: number | number[]): void => {
        if (typeof newValue === 'number') {
            setValue('clientRating', newValue);
        }
    };

    const displayLoading = () => (
        <>
            <LoadingComponent title="Loading Survey" />
        </>
    );

    const displayError = () => (
        <div className={styles.error}>
            <Typography align="center" className={styles.header} gutterBottom>
                This survey is no longer available.
            </Typography>
        </div>
    );

    const displayThanks = () => (
        <div className={styles.success}>
            <Typography align="center" className={styles.header} gutterBottom>
                Thank you for your feedback!
            </Typography>
            <div>
                <Box my={2} className={styles.thankYou}>
                    <Typography align="center" variant="subtitle1">
                        Your feedback is very important to us and will help us to improve our services.
                    </Typography>
                    <Typography align="center" variant="subtitle1">
                        You may now close this window.
                    </Typography>
                </Box>
            </div>
        </div>
    );

    const displaySurvey = () => (
        <>
            <Typography align="center" className={styles.header} gutterBottom>
                Project Feedback Survey
            </Typography>

            <Typography align="center" className={styles.subheader} gutterBottom>
                {feedback?.projectCode !== null && feedback?.projectTitle !== null ? `${feedback?.projectCode} - ${feedback?.projectTitle}` : ''}
            </Typography>

            <Typography align="center" className={styles.subheader} gutterBottom>
                {feedback.clientName || ''}
            </Typography>

            <div>
                <FormProvider {...methods}>
                    <form onSubmit={onSubmit}>
                        <Box component="fieldset" sx={{width: '100%'}} borderColor="transparent" className={styles.ratingStarBox}>
                            <p className={styles.description}>
                                On a scale of 1-10, with 1 being not at all likely and 10 being very likely, would you recommend H+M to a friend or
                                colleague?
                            </p>
                            <RHFController
                                render={({field}): JSX.Element => (
                                    <Slider
                                        {...field}
                                        name="client-rating"
                                        aria-label="Restricted value"
                                        step={null}
                                        valueLabelFormat={valueLabelFormat}
                                        getAriaValueText={valuetext}
                                        valueLabelDisplay="on"
                                        marks={marks}
                                        onChange={handleRatingChange}
                                        min={1}
                                        max={10}
                                        track={false}
                                        disabled={formDisabled}
                                    />
                                )}
                                name="clientRating"
                                rules={{
                                    required: true,
                                }}
                            />
                        </Box>
                        <Box component="fieldset" mb={3} borderColor="transparent">
                            <p className={styles.description}>
                                Is there anything, positive or negative, that you would like to share with us about your experience?
                            </p>
                            <RHFController
                                render={({field}): JSX.Element => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        id="client-comments"
                                        // variant="filled"
                                        multiline
                                        rows={8}
                                        placeholder=""
                                        className={styles.commentsBox}
                                        disabled={formDisabled}
                                    />
                                )}
                                name="clientComments"
                            />
                        </Box>
                        <Box component="fieldset" mb={3} borderColor="transparent">
                            <p className={styles.description}>Lessons to be carried into future projects to improve project success.</p>
                            <RHFController
                                render={({field}): JSX.Element => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        id="lessons-learned"
                                        // variant="filled"
                                        multiline
                                        rows={8}
                                        placeholder=""
                                        className={styles.commentsBox}
                                        disabled={formDisabled}
                                    />
                                )}
                                name="clientLessonsLearned"
                            />
                        </Box>
                        <Box component="fieldset" mb={3} borderColor="transparent">
                            <p className={styles.description}>Are you satisfied with our overall performance?</p>
                            <RHFController
                                render={(): JSX.Element => (
                                    <RadioGroup
                                        aria-label="client-satisfied"
                                        name="client-satisfied"
                                        value={clientSatisfied}
                                        onChange={handleChange}
                                        row
                                        className={styles.satisfactionRadioGroup}>
                                        <FormControlLabel value="Yes" control={<Radio disabled={formDisabled} />} label="Yes" />
                                        <FormControlLabel value="No" control={<Radio disabled={formDisabled} />} label="No" />
                                    </RadioGroup>
                                )}
                                name="clientSatisfied"
                            />
                        </Box>
                        <div>
                            <Box my={2} className={styles.thankYou}>
                                <Typography align="center" variant="subtitle1">
                                    Thank you in advance for your valued feedback.
                                </Typography>
                            </Box>
                        </div>
                        {!formDisabled && (
                            <div className={styles.submit}>
                                <BusyButton busy={busy} type="submit" className="save-button" id="save-client-rating-button">
                                    Save
                                </BusyButton>
                            </div>
                        )}
                    </form>
                </FormProvider>
            </div>
        </>
    );

    return (
        <div id="close-out-page-root" className={styles.root}>
            {statusState === 'Loading' && displayLoading()}
            {statusState === 'Error' && displayError()}
            {statusState === 'Idle' && displaySurvey()}
            {statusState === 'Success' && displayThanks()}
        </div>
    );
}

import {createContext} from 'react';
import {Comment} from '~/types';

type ChatHistoryContextType = {
    currentUser: number;
    selectedComment: Comment;
    setSelectedComment: (comment: Comment) => void;
    mobile: boolean;
    target: string;
};

const ChatHistoryContext = createContext<ChatHistoryContextType>({
    currentUser: null,
    selectedComment: null,
    setSelectedComment: () => true,
    mobile: true,
    target: null,
});

export default ChatHistoryContext;

import {Button, ClickAwayListener, Grow, Popper} from '@mui/material';
import {usePopupState, bindToggle, bindPopper} from 'material-ui-popup-state/hooks';
import {useCallback} from 'react';
import * as React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useAuth} from '~/api';
import {OnEnterPressWrapper} from '~/helpers';
import styles from '~/styles/AccountNav.module.scss';

export default function AccountNav({handleClose}: {handleClose: ()=>void;}): JSX.Element {
    const auth = useAuth();
    const navigate = useNavigate();
    const popupState = usePopupState({variant: 'popper', popupId: 'accountMenu'});
    const [arrowRef, setArrowRef] = React.useState(null);

    const handleOptionSelected = (): void => {
        popupState.close();
        handleClose();
    };

    const handleClickAway = useCallback((): void => {
        popupState.close();
    }, [popupState]);

    const signOut = useCallback((): void => {
        auth.signOut(() => {
            navigate('/');
        });
    }, [auth, navigate]);

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className={styles.root}>
                <Button variant="contained" {...bindToggle(popupState)} className={`${styles.menuButton} ${popupState.isOpen ? styles.open : ''}`}>
                    Account
                </Button>
                <Popper
                    {...bindPopper(popupState)}
                    transition
                    placement="left-start"
                    disablePortal
                    modifiers={[
                        {
                            name: 'arrow',
                            enabled: true,
                            options: {
                                element: arrowRef,
                            },
                        },
                    ]}
                    className={styles.popper}
                >
                    {({TransitionProps}): JSX.Element => (
                        <Grow {...TransitionProps}>
                            <div>
                                <span className={styles.arrow} ref={setArrowRef} />
                                <div className={styles.menuContainer}>
                                    <Link to="/profile" onClick={handleOptionSelected}>
                                        <div className={styles.doubleWidthMenuItem}>
                                            <p>Update Profile</p>
                                        </div>
                                    </Link>
                                    <Link to="/settings" onClick={handleOptionSelected}>
                                        <div className={styles.doubleWidthMenuItem}>
                                            <p>Settings</p>
                                        </div>
                                    </Link>
                                    <div
                                        className={styles.doubleWidthMenuItem}
                                        onClick={signOut}
                                        onKeyDown={OnEnterPressWrapper(signOut)}
                                        role="button"
                                        tabIndex={0}
                                    >
                                        <p>Sign Out</p>
                                    </div>
                                </div>
                            </div>
                        </Grow>
                    )}
                </Popper>
            </div>
        </ClickAwayListener>
    );
}

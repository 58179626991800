import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Zoom} from '@mui/material';
import round from 'lodash/round';
import {useContext, useEffect, useMemo, useCallback} from 'react';
import * as React from 'react';
import {BlockMath} from 'react-katex';
import ProjectContext from '~/components/dashboard/project-log/ProjectContext';
import HealthCriteriaTable from '~/components/subsite/health/HealthCriteriaTable';
import HealthReportCardContext from '~/components/subsite/health/HealthReportCardContext';
import {useStoreActions, useStoreState} from '~/store/storeHooks';
import styles from '~/styles/ProjectHealthDetailDialog.module.scss';

interface ProjectHealthDetailDialogProps {
    open: boolean;
    category: string;
    title: string;
    result: {title: string; color: string; score: number | string};
    onCloseCallback: () => void;
}

const DEFAULT_CALCULATIONS = {
    overall: '\\text{Budget Score} + \\text{Schedule Score}',
    hse: '\\frac{0.02TI_{0} + 0.05TI_{1} + 0.50TI_{2} + TI_{3} + 0.01TU_{0} + 0.025TU_{1} + 0.25TU_{2} + 0.5TU_{3} - 0.005(\\sum{\\text{Incidents}} + \\sum{\\text{Unwanted Events}} + \\sum{\\text{Good Catches}})}{\\text{Actual Hours / 100,000}}',
    schedule: '\\frac{{\\text{Cumulative Percent Complete}}}{\\text{Cumulative Planned Percent Complete}}',
    budget: '\\frac{{\\text{Actual \\% Complete}}*({\\text{Original Budget}} + \\sum{\\text{Change Order}})}{\\text{Actual Cost (Minus Pre-bid costs)}}',
};

export default function ProjectHealthDetailDialog({open, category, title, result, onCloseCallback}: ProjectHealthDetailDialogProps): JSX.Element {
    const {project} = useContext(ProjectContext);
    const standardHealthCriteria = useStoreState((state) => state.project.standardHealthCriteria);
    const getStandardHealthCriteria = useStoreActions((actions) => actions.project.getStandardHealthCriteria);

    const data = useMemo((): {formula: string; filterCategory: string} => {
        switch (category) {
            case 'overall':
                return {formula: DEFAULT_CALCULATIONS.overall, filterCategory: 'Overall'};
            case 'hse':
                return {formula: DEFAULT_CALCULATIONS.hse, filterCategory: 'HSE'};
            case 'schedule':
                return {formula: DEFAULT_CALCULATIONS.schedule, filterCategory: 'Schedule'};
            case 'budget':
                return {formula: DEFAULT_CALCULATIONS.budget, filterCategory: 'Budget'};
            default:
                return {formula: 'Missing Formula', filterCategory: 'Invalid'};
        }
    }, [category]);

    useEffect(() => {
        if (standardHealthCriteria.length === 0) {
            getStandardHealthCriteria();
        }
    }, [getStandardHealthCriteria, standardHealthCriteria.length]);
    const healthReportCardContext = useMemo(
        () => ({
            healthCriteria:
                project && project.healthCriteria.length > 0
                    ? project.healthCriteria
                    : standardHealthCriteria.filter((c) => c.category === data.filterCategory),
        }),
        [data, project, standardHealthCriteria],
    );

    const score = useMemo(() => {
        if (typeof result.score === 'string') {
            if (result.score === 'NA') {
                return 'N/A';
            }
            return round(parseFloat(result.score), 2);
        }
        if (typeof result.score === 'number') {
            if (Number.isNaN(result.score)) {
                return 'N/A';
            }
        }
        return round(result.score, 2);
    }, [result]);

    const handleClose = useCallback(
        (e: React.UIEvent): void => {
            e.stopPropagation();
            onCloseCallback();
        },
        [onCloseCallback],
    );

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="lg"
            fullWidth
            id="project-health-detail-dialog-root"
            className={styles.root}
            TransitionComponent={Zoom}
        >
            <DialogTitle id="project-health-detail-dialog-title">{title}</DialogTitle>
            <DialogContent id="project-health-detail-dialog-content">
                <HealthReportCardContext.Provider value={healthReportCardContext}>
                    <HealthCriteriaTable />
                </HealthReportCardContext.Provider>
                <div className={styles.calculationContainer}>
                    <p>Score Calculation</p>
                    <BlockMath math={data.formula} />
                </div>
                <div className={styles.resultContainer}>
                    <div>
                        <p>Score</p>
                        <p className={styles.score}>{score < 0 ? 0 : score}</p>
                    </div>
                    <Divider orientation="vertical" flexItem />
                    <div>
                        <p>Grade</p>
                        <FontAwesomeIcon icon={['fas', 'circle']} color={result.color} />
                    </div>
                </div>
            </DialogContent>
            <DialogActions id="project-health-detail-dialog-actions">
                <Button onClick={handleClose} className="close-button" id="project-health-detail-close-button">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

import {Button, TextField, Zoom} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {useSnackbar} from 'notistack';
import {useCallback, useMemo, useEffect, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {trackEvent} from '~/api';
import BusyButton from '~/components/generics/BusyButton';
import RHFController from '~/components/generics/RHFController';
import {useStoreActions} from '~/store/storeHooks';
import styles from '~/styles/UserFeedbackDialog.module.scss';
import {UserFeedback} from '~/types';

interface NewDocumentDialogProps {
    category: string;
    open: boolean;
    handleClose: () => void;
    categoryChange: (value: string) => void;
}

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

export default function UserFeedbackDialog({category, categoryChange, open, handleClose}: NewDocumentDialogProps): JSX.Element {
    const postUserFeedback = useStoreActions((actions) => actions.shared.postUserFeedback);
    const {enqueueSnackbar} = useSnackbar();

    const DEFAULT_NEW_FEEDBACK: UserFeedback = useMemo(
        () => ({
            category,
            title: '',
            description: '',
        }),
        [category],
    );
    const methods = useForm<UserFeedback>({
        defaultValues: DEFAULT_NEW_FEEDBACK,
    });

    const {setValue, handleSubmit, reset} = methods;
    const [busy, setBusy] = useState(false);

    const handleCancel = useCallback(() => {
        reset(DEFAULT_NEW_FEEDBACK);
        handleClose();
    }, [reset, handleClose, DEFAULT_NEW_FEEDBACK]);

    useEffect(() => {
        setValue('category', category);
    }, [category, setValue]);

    const onSubmit = handleSubmit(async (data: UserFeedback): Promise<void> => {
        setBusy(true);
        trackEvent('user feedback', data);

        const {result, message} = await postUserFeedback({
            ...data,
        });

        setBusy(false);

        if (result !== 'error') {
            handleCancel();
        } else {
            enqueueSnackbar(message, {variant: result});
        }
    });

    const handleChange = (newVal: string): void => {
        categoryChange(newVal);
        setValue('category', newVal);
    };

    return (
        <ThemeProvider theme={darkTheme}>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="md"
                fullWidth
                id="user-feedback-dialog-root"
                className={styles.root}
                TransitionComponent={Zoom}>
                <FormProvider {...methods}>
                    <form onSubmit={onSubmit}>
                        <DialogTitle id="user-feedback-dialog-title">
                            <h4>User Feedback</h4>
                            <p>All fields required</p>
                        </DialogTitle>
                        <DialogContent id="user-feedback-dialog-content">
                            <RHFController
                                render={({field}): JSX.Element => (
                                    <Autocomplete
                                        {...field}
                                        options={['I like something', 'I do not like something', 'I have a suggestion']}
                                        renderInput={(params): JSX.Element => (
                                            <TextField label="Feedback" fullWidth variant="standard" {...params} required />
                                        )}
                                        onChange={(_, val): void => handleChange(val as string)}
                                    />
                                )}
                                name="category"
                                rules={{
                                    required: true,
                                }}
                            />
                            <RHFController
                                render={({field}): JSX.Element => <TextField {...field} fullWidth label="Title *" multiline />}
                                name="title"
                                rules={{
                                    required: true,
                                }}
                            />
                            <RHFController
                                render={({field}): JSX.Element => <TextField {...field} fullWidth label="Description *" multiline />}
                                name="description"
                                rules={{
                                    required: true,
                                }}
                            />
                        </DialogContent>
                        <DialogActions id="user-feedback-dialog-actions">
                            <Button onClick={handleCancel} className="close-button" id="cancel-user-feedback-button">
                                Cancel
                            </Button>
                            <BusyButton busy={busy} type="submit" className="save-button" id="save-user-feedback-button">
                                Save
                            </BusyButton>
                        </DialogActions>
                    </form>
                </FormProvider>
            </Dialog>
        </ThemeProvider>
    );
}

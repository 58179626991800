import {useMediaQuery} from '@mui/material';
import {ApexOptions} from 'apexcharts';
import numeral from 'numeral';
import Chart from 'react-apexcharts';
import NoDataPlaceholder from '~/components/generics/NoDataPlaceholder';
import {useStoreState} from '~/store/storeHooks';
import type {ReactApexChartType} from '~/types';

const MOBILE_HEIGHT = '180px';

interface ProjectValueByStatusChartProps {
    chartType?: ReactApexChartType;
}

ProjectValueByStatusChart.defaultProps = {
    chartType: 'donut',
};

export default function ProjectValueByStatusChart({chartType}: ProjectValueByStatusChartProps): JSX.Element {
    const mobile = useMediaQuery('(max-width: 600px)');
    const {labels, series} = useStoreState((state) => state.project.projectValueByStatusChartData);

    const mobileOptions: ApexOptions = {
        chart: {
            background: 'transparent',
            toolbar: {
                show: false,
            },
            fontFamily: 'Gotham Book, Roboto, Helvetica, sans-serif',
            parentHeightOffset: 0,
        },
        theme: {
            mode: 'dark',
            palette: 'palette1',
        },
        labels,
        plotOptions: {
            pie: {
                customScale: mobile ? 0.8 : 1,
                offsetX: mobile ? -80 : -120,
                offsetY: 20,
                donut: {
                    size: mobile ? '90%' : '94%',
                    labels: {
                        show: true,
                        name: {
                            fontSize: '8px',
                            offsetY: -10,
                        },
                        value: {
                            offsetY: 0,
                            formatter: (value): string => `$${numeral(value).format('0,0.0a')}`,
                        },
                        total: {
                            show: true,
                            fontSize: mobile ? '10px' : '1rem',
                            formatter(w): string {
                                const total = w.globals.seriesTotals.reduce((a: number, b: number): number => a + b, 0);
                                return `$${numeral(total).format(total < 1000000 ? '0,0a' : '0,0.0a')}`;
                            },
                        },
                    },
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: false,
        },
        tooltip: {
            enabled: false,
        },
        legend: {
            position: 'right',
            horizontalAlign: 'left',
            width: mobile ? 140 : 200,
            fontSize: mobile ? '8px' : '10px',
            floating: true,
            markers: {
                height: 8,
                width: 8,
            },
        },
    };

    return series.length > 0 ? <Chart height={MOBILE_HEIGHT} type={chartType} series={series} options={mobileOptions} /> : <NoDataPlaceholder />;
}

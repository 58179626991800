import {createContext} from 'react';

type ProjectLogListContextType = {
    setSize: (index: number, size: number) => void;
};

const ProjectLogListContextDefaultValue = {
    setSize: (): void => null,
};

const ProjectLogListContext = createContext<ProjectLogListContextType>(ProjectLogListContextDefaultValue);

export default ProjectLogListContext;

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useMediaQuery} from '@mui/material';
import Grid from '@mui/material/Grid';
import numeral from 'numeral';
import {ReactNode, useContext, useEffect, useMemo} from 'react';
import {Link} from 'react-router-dom';
import ProjectContext from '~/components/dashboard/project-log/ProjectContext';
import {CPPValidStatus} from '~/helpers';
import {usePermissions} from '~/hooks/usePermissions';
import useProjectHealth from '~/hooks/useProjectHealth';
import {useStoreActions, useStoreState} from '~/store/storeHooks';
import styles from '~/styles/ProjectStack.module.scss';

export default function ProjectInfoCard(): JSX.Element {
    const mobile = useMediaQuery('(max-width: 600px)');
    const {project} = useContext(ProjectContext);
    const {clientPm, pm, status, projectHealth, foundationJob, surveyAvailable} = project;
    const currentUser = useStoreState((state) => state.shared.currentUser);
    const {originalContract, totalApprovedIncomeAdj, tm} = foundationJob || {};
    const {calculateOverallGrade, calculateHSEGrade, calculateBudgetScore, calculateScheduleScore} = useProjectHealth();
    const overallGrade = calculateOverallGrade(project);
    const currentContractValue = (originalContract ?? 0) + (totalApprovedIncomeAdj ?? 0);
    const standardHealthCriteria = useStoreState((state) => state.project.standardHealthCriteria);
    const getStandardHealthCriteria = useStoreActions((actions) => actions.project.getStandardHealthCriteria);
    const view = usePermissions();

    const projectStatus = surveyAvailable ? 'Survey Available' : CPPValidStatus(status.status);
    useEffect(() => {
        if (standardHealthCriteria.length === 0) {
            getStandardHealthCriteria();
        }
    }, [getStandardHealthCriteria, standardHealthCriteria.length]);

    const healthCriteria = useMemo(
        () => (project && project.healthCriteria.length > 0 ? project.healthCriteria : standardHealthCriteria.filter((c) => c.category === 'HSE')),
        [project, standardHealthCriteria],
    );

    const scheduleScore = calculateScheduleScore(project);
    const budgetScore = calculateBudgetScore(project);
    const hseGrade = calculateHSEGrade(projectHealth, foundationJob, healthCriteria);

    let mobileGrade: ReactNode | null = null;
    if (mobile) {
        if (overallGrade.indicator.label !== 'Pending') {
            mobileGrade = (
                <div className={styles.healthContainer}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <p>HSE</p>
                        </Grid>
                        <Grid item xs={4}>
                            <p>Budget</p>
                        </Grid>
                        <Grid item xs={4}>
                            <p>Schedule</p>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <FontAwesomeIcon icon={['fas', 'circle']} style={{color: hseGrade.indicator.color}} size="2x" />
                        </Grid>
                        <Grid item xs={4}>
                            <FontAwesomeIcon icon={['fas', 'circle']} style={{color: budgetScore.indicator.color}} size="2x" />
                        </Grid>
                        <Grid item xs={4}>
                            <FontAwesomeIcon icon={['fas', 'circle']} style={{color: scheduleScore.indicator.color}} size="2x" />
                        </Grid>
                    </Grid>
                </div>
            );
        } else {
            mobileGrade = (
                <div className={styles.healthContainer}>
                    <p>Health</p>
                    <div className={styles.healthIndicator} style={{backgroundColor: `${overallGrade.indicator.color}`}}>
                        <p>{overallGrade.indicator.label.toUpperCase()}</p>
                    </div>
                </div>
            );
        }
    }

    const href: string = `/project/${project.id}/summary`;
    const samePage: boolean = href === window.location.pathname;

    return (
        <div className={styles.projectInfoCardRoot}>
            <h3 className={styles.title}>{samePage ? project.title : <Link to={href}>{project.title}</Link>}</h3>
            <p>{project.code}</p>
            <p>{project.clientProjectCode ? `AFE # | ${project.clientProjectCode}` : null}</p>
            <p>{`Status | ${projectStatus}`}</p>
            {pm && <p>{`H+M Project Manager | ${pm.fullName}`}</p>}
            {currentUser?.id !== clientPm?.person?.id && <p>{`Project Manager | ${clientPm?.person?.fullName}`}</p>}
            {currentContractValue > 0 && view && (
                <p>{`Contract | ${tm ? 'T&M' : 'Fixed Cost'} | ${numeral(currentContractValue).format(
                    currentContractValue > 1000000 ? '$0,0.0a' : '$0,0a',
                )}`}</p>
            )}
            <p>Scope of Service | {project.category?.category}</p>
            {mobileGrade}
        </div>
    );
}

import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {motion} from 'framer-motion';
import {createContext, useRef, useState} from 'react';
import Swiper, {ReactIdSwiperChildren, ReactIdSwiperProps} from 'react-id-swiper';
import {listItemVariants} from '~/styles/animations/__animationVariants';
import styles from '~/styles/SwipeStack.module.scss';

interface SwipeStackProps {
    children: ReactIdSwiperChildren;
    id?: string;
}

SwipeStack.defaultProps = {
    id: '',
};

export const StackContext = createContext(0);

export default function SwipeStack({children, id}: SwipeStackProps): JSX.Element {
    const swiperRef = useRef(null);
    const [activeIndex, setActiveIndex] = useState(0);

    const params: ReactIdSwiperProps = {
        slidesPerView: 1,
        spaceBetween: 15,
        mousewheel: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: false,
        },
        on: {
            slideChange: (): void => {
                if (swiperRef.current) {
                    setActiveIndex(swiperRef.current.swiper.activeIndex);
                }
            },
        },
        observer: true, // Required for dynamic number of cards, otherwise number of cards & card index will not be correct
        cssMode: true, // Not ideal but the swiping action will stutter without this, unfortunately its a bit wild with this option on
        navigation: {
            nextEl: `#swiper-button-next-${id}`,
            prevEl: `#swiper-button-prev-${id}`,
        },
        renderPrevButton: () => (
            <span id={`swiper-button-prev-${id}`} className={styles.swiperButtonPrev}>
                <NavigateBeforeIcon />
            </span>
        ),
        renderNextButton: () => (
            <span id={`swiper-button-next-${id}`} className={styles.swiperButtonNext}>
                <NavigateNextIcon />
            </span>
        ),
    };

    return (
        <motion.div variants={listItemVariants} initial="closed" animate="open" className={styles.root} id={id}>
            <StackContext.Provider value={activeIndex}>
                <Swiper {...params} ref={swiperRef}>
                    {children}
                </Swiper>
            </StackContext.Provider>
        </motion.div>
    );
}

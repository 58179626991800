import {AppBar, Toolbar, useMediaQuery} from '@mui/material';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useLocation, useMatch} from 'react-router';
import {Link, useSearchParams} from 'react-router-dom';
import cppLogoSmall from '../../../assets/img/cpp-logo-dark-mode.svg';
import cppLogoExtended from '../../../assets/img/cpp-logo-extended.svg';
import hmLogo from '../../../assets/img/hm-logo-white.svg';
import NavBarContext from './NavBarContext';
import { ProjectsNav } from './ProjectsNav';
import UserMenu from './UserMenu';
import NavMenu from '~/components/app/navmenu/NavMenu';
import NotificationButton from '~/components/app/notification/NotificationButton';
import ChatButton from '~/components/chat/ChatButton.jsx';
import TimelineButton from '~/components/timeline/TimelineButton';
import { usePermissions } from '~/hooks/usePermissions';
import { useStoreState } from '~/store/storeHooks';
import styles from '~/styles/NavBar.module.scss';

export function RootNav() {
    return (
        <div className={styles.dashboardOptionsContainer}>
            <NotificationButton />
            <UserMenu/>
        </div>
    );
}

export function ProjectNav({projectId, page}: {projectId: string; page: string}) {
    const validTabWidth = useMediaQuery('(min-width: 1360px)');
    const largeScreen = useMediaQuery('(min-width: 1590px)')
    const getNavStyle = useCallback((check: string) => `${styles.menuFolderTab} ${check === page && styles.currentPage} ${largeScreen && styles.largeScreen}`, [page,largeScreen]);
    const project = useStoreState((state) => state.subsite.project);
    const shortTitle = useMediaQuery(`(max-width: ${(project?.surveyAvailable || project?.id === 536) ? '1480px' : '1368px'})`);
    const view = usePermissions();
    const {pathname} = useLocation();
    const isProjectPage = pathname.startsWith('/project');
    const validDrawerWidth = useMediaQuery('(max-width: 1360px)');

    return (
        <div className={styles.optionsContainer}>
            <ProjectsNav/>
            {validTabWidth && (
                <div className={styles.menuOptions}>
                    {
                        validTabWidth && (project?.surveyAvailable || project?.id === 536) 
                        ?
                        <Link to={`project/${projectId}/closeout`} className={getNavStyle('closeout')}>
                            Survey
                        </Link>
                        :
                        ''
                    }
                    <Link to={`project/${projectId}/summary`} className={getNavStyle('summary')}>
                        Summary
                    </Link>
                    <Link to={`project/${projectId}/hse`} className={getNavStyle('hse')}>
                        HSE
                    </Link>
                    <Link to={`project/${projectId}/progress`} className={getNavStyle('progress')}>
                        Progress
                    </Link>
                    <Link to={`project/${projectId}/deliverables`} className={getNavStyle('deliverables')}>
                        Deliverables
                    </Link>
                    {view &&
                        <Link to={`project/${projectId}/change`} className={getNavStyle('change')}>
                            {shortTitle ? 'Chg Mgmt' : 'Change Management'}
                        </Link>
                    }
                    <Link to={`project/${projectId}/correspondence`} className={getNavStyle('correspondence')}>
                        Correspondence
                    </Link>
                </div>
            )}
            <div className={styles.iconContainer}>
                <NotificationButton/>
                <ChatButton target="global" />
                <TimelineButton />
                {isProjectPage && validDrawerWidth && <NavMenu />}
                <UserMenu/>
            </div>
        </div>
    );
}

function ProjectMatch() {
    const match = useMatch('/project/:projectId/:page');

    if (!match) {
        return <RootNav />;
    }

    const {projectId, page} = match.params;
    return <ProjectNav projectId={projectId} page={page} />;
}

let scrollPos = 0;

export default function NavBar(): JSX.Element {
    const extendedLogoBreakpoint = useMediaQuery('(min-width: 810px)');
    const validRightLogoWidth = useMediaQuery('(min-width: 1660px)');
    const [searchParams,setSearchParams] = useSearchParams();
    const shouldOpenMessages = searchParams.get('openmessages');
    const [expanded,setExpanded] = useState(shouldOpenMessages ? 'chat' : '');

    useEffect(()=>{
        if (shouldOpenMessages) {
            searchParams.delete('openmessages');
            setSearchParams(searchParams);
            setExpanded('chat');
        }
    },[shouldOpenMessages,searchParams,setSearchParams,setExpanded])

    const navBarContext = useMemo(() => ({
        expanded,
        setExpanded,
    }),
    [expanded, setExpanded],
    );

    // Disable main body scrollbar if chat, notification or timeline is expanded
    useEffect(() => {
        if (expanded) {
            scrollPos = window.pageYOffset;
            document.body.style.position = 'fixed';
            document.body.style.top = `-${scrollPos}px`;
            setTimeout(()=>{document.body.style.overflow = 'visible'},0);
        } else {
            document.body.style.position = '';
            document.body.style.top = '';
            window.scrollTo(0,scrollPos);
        }
    }, [expanded]);

    return (
        <AppBar className={styles.root} role="navigation">
            <Toolbar>
                <Link to="/" aria-label="Capital Projects Portal">
                    <img src={extendedLogoBreakpoint ? cppLogoExtended : cppLogoSmall} className={styles.logo} alt="cpp-logo" />
                </Link>
                <NavBarContext.Provider value={navBarContext}>
                    <ProjectMatch />
                </NavBarContext.Provider>
                {validRightLogoWidth && <img src={hmLogo} className={styles.inactiveLogo} alt="hm-logo-white" />}
                
            </Toolbar>
        </AppBar>
    );
}

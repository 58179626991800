import type {ReactNode, UIEvent} from 'react';
import {OnEnterPressWrapper} from '~/helpers';
import styles from '~/styles/KPIContainer.module.scss';

interface KPIGridProps {
    clickable?: boolean;
    onClick?: (e: UIEvent) => void;
    className?: string;
    children: ReactNode;
}

KPIGrid.defaultProps = {
    clickable: false,
    onClick: (_: UIEvent) => {},
    className: '',
};

export default function KPIGrid(props: KPIGridProps): JSX.Element {
    const {children, className, onClick, clickable} = props;

    return (
        <div
            onClick={onClick}
            onKeyDown={OnEnterPressWrapper(onClick)}
            role="button"
            tabIndex={0}
            className={`${styles.grid} ${clickable ? styles.clickable : ''} ${className}`}
        >
            {children}
        </div>
    );
}

import {useMediaQuery} from '@mui/material';
import {useContext} from 'react';
import {Link} from 'react-router-dom';
import ProjectContext from '~/components/dashboard/project-log/ProjectContext';
import ProgressAreaChart from '~/components/visualizations/ProgressAreaChart';
import { useStoreState } from '~/store/storeHooks';
import styles from '~/styles/ProjectStack.module.scss';

export default function ProgressCard(): JSX.Element {
    const {project} = useContext(ProjectContext);
    const mobile = useMediaQuery('(max-width: 600px)');
    const progressChartSeries = useStoreState((state) => state.project.progressChartSeries);
    const {series} = progressChartSeries(+project.id);
    return (
        <div className={styles.progressCardRoot}>
            <h3 className={styles.title}>
                {
                    series?.length === 0
                    ?
                    `${mobile ? 'project.code' : ''} Progress`
                    :
                    <Link to={`/project/${project.id}/progress`}>{mobile ? `${project.code}` : null} Progress</Link>
                }
            </h3>
            <ProgressAreaChart projectId={project.id} projectCode={project.code} />
        </div>
    );
}

import {Button, ClickAwayListener, Grow, Popper} from '@mui/material';
import {usePopupState, bindToggle, bindPopper} from 'material-ui-popup-state/hooks';
import {useCallback} from 'react';
import * as React from 'react';
import comingSoonLogo from '~/assets/img/coming-soon-logo.svg';
import styles from '~/styles/ResourcesNav.module.scss';

export default function ResourcesNav({handleClose}: {handleClose: ()=>void;}): JSX.Element {
    const popupState = usePopupState({variant: 'popper', popupId: 'contactMenu'});
    const [arrowRef, setArrowRef] = React.useState(null);

    const handleOptionSelected = (): void => {
        popupState.close();
        handleClose();
    };
    const handleClickAway = useCallback(() => {
        popupState.close();
    }, [popupState]);

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className={styles.root}>
                <Button variant="contained" {...bindToggle(popupState)} className={`${styles.menuButton} ${popupState.isOpen ? styles.open : ''}`}>
                    Resources
                </Button>
                <Popper
                    {...bindPopper(popupState)}
                    transition
                    placement="left-start"
                    disablePortal
                    modifiers={[
                        {
                            name: 'arrow',
                            enabled: true,
                            options: {
                                element: arrowRef,
                            },
                        },
                    ]}
                    className={styles.popper}
                >
                    {({TransitionProps}): JSX.Element => (
                        <Grow {...TransitionProps}>
                            <div>
                                <span className={styles.arrow} ref={setArrowRef} />
                                <div className={styles.menuContainer}>
                                    <div className={styles.doubleWidthMenuItem}
                                        onClick={handleOptionSelected}
                                        onKeyDown={handleOptionSelected}
                                        role="button"
                                        tabIndex={0}>
                                        <img src={comingSoonLogo} alt="coming-soon-icon-button" className={styles.comingSoonIcon} />
                                        <p>Training</p>
                                    </div>
                                    <div className={styles.doubleWidthMenuItem}
                                        onClick={handleOptionSelected}
                                        onKeyDown={handleOptionSelected}
                                        role="button"
                                        tabIndex={0}>
                                        <img src={comingSoonLogo} alt="coming-soon-icon-button" className={styles.comingSoonIcon} />
                                        <p>Library</p>
                                    </div>
                                </div>
                            </div>
                        </Grow>
                    )}
                </Popper>
            </div>
        </ClickAwayListener>
    );
}

import {Checkbox, FormControlLabel, Switch} from '@mui/material';
import {useCallback, useEffect, useState} from 'react';
import * as React from 'react';
import {useTrackPage} from '~/api';
import LoadingComponent from '~/components/visualizations/LoadingComponent';
import {useStoreActions, useStoreState} from '~/store/storeHooks';
import styles from '~/styles/SettingsPage.module.scss';

export default function NotificationSettingsPage(): JSX.Element {
    const [busy, setBusy] = useState(false);
    const getNotificationSettings = useStoreActions((actions) => actions.activity.getNotificationSettings);
    const updateCategoryNotification = useStoreActions((actions) => actions.activity.updateCategoryNotification);
    const updateDeliveryMethod = useStoreActions((actions) => actions.activity.updateDeliveryMethod);
    const deliveryMethod = useStoreState((state) => state.activity.notificationDeliveryMethod);
    const notificationCategories = useStoreState((state) => state.activity.notificationCategories);

    useTrackPage('Notification Settings');

    useEffect(() => {
        (async function fetchInitial(): Promise<void> {
            setBusy(true);
            await getNotificationSettings();
            setBusy(false);
        })();
        return (): void => setBusy(false);
    }, [getNotificationSettings]);

    const handleChange = useCallback(
        (category: string) => {
            updateCategoryNotification(category);
        },
        [updateCategoryNotification],
    );

    const handleDeliveryMethodChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
            updateDeliveryMethod({...deliveryMethod, [e.target.name]: checked});
        },
        [deliveryMethod, updateDeliveryMethod],
    ) as (e: React.ChangeEvent<{}>, checked: boolean) => void;

    return (
        <>
            <div id="notification-settings-page-root" className={styles.root}>
                {busy ? (
                    <LoadingComponent title="Loading Notification Settings" />
                ) : (
                    <>
                        <h4 className={styles.header}>Notification Settings</h4>
                        <div id="notification-delivery-methods" className={styles.deliveryMethodContainer}>
                            <h5 className={styles.subheader}>Delivery Method</h5>
                            <p>Select how you would like notifications to be delivered</p>
                            <FormControlLabel
                                checked={deliveryMethod.email}
                                control={<Checkbox />}
                                label="Email"
                                name="email"
                                className={styles.deliveryMethodOption}
                                onChange={handleDeliveryMethodChange}
                            />
                            {/* <FormControlLabel*/}
                            {/*    checked={deliveryMethod.push}*/}
                            {/*    control={<Checkbox />}*/}
                            {/*    label={'Push Notifications'}*/}
                            {/*    name="push"*/}
                            {/*    className={styles.deliveryMethodOption}*/}
                            {/*    onChange={handleDeliveryMethodChange}*/}
                            {/*/ >*/}
                        </div>
                        <div id="notification-categories" className={styles.categoriesContainer}>
                            <h5 className={styles.subheader}>Notification Categories</h5>
                            <p>Select activity categories that will trigger a notification</p>
                            {notificationCategories.map(({category, subscribed}) => (
                                <FormControlLabel
                                    key={category}
                                    checked={subscribed}
                                    onChange={(): void => handleChange(category)}
                                    control={<Switch name={category} />}
                                    label={category}
                                    className={styles.categorySwitch}
                                />
                            ))}
                        </div>
                    </>
                )}
            </div>
        </>
    );
}

import {Action, action, thunk, Thunk} from 'easy-peasy';
import {axios} from '~/api';
import {AxiosTryCatchBlock} from '~/helpers';
import {ClientEmployee, NewPerson, NewProjectTeamMember, Project, ProjectTeamMember, Role} from '~/types';

const defaultTimeout = 30000;

export interface SubsiteModel {
    project: Project;
    setSubsiteInitialData: Action<SubsiteModel, {project: Project}>;
    getSubsiteInitialData: Thunk<SubsiteModel, number>;
    clientEmployees: ClientEmployee[];
    setClientEmployees: Action<SubsiteModel, ClientEmployee[]>;
    getClientEmployees: Thunk<SubsiteModel, number>;
    projectTeam: ProjectTeamMember[];
    setProjectTeam: Action<SubsiteModel, ProjectTeamMember[]>;
    getProjectTeam: Thunk<SubsiteModel, number>;
    projectTeamRoles: Role[];
    setProjectTeamRoles: Action<SubsiteModel, Role[]>;
    getProjectTeamRoles: Thunk<SubsiteModel>;

    updateTeamMember: Thunk<SubsiteModel, ProjectTeamMember>;
    postTeamMember: Thunk<SubsiteModel, NewProjectTeamMember & NewPerson>;
    toggleTeamMemberPOC: Thunk<SubsiteModel, {id: number; poc: boolean}>;
    deleteTeamMember: Thunk<SubsiteModel, {id: number; projectId: number}>;
}

const subsiteModel: SubsiteModel = {
    project: null,
    projectTeam: [],
    clientEmployees: [],
    projectTeamRoles: [],
    setSubsiteInitialData: action((draftState, initialData) => {
        const {project} = initialData;
        draftState.project = project;
    }),
    getSubsiteInitialData: thunk((actions, projectId) =>
        AxiosTryCatchBlock(async () => {
            const {data} = await axios.get(`/SubsiteInitialData?projectId=${projectId}`, {
                timeout: defaultTimeout,
            });
            actions.setSubsiteInitialData(data);
            return data;
        }),
    ),
    setProjectTeam: action((draftState, projectTeam) => {
        draftState.projectTeam = projectTeam;
    }),
    getProjectTeam: thunk((actions, projectId) =>
        AxiosTryCatchBlock(async () => {
            const {data} = await axios.get(`/ProjectTeam?projectId=${projectId}`, {
                timeout: defaultTimeout,
            });
            actions.setProjectTeam(data);
        }),
    ),
    setClientEmployees: action((draftState, clientEmployees) => {
        draftState.clientEmployees = clientEmployees;
    }),
    getClientEmployees: thunk((actions, projectId) =>
        AxiosTryCatchBlock(async () => {
            const {data} = await axios.get(`/ClientEmployees?projectId=${projectId}`, {
                timeout: defaultTimeout,
            });
            actions.setClientEmployees(data);
        }),
    ),
    setProjectTeamRoles: action((draftState, roles) => {
        draftState.projectTeamRoles = roles;
    }),
    getProjectTeamRoles: thunk((actions) =>
        AxiosTryCatchBlock(async () => {
            const {data} = await axios.get('/ProjectTeamRoles', {timeout: defaultTimeout});
            actions.setProjectTeamRoles(data);
        }),
    ),
    postTeamMember: thunk(async (actions, teamMember) => {
        try {
            const {data} = await axios.post('/PostTeamMember', teamMember, {
                timeout: defaultTimeout,
            });
            const {projectTeam} = data;
            if (projectTeam) {
                actions.setProjectTeam(projectTeam);
            }
            return data;
        } catch (e) {
            return {
                result: 'error',
                message: 'New team member post request failed - Please contact tech support',
            };
        }
    }),
    updateTeamMember: thunk(async (actions, teamMember) => {
        try {
            const {data} = await axios.post('/UpdateTeamMember', teamMember, {
                timeout: defaultTimeout,
            });
            const {projectTeam} = data;
            if (projectTeam) {
                actions.setProjectTeam(projectTeam);
            }
            return data;
        } catch (e) {
            return {
                result: 'error',
                message: 'Team member information update request failed - Please contact tech support',
            };
        }
    }),
    toggleTeamMemberPOC: thunk(async (actions, payload) => {
        const {data} = await axios.post('/TogglePoc', payload);
        actions.setProjectTeam(data);
    }),
    deleteTeamMember: thunk(async (actions, record) => {
        try {
            const {data} = await axios.post('/RemoveTeamMember', record, {
                timeout: defaultTimeout,
            });
            const {projectTeam} = data;
            if (projectTeam && projectTeam.length > 0) {
                actions.setProjectTeam(projectTeam);
            }
            return data;
        } catch (e) {
            return {
                result: 'error',
                message: 'Request to remove team member failed - Please contact tech support',
            };
        }
    }),
};

export default subsiteModel;

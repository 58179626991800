import {action, Action, actionOn, ActionOn, computed, Computed, thunk, Thunk} from 'easy-peasy';
import {axios} from '~/api';
import {AxiosTryCatchBlock} from '~/helpers';
import type {ActualRecord, ChangeOrderRecord, FoundationJob, InvoiceRecord, Project, StoreModel} from '~/types';

export interface AccountingModel {
    actuals: ActualRecord[];
    changeOrders: ChangeOrderRecord[];
    // costs: CostRecord[];
    foundationJobs: FoundationJob[];
    invoices: InvoiceRecord[];
    setAccountingData: ActionOn<AccountingModel, StoreModel>;
    projectInvoiceStatus: Computed<AccountingModel, (foundationCode: string) => {amountInvoiced: number; amountPaid: number}>;
    projectSetInvoiceStatus: Computed<AccountingModel, (activeOnly: boolean) => {labels: string[]; series: number[]}, StoreModel>;
    setProjectInvoices: Action<AccountingModel, InvoiceRecord[]>;
    getProjectInvoices: Thunk<AccountingModel, number>;
    setProjectAccountingData: ActionOn<AccountingModel, StoreModel>;
    getProjectSubsetInvoices: Thunk<AccountingModel, {projectSubsetFoundationCodes: string[]}>;
}

const accountingModel: AccountingModel = {
    actuals: [],
    changeOrders: [],
    // costs: [],
    foundationJobs: [],
    invoices: [],
    setAccountingData: actionOn(
        (actions, storeActions) => storeActions.dashboard.getInitialData.successType,
        (draftState, target) => {
            const {actuals, changeOrders, foundationJobs, invoices} = target.result.projectAccounting;
            draftState.actuals = actuals;
            draftState.changeOrders = changeOrders;
            // draftState.costs = costs;
            draftState.foundationJobs = foundationJobs;
            draftState.invoices = invoices;
        },
    ),
    setProjectAccountingData: actionOn(
        (actions, storeActions) => storeActions.subsite.getSubsiteInitialData.successType,
        (draftState, target) => {
            const {project} = target.result;
            if (project && project.foundationJob) {
                draftState.foundationJobs = [project.foundationJob];
            } else {
                draftState.foundationJobs = [];
            }
        },
    ),
    projectInvoiceStatus: computed((state) => (foundationCode): {amountInvoiced: number; amountPaid: number} => {
        const projectInvoiceRecords = state.invoices.filter((o) => o.jobId === foundationCode);
        let result = {
            amountInvoiced: 0,
            amountPaid: 0,
        };

        if (projectInvoiceRecords.length > 0) {
            const total = projectInvoiceRecords.reduce(
                ({invoiced, unpaid}, e) => ({
                    invoiced: invoiced + (e.amountInvoiced ?? 0),
                    unpaid: invoiced + (e.amountUnpaid ?? 0),
                }),
                {invoiced: 0, unpaid: 0},
            );
            result = {
                amountInvoiced: total.invoiced,
                amountPaid: total.invoiced - total.unpaid,
            };
        }
        return result;
    }),
    projectSetInvoiceStatus: computed(
        [(state): InvoiceRecord[] => state.invoices, (state, storeState): Project[] => storeState.project.projectSubset],
        (invoices, projects) =>
            (activeOnly: boolean): {labels: string[]; series: number[]} => {
                const activeFoundationJobs = projects
                    .filter((p) => ((activeOnly && p.status.status === 'Active') || !activeOnly) && p.foundationJob)
                    .map((q) => q.foundationJob);
                const projectInvoiceRecords = invoices.filter((o) => activeFoundationJobs.some((p) => p.id === o.jobId));
                let series: number[] = [];
                if (projectInvoiceRecords.length > 0) {
                    const total = projectInvoiceRecords.reduce(
                        ({invoiced, unpaid}, e) => ({
                            invoiced: invoiced + (e.amountInvoiced ?? 0),
                            unpaid: invoiced + (e.amountUnpaid ?? 0),
                        }),
                        {invoiced: 0, unpaid: 0},
                    );
                    const totalContract = activeFoundationJobs.reduce((t, e) => t + (e.originalContract ?? 0) + (e.totalApprovedIncomeAdj ?? 0), 0);
                    series = [total.invoiced - total.unpaid, total.unpaid, totalContract - total.invoiced];
                }
                return {
                    labels: ['Invoice Paid', 'Invoice Not Paid', 'Remaining To Invoice'],
                    series,
                };
            },
    ),
    setProjectInvoices: action((draftState, invoices) => {
        draftState.invoices = invoices;
    }),
    getProjectInvoices: thunk((actions, projectId) =>
        AxiosTryCatchBlock(async () => {
            const {data} = await axios.get(`/ProjectInvoices?projectId=${projectId}`);
            actions.setProjectInvoices(data);
        }),
    ),
    getProjectSubsetInvoices: thunk(async (actions, payload) => {
        const {data} = await axios.post('/ProjectSubsetInvoices', payload, {
            timeout: 60000,
        });
        actions.setProjectInvoices(data);
    }),
};

export default accountingModel;

import {useContext} from 'react';
import ScrollToDiv from '~/components/generics/ScrollToDiv';
import SwipeStack from '~/components/generics/SwipeStack';
import CorrespondenceStackContext from '~/components/subsite/summary/correspondenceStack/CorrespondenceStackContext';
import NoOpenItemCard from '~/components/subsite/summary/correspondenceStack/NoOpenItemCard';
import TQCard from '~/components/subsite/summary/TQCard';
import {useStoreState} from '~/store/storeHooks';

interface TQStackProps {
    projectLabel?: boolean;
}

TQStack.defaultProps = {
    projectLabel: false,
};

export default function TQStack({projectLabel}: TQStackProps): JSX.Element {
    const {tqs} = useStoreState((state) => state.correspondence);
    const {showTQ} = useContext(CorrespondenceStackContext);

    if (!showTQ) {
        return null;
    }

    return (
        <>
            <ScrollToDiv id="tq-stack-scroll-to"/>
            {
                tqs.length === 0
                ?
                <NoOpenItemCard type="tqs" />
                :
                <SwipeStack id="tq-stack-root">
                    {[...tqs.filter((o)=>o.status !== 'Closed' && !o.descendent).map((t, i) => <TQCard index={i} tq={t} key={`tq:${t.id}`} projectLabel={projectLabel} />)]}
                </SwipeStack>
            }
        </>
    );
}

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import styles from '~/styles/LibraryPage.module.scss';

interface LibraryCardProps extends React.HTMLAttributes<HTMLDivElement> {
    title: string;
    description: string;
    image: any;
}

export default function LibraryCard(props: LibraryCardProps): JSX.Element {
    const {title, description, image} = props;

    return (
        <Card sx={{maxWidth: 350, height: 415}} className={styles.libraryCard}>
            <CardMedia component="img" height="300" src={image} alt="image" />
            <CardContent className={styles.cardContent}>
                <Typography gutterBottom variant="h5" component="div" className={styles.title}>
                    {title}
                </Typography>
                <Typography variant="body2" color="text.secondary" className={styles.description}>
                    {description}
                </Typography>
            </CardContent>
        </Card>
    );
}

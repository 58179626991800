import {useEffect, useState, useMemo} from 'react';
import {useParams} from 'react-router-dom';
import NoDataPlaceholder from '~/components/generics/NoDataPlaceholder';
import LoadingComponent from '~/components/visualizations/LoadingComponent';
import {useStoreActions, useStoreState} from '~/store/storeHooks';

interface Props {
    type: string;
    children: JSX.Element | JSX.Element[];
}

export default function CorrespondenceDataFetch({type, children}: Props): JSX.Element {
    const {getRFIs, getTQs, getTDRs, getHolds, getActionItems, getNeeds} = useStoreActions((actions) => actions.correspondence);
    const rfis = useStoreState((state) => state.correspondence.rfis);
    const tqs = useStoreState((state) => state.correspondence.tqs);
    const tdrs = useStoreState((state) => state.correspondence.tdrs);
    const holds = useStoreState((state) => state.correspondence.holds);
    const needs = useStoreState((state) => state.correspondence.needs);
    const actionItems = useStoreState((state) => state.correspondence.actionItems);
    const {projectId} = useParams<{projectId: string}>();
    const [busy, setBusy] = useState<boolean>(true);

    useEffect(() => {
        const id = parseInt(projectId, 10);
        if (id) {
            (async function fetchData(): Promise<void> {
                let fetchRequest: (i: number) => Promise<void>;
                switch (type) {
                    case 'rfi':
                        fetchRequest = getRFIs;
                        break;
                    case 'tq':
                        fetchRequest = getTQs;
                        break;
                    case 'tdr':
                        fetchRequest = getTDRs;
                        break;
                    case 'hold':
                        fetchRequest = getHolds;
                        break;
                    case 'need':
                        fetchRequest = getNeeds;
                        break;
                    case 'action':
                        fetchRequest = getActionItems;
                        break;
                    default:
                        break;
                }
                if (fetchRequest) {
                    setBusy(true);
                    await fetchRequest(id);
                }
                setBusy(false);
            })();
        }
        return (): void => setBusy(true);
    }, [getActionItems, getHolds, getNeeds, getRFIs, getTDRs, getTQs, projectId, type]);

    const dataAvailable = useMemo(() => {
        switch (type) {
            case 'rfi':
                return rfis.length > 0;
            case 'tq':
                return tqs.length > 0;
            case 'tdr':
                return tdrs.length > 0;
            case 'hold':
                return holds.length > 0;
            case 'need':
                return needs.length > 0;
            case 'action':
                return actionItems.length > 0;
            default:
                return false;
        }
    }, [actionItems.length, holds.length, needs.length, rfis.length, tdrs.length, tqs.length, type]);

    let child = children;
    if (busy) {
        child = <LoadingComponent />;
    } else if (!dataAvailable) {
        child = <NoDataPlaceholder />;
    }

    return (
        <div style={{position: 'relative', height: '50vh'}} id="correspondence-data-fetch">
            {child}
        </div>
    );
}

import {createContext, useContext, useLayoutEffect, useState, useMemo} from 'react';
import {FBAppAdapter} from './FBAppAdapter';
import {FBUserAdapter} from './FBUserAdapter';

export interface AuthContextType {
    app: FBAppAdapter;
    currentUser: FBUserAdapter;
    pending: boolean;
    isAuthenticated: boolean;
    getUserToken: (callback?: (token: string | null) => void) => Promise<string | null>;
    signIn: (
        email: string,
        password: string,
        rememberMe?: boolean | undefined,
        callback?: (newUser: FBUserAdapter | null) => void,
    ) => Promise<FBUserAdapter | null>;
    signOut: (callback?: () => void) => Promise<void>;
}

const AuthContext = createContext<AuthContextType>(null!);

export const fbApp = new FBAppAdapter({
    apiKey: 'AIzaSyBUIfzpT5IRYuRXa9fDnXjXTPr25njrH20',
    authDomain: 'th-cpp.firebaseapp.com',
    projectId: 'th-cpp',
    storageBucket: 'th-cpp.appspot.com',
    messagingSenderId: '284828242637',
    appId: '1:284828242637:web:75244f55f401f32ce70f84',
    measurementId: 'G-XT6GKLEMTC',
});

export function AuthProvider({children}: {children: React.ReactNode}): JSX.Element {
    const [pending, setPending] = useState<boolean>(true);
    const [currentUser, setUser] = useState<FBUserAdapter | null>(() => fbApp.currentUser);

    useLayoutEffect(() => {
        setUser((u) => {
            const newUser = fbApp.currentUser;
            return FBUserAdapter.equals(u, newUser) ? u : fbApp.currentUser;
        });

        return fbApp.onAuthStateChanged((newUser) => {
            setUser((u) => (FBUserAdapter.equals(u, newUser) ? u : newUser));
            setPending(false);
        });
    }, []);

    const context = useMemo<AuthContextType>(
        () => ({
            app: fbApp,
            currentUser,
            pending: pending && !currentUser,
            isAuthenticated: !!currentUser,

            getUserToken: async (callback?: (token: string | null) => void): Promise<string | null> => {
                if (currentUser == null) {
                    return null;
                }

                const token = await currentUser.getIdToken();
                callback?.(token);
                return token;
            },

            signOut: async (callback?: () => void): Promise<void> => {
                await fbApp.signOut();
                setUser(null);
                callback?.();
            },

            signIn: async (
                email: string,
                password: string,
                rememberMe?: boolean | undefined,
                callback?: (newUser: FBUserAdapter | null) => void,
            ): Promise<FBUserAdapter | null> => {
                try {
                    const newUser = await fbApp.signIn(email, password, rememberMe);
                    callback?.(newUser);
                    return newUser;
                } catch (err) {
                    setUser(null);
                    throw err;
                }
            },
        }),
        [currentUser, pending],
    );

    return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
}

export function useAuth(): AuthContextType {
    return useContext(AuthContext);
}

import { GridRowClassNameParams, GridRowParams } from '@mui/x-data-grid-pro';
import {useCallback, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import DataGridProTable from '~/components/generics/DataGridProTable';
import HoldDialog from '~/components/subsite/correspondence/hold/HoldDialog';
import HoldsTableCols from '~/components/subsite/correspondence/hold/HoldsTableColsPro';
import LoadingComponent from '~/components/visualizations/LoadingComponent';
import {useStoreActions, useStoreState} from '~/store/storeHooks';
import styles from '~/styles/DataGridPro.module.scss';

const tableCacheVersion = 1;

export default function HoldTable(): JSX.Element {
    const {projectId} = useParams<{projectId: string}>();
    const project = useStoreState((state) => state.subsite.project);
    const getHolds = useStoreActions((actions) => actions.correspondence.getHolds);
    const holds = useStoreState((state) => state.correspondence.holds);
    const [busy, setBusy] = useState(false);
    const columns = HoldsTableCols();
    const navigate = useNavigate();


    useEffect(() => {
        if (projectId) {
            setBusy(true);
            (async function fetchData(): Promise<void> {
                await getHolds(parseInt(projectId, 10));
            })();
            setBusy(false);
        }
        return (): void => setBusy(false);
    }, [getHolds, projectId]);

    const handleRowClick = useCallback(
        (params: GridRowParams): void => {
            navigate(`/project/${projectId}/correspondence?hold=${params.row.id}`);
        },
        [navigate, projectId],
    );

    const getRowClassName = (params: GridRowClassNameParams)=> {
        const {status} = params.row;
        let cn = styles.row;
        if (status === 'Open') {
            cn = `${cn} ${styles.actionable}`;
        } else {
            cn = `${cn} ${styles.readOnly}`;
        }
        if (params.row.descendent) cn = `${cn} ${styles.treeBranch}`;
        return cn;
    }

    return (
        <div id="Hold-table-root">
            {busy ? (
                <LoadingComponent title="Loading Hold Data" contained/>
            ) : (
                <>
                    <DataGridProTable
                        columns={columns}
                        data={holds}
                        optionKey='holdsTable'
                        csvFileName={`${project?.code ?? 'Project'} Holds List.csv`}
                        cacheVersion={tableCacheVersion}
                        handleRowClick={handleRowClick}
                        getRowClassName={getRowClassName}
                    />
                    <HoldDialog />
                </>
            )}
        </div>
    );
}

import {useEffect, useState} from 'react';
import SwipeStack from '~/components/generics/SwipeStack';
import SwipeStackCard from '~/components/generics/SwipeStackCard';
import ActiveProjectTypeChart from '~/components/visualizations/ActiveProjectTypeChart';
import InvoiceStatusChart from '~/components/visualizations/InvoiceStatusChart';
import LoadingComponent from '~/components/visualizations/LoadingComponent';
import ProjectValueByStatusChart from '~/components/visualizations/ProjectValueByStatusChart';
import {useStoreActions, useStoreState} from '~/store/storeHooks';
import styles from '~/styles/DashboardFinancialStack.module.scss';

export default function DashboardFinancialStack(): JSX.Element {
    const getProjectSubsetInvoices = useStoreActions((actions) => actions.accounting.getProjectSubsetInvoices);
    const projectSubset = useStoreState((state) => state.project.projectSubset);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        (async function fetchData(): Promise<void> {
            setLoading(true);
            await getProjectSubsetInvoices({projectSubsetFoundationCodes: projectSubset.map((p) => p.foundationCode)});
            setLoading(false);
        })();
        return (): void => setLoading(false);
    }, [getProjectSubsetInvoices, projectSubset]);

    return (
        <SwipeStack id="financial-stack-root">
            <SwipeStackCard cardIndex={0}>
                <div className={styles.donutChartCard}>
                    <h3 className={styles.title}>Project Value By Status</h3>
                    <ProjectValueByStatusChart />
                </div>
            </SwipeStackCard>
            <SwipeStackCard cardIndex={1}>
                <div className={styles.donutChartCard}>
                    <h3 className={styles.title}>Active Project Value By Type</h3>
                    <ActiveProjectTypeChart />
                </div>
            </SwipeStackCard>
            {/* TODO: Unhide once we figure out the vista columns for invoices */}
            {/* <SwipeStackCard cardIndex={2}>
                <div className={styles.donutChartCard}>
                    <h3 className={styles.title}>Active Project Invoice Status</h3>
                    {loading ? <LoadingComponent contained/> : <InvoiceStatusChart />}
                </div>
            </SwipeStackCard> */}
        </SwipeStack>
    );
}

import { useTrackPage } from '~/api';
import ScrollToDiv from '~/components/generics/ScrollToDiv';
import DocumentCountKPI from '~/components/kpi/DocumentCountKPI';
import KPIContainer from '~/components/kpi/KPIContainer';
import DocumentTable from '~/components/subsite/documentTable/DocumentTablePro';
import {useStoreState} from '~/store/storeHooks';
import styles from '~/styles/SubsiteContentPanel.module.scss';

export default function DeliverablesPanel(): JSX.Element {
    const documentCategoryForTitle = useStoreState((state) => state.document.documentCategoryForTitle);

    const deliverableCategory = documentCategoryForTitle('deliverable');
    const vendorDataCategory = documentCategoryForTitle('vendor data');

    useTrackPage('Deliverables');

    return (
        <div id="deliverables-panel-root" className={styles.root}>
            <KPIContainer className={styles.kpiContainer}>
                <DocumentCountKPI
                    categoryTitle="deliverable"
                    title="Deliverables"
                    scrollTo="deliverables-table-scroll-to"
                    tooltipTitle="Navigate to Deliverables"
                    countIfaOnly
                />
                <DocumentCountKPI
                    categoryTitle="vendor data"
                    title="Vendor Data"
                    scrollTo="vendor-data-table-scroll-to"
                    tooltipTitle="Navigate to Vendor Data"
                    countIfaOnly
                />
            </KPIContainer>
            {deliverableCategory && (
                <div id="deliverables-table-root">
                    <ScrollToDiv id="deliverables-table-scroll-to"/>
                    <h4 className={styles.header}>Deliverables</h4>
                    <DocumentTable categories={deliverableCategory.id} />
                </div>
            )}
            {vendorDataCategory && (
                <div id="vendor-data-table-root">
                    <ScrollToDiv id="vendor-data-table-scroll-to"/>
                    <h4 className={styles.header}>Vendor Data</h4>
                    <DocumentTable categories={vendorDataCategory.id} />
                </div>
            )}
            {/* <Timeline />*/}
        </div>
    );
}

import {createContext} from 'react';

type MilestoneStackContextType = {
    showMilestones: boolean;
    setShowMilestones: (show: boolean) => void;
};

const CorrespondenceStackContext = createContext<MilestoneStackContextType>({
    showMilestones: false,
    setShowMilestones: () => true,
});

export default CorrespondenceStackContext;

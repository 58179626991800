import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconButton} from '@mui/material';
import {SnackbarKey, useSnackbar} from 'notistack';
import * as React from 'react';
import styles from '~/styles/SnackbarCloseButton.module.css';

export function SnackbarCloseButton({id}: {id: SnackbarKey}): JSX.Element {
    const {closeSnackbar} = useSnackbar();

    const handleClick = React.useCallback(() => {
        closeSnackbar(id);
    }, [closeSnackbar, id]);

    return (
        <IconButton onClick={handleClick} className={styles.root}>
            <FontAwesomeIcon icon={['fal', 'times']} size="sm" />
        </IconButton>
    );
}

export function ProviderAction(key: SnackbarKey): JSX.Element {
    return <SnackbarCloseButton id={key} />;
}

import {useMemo, useContext, useEffect, useState} from 'react';
import Masonry from 'react-masonry-css';
import MilestoneContext from './milestone/MilestoneContext';
import FinanceCard from '~/components/dashboard/project-log/FinanceCard';
import ProjectContext from '~/components/dashboard/project-log/ProjectContext';
import ProjectScopeCard from '~/components/dashboard/summary-stack/ProjectScopeCard';
import ScopeAttachmentCard from '~/components/dashboard/summary-stack/ScopeAttachmentCard';
import SummaryInvoiceStatus from '~/components/dashboard/summary-stack/SummaryInvoiceStatus';
import SummaryProgressCard from '~/components/dashboard/summary-stack/SummaryProgressCard';
import SummaryProjectInfo from '~/components/dashboard/summary-stack/SummaryProjectInfo';
import ScrollToDiv from '~/components/generics/ScrollToDiv';
import SummaryCard from '~/components/generics/SummaryCard';
import KPIContainer from '~/components/kpi/KPIContainer';
import PercentCompleteKPI from '~/components/kpi/PercentCompleteKPI';
import ActionItemStack from '~/components/subsite/summary/correspondenceStack/ActionItemStack';
import CorrespondenceStackContext from '~/components/subsite/summary/correspondenceStack/CorrespondenceStackContext';
import HoldStack from '~/components/subsite/summary/correspondenceStack/HoldStack';
import NeedStack from '~/components/subsite/summary/correspondenceStack/NeedStack';
import RFIStack from '~/components/subsite/summary/correspondenceStack/RFIStack';
import TDRStack from '~/components/subsite/summary/correspondenceStack/TDRStack';
import TQStack from '~/components/subsite/summary/correspondenceStack/TQStack';
import HealthGradeKPI from '~/components/subsite/summary/kpis/HealthGradeKPI';
import MilestoneCountKPI from '~/components/subsite/summary/kpis/MilestoneCountKPI';
import SummaryCorrespondenceKPI from '~/components/subsite/summary/kpis/SummaryCorrespondenceKPI';
import MilestoneList from '~/components/subsite/summary/milestone/MilestoneList';
import {usePermissions} from '~/hooks/usePermissions';
import {useStoreActions} from '~/store/storeHooks';
import styles from '~/styles/ProjectSummaryDesktop.module.scss';

export default function ProjectSummaryDesktop(): JSX.Element {
    const getProgressTableData = useStoreActions((actions) => actions.progress.getProgressTableData);
    const view = usePermissions();

    const [showActionItem, setShowActionItem] = useState(localStorage.cppShowActionItem === 'true');
    const [showRFI, setShowRFI] = useState(localStorage.cppShowRFI === 'true');
    const [showTDR, setShowTDR] = useState(localStorage.cppShowTDR === 'true');
    const [showTQ, setShowTQ] = useState(localStorage.cppShowTQ === 'true');
    const [showHold, setShowHold] = useState(localStorage.cppShowHold === 'true');
    const [showNeed, setShowNeed] = useState(localStorage.cppShowNeed === 'true');

    const getRFIs = useStoreActions((actions) => actions.correspondence.getRFIs);
    const getTQs = useStoreActions((actions) => actions.correspondence.getTQs);
    const getTDRs = useStoreActions((actions) => actions.correspondence.getTDRs);
    const getHolds = useStoreActions((actions) => actions.correspondence.getHolds);
    const getNeeds = useStoreActions((actions) => actions.correspondence.getNeeds);
    const getActionItems = useStoreActions((actions) => actions.correspondence.getActionItems);

    const {project} = useContext(ProjectContext);
    const {showMilestones} = useContext(MilestoneContext);
    const isTM = project?.foundationJob?.tm;
    const isPreBid = project.status.stage === 'Pre Bid' || project.status.stage === 'Bid';

    const correspondenceStackContext = useMemo(
        () => ({
            showRFI,
            showTQ,
            showTDR,
            showActionItem,
            showHold,
            showNeed,
            setShowTDR,
            setShowActionItem,
            setShowRFI,
            setShowTQ,
            setShowHold,
            setShowNeed,
        }),
        [showRFI, showTQ, showTDR, showActionItem, showHold, showNeed],
    );

    useEffect(() => {
        if (project.id) {
            (async function fetchData(): Promise<void> {
                await getProgressTableData(project.id);
            })();
        }
    }, [getProgressTableData, project.id]);

    useEffect(() => {
        if (project?.id) {
            // TODO: Change the Summary Correspondence KPI to accept a fetch request property instead of this and handle loading state
            getRFIs(project.id);
            getTQs(project.id);
            getTDRs(project.id);
            getHolds(project.id);
            getNeeds(project.id);
            getActionItems(project.id);
        }
    }, [getRFIs, getTQs, getTDRs, getHolds, getNeeds, getActionItems, project?.id]);

    return (
        <div className={styles.root} id="project-summary-grid-root">
            <CorrespondenceStackContext.Provider value={correspondenceStackContext}>
                <KPIContainer className={styles.kpiContainer}>
                    <PercentCompleteKPI />
                    {isPreBid || !isTM ? null : <HealthGradeKPI type="overall" tooltipTitle="Click to open Overall Health detail" />}
                    {isPreBid ? null : <HealthGradeKPI type="hse" tooltipTitle="Click to open HSE detail" />}
                    {!isPreBid && isTM ? <HealthGradeKPI type="budget" tooltipTitle="Click to open Budget detail" /> : null}
                    {isPreBid ? null : <HealthGradeKPI type="schedule" tooltipTitle="Click to open Schedule detail" />}
                    <MilestoneCountKPI scrollTo="milestone-stack-scroll-to" />
                    <SummaryCorrespondenceKPI
                        type="rfis"
                        tooltipTitle={`Click to ${showRFI ? 'hide' : 'show'} RFI detail card below`}
                        scrollTo="rfi-stack-scroll-to"
                    />
                    <SummaryCorrespondenceKPI
                        type="tqs"
                        tooltipTitle={`Click to ${showTQ ? 'hide' : 'show'} Technical Query detail card below`}
                        scrollTo="tq-stack-scroll-to"
                    />
                    <SummaryCorrespondenceKPI
                        type="tdrs"
                        tooltipTitle={`Click to ${showTDR ? 'hide' : 'show'} Deviation Request detail card below`}
                        scrollTo="tdr-stack-scroll-to"
                    />
                    <SummaryCorrespondenceKPI
                        type="holds"
                        tooltipTitle={`Click to ${showHold ? 'hide' : 'show'} Hold detail card below`}
                        scrollTo="hold-stack-scroll-to"
                    />
                    <SummaryCorrespondenceKPI
                        type="needs"
                        tooltipTitle={`Click to ${showNeed ? 'hide' : 'show'} Need detail card below`}
                        scrollTo="need-stack-scroll-to"
                    />
                    <SummaryCorrespondenceKPI
                        type="actionItems"
                        tooltipTitle={`Click to ${showActionItem ? 'hide' : 'show'} Action Item detail card below`}
                        scrollTo="action-item-stack-scroll-to"
                    />
                </KPIContainer>
                <Masonry
                    breakpointCols={{
                        default: 4,
                        2000: 3,
                        1530: 2,
                        1040: 1,
                    }}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column">
                    <SummaryProjectInfo />
                    <SummaryProgressCard />
                    {view && (
                        <>
                            <SummaryCard>
                                <FinanceCard />
                            </SummaryCard>
                            {/* TODO: Unhide once we figure out the vista columns for invoices */}
                            {/* <SummaryInvoiceStatus /> */}
                        </>
                    )}
                    <SummaryCard>
                        <ProjectScopeCard />
                    </SummaryCard>
                    <SummaryCard>
                        <ScopeAttachmentCard />
                    </SummaryCard>
                    {showMilestones ? (
                        <>
                            <ScrollToDiv id="milestone-stack-scroll-to" />
                            <SummaryCard id="milestone-stack-root">
                                <h3 className={styles.title}>Milestones</h3>
                                <MilestoneList />
                            </SummaryCard>
                        </>
                    ) : (
                        ''
                    )}
                    <RFIStack />
                    <TQStack />
                    <TDRStack />
                    <HoldStack />
                    <NeedStack />
                    <ActionItemStack />
                </Masonry>
            </CorrespondenceStackContext.Provider>
        </div>
    );
}

import {useContext} from 'react';
import ScrollToDiv from '~/components/generics/ScrollToDiv';
import SwipeStack from '~/components/generics/SwipeStack';
import ActionItemCard from '~/components/subsite/summary/ActionItemCard';
import CorrespondenceStackContext from '~/components/subsite/summary/correspondenceStack/CorrespondenceStackContext';
import NoOpenItemCard from '~/components/subsite/summary/correspondenceStack/NoOpenItemCard';
import {useStoreState} from '~/store/storeHooks';

interface ActionItemStackProps {
    projectLabel?: boolean;
}

ActionItemStack.defaultProps = {
    projectLabel: false,
};

export default function ActionItemStack({projectLabel}: ActionItemStackProps): JSX.Element {
    const {actionItems} = useStoreState((state) => state.correspondence);
    const {showActionItem} = useContext(CorrespondenceStackContext);

    if (!showActionItem) {
        return null;
    }

    return (
        <>
            <ScrollToDiv id="action-item-stack-scroll-to"/>
            {
                actionItems.length === 0
                ?
                <NoOpenItemCard type="actionItems" />
                :
                <SwipeStack id="action-item-stack-root">
                    {[...actionItems.filter((o)=>o.status !== 'Closed' && !o.descendent).map((a, i) => <ActionItemCard index={i} actionItem={a} key={`actionItem:${a.id}`} projectLabel={projectLabel} />)]}
                </SwipeStack>
            }
        </>
    );
}

import {Button, Grid} from '@mui/material';
import { toPng } from 'html-to-image';
import {useCallback, useEffect, useRef, useState} from 'react';
import {Controller} from 'react-hook-form';
import SignaturePad from 'react-signature-canvas';
import { useStoreState } from '~/store/storeHooks';
import styles from '~/styles/SignatureControl.module.scss';

type ControllerProps = typeof Controller extends React.ComponentType<infer P> ? P : never;
type ControllerRenderFunc = ControllerProps['render'];
type ControllerRenderProps = Parameters<ControllerRenderFunc>[0]['field'];

interface SignatureControlProps {
    field: ControllerRenderProps;
    title?: string;
    height?: number;
    required?: boolean;
    defaultSignatureText?: string;
}

SignatureControl.defaultProps = {
    title: 'Signature',
    height: 300,
    required: false,
    defaultSignatureText: null
};

export default function SignatureControl({field, title, height, required, defaultSignatureText}: SignatureControlProps): JSX.Element {
    const currentUser = useStoreState((state) => state.shared.currentUser);
    const {value, onChange} = field;
    const canvasRef = useRef<SignaturePad>(null);

    useEffect(() => {
        canvasRef.current.fromDataURL(value);
    }, [value]);

    const handleSave = useCallback((): void => {
        onChange(canvasRef.current.toDataURL('image/png'));
    }, [onChange]);

    const handleClear = useCallback((): void => {
        onChange(null);
        canvasRef.current.clear();
    }, [onChange]);

    const generateSignature = ()=>{
        const sigP = document.getElementById('imgToPng');
        sigP.style.display = 'block';
        sigP.innerHTML = defaultSignatureText ?? currentUser.fullName;
        toPng(sigP).then((dataUrl:any)=> {
            sigP.style.display = 'none';
            handleClear();
            canvasRef.current.fromDataURL(dataUrl);
            setTimeout(()=>handleSave(),10);
        })
    }

    return (
        <div className={styles.root}>  
            <Grid container>
                <Grid item><p className={required ? styles.required : ""}>{title} {required && '*'}</p></Grid>
                <Grid item><Button size='small' variant='outlined' color={required ?'success' : 'primary'} className={styles.generate} onClick={generateSignature}>Auto Generate</Button></Grid>
                    
            </Grid>
            <SignaturePad
                penColor="#FF4500"
                canvasProps={{height}}
                ref={(ref): void => {
                    canvasRef.current = ref;
                }}
                onEnd={(): void => handleSave()}
                clearOnResize={false}
                throttle={0}
                maxWidth={6}
            />
            <Button className={styles.clear} onClick={handleClear}>
                Clear Signature
            </Button>
            <p id='imgToPng' style={{fontFamily:'cursive',fontSize:22,padding: 20, display:'none',position:'absolute', zIndex: -10, width: 400, color: '#eb2f06', textAlign: 'center'}}/>
        </div>
    );
}

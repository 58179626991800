import {GridRowClassNameParams, GridRowParams} from '@mui/x-data-grid-pro';
import {useCallback, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import DataGridProTable from '~/components/generics/DataGridProTable';
import RFIDialog from '~/components/subsite/correspondence/rfi/RFIDialog';
import RFITableCols from '~/components/subsite/correspondence/rfi/RFITableColsPro';
import LoadingComponent from '~/components/visualizations/LoadingComponent';
import {useStoreActions, useStoreState} from '~/store/storeHooks';
import styles from '~/styles/DataGridPro.module.scss';

const tableCacheVersion = 10;

export default function RFITable(): JSX.Element {
    const {projectId} = useParams<{projectId: string}>();
    const project = useStoreState((state) => state.subsite.project);
    const getRFIs = useStoreActions((actions) => actions.correspondence.getRFIs);
    const rfis = useStoreState((state) => state.correspondence.rfis);
    const [busy, setBusy] = useState<any>(true);

    const navigate = useNavigate();

    const columns = RFITableCols();

    useEffect(() => {
        if (projectId) {
            setBusy(true);
            (async function fetchData(): Promise<void> {
                await getRFIs(parseInt(projectId, 10));
            })();
            setBusy(false);
        }
        return (): void => {
            setBusy(false);
        };
    }, [getRFIs, projectId]);

    const handleRowClick = useCallback(
        (params: GridRowParams): void => {
            navigate(`/project/${projectId}/correspondence?rfi=${params.row.id}`);
        },
        [navigate, projectId],
    );

    const getRowClassName = (params: GridRowClassNameParams) => {
        const {status, answered} = params.row;
        let cn = styles.row;
        if (status === 'Open' && !answered) {
            cn = `${cn} ${styles.actionRequired}`;
        } else if (status === 'Open' && answered) {
            cn = `${cn} ${styles.actionable}`;
        } else {
            cn = `${cn} ${styles.readOnly}`;
        }
        if (params.row.descendent) cn = `${cn} ${styles.treeBranch}`;
        return cn;
    };

    return (
        <div id="RFI-table-root">
            {busy ? (
                <LoadingComponent title="Loading RFI Data" contained />
            ) : (
                <>
                    <DataGridProTable
                        columns={columns}
                        data={rfis}
                        optionKey="rfiTable"
                        exportAllColumns
                        csvFileName={project?.code ?? 'Project RFI Log'}
                        cacheVersion={tableCacheVersion}
                        handleRowClick={handleRowClick}
                        getRowClassName={getRowClassName}
                        excelOptions={{tableName: 'rfis', projectId: parseInt(projectId, 10), projectCode: project?.code}}
                    />
                    <RFIDialog />
                </>
            )}
        </div>
    );
}

import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import {useContext, useEffect, useRef} from 'react';
import {ListChildComponentProps} from 'react-window';
import TimelineContext from './TimelineContext';
import styles from '~/styles/Timeline.module.scss';
import {Activity} from '~/types';

const DEFAULT_ACTIVITY_ITEM_HEIGHT = 70;

export default function TimelineEvent(props: ListChildComponentProps): JSX.Element {
    const {index, style, data} = props;
    const rowRef = useRef<null | HTMLDivElement>(null);
    const {setSize} = useContext(TimelineContext);
    const rowHeight = rowRef.current?.getBoundingClientRect().height ?? DEFAULT_ACTIVITY_ITEM_HEIGHT;

    useEffect(() => {
        if (rowRef.current) {
            setSize(index, rowHeight);
        }
    }, [index, rowHeight, setSize]);

    const {created, createdBy, action, subject} = data[index] as Activity;
    return (
        <div id={`timeline-event-row-${data[index].id}`} style={{...style}} key={index}>
            <div ref={rowRef}>
                <TimelineItem className={styles.timelineEventRoot}>
                    <TimelineOppositeContent>
                        <p>{`${new Date(created).toLocaleDateString('en-US', {day: '2-digit', month: '2-digit', year: '2-digit'})}`}</p>
                        <p>{`${new Date(created).toLocaleTimeString(navigator.language, {hour: '2-digit', minute: '2-digit'})}`}</p>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <div>
                            <h6>{createdBy?.fullName ?? 'Unknown'}</h6>
                            <p>{` ${action} ${subject}`}</p>
                        </div>
                    </TimelineContent>
                </TimelineItem>
            </div>
        </div>
    );
}

import {ErrorMessage} from '@hookform/error-message';
import startCase from 'lodash/startCase';
import {Controller, ControllerProps, useFormContext} from 'react-hook-form';

type ErrorMessageRender = (typeof ErrorMessage extends React.ComponentType<infer Props> ? Props : never)['render'];

function MessageRender(name: string): ErrorMessageRender {
    
    return (props: any) => {
        const {message,  messages} = props;
        if (messages && typeof messages === 'object') {
            return (
                Object.entries(messages).map(([type, msg]: any) => 
                    <p className="react-hook-form-error-message">{type === 'required' ? `${startCase(name)} is required` : msg}</p>
                )
            )
        } 
        if (message) {
            return <p style={{color: "#ff8b8b"}}>{message}</p>
        }
        return <></>;
    }
        
}

export default function RHFController(props: ControllerProps): JSX.Element {
    const {name} = props;

    const {
        control,
        formState: {errors},
    } = useFormContext();

    return (
        <div className="react-hook-form-controller-container">
            <Controller {...props} control={control} />
            <ErrorMessage errors={errors} name={name} render={MessageRender(name)} />
        </div>
    );
}

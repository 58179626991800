import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { Button, ClickAwayListener, Grow, MenuItem, Paper, Popper, MenuList } from "@mui/material";
import { GridInitialState, useGridApiContext } from "@mui/x-data-grid-pro";
import React, { useCallback, useState, useRef, useEffect } from 'react';
import { useStoreActions, useStoreState } from '~/store/storeHooks';

export function ProjectLogTableSettings ({getDefaultGridState}: {getDefaultGridState: (reason?: string)=>GridInitialState}) {
    const {applyFilterToDocket,defaultOpenInNewTab, includeArchived} = useStoreState((state) => state.shared.userOptions.projectTableOptions);
    const {setUserOptions} = useStoreActions((actions) => actions.shared);
    const apiRef = useGridApiContext();
    const anchorRef = useRef<HTMLButtonElement>(null);
    const [open, setOpen] = useState(false);

    const handleToggle = useCallback(() => {
      setOpen((prevOpen) => !prevOpen);
    },[setOpen]);

    const handleClose = useCallback((event: Event | React.SyntheticEvent) => {
      if (
        anchorRef.current &&
        anchorRef.current.contains(event.target as HTMLElement)
      ) {
        return;
      }

      setOpen(false);
    },[setOpen]);

    const handleListKeyDown = useCallback((event: React.KeyboardEvent)=> {
      if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
      } else if (event.key === 'Escape') {
        setOpen(false);
      }
    },[setOpen]);

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(open);
    useEffect(() => {
      if (prevOpen.current === true && open === false) {
        anchorRef.current!.focus();
      }

      prevOpen.current = open;
    }, [open]);


    const handleDefaultNewTab: () => void = useCallback(()=> {
      setUserOptions({ 
        option: 'projectTableOptions',
        key: 'defaultOpenInNewTab', 
        value: !defaultOpenInNewTab
      }); 
    },[setUserOptions,defaultOpenInNewTab]);

    const handleApplyFilterToDocket = useCallback(()=> {
        setOpen(false);
        setUserOptions({ 
          option: 'projectTableOptions',
          key: 'applyFilterToDocket', 
          value: !applyFilterToDocket
        }); 
        setTimeout(()=>{setOpen(true)},300);
    },[setUserOptions,applyFilterToDocket]);

    const handleIncludeArchived = useCallback(()=> {
      setOpen(false);
      setUserOptions({ 
        option: 'projectTableOptions',
        key: 'includeArchived', 
        value: !includeArchived
      }); 
      setTimeout(()=>{setOpen(true)},300);
    },[setUserOptions,includeArchived]);

    const handleResetProjectLogTableSettings = useCallback(()=> {
        const defaultState = getDefaultGridState('resetting');
        setUserOptions({ 
          option: 'projectTableOptions',
          key: 'projectLogTableInitialState', 
          value: defaultState
        }); 
        setTimeout(()=>{
          apiRef.current.restoreState(defaultState);
          // there is an open issue with restoreState not restoring columnVisibilityModel
          // have to restore it separately with setColumnVisibilityModel.  Can remove this in future
          // once they have restoreState restoring the full state of everything
          apiRef.current.setColumnVisibilityModel(defaultState?.columns?.columnVisibilityModel);
          setOpen(false);
        },0)
    },[getDefaultGridState, setUserOptions, apiRef]);

    return (
      <>
        <Button key='pltb1'
                ref={anchorRef}
                id="projectLogtableSettingsButton"
                aria-controls={open ? 'projectLogTableSettings' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}>
            <SettingsIcon/>
        </Button>
        <Popper open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                sx={{zIndex:9}}
                transition
                >
                {({ TransitionProps, placement }) => (
                    <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === 'bottom-start' ? 'left top' : 'left bottom',
                    }}
                  >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList 
                                  autoFocusItem={open} 
                                  id="projectLogTableSettings" 
                                  onKeyDown={handleListKeyDown}
                                  aria-labelledby='projectLogtableSettingsButton'
                                >
                                  <MenuItem onClick={handleDefaultNewTab} key='pltm1'>
                                      {defaultOpenInNewTab ? <CheckBoxIcon/> : <CheckBoxOutlineBlankIcon/> }&nbsp;Default Open In New Tab
                                  </MenuItem>
                                  <MenuItem onClick={handleApplyFilterToDocket} key='pltm2'>
                                      {applyFilterToDocket ? <CheckBoxIcon/> : <CheckBoxOutlineBlankIcon/> }&nbsp;Apply Filter To Docket
                                  </MenuItem>
                                  <MenuItem onClick={handleIncludeArchived} key='pltm2'>
                                      {includeArchived ? <CheckBoxIcon/> : <CheckBoxOutlineBlankIcon/> }&nbsp;Include Archived Records
                                  </MenuItem>
                                  <MenuItem onClick={handleResetProjectLogTableSettings} key='pltm3'>
                                      <SettingsBackupRestoreIcon/>&nbsp;Restore Table Defaults
                                  </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
      </>
    );
  }
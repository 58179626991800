import {Checkbox} from '@mui/material';
import {useCallback} from 'react';
import * as React from 'react';
import {useStoreActions} from '~/store/storeHooks';
import {ProjectTeamMember} from '~/types';

interface POCCheckboxProps {
    row: ProjectTeamMember;
    isHM: boolean;
}

export default function POCCheckbox({row, isHM}: POCCheckboxProps): JSX.Element {
    const toggleTeamMemberPOC = useStoreActions((actions) => actions.subsite.toggleTeamMemberPOC);
    const {id, poc} = row;

    const handleClick = useCallback(
        (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.stopPropagation();
            toggleTeamMemberPOC({id, poc: !poc});
        },
        [id, poc, toggleTeamMemberPOC],
    );

    return (
        <Checkbox
            checked={poc}
            color="default"
            size="medium"
            disabled={isHM}
            readOnly={isHM}
            onClick={handleClick}
        />
    );
}

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Badge, Button, Collapse, IconButton, useMediaQuery} from '@mui/material';
import {useCallback, useEffect, useMemo, useState} from 'react';
import * as React from 'react';
import {useMatch, useNavigate, useParams} from 'react-router-dom';
import NavBarContext from '../app/navbar/NavBarContext';
import ChatHistory from '~/components/chat/ChatHistory';
import ChatHistoryContext from '~/components/chat/ChatHistoryContext';
import ChatInput from '~/components/chat/ChatInput';
import {useStoreActions, useStoreState} from '~/store/storeHooks';
import styles from '~/styles/ChatButton.module.scss';


interface ChatButtonProps {
    target: string;
    foreignKey?: number;
}

ChatButton.defaultProps = {
    foreignKey: -1,
};

function useIsChatPage() {
    const match = useMatch('/project/:projectId/:page');
    if (!match?.params.page) {
        return false;
    }

    return match.params.page === 'chat';
}

export default function ChatButton({target, foreignKey}: ChatButtonProps): JSX.Element {
    const navigate = useNavigate();
    const {projectId} = useParams<{projectId: string}>();
    const isChatPage = useIsChatPage();
    // eslint-disable-next-line no-unneeded-ternary
    const {expanded, setExpanded} = React.useContext(NavBarContext);
    const currentUser = useStoreState((state) => state.shared.currentUser);
    const updateCommentsRead = useStoreActions((actions) => actions.comment.updateCommentsRead);
    const [selectedComment, setSelectedComment] = useState(null);
    const mobile = useMediaQuery('(max-width: 600px)');

    const comments = useStoreState((state) => state.comment.comments);
    const unreadComments = comments.filter((o) => !o.read);

    
    // explicitly close on unmount if they switch to dashboard without closing and resetting scroll bar
    useEffect(() => ()=> {
        setExpanded('');
    }, [setExpanded]);

    const commentSet = useMemo(() => {
        let set = comments;
        switch (target) {
            case 'document':
                if (foreignKey > 0) {
                    set = set.filter((c) => c.document && c.document.id === foreignKey);
                }
                break;
            case 'global':
            default:
                break;
        }
        return set;
    }, [comments, foreignKey, target]);

    const handleClick = useCallback(
        (e: React.MouseEvent): void => {
            e.stopPropagation();
            if (isChatPage) {
                setExpanded('');
                navigate(-1);
            } else if (mobile) {
                navigate(`/project/${projectId}/chat`);
            } else {
                setExpanded(expanded === 'chat' ? '' : 'chat');
            }
        },
        [expanded, isChatPage, mobile, navigate, projectId,setExpanded],
    );

    const onLoadedCallback = useCallback((): void => {
        updateCommentsRead({
            projectId: parseInt(projectId, 10),
            docId: null,
        });
    }, [projectId, updateCommentsRead]);

    const chatHistoryContext = useMemo(
        () => ({currentUser: currentUser.id, selectedComment, setSelectedComment, mobile, target}),
        [currentUser.id, mobile, selectedComment, target],
    );

    const unexpand = useCallback((): void => {
        setExpanded('');
    }, [setExpanded]);

    return (
        currentUser && (
            <div id="chat-button-root" className={styles.root}>
                <IconButton onClick={handleClick} aria-label="chat messages">
                    <Badge badgeContent={unreadComments.length} color="error" max={100}>
                        <FontAwesomeIcon icon={['fad', 'comments-alt']} className={styles.chatIcon} />
                    </Badge>
                </IconButton>
                {!mobile && (
                    <ChatHistoryContext.Provider value={chatHistoryContext}>
                        <Collapse in={expanded === 'chat'} timeout={800} className={styles.chatCollapse} mountOnEnter unmountOnExit>
                            <div className={styles.header}>
                                <h6>Messages</h6>
                                <div className={styles.buttonContainer}>
                                    <Button id="close-timeline-button" onClick={unexpand} variant='text' color='inherit'>
                                        <p>Close</p>
                                    </Button>
                                </div>
                            </div>
                            <div className={styles.content} id="comments-root">
                                <ChatHistory comments={commentSet} onLoadedCallback={onLoadedCallback} />
                            </div>
                            <div className={styles.chatInputContainer}>
                                <ChatInput />
                            </div>
                        </Collapse>
                    </ChatHistoryContext.Provider>
                )}
            </div>
        )
    );
}

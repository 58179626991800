interface ScroollToDivProps {
    id: string;
    top?: number;
}

ScrollToDiv.defaultProps = {
    top: -60
}

export default function ScrollToDiv({id, top}: ScroollToDivProps): JSX.Element {
    return (
        <div style={{position:'relative',zIndex:-1}}>
            <div style={{position:'absolute', top, bottom: -200}} id={id}/>
        </div>
    )
}
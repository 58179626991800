import {ReactNode} from 'react';
import UserFeedbackFabButton from './feedbackFAB/UserFeedbackFabButton';
import NavBar from './navbar/NavBar';
import ScrollToTopButton from './ScrollToTopButton';
import {SharedFramework} from './SharedFramework';

interface PageFrameworkProps {
    subsite?: boolean;
    children: ReactNode;
}

PageFramework.defaultProps = {
    subsite: false,
};

export function PageFramework({subsite, children}: PageFrameworkProps): JSX.Element {
    return (
        <SharedFramework>
            <NavBar />
            {children}
            <UserFeedbackFabButton />
            <ScrollToTopButton />
        </SharedFramework>
    );
}

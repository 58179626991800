
import {useCallback, useEffect} from 'react';
import ProjectPageNavMenuOptions from '~/components/app/navmenu/ProjectPageNavMenuOptions';

interface DashboardNavMenuOptionsProps {
    open: boolean;
    handleToggle: () => void;
}

export default function NavMenuOptions({open, handleToggle}: DashboardNavMenuOptionsProps): JSX.Element {

    const collapseAll = useCallback((): void => {

        if (open) {
            handleToggle();
        }
    }, [handleToggle, open]);

    useEffect(() => {
        if (!open) {
            collapseAll();
        }
    }, [open, collapseAll]);

    return <ProjectPageNavMenuOptions handleToggle={collapseAll} />
}

import { Box } from "@mui/system";
import { GridInitialState, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid-pro";
import { ProjectLogTableSearchBar } from './ProjectLogTableSearchBar';
import { ProjectLogTableSettings } from './ProjectLogTableSettings';

export function ProjectLogTableToolbar(props: {getDefaultGridState: ()=>GridInitialState;}) {
    return (
      
      <GridToolbarContainer>
        <Box sx={{width: '100%'}}>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
          <GridToolbarExport />
          <ProjectLogTableSettings getDefaultGridState={props.getDefaultGridState}/>
          <ProjectLogTableSearchBar/>
        </Box>
      </GridToolbarContainer>
      
    );
  }
import {useMediaQuery} from '@mui/material';
import ProjectLogTable from './table/ProjectLogTable';
import ProjectLogList from '~/components/dashboard/project-log/list/ProjectLogList';
import {usePermissions} from '~/hooks/usePermissions';
import styles from '~/styles/ProjectLog.module.scss';

export default function ProjectLog(): JSX.Element {
    const mobile = useMediaQuery('(max-width: 600px)');
    const view = usePermissions();

    if (view === undefined) return <></>; // dont initialize the components until we know for sure what user can see to prevent flashing or resizing

    return (
        <div className={styles.root} id="project-log-root">
            {mobile ? <ProjectLogList/> : <ProjectLogTable viewCommercial={view}/>}
        </div>
    );
}

import {useContext} from 'react';
import ProjectContext from '~/components/dashboard/project-log/ProjectContext';
import NoDataPlaceholder from '~/components/generics/NoDataPlaceholder';
import MilestoneListItem from '~/components/subsite/summary/milestone/MilestoneListItem';
import styles from '~/styles/MilestoneList.module.scss';

export default function MilestoneList(): JSX.Element {
    const {project} = useContext(ProjectContext);
    const {milestones} = project;
    return milestones?.length > 0 ? (
        <div className={styles.root} id="milestone-list-root">
            <ul className={styles.list}>
                {milestones.map((m) => (
                    <MilestoneListItem milestone={m} key={m.id} />
                ))}
            </ul>
        </div>
    ) : (
        <NoDataPlaceholder title="No Milestones" />
    );
}

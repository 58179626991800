import {Button, ClickAwayListener, Grow, Popper} from '@mui/material';
import {usePopupState, bindToggle, bindPopper} from 'material-ui-popup-state/hooks';
import {useCallback} from 'react';
import * as React from 'react';
import {Link} from 'react-router-dom';
import styles from '~/styles/ContactNav.module.scss';

// eslint-disable-next-line react/prop-types
export default function ContactNav({handleClose}: {handleClose: ()=>void;}): React.ReactElement {
    const popupState = usePopupState({variant: 'popper', popupId: 'contactMenu'});
    const [arrowRef, setArrowRef] = React.useState(null);

    const handleOptionSelected = useCallback((): void => {
        popupState.close();
        handleClose();
    }, [popupState,handleClose]);

    const handleClickAway = useCallback(() => {
        popupState.close();
    }, [popupState]);

    const sendTechSupportEmail = (): void => {
        const mailto = 'helpdesk@hm-ec.com';
        const subject = 'Capital Projects Portal Support Request';

        window.location.href = `mailto:${mailto}?subject=${subject}`;
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className={styles.root}>
                <Button variant="contained" {...bindToggle(popupState)} className={`${styles.menuButton} ${popupState.isOpen ? styles.open : ''}`}>
                    Contact
                </Button>
                <Popper
                    {...bindPopper(popupState)}
                    transition
                    placement="left-start"
                    disablePortal
                    modifiers={[
                        {
                            name: 'arrow',
                            enabled: true,
                            options: {
                                element: arrowRef,
                            },
                        },
                    ]}
                    className={styles.popper}
                >
                    {({TransitionProps}): React.ReactElement => (
                        <Grow {...TransitionProps}>
                            <div>
                                <span className={styles.arrow} ref={setArrowRef} />
                                <div className={styles.menuContainer}>
                                    <Link to="/opportunity" onClick={handleOptionSelected}>
                                        <div className={styles.doubleWidthMenuItem}>
                                            <p>Project Request</p>
                                        </div>
                                    </Link>
                                    <Link to="/customerService" onClick={handleOptionSelected}>
                                        <div className={styles.doubleWidthMenuItem}>
                                            <p>Customer Service</p>
                                        </div>
                                    </Link>
                                    <div
                                        className={styles.doubleWidthMenuItem}
                                        onClick={sendTechSupportEmail}
                                        onKeyDown={sendTechSupportEmail}
                                        role="button"
                                        tabIndex={0}
                                    >
                                        <p>Technical Support</p>
                                    </div>
                                </div>
                            </div>
                        </Grow>
                    )}
                </Popper>
            </div>
        </ClickAwayListener>
    );
}

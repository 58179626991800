import {Typography} from '@mui/material';
import {trackEvent} from '~/api';
import {formatPhoneNumber} from '~/helpers';
import styles from '~/styles/EPCServiceCard.module.scss';
import {Contact} from '~/types';

interface EPCServiceCardProps {
    contact: Contact;
}

export default function EPCServiceCard({contact}: EPCServiceCardProps): JSX.Element {
    const onClick = () => {
        trackEvent(`email ${contact.title}`, {email: contact.email});
    };

    return (
        <>
            <Typography align="center" variant="h4" className={styles.Title}>
                {contact.title}
            </Typography>
            <Typography align="center" variant="subtitle1" className={styles.Address}>
                <a id="address" href={`https://maps.google.com/?q=${contact.address1}, ${contact.address2}`} target="_blank" rel="noreferrer">
                    {contact.address1}
                    <br />
                    {contact.address2}
                </a>
            </Typography>
            {contact.phone && (
                <Typography align="center" variant="subtitle2" className={styles.Phone}>
                    <a id="phone" href={`tel:${contact.phone}`}>
                        {formatPhoneNumber(contact.phone)}
                    </a>
                </Typography>
            )}
            {contact.email && (
                <Typography align="center" variant="subtitle2" className={styles.Email}>
                    <a href={`mailto:${contact.email}`} onClick={onClick}>
                        {contact.email}
                    </a>
                </Typography>
            )}
        </>
    );
}

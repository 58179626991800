import differenceInDays from 'date-fns/differenceInDays';
import parse from 'html-react-parser';
import {useCallback, useMemo} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import SwipeStackCard from '~/components/generics/SwipeStackCard';
import {CleanSunEditorHTML, OnEnterPressWrapper} from '~/helpers';
import {useStoreState} from '~/store/storeHooks';
import styles from '~/styles/CorrespondenceCard.module.scss';
import {Hold} from '~/types';

interface HoldCardProps {
    index: number;
    hold: Hold;
    projectLabel: boolean;
}

export default function HoldCard({index, hold, projectLabel}: HoldCardProps): JSX.Element {
    const navigate = useNavigate();
    const projectSubset = useStoreState((state) => state.project.projectSubset);
    const projectCode = projectSubset.find((p) => p.id === hold.projectId)?.code ?? 'Unknown Project Code';
    const href: string = useMemo(()=>`/project/${hold.projectId}/correspondence?hold=${hold.id}`,[hold.projectId,hold.id]);
    const handleClick = useCallback((): void => {
        navigate(href);
    }, [navigate, href]);

    const {number, description, requestor, dueDate} = hold;

    return (
        <SwipeStackCard cardIndex={index}>
            <div className={styles.root}>
                <h4 className={styles.title}>
                    <Link to={href} onKeyDown={OnEnterPressWrapper(handleClick)}>
                        {`${number}`}
                    </Link>
                </h4>
                {projectLabel && <p className={styles.projectLabel}>{projectCode}</p>}
                <h6 className={styles.subheader}>Description</h6>
                <div className={styles.description}>{parse(CleanSunEditorHTML(description))}</div>
                <div className={styles.flexContainer}>
                    <div>
                        <h6 className={styles.subheader}>Requestor</h6>
                        <p>{requestor}</p>
                    </div>
                    <div>
                        <h6 className={styles.subheader}>Due Date</h6>
                        <p>{new Date(dueDate).toLocaleDateString()}</p>
                    </div>
                </div>
                <p className={styles.openDuration}>{`Open ${differenceInDays(new Date(), hold.created)} Days`}</p>
            </div>
        </SwipeStackCard>
    );
}

import {initializeApp, FirebaseApp, FirebaseOptions} from 'firebase/app';
import {getAuth, sendPasswordResetEmail, signInWithEmailAndPassword, browserLocalPersistence, browserSessionPersistence, Auth} from 'firebase/auth';
import {FBUserAdapter} from './FBUserAdapter';

export class FBAppAdapter {
    private app: FirebaseApp;
    private auth: Auth;
    private user: FBUserAdapter;

    constructor(options: FirebaseOptions) {
        this.app = initializeApp(options);
        this.auth = getAuth(this.app);
    }

    public authErrors = {
        UserNotFound: 'auth/user-not-found',
        InvalidEmail: 'auth/invalid-email',
        WrongPassword: 'auth/wrong-password',
        EmailAlreadyInUse: 'auth/email-already-in-use',
        RequiresRecentLogin: 'auth/requires-recent-login',
    } as const;

    public get currentUser(): FBUserAdapter | null {
        const {currentUser} = this.auth;

        if (currentUser?.email !== this.user?.email) {
            this.user = new FBUserAdapter(currentUser);
        }

        return this.user;
    }

    public handleAuthError(err: unknown, defaultMesssage: string = 'An unknown error occurred.'): [string, string] {
        // eslint-disable-next-line no-console
        console.error(err);

        if (this.isFirebaseError(err)) {
            const {code, message} = err;

            switch (code) {
                case this.authErrors.UserNotFound: {
                    return ['email', 'The user was not found.'];
                }

                case this.authErrors.InvalidEmail: {
                    return ['email', 'The email address is incorrectly formatted.'];
                }

                case this.authErrors.WrongPassword: {
                    return ['password', 'Wrong password.'];
                }

                case this.authErrors.EmailAlreadyInUse: {
                    return ['email', 'That email address is already in use.'];
                }

                case this.authErrors.RequiresRecentLogin: {
                    return ['email', 'Please sign out and try again.'];
                }

                default: {
                    return ['unknown', message ?? defaultMesssage];
                }
            }
        } else {
            return ['unknown', String(err ?? defaultMesssage)];
        }
    }

    public onAuthStateChanged(next: (user: FBUserAdapter | null) => void, error?: (e: Error) => void, completed?: () => void): () => void {
        return this.auth.onAuthStateChanged((u) => next(u ? new FBUserAdapter(u) : null), error, completed);
    }

    public signOut(): Promise<void> {
        return this.auth?.signOut();
    }

    public async signIn(email: string, password: string, rememberMe?: boolean | undefined): Promise<FBUserAdapter | null> {
        if (!email) {
            throw new Error('Email is required');
        }

        if (!password) {
            throw new Error('Password is required');
        }

        try {
            if (rememberMe !== undefined) {
                const persistence = rememberMe ? browserLocalPersistence : browserSessionPersistence;
                await this.auth.setPersistence(persistence);
            }

            // await this.auth.signOut();
            const cred = await signInWithEmailAndPassword(this.auth, email, password);
            const newUser = cred.user;
            return newUser ? new FBUserAdapter(newUser) : null;
        } catch (err) {
            this.auth.signOut();
            throw err;
        }
    }

    public isFirebaseError(err: any): err is {code: string; message?: string} {
        return typeof err === 'object' && typeof err.code === 'string';
    }

    public sendPasswordResetEmail(email: string) {
        if (!email) {
            throw new Error('Email is required');
        }

        return sendPasswordResetEmail(this.auth, email);
    }
}

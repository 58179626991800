/* eslint-disable react-hooks/exhaustive-deps */
import useMediaQuery from '@mui/material/useMediaQuery';
import {
    DataGridPro,
    useGridApiRef,
    DataGridProProps,
    GRID_TREE_DATA_GROUPING_FIELD,
    GridValueGetterParams,
    GridRowClassNameParams,
    GridColDef,
    GridRowParams,
    GridRowIdGetter,
    GridDensity,
    GridState,
} from '@mui/x-data-grid-pro';
import {ReactElement, useCallback, useEffect, useMemo, useState} from 'react';
import {DataGridProTableToolbar} from './DataGridProToolbar';
import NoDataPlaceholder from '~/components/generics/NoDataPlaceholder';
import {defaultMediaSizes} from '~/helpers';
import {useStoreState, useStoreActions} from '~/store/storeHooks';
import styles from '~/styles/DataGridPro.module.scss';
import {GridInitialStateWithDensity} from '~/types';

const baseTableCacheVersion = 1;
let saveOptionsTimeout: ReturnType<typeof setTimeout> = null;

interface DataGridProTableProps {
    columns: GridColDef[];
    data: any[];
    optionKey: string;
    cacheVersion: number;
    handleRowClick?: (params: GridRowParams) => void;
    addButton?: ReactElement;
    treeField?: string;
    csvFileName?: string;
    toolbar?: boolean;
    getRowClassName?: (params: GridRowClassNameParams) => string;
    noAutoHeight?: boolean;
    getRowId?: (row: GridRowIdGetter) => string | number;
    density?: GridDensity;
    components?: any;
    toolbarHide?: {[key: string]: boolean};
    pinnedColumnsLeft?: string[];
    exportAllColumns?: boolean;
    excelOptions?: {tableName: string; projectId: number; projectCode: string};
}

DataGridProTable.defaultProps = {
    handleRowClick: null,
    addButton: null,
    treeField: 'descendent',
    csvFileName: 'data.csv',
    toolbar: true,
    getRowClassName: null,
    noAutoHeight: false,
    getRowId: null,
    density: null,
    components: null,
    toolbarHide: {},
    pinnedColumnsLeft: null,
    exportAllColumns: false,
    excelOptions: {},
};

export default function DataGridProTable({
    columns,
    data,
    optionKey,
    cacheVersion,
    handleRowClick,
    addButton,
    treeField,
    csvFileName,
    toolbar,
    getRowClassName,
    noAutoHeight,
    getRowId,
    density,
    components,
    toolbarHide,
    pinnedColumnsLeft,
    exportAllColumns,
    excelOptions,
}: DataGridProTableProps): JSX.Element {
    const {userOptions, tableSearchText} = useStoreState((state) => state.shared);
    const {setUserOptions} = useStoreActions((actions) => actions.shared);
    const [tableVersion, setTableVersion] = useState(0);
    const [pinChange, setPinChange] = useState(false);
    const [resetting, setResetting] = useState(false);
    const mediaSizes = defaultMediaSizes;
    const xxs = useMediaQuery(mediaSizes.xxs);
    const xs = useMediaQuery(mediaSizes.xs);
    const sm = useMediaQuery(mediaSizes.sm);
    const md = useMediaQuery(mediaSizes.md);
    const lg = useMediaQuery(mediaSizes.lg);

    const apiRef = useGridApiRef();

    const combinedCacheVersion = cacheVersion + baseTableCacheVersion;

    useEffect(() => {
        if (userOptions?.genericTableOptions?.cacheVersions?.[optionKey] !== combinedCacheVersion) {
            setUserOptions({
                option: 'genericTableOptions',
                key: optionKey,
                value: {},
            });
            const cacheVersions = userOptions?.genericTableOptions?.cacheVersions ? userOptions.genericTableOptions.cacheVersions : {};
            setUserOptions({
                option: 'genericTableOptions',
                key: 'cacheVersions',
                value: {...cacheVersions, [optionKey]: combinedCacheVersion},
            });
        }
    }, [userOptions, optionKey, combinedCacheVersion, setUserOptions]);

    const tableSizeKey = useMemo(() => {
        if (xxs) return 'xxSmallTable';
        if (xs) return 'extraSmallTable';
        if (sm) return 'smallTable';
        if (md) return 'mediumTable';
        if (lg) return 'largeTable';
        return 'extraLargeTable';
    }, [xxs, xs, sm, md, lg]);

    const isTreeData: boolean = useMemo(() => {
        let isTree = false;
        let possibleTree = false;
        // Due to data inconsistency with parent being deleted adn children being orphaned, for now
        // protecting from orphans on client side, need to fix this at the source though
        const idLookup: any = {};
        data.forEach((row) => {
            idLookup[row.id] = true;
            if (row.descendent) possibleTree = true;
        });
        if (possibleTree) {
            data.forEach((row, index) => {
                if (row.descendent) {
                    if (idLookup[row.descendent]) {
                        isTree = true;
                    } else {
                        // eslint-disable-next-line no-param-reassign
                        data[index].descendent = null;
                    }
                }
            });
        }

        return isTree;
    }, [data]);

    useEffect(() => {
        if (isTreeData && columns?.[0]?.field === 'number') columns.splice(0, 1);
    }, [isTreeData, columns]);

    const fixLeftColumn = (initState: any) => {
        if (!initState?.columns || !initState?.pinnedColumns) return initState;
        const {pinnedColumns} = initState;
        const index = isTreeData ? 1 : 0;
        if (pinnedColumns?.left?.[index] && initState?.columns?.orderedFields?.length) {
            const updatedInitState = initState;
            const leftColumn = pinnedColumns?.left?.[index];
            updatedInitState.columns.orderedFields = initState.columns.orderedFields.filter((c: string) => c !== leftColumn);
            updatedInitState.columns.orderedFields.splice(index, 0, leftColumn);
            return updatedInitState;
        }
        return initState;
    };

    const fixTreeData = (initState: any) => {
        const updatedInitState = initState;
        if (isTreeData) {
            if (updatedInitState?.pinnedColumns?.left?.length && updatedInitState.pinnedColumns.left[0] !== GRID_TREE_DATA_GROUPING_FIELD) {
                updatedInitState.pinnedColumns.left.splice(0, 0, GRID_TREE_DATA_GROUPING_FIELD);
            } else if (!updatedInitState?.pinnedColumns?.left?.length) {
                if (!updatedInitState.pinnedColumns) updatedInitState.pinnedColumns = {};
                updatedInitState.pinnedColumns.left = [GRID_TREE_DATA_GROUPING_FIELD];
            }
            if (updatedInitState?.columns?.orderedFields?.length && updatedInitState.columns?.orderedFields?.[0] !== GRID_TREE_DATA_GROUPING_FIELD) {
                if (!updatedInitState?.columns?.orderedFields) updatedInitState.columns.orderedFields = [];
                updatedInitState.columns.orderedFields.splice(0, 0, GRID_TREE_DATA_GROUPING_FIELD);
            }
        }
        return updatedInitState;
    };

    let initialState = useMemo(() => {
        let initState: any = {};
        if (resetting) return {};
        const {genericTableOptions} = userOptions;
        if (genericTableOptions && genericTableOptions?.cacheVersions?.[optionKey] === combinedCacheVersion) {
            if (genericTableOptions[optionKey]) {
                initState = genericTableOptions[optionKey];
            }
        }

        if (initState?.[tableSizeKey]?.columns) {
            initState.columns = initState[tableSizeKey].columns;
        }

        if (initState?.[tableSizeKey]?.pinnedColumns?.left) {
            initState.pinnedColumns = initState[tableSizeKey].pinnedColumns;
        } else if (initState.pinnedColumns) {
            initState.pinnedColumns = null;
        }

        if (pinnedColumnsLeft) {
            if (!initState?.pinnedColumns) initState.pinnedColumns = {};
            initState.pinnedColumns.left = pinnedColumnsLeft;
        }
        initState = fixTreeData(initState);
        initState = fixLeftColumn(initState);
        return initState;
    }, [combinedCacheVersion, optionKey, pinnedColumnsLeft, tableSizeKey, isTreeData, userOptions]);

    const saveTableChange = useCallback(
        (trigger: string): void => {
            if (apiRef?.current?.exportState) {
                const exportedState: GridInitialStateWithDensity = apiRef.current.exportState();

                if (trigger === 'newTableSizeToSave') {
                    const defObj = getDefaultGridState();
                    exportedState.columns = defObj.columns;
                    exportedState.pinnedColumns = defObj.pinnedColumns;
                }

                // known issue with exportState and columnVisibility not working right
                // so explicity set it before saving to localStorage
                if (
                    exportedState?.columns &&
                    !exportedState?.columns?.columnVisibilityModel &&
                    apiRef.current.state?.columns?.columnVisibilityModel
                ) {
                    exportedState.columns = {
                        ...exportedState.columns,
                        columnVisibilityModel: apiRef.current.state.columns.columnVisibilityModel,
                    };
                }
                exportedState.density = apiRef.current.state?.density?.value;
                if (exportedState.preferencePanel) delete exportedState.preferencePanel;
                let value = {
                    ...initialState,
                    ...exportedState,
                    density: apiRef?.current?.state?.density?.value,
                    [tableSizeKey]: {
                        columns: exportedState.columns,
                        pinnedColumns: exportedState.pinnedColumns,
                    },
                };
                value = fixTreeData(value);
                value = fixLeftColumn(value);
                initialState = value;
                if (trigger === 'newTableSizeToSave') {
                    setUserOptions({
                        option: 'genericTableOptions',
                        key: optionKey,
                        value,
                    });
                } else {
                    // debounce / delay saves of state change as the change is triggered rapidly while user is dragging around a column or default settings are restored trigger 4 rapid state changes
                    if (saveOptionsTimeout) clearTimeout(saveOptionsTimeout);
                    saveOptionsTimeout = setTimeout(() => {
                        saveOptionsTimeout = null;
                        setUserOptions({
                            option: 'genericTableOptions',
                            key: optionKey,
                            value,
                        });
                    }, 2000);
                }

                const index = isTreeData ? 1 : 0;
                const pinnedColumnLeft = initialState?.columns?.pinnedColumns?.left?.[index];
                const leftColumn = initialState?.columns?.orderedFields?.[index];
                if (trigger === 'pinned' || (pinnedColumnLeft && pinnedColumnLeft !== leftColumn)) {
                    // pinning a column also changes its order but the order change is not caught with pinned column change and column order change is not triggered
                    // so triggering another state change after the pinned change and then it will catch the new column order in a state change
                    setPinChange(true);
                }
            }
        },
        [apiRef, optionKey, setUserOptions, tableSizeKey, initialState],
    );

    useEffect(() => {
        // using tableversions as a key in the div wrapping table so screen size changes will trigger data grid table to render as a new component
        // which will pick up changes to the columns that are based on screen size
        let newTableVersion = tableVersion;
        if (lg) newTableVersion += 3;
        if (md) newTableVersion += 4;
        if (sm) newTableVersion += 5;
        if (xs) newTableVersion += 6;
        if (xxs) newTableVersion += 7;
        if (data?.length) {
            newTableVersion = tableVersion + data.length + 20;
        } else {
            newTableVersion += 10;
        }
        setTableVersion(newTableVersion);
    }, [xxs, xs, sm, md, lg, data]);

    useEffect(() => {
        if (
            userOptions?.genericTableOptions?.[optionKey]?.columns &&
            initialState?.columns &&
            !initialState?.[tableSizeKey] &&
            apiRef?.current?.exportState &&
            !resetting
        ) {
            // we've never saved options for this table size so save them to lock them in to current options
            saveTableChange('newTableSizeToSave');
        }
    }, [tableSizeKey, initialState, userOptions, resetting]);

    const [savedDensity, setSavedDensity] = useState(initialState?.density ?? 'standard');

    const handleStateChange = useCallback(
        (state: GridState): void => {
            if (resetting) {
                setTimeout(() => setResetting(false), 0);
                return;
            }
            if (pinChange) {
                setPinChange(false);
                saveTableChange('afterPinChange');
            }
            // density doesn't have a specific state change handle
            // and doesn't get saved in exportState as of yet
            // so have to look for changes here in every state change
            if (state?.density?.value !== savedDensity) {
                setSavedDensity(state.density.value);
                saveTableChange('density');
            }
        },
        [savedDensity, saveTableChange, resetting],
    );

    // eslint-disable-next-line no-nested-ternary
    const maxRowsAllowed = savedDensity === 'compact' ? 11 : savedDensity === 'standard' ? 8 : 6;

    // eslint-disable-next-line no-unneeded-ternary
    const tableAutoHeight = data?.length && data.length < maxRowsAllowed ? true : false;

    const getDefaultGridState: (reason?: string) => GridInitialStateWithDensity = useCallback(
        (reason) => {
            // need to save default state for when user wants to restore table to default state
            // dataGrid pro restoreState doesn't currently support restoring to default but requires
            // explicit state passed in as argument so here we are saving the default state.
            const defaultTableState: GridInitialStateWithDensity = {
                filter: {
                    filterModel: {
                        items: [],
                    },
                },
                sorting: {
                    sortModel: [],
                },
                pinnedColumns: {
                    left: isTreeData ? [GRID_TREE_DATA_GROUPING_FIELD] : [],
                    right: [],
                },
                columns: {
                    orderedFields: [],
                    dimensions: {},
                    columnVisibilityModel: {},
                },
                density: 'standard',
            };
            defaultTableState.density = 'standard';
            if (isTreeData && columns?.[0]?.field === 'number') columns.splice(0, 1);
            // Using values from column definitions to set the default state object values
            columns.forEach((col) => {
                defaultTableState.columns.orderedFields.push(col.field);
                defaultTableState.columns.columnVisibilityModel[col.field] = !col.hide;
                const minWidth: number = col.minWidth ? col.minWidth : col.width;
                const width: number = col.width ? col.width : minWidth;
                defaultTableState.columns.dimensions[col.field] = {
                    width: width !== undefined ? width : 100,
                    maxWidth: col.maxWidth ? col.maxWidth : -1,
                    minWidth: minWidth !== undefined ? minWidth : 100,
                };
            });
            if (reason === 'resetting') {
                setResetting(true);
            }
            return defaultTableState;
        },
        [columns, isTreeData, tableSizeKey],
    );

    const groupingColDef: DataGridProProps['groupingColDef'] = {
        headerName: 'Number',
        valueGetter: (params: GridValueGetterParams): string => (params.row[treeField] ? '' : params.row.number),
        width: 200,
    };

    const searchText = tableSearchText?.[optionKey]?.toLowerCase();

    const searchableText: any = useMemo(() => {
        const lookupObj: any = {};
        if (!apiRef?.current?.state?.columns?.lookup) return lookupObj;
        data.forEach((row) => {
            lookupObj[row.id] = '';
            for (let i = 0; i < columns.length; i++) {
                const {field} = columns[i];
                const {lookup} = apiRef.current.state.columns;
                const value = lookup?.[field]?.valueGetter
                    ? lookup[field].valueGetter({
                          row,
                          value: row[field],
                          field,
                          api: null,
                          id: row.id,
                          rowNode: null,
                          colDef: null,
                          cellMode: null,
                          tabIndex: 0,
                          hasFocus: null,
                          getValue: null,
                      })
                    : row[field];
                if (value && typeof value === 'string') {
                    lookupObj[row.id] = `${lookupObj[row.id]}${value.toLowerCase()}`;
                }
            }
        });
        return lookupObj;
    }, [data, apiRef?.current?.state?.columns?.lookup]);

    const searchTable = (row: any) => {
        if (!searchText || !searchableText[row.id]) return true;
        return searchableText[row.id].indexOf(searchText) > -1;
    };

    const visibleRows = useMemo(() => {
        if (!searchText?.length || !data?.length) return data;
        return data.filter(searchTable);
    }, [data, searchText]);

    const getTreeDataPath: DataGridProProps['getTreeDataPath'] = (row) => (row[treeField] ? [row[treeField], row.id] : [row.id]);

    return (
        <div
            className={`${styles.tableRoot} ${!data?.length ? styles.nodata : (!tableAutoHeight || noAutoHeight) && styles.bigdata}`}
            key={`v${tableVersion.toString()}`}>
            <DataGridPro
                initialState={initialState}
                rows={visibleRows}
                columns={columns}
                treeData={isTreeData}
                getTreeDataPath={isTreeData ? getTreeDataPath : undefined}
                groupingColDef={isTreeData ? groupingColDef : undefined}
                autoHeight={tableAutoHeight && !noAutoHeight}
                density={density || savedDensity}
                components={{
                    Toolbar: toolbar ? DataGridProTableToolbar : undefined,
                    NoRowsOverlay: NoDataPlaceholder,
                    ...(components || {}),
                }}
                componentsProps={{
                    toolbar: toolbar
                        ? {
                              csvOptions: {fileName: csvFileName, allColumns: exportAllColumns},
                              excelOptions,
                              addButton,
                              getDefaultGridState,
                              optionKey,
                              toolbarHide,
                              searchBar: !toolbarHide?.searchBar && data?.length > 1,
                          }
                        : {},
                }}
                getRowClassName={getRowClassName}
                onRowClick={handleRowClick}
                disableMultipleSelection
                apiRef={apiRef}
                getRowId={getRowId}
                onStateChange={handleStateChange}
                onColumnOrderChange={() => saveTableChange('columnOrder')}
                onColumnWidthChange={() => saveTableChange('columnWidth')}
                onSortModelChange={() => saveTableChange('sort')}
                onFilterModelChange={() => saveTableChange('filter')}
                onPinnedColumnsChange={() => saveTableChange('pinned')}
                onColumnVisibilityModelChange={() => saveTableChange('visible')}
                onPreferencePanelClose={() => saveTableChange('panelClose')}
                hideFooter={(tableAutoHeight && !noAutoHeight) || !data?.length}
            />
        </div>
    );
}

import {useState, useEffect} from 'react';
import SummaryCard from '~/components/generics/SummaryCard';
import ProjectCorrespondenceStack from '~/components/subsite/summary/ProjectCorrespondenceStack';
import ActiveProjectTypeChart from '~/components/visualizations/ActiveProjectTypeChart';
import InvoiceStatusChart from '~/components/visualizations/InvoiceStatusChart';
import LoadingComponent from '~/components/visualizations/LoadingComponent';
import ProjectValueByStatusChart from '~/components/visualizations/ProjectValueByStatusChart';
import {usePermissions} from '~/hooks/usePermissions';
import {useStoreActions, useStoreState} from '~/store/storeHooks';
import styles from '~/styles/DashboardDesktop.module.scss';

export default function DashboardDesktop(): JSX.Element {
    const getProjectSubsetInvoices = useStoreActions((actions) => actions.accounting.getProjectSubsetInvoices);
    const projectSubset = useStoreState((state) => state.project.projectSubset);
    const [loading, setLoading] = useState<boolean>(false);
    const view = usePermissions();

    useEffect(() => {
        (async function fetchData(): Promise<void> {
            if (projectSubset.length > 0) {
                setLoading(true);
                await getProjectSubsetInvoices({projectSubsetFoundationCodes: projectSubset.map((p) => p.foundationCode)});
                setLoading(false);
            }
        })();
        return (): void => setLoading(false);
    }, [getProjectSubsetInvoices, projectSubset]);

    return (
        <div className={styles.root}>
            <div className={styles.flexContainer} id="dashboard-financial-grid-root">
                {view && (
                    <>
                        <SummaryCard>
                            <div className={styles.donutChartCard}>
                                <h3 className={styles.title}>Project Value By Status</h3>
                                <ProjectValueByStatusChart />
                            </div>
                        </SummaryCard>
                        <SummaryCard>
                            <div className={styles.donutChartCard}>
                                <h3 className={styles.title}>Active Project Value By Type</h3>
                                <ActiveProjectTypeChart />
                            </div>
                        </SummaryCard>
                        {/* TODO: Unhide once we figure out the vista columns for invoices */}
                        {/* <SummaryCard>
                            <div className={styles.donutChartCard}>
                                <h3 className={styles.title}>Active Project Invoice Status</h3>
                                {loading ? <LoadingComponent contained/> : <InvoiceStatusChart />}
                            </div>
                        </SummaryCard> */}
                    </>
                )}
                <ProjectCorrespondenceStack projectLabel />
            </div>
        </div>
    );
}

import Tooltip from '@mui/material/Tooltip';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { scroller } from 'react-scroll/modules';
import { useTrackPage } from '~/api';
import ScrollToDiv from '~/components/generics/ScrollToDiv';
import CorrespondenceCountKPI from '~/components/kpi/CorrespondenceCountKPI';
import DocumentCountKPI from '~/components/kpi/DocumentCountKPI';
import KPIContainer from '~/components/kpi/KPIContainer';
import ActionItemsTable from '~/components/subsite/correspondence/actionItem/ActionItemsTable';
import HoldTable from '~/components/subsite/correspondence/hold/HoldsTable';
import NeedsTable from '~/components/subsite/correspondence/need/NeedsTable';
import RFITable from '~/components/subsite/correspondence/rfi/RFITable';
import TDRTable from '~/components/subsite/correspondence/tdr/TDRTable';
import TQTable from '~/components/subsite/correspondence/tq/TQTable';
import DocumentTable from '~/components/subsite/documentTable/DocumentTablePro';
import {useStoreState} from '~/store/storeHooks';
import styles from '~/styles/SubsiteContentPanel.module.scss';


export default function CorrespondencePanel(): JSX.Element {
    const [searchParams, setSearchParams] = useSearchParams();
    const documentCategoryForTitle = useStoreState((state) => state.document.documentCategoryForTitle);
    const notesCategory = documentCategoryForTitle('Notes');
    const clientSuppliedDocumentCategory = documentCategoryForTitle('Client Supplied Document');

    const autoScrollTo = searchParams.get('scrollTo');

    useTrackPage('Correspondence');    

    useEffect(() => {
      if (autoScrollTo) {
        searchParams.delete('scrollTo') 
        setSearchParams(searchParams);
        setTimeout(()=>{
            scroller.scrollTo(autoScrollTo, {
                smooth: true,
                duration: 500,
            });
        },500)
      }
    }, [autoScrollTo, searchParams, setSearchParams])
    
    

    return (
        <div id="notes-panel-root" className={styles.root}>
            <KPIContainer className={styles.kpiContainer}>
                <CorrespondenceCountKPI type="rfis" scrollTo="rfis-scroll-to" tooltipTitle="Navigate to RFI" />
                <CorrespondenceCountKPI type="tqs" scrollTo="tqs-scroll-to" tooltipTitle="Navigate to TQ" />
                <CorrespondenceCountKPI type="tdrs" scrollTo="tdrs-scroll-to" tooltipTitle="Navigate to Deviations" />
                <CorrespondenceCountKPI type="holds" scrollTo="holds-scroll-to" tooltipTitle="Navigate to Holds" />
                <CorrespondenceCountKPI type="needs" scrollTo="needs-scroll-to" tooltipTitle="Navigate to Needs" />
                <CorrespondenceCountKPI type="actionItems" scrollTo="action-items-scroll-to" tooltipTitle="Navigate to Action Items" />
                <DocumentCountKPI
                    categoryTitle="client supplied document"
                    title="Documents For H+M"
                    scrollTo="client-supplied-document-table-scroll-to"
                    tooltipTitle="Navigate to Documents For H+M"
                />
                <DocumentCountKPI categoryTitle="notes" title="Notes" scrollTo="notes-table-scroll-to" tooltipTitle="Navigate to Notes" />
            </KPIContainer>
            <div id="rfis-root">
                <ScrollToDiv id="rfis-scroll-to"/>
                <Tooltip title="Information requested from H+M Engineering or client by a 3rd party/subcontractor or H+M Construction.">
                    <h4 className={styles.header}>RFI</h4>
                </Tooltip>
                <RFITable />
            </div>
            <div id="tqs-root">
                <ScrollToDiv id="tqs-scroll-to"/>
                <Tooltip title="Technical queries from H+M Engineering that require a Client response.">
                    <h4 className={styles.header}>Technical Queries</h4>
                </Tooltip>
                <TQTable />
            </div>
            <div id="tdrs-root">
                <ScrollToDiv id="tdrs-scroll-to"/>
                <Tooltip title="TDRs allow H+M Engineering to request to deviate from the provided project/client specifications. This document requires a Client response.">
                    <h4 className={styles.header}>Deviation Requests</h4>
                </Tooltip>
                <TDRTable />
            </div>
            <div id="holds-root">
                <ScrollToDiv id="holds-scroll-to"/>
                <Tooltip title="When H+M engineering cannot finalize an aspect of the design prior to scheduled document issuance, a hold will be placed on the affected aspect of the document. Holds are issued to prevent purchasing, fabrication, and construction of the design element until the Hold is removed and the design is finalized.">
                    <h4 className={styles.header}>Holds</h4>
                </Tooltip>
                <HoldTable />
            </div>
            <div id="needs-root">
                <ScrollToDiv id="needs-scroll-to"/>
                <Tooltip title="Informational items or drawings that are requested / required from the Client during the kick-off, set-up, or execution of a project. This document requires a Client response">
                    <h4 className={styles.header}>Needs</h4>
                </Tooltip>
                <NeedsTable />
            </div>
            <div id="action-items-root">
                <ScrollToDiv id="action-items-scroll-to"/>
                <Tooltip title="The Action Item list documents all open and closed action items assigned to the H+M and client project teams. This document requires both Client and/or H+M Engineering to respond to “CLOSE” each item.">
                    <h4 className={styles.header}>Action Items</h4>
                </Tooltip>
                <ActionItemsTable />
            </div>
            {clientSuppliedDocumentCategory && (
                <div id="client-supplied-document-table-root">
                    <ScrollToDiv id="client-supplied-document-table-scroll-to"/>
                    <Tooltip title="This section can be used by the client to upload any documentation they wish to share with H+M and to store in the capital projects portal. Including, but not limited to, AMLs (approved manufacturers lists) and Specifications. ">
                        <h4 className={styles.header}>Documents For H+M</h4>
                    </Tooltip>
                    <DocumentTable categories={clientSuppliedDocumentCategory.id} addDocument />
                </div>
            )}
            {notesCategory && (
                <div id="notes-table-root">
                    <ScrollToDiv id="notes-table-scroll-to"/>
                    <Tooltip title="Meeting Minutes from weekly meetings, model reviews, and other project meetings. These should be reviewed and approved after meetings.">
                    <h4 className={styles.header}>Notes</h4>
                        </Tooltip>
                    <DocumentTable categories={notesCategory.id} addDocument />
                </div>
            )}
        </div>
    );
}

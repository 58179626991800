import useMediaQuery from '@mui/material/useMediaQuery';
import { GridColDef, GridRenderCellParams, getGridDateOperators } from '@mui/x-data-grid-pro';
import {useMemo} from 'react';
import {getGridStringArrayAutocompleteOperators} from '~/components/generics/MUIDataGridCustomOperators';
import {HtmlToString, defaultMediaSizes} from '~/helpers';
import {Hold} from '~/types';

const renderDate = (params: GridRenderCellParams): string => (params.value ? new Date(params.value).toLocaleDateString() : '');

const renderHtml = (params: GridRenderCellParams): string => HtmlToString(params.value);

export default function HoldsTableCols(): GridColDef<Hold>[] {
    const mediaSizes = defaultMediaSizes;
    const xxs = useMediaQuery(mediaSizes.xxs);
    const xs = useMediaQuery(mediaSizes.xs);
    const sm = useMediaQuery(mediaSizes.sm);
    const md = useMediaQuery(mediaSizes.md);
    const lg = useMediaQuery(mediaSizes.lg);

    return useMemo(
        (): GridColDef<Hold>[] => [
            {
                headerName: 'Number',
                field: 'number',
                minWidth: 120,
                flex:1,
            },
            {
                headerName: 'Description',
                field: 'description',
                renderCell: renderHtml,
                hide: xxs,
                minWidth: 120,
                flex: 2,
            },
            {
                headerName: 'Status',
                field: 'status',
                width: 120,
                flex:1,
                filterOperators: getGridStringArrayAutocompleteOperators([
                    'Closed',
                    'Open',
                ]),
            },
            {
                headerName: 'Due Date',
                field: 'dueDate',
                hide: xxs || xs,
                renderCell: renderDate,
                width: 120,
                filterOperators: getGridDateOperators(),
            },
            {
                headerName: 'Created',
                field: 'created',
                hide: xxs || xs || sm,
                renderCell: renderDate,
                width: 120,
                filterOperators: getGridDateOperators(),
            },
            {
                headerName: 'Completed',
                field: 'completed',
                hide: xxs || xs || sm,
                renderCell: renderDate,
                width: 120,
                filterOperators: getGridDateOperators(),
            },
            {
                headerName: 'Requestor',
                field: 'requestor',
                hide: xxs || xs || sm,
                width: 140,
            },
            {
                headerName: 'Comments',
                field: 'comments',
                hide: xxs || xs || sm || md || lg,
                renderCell: renderHtml,
                flex: 2,
                minWidth: 120
            },
        ],
        [md, sm, xs, xxs, lg],
    );
}

import {useContext} from 'react';
import ScrollToDiv from '~/components/generics/ScrollToDiv';
import SwipeStack from '~/components/generics/SwipeStack';
import CorrespondenceStackContext from '~/components/subsite/summary/correspondenceStack/CorrespondenceStackContext';
import NoOpenItemCard from '~/components/subsite/summary/correspondenceStack/NoOpenItemCard';
import NeedCard from '~/components/subsite/summary/NeedCard';
import {useStoreState} from '~/store/storeHooks';

interface NeedStackProps {
    projectLabel?: boolean;
}

NeedStack.defaultProps = {
    projectLabel: false,
};

export default function NeedStack({projectLabel}: NeedStackProps): JSX.Element {
    const {needs} = useStoreState((state) => state.correspondence);
    const {showNeed} = useContext(CorrespondenceStackContext);

    if (!showNeed) {
        return null;
    }

    return (
        <>
            <ScrollToDiv id="need-stack-scroll-to"/>
            {
                needs.length === 0
                ?
                <NoOpenItemCard type="needs" />
                :
                <SwipeStack id="need-stack-root">
                    {[...needs.filter((o)=>o.status !== 'Closed').map((n, i) => <NeedCard index={i} need={n} key={`need:${n.id}`} projectLabel={projectLabel} />)]}
                </SwipeStack>
            }
        </>
    );
}

import Tooltip from '@mui/material/Tooltip';
import {useCallback, useMemo} from 'react';
import {scroller} from 'react-scroll/modules';
import KPIGrid from '~/components/kpi/KPIGrid';
import {useStoreState} from '~/store/storeHooks';
import styles from '~/styles/KPI.module.scss';

interface CorrespondenceCountKPIProps {
    type: 'rfis' | 'tqs' | 'tdrs' | 'holds' | 'actionItems' | 'needs';
    scrollTo?: string;
    tooltipTitle?: string;
}

CorrespondenceCountKPI.defaultProps = {
    scrollTo: '',
    tooltipTitle: '',
};

export default function CorrespondenceCountKPI({type, scrollTo, tooltipTitle}: CorrespondenceCountKPIProps): JSX.Element {
    const rfis = useStoreState((state) => state.correspondence.rfis);
    const tqs = useStoreState((state) => state.correspondence.tqs);
    const tdrs = useStoreState((state) => state.correspondence.tdrs);
    const holds = useStoreState((state) => state.correspondence.holds);
    const needs = useStoreState((state) => state.correspondence.needs);
    const actionItems = useStoreState((state) => state.correspondence.actionItems);

    const data = useMemo((): {title: string; count: number} => {
        switch (type) {
            case 'rfis':
                return {title: 'RFI', count: rfis.filter((o) => !o.descendent && o.status === 'Open').length};
            case 'tqs':
                return {title: 'TQ', count: tqs.filter((o) => !o.descendent && o.status === 'Open').length};
            case 'tdrs':
                return {title: 'Deviations', count: tdrs.filter((o) => !o.descendent && o.status === 'Open').length};
            case 'holds':
                return {
                    title: 'Holds',
                    count: holds.filter((o) => o.status === 'Open').length,
                };
            case 'needs':
                return {
                    title: 'Needs',
                    count: needs.filter((o: any) => o.status === 'Open').length,
                };
            case 'actionItems':
                return {
                    title: 'Action Items',
                    count: actionItems.filter((o) => o.status === 'Open' && o.assignedToClient).length,
                };
            default:
                return {
                    title: 'KPI',
                    count: 0,
                };
        }
    }, [actionItems, holds, needs, rfis, tdrs, tqs, type]);

    const handleClick = useCallback((): void => {
        if (scrollTo) {
            scroller.scrollTo(scrollTo, {
                smooth: true,
                duration: 500,
            });
        }
    }, [scrollTo]);

    return (
        <Tooltip title={tooltipTitle} arrow>
            <div>
                <KPIGrid onClick={handleClick} clickable>
                    <div className={styles.container}>
                        <h5 className={styles.title}>{data.title}</h5>
                        <h1 className={styles.count}>{data.count}</h1>
                    </div>
                </KPIGrid>
            </div>
        </Tooltip>
    );
}

import {Chip} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { GridColDef, GridRenderCellParams, getGridDateOperators, GridValueGetterParams } from '@mui/x-data-grid-pro';
import {useMemo} from 'react';
import {getGridStringArrayAutocompleteOperators} from '~/components/generics/MUIDataGridCustomOperators';
import {HtmlToString, defaultMediaSizes} from '~/helpers';
import styles from '~/styles/DataGridPro.module.scss';
import {ActionItem, Project} from '~/types';

const renderDate = (params: GridRenderCellParams): string => (params.value ? new Date(params.value).toLocaleDateString() : '');

const renderHtml = (params: GridRenderCellParams): string => HtmlToString(params.value);

export default function ActionItemsTableCols(project: Project): GridColDef<ActionItem>[] {
    const mediaSizes = defaultMediaSizes;
    const xxs = useMediaQuery(mediaSizes.xxs);
    const xs = useMediaQuery(mediaSizes.xs);
    const sm = useMediaQuery(mediaSizes.sm);
    const md = useMediaQuery(mediaSizes.md);
    const lg = useMediaQuery(mediaSizes.lg);

    return useMemo(
        (): GridColDef<ActionItem>[] => [
            {
                headerName: 'Number',
                field: 'number',
                width: 120,
            },
            {
                headerName: 'Action',
                field: 'description',
                renderCell: renderHtml,
                flex: 2,
            },
            {
                headerName: 'Status',
                field: 'status',
                width: 120,
                filterOperators: getGridStringArrayAutocompleteOperators([
                    'Closed',
                    'Open',
                ]),
            },
            {
                headerName: 'Requestor',
                field: 'requestor',
                hide: xxs || xs || sm,
                width: 140,
            },
            {
                headerName: 'Owner',
                field: 'assignedToClient',
                hide: xxs || xs || sm,
                width: 140,
                align: 'center',
                headerAlign: 'center',
                valueGetter: (params: GridValueGetterParams) => params.value ? project?.client?.name : 'H+M',
                renderCell: (params: GridRenderCellParams): JSX.Element => {
                    const company = params?.value;
                    const color = '#fff';
                    let background = '#2a445b';

                    if (company !== 'H+M') {
                        background = '#2875a4';
                    }
                    return <Chip style={{background, color}} label={company} className={styles.statusChip} size="small" />;
                },
                filterOperators: getGridStringArrayAutocompleteOperators([
                    project?.client?.name,
                    'H+M',
                ]),
            },
            {
                headerName: 'Assignment',
                field: 'clientAssignee',
                hide: xxs || xs || sm || md,
                width: 140,
                valueGetter: (params: GridValueGetterParams) => {
                    const {assignedToClient, hmAssignee, clientAssignee} = params.row;
                    if (assignedToClient) {
                        return clientAssignee;
                    }
                    return hmAssignee?.fullName;
                },
            },
            {
                headerName: 'Comments',
                field: 'comments',
                hide: xxs || xs || sm || md || lg,
                renderCell: renderHtml,
                flex: 1
            },
            {
                headerName: 'Created',
                field: 'created',
                hide: xxs || xs || sm,
                renderCell: renderDate,
                width: 120,
                filterOperators: getGridDateOperators(),
            },
            {
                headerName: 'Due Date',
                field: 'dueDate',
                hide: xxs || xs,
                renderCell: renderDate,
                width: 120,
                filterOperators: getGridDateOperators(),
            },
            {
                headerName: 'Completed',
                field: 'completed',
                hide: xxs || xs || sm,
                renderCell: renderDate,
                width: 120,
                filterOperators: getGridDateOperators(),
            },
            {
                headerName: 'Completed By',
                field: 'completedBy',
                hide: xxs || xs || sm,
                width: 140,
                valueGetter: (params: GridValueGetterParams) => params.value?.fullName
            },
        ],
        [lg, md, sm, xs, xxs, project],
    );
}

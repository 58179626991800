import {scroller} from 'react-scroll/modules';
import KPIGrid from '~/components/kpi/KPIGrid';
import HSEGrade from '~/components/subsite/health/HSEGrade';
import styles from '~/styles/KPI.module.scss';

export default function HSEGradeKPI(): JSX.Element {
    const handleClick = (): void => {
        scroller.scrollTo('hse-table-scroll-to', {
            smooth: true,
            duration: 500,
        });
    };

    return (
        <KPIGrid onClick={handleClick} clickable>
            <div className={styles.container}>
                <h5 className={styles.title}>HSE Grade</h5>
                <HSEGrade className={styles.icon} />
            </div>
        </KPIGrid>
    );
}

import {IconButton} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useCallback, useEffect, useState} from 'react';
import * as React from 'react';
import { trackEvent } from '~/api';
import DeleteIcon from '~/assets/img/delete-icon.svg';
import ConfirmationDialog from '~/components/generics/ConfirmationDialog';
import {useStoreActions} from '~/store/storeHooks';
import { Need } from '~/types';

interface DeleteNeedButtonProps {
    need: Need;
    handleClose: ()=>void;
}

export default function DeleteNeedButton({need,handleClose}: DeleteNeedButtonProps): JSX.Element {
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [busy, setBusy] = useState(false);
    const deleteNeed = useStoreActions((actions) => actions.correspondence.deleteNeed);
    const {enqueueSnackbar} = useSnackbar();

    const handleDelete = useCallback(async () => {
        setBusy(true);
        trackEvent('delete need', {id: need.id, number: need.number});

        const {result, message} = await deleteNeed({id: need.id, project: need.projectId});
        setBusy(false);
        enqueueSnackbar(message, {variant: result});
        if (result !== 'error') {
            handleClose();
        }
    }, [deleteNeed, need, enqueueSnackbar, handleClose]);

    useEffect(
        () => (): void => {
            setBusy(false);
        },
        [],
    );

    const handleClick = (e: React.MouseEvent): void => {
        e.stopPropagation();
        setConfirmDialogOpen(true);
    };

    return (
        <>
            <IconButton onClick={handleClick} disabled={busy} className="delete-button" style={{position:'absolute', left: 8, bottom: 8}}>
                <img src={DeleteIcon} alt="delete-need-icon-button" />
            </IconButton>
            <ConfirmationDialog
                open={confirmDialogOpen}
                title="Confirm Delete Request"
                message={`Please confirm you wish to delete need ${need.number}, this process cannot be undone`}
                onConfirm={handleDelete}
                onCancel={(): void => setConfirmDialogOpen(false)}
            />
        </>
    );
}

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {CircularProgress, Fade, IconButton, List, ListItem, ListItemText} from '@mui/material';
import {SetStateAction, useCallback, useMemo, useState, useEffect} from 'react';
import * as React from 'react';
import {useDropzone} from 'react-dropzone';
import styles from '~/styles/FileDropZone.module.scss';

interface FileDropZoneProps {
    callback: (files: File[]) => void | SetStateAction<File[]>;
    acceptedTypes?: string;
    dropzoneText?: string;
    showList?: boolean;
    multiple?: boolean;
    disabled?: boolean;
    uploadInProgress?: boolean;
}

FileDropZone.defaultProps = {
    acceptedTypes: null,
    dropzoneText: 'Drop files or click to upload',
    showList: true,
    multiple: false,
    disabled: false,
    uploadInProgress: false,
};

export default function FileDropZone({
    callback,
    acceptedTypes,
    dropzoneText,
    showList,
    multiple,
    disabled,
    uploadInProgress,
}: FileDropZoneProps): JSX.Element {
    const maxSize = 50000000;
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            if (acceptedFiles.length > 0) {
                setUploadedFiles(acceptedFiles);
                callback(acceptedFiles);
            }
        },
        [callback],
    );

    const {isDragActive, getRootProps, getInputProps, isDragReject, fileRejections} = useDropzone({
        onDrop,
        accept: acceptedTypes,
        minSize: 0,
        maxSize,
        multiple,
    });

    useEffect(() => (): void => setUploadedFiles([]), []);

    const removeFile = useCallback(
        (file: File): void => {
            const n = uploadedFiles.filter((f) => f.name !== file.name);
            setUploadedFiles(n);
            callback(n);
        },
        [callback, uploadedFiles],
    );

    const files = useMemo(
        (): JSX.Element => (
            <>
                {uploadedFiles.map((file) => (
                    <ListItem className={styles.selectedFile} key={file.name}>
                        <ListItemText primary={file.name ?? 'Missing file name'} />
                        <IconButton edge="end" onClick={(): void => removeFile(file)}>
                            <FontAwesomeIcon icon={['fal', 'times']} color="tomato" />
                        </IconButton>
                    </ListItem>
                ))}
            </>
        ),
        [removeFile, uploadedFiles],
    );

    const isFileTooLarge = fileRejections.length > 0 && fileRejections.some((o) => o.errors.some((err) => err.code === 'file-too-large'));

    return (
        <Fade in={!disabled} unmountOnExit mountOnEnter>
            <div className={styles.root}>
                <div className={styles.dropZoneContainer}>
                    {uploadInProgress ? (
                        <CircularProgress />
                    ) : (
                        <div {...(getRootProps() as React.HTMLAttributes<HTMLElement>)}>
                            <input {...getInputProps()} />
                            <p>
                                {!isDragActive && dropzoneText}
                                {isDragReject && 'File type not accepted, sorry!'}
                                {isFileTooLarge && <div className="text-danger mt-2">File is too large.</div>}
                            </p>
                            <div>
                                <FontAwesomeIcon icon={['fad', 'cloud-upload']} size="2x" />
                            </div>
                        </div>
                    )}
                </div>
                {showList && (
                    <div className={styles.fileListContainer}>
                        {uploadedFiles.length > 0 && <p>Staged Files</p>}
                        <List>{files}</List>
                    </div>
                )}
            </div>
        </Fade>
    );
}

import {memo, useEffect, useState} from 'react';
import {Outlet, useParams} from 'react-router-dom';
import LoadingComponent from '~/components/visualizations/LoadingComponent';
import {useStoreActions} from '~/store/storeHooks';

const ProjectPage = (): JSX.Element => {
    const [busy, setBusy] = useState(true);
    const {projectId} = useParams<{projectId: string}>();
    
    const [getSubsiteInitialData, getProjectDocuments, getProjectComments] = useStoreActions((actions) => [
        actions.subsite.getSubsiteInitialData,
        actions.document.getProjectDocuments,
        actions.comment.getProjectComments,
    ]);

    useEffect(() => {
        (async function fetchRequests(): Promise<void> {
            const p = parseInt(projectId, 10);
            if (p > 0) {
                setBusy(true);
                await Promise.all([getProjectDocuments(p), getSubsiteInitialData(p)]);
                setBusy(false);
                await getProjectComments(parseInt(projectId, 10));
            }
        })();
        return (): void => setBusy(false);
    }, [getProjectComments, getProjectDocuments, getSubsiteInitialData, projectId]);

    return <div id="project-subsite-root">{busy ? <LoadingComponent title="Loading Subsite Data" /> : <Outlet />}</div>;
};

export default memo(ProjectPage);

import Tooltip from '@mui/material/Tooltip';
import {useCallback} from 'react';
import {scroller} from 'react-scroll/modules';
import KPIGrid from '~/components/kpi/KPIGrid';
import {useStoreState} from '~/store/storeHooks';
import styles from '~/styles/KPI.module.scss';

interface DocumentCountKPIProps {
    categoryTitle: string;
    title?: string;
    scrollTo?: string;
    tooltipTitle?: string;
    countIfaOnly?: boolean;
}

DocumentCountKPI.defaultProps = {
    title: '',
    scrollTo: '',
    tooltipTitle: '',
    countIfaOnly: false
};

export default function DocumentCountKPI({categoryTitle, title, scrollTo, tooltipTitle, countIfaOnly}: DocumentCountKPIProps): JSX.Element {
    const documentCategoryForTitle = useStoreState((state) => state.document.documentCategoryForTitle);
    const documents = useStoreState((state) => state.document.documents);

    const category = documentCategoryForTitle(categoryTitle);
    const categoryDocuments = documents.filter((d) => category && d.categoryId === category.id && !d.descendent && (!countIfaOnly || d.status.title === 'IFA'));

    const handleClick = useCallback((): void => {
        if (scrollTo) {
            scroller.scrollTo(scrollTo, {
                smooth: true,
                duration: 500,
            });
        }
    }, [scrollTo]);

    return (
        category && (
            <Tooltip title={tooltipTitle} arrow>
                <div>
                    <KPIGrid onClick={handleClick} clickable>
                        <div className={styles.container}>
                            <div className={styles.title}>{title || category.title}</div>
                            <h1 className={styles.count}>{categoryDocuments.length}</h1>
                        </div>
                    </KPIGrid>
                </div>
            </Tooltip>
        )
    );
}

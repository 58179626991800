import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useMediaQuery} from '@mui/material';
import numeral from 'numeral';
import {useContext} from 'react';
import {Link} from 'react-router-dom';
import ProjectContext from '~/components/dashboard/project-log/ProjectContext';
import NoDataPlaceholder from '~/components/generics/NoDataPlaceholder';
import {useStoreState} from '~/store/storeHooks';
import styles from '~/styles/FinanceCard.module.scss';

export default function FinanceCard(): JSX.Element {
    const mobile = useMediaQuery('(max-width: 600px)');
    const {project} = useContext(ProjectContext);
    const foundationJobs = useStoreState((state) => state.accounting.foundationJobs);
    const {originalContract, totalApprovedIncomeAdj} = foundationJobs.find((o) => o.id === project.foundationCode) || {};
    const totalRevisedContract = originalContract + totalApprovedIncomeAdj;
    const href: string = `/project/${project.id}/summary`;
    const samePage: boolean = href === window.location.pathname;
    return (
        <div className={styles.financeCardRoot}>
            <h3 className={styles.title}>
                {
                    samePage
                    ?
                    `${mobile ? project.code : ''} Contract Value`
                    :
                    <Link to={href}>{mobile ? `${project.code}` : null} Contract Value</Link>
                }
            </h3>
            <div className={styles.financeCardContent}>
                {project.foundationCode && originalContract ? (
                    <>
                        <div className={`${styles.container} ${styles.twoRow}`}>
                            <div className={styles.valueGroup}>
                                <h1>{`${numeral(originalContract).format(originalContract > 1000000 ? '$0,0.0a' : '$0,0a')}`}</h1>
                                <p>Original Contract Value</p>
                            </div>
                            <div>
                                <p className={styles.plus}>{totalApprovedIncomeAdj >= 0 ? '+' : '-'}</p>
                            </div>
                            <div className={styles.valueGroup}>
                                <h1>
                                    <FontAwesomeIcon
                                        icon={['fad', 'caret-up']}
                                        size="lg"
                                        style={{color: totalApprovedIncomeAdj > 0 ? 'chartreuse' : 'tomato', marginRight: 8}}
                                    />
                                    {`${
                                        totalApprovedIncomeAdj
                                            ? numeral(totalApprovedIncomeAdj).format(totalApprovedIncomeAdj > 1000000 ? '$0,0.0a' : '$0,0a')
                                            : 0
                                    }`}
                                </h1>
                                <p>Approved Change Orders</p>
                            </div>
                        </div>
                        <div className={styles.container}>
                            <div className={styles.valueGroup}>
                                <h1>
                                    {`${
                                        totalRevisedContract
                                            ? numeral(totalRevisedContract).format(totalRevisedContract > 1000000 ? '$0,0.0a' : '$0,0a')
                                            : 0
                                    }`}
                                </h1>
                                <p>Current Contract Value</p>
                            </div>
                        </div>
                    </>
                ) : (
                    <NoDataPlaceholder />
                )}
            </div>
        </div>
    );
}

import useMediaQuery from '@mui/material/useMediaQuery';
import {motion} from 'framer-motion';
import {useRef, useEffect} from 'react';
import * as React from 'react';
import {VariableSizeList} from 'react-window';
import ProjectLogListRow from './ProjectLogListRow';
import ProjectLogListContext from '~/components/dashboard/project-log/list/ProjectLogListContext';
import NoDataPlaceholder from '~/components/generics/NoDataPlaceholder';
import {useStoreState} from '~/store/storeHooks';
import {listVariants} from '~/styles/animations/__animationVariants';
import styles from '~/styles/ProjectLogList.module.scss';

const PADDING_LEFT_RIGHT = 8;
const DEFAULT_ITEM_SIZE = 264;

export default function ProjectLogList(): JSX.Element {
    const mobile = useMediaQuery('(max-width: 485px)');
    const projectSubset = useStoreState((state) => state.project.projectSubset);

    const listRef = useRef<VariableSizeList | null>(null);

    const sizeMap = React.useRef<{[key: string]: number}>({});

    const projectLogListContext = React.useMemo(
        () => ({
            setSize: (index: number, size: number) => {
                // Performance: Only update the sizeMap and reset cache if an actual value changed
                if (sizeMap.current[index] !== size) {
                    sizeMap.current = {...sizeMap.current, [index]: size};
                    if (listRef.current) {
                        // Clear cached data and rerender
                        listRef.current?.resetAfterIndex(0);
                    }
                }
            },
        }),
        [],
    );

    const getSize = React.useCallback((index: number) => sizeMap.current[index] || DEFAULT_ITEM_SIZE, []);

    const calcEstimatedSize = React.useCallback(() => {
        const keys = Object.keys(sizeMap.current);
        const estimatedHeight = keys.reduce((p, i) => p + sizeMap.current[i], 0);
        return estimatedHeight / keys.length;
    }, []);

    useEffect(() => {
        listRef.current?.resetAfterIndex(0);
    }, [projectSubset]);

    return projectSubset.length > 0 ? (
        <motion.div initial="closed" animate="open" variants={listVariants} className={styles.root}>
            <h2 className={styles.header}>Projects</h2>
            <ProjectLogListContext.Provider value={projectLogListContext}>
                <VariableSizeList
                    ref={listRef}
                    height={600}
                    itemSize={getSize}
                    width={mobile ? `calc(100% - ${PADDING_LEFT_RIGHT}px)` : 425}
                    itemCount={projectSubset.length}
                    itemData={projectSubset}
                    estimatedItemSize={calcEstimatedSize()}
                >
                    {ProjectLogListRow}
                </VariableSizeList>
            </ProjectLogListContext.Provider>
        </motion.div>
    ) : (
        <NoDataPlaceholder title="No Projects" />
    );
}

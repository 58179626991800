import MilestoneList from '~/components/subsite/summary/milestone/MilestoneList';
import styles from '~/styles/MilestoneStack.module.scss';

export default function ProjectStackMilestoneList(): JSX.Element {
    return (
        <div className={styles.cardRoot}>
            <h3 className={styles.title}>Milestones</h3>
            <MilestoneList />
        </div>
    );
}

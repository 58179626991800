import {Box, ClickAwayListener, Typography} from '@mui/material';
import Fade from '@mui/material/Fade/Fade';
import Popper from '@mui/material/Popper';
import TextField from '@mui/material/TextField';
import {useSnackbar} from 'notistack';
import {useState, useMemo} from 'react';
import * as React from 'react';
import BusyButton from '~/components/generics/BusyButton';
import styles from '~/styles/ChangeOrderDialog.module.scss';
import {ChangeOrder, COStatusAction} from '~/types';

interface ActionProps {
    action: COStatusAction;
    changeOrder: ChangeOrder;
    handleAction: ({comment,actionStatus}: {comment:string; actionStatus:string})=>void;
}


const Action = ({action,changeOrder,handleAction}: ActionProps): JSX.Element => {
    const [busy, setBusy] = useState<boolean>(false);
    const {enqueueSnackbar} = useSnackbar();
    const [anchorEl, setAnchorEl] = useState(null);
    const [comment, setComment] = useState<string>('');
    const [open,setOpen] = useState(false);

    const commentRequired = useMemo((): boolean => action.action === 'Reject'  || action.action === 'Revise & Resubmit', [action.action]);
 
    const handleClick = (e: React.MouseEvent<HTMLElement>): void => {
       setOpen(true);
       setAnchorEl(e.currentTarget);
    }

    const handleConfirm = async (): Promise<void> => {
        if (commentRequired && comment.length === 0) {
            enqueueSnackbar('A comment is required for this action', {variant: 'error'});
            return;
        }

        if (comment.length > 255) {
            enqueueSnackbar(`The comment length, ${comment.length}, is over the 255 limit`, {variant: 'error'});
            return;
        }

        handleAction({actionStatus:action.updateStatusTo, comment});
        setOpen(false);
    };

    // const open = Boolean(anchorEl);
    const id = open ? 'comment-popper' : undefined;

    return (
        <ClickAwayListener
            onClickAway={(): void => {
                setOpen(false);
            }}>
            <div>
                <BusyButton onClick={handleClick} busy={busy} size="small" tooltip={action.description ?? ''} className={styles.statusActionButton}>
                    {action.action}
                </BusyButton>
                <Popper id={id} open={open} anchorEl={anchorEl} transition disablePortal placement="top">
                    {({TransitionProps}): JSX.Element => (
                        <Fade {...TransitionProps}>
                            <div className={styles.popper}>
                                <h3>{`${action.action} Comment${commentRequired ? ' Required' : ''}`}</h3>
                                <TextField
                                    multiline
                                    label="Comment"
                                    value={comment}
                                    fullWidth
                                    required={commentRequired}
                                    autoFocus
                                    onChange={(e): void => setComment(e.target.value)}
                                    InputProps={{
                                        inputProps: {
                                            autoComplete: 'off',
                                            autoFocus: true,
                                            maxLength: 255,
                                            style:{overflow: 'auto', maxHeight: 90, height: 50, padding: 10},
                                        },
                                    }}
                                />
                                {/* <FileDropZone callback={(files): void => setAttachments(files)} multiple uploadInProgress={busy} /> */}
                                <div className={styles.popperActions}>
                                    <BusyButton onClick={handleConfirm} busy={busy} className="save-button">
                                        Confirm
                                    </BusyButton>
                                </div>
                                <span className={styles.arrow} />
                            </div>
                        </Fade>
                    )}
                </Popper>
            </div>
        </ClickAwayListener>
    );
};

const COActions = ({changeOrder, handleAction, actions, history, title}: {changeOrder: ChangeOrder, handleAction: ({comment,actionStatus}: {comment:string; actionStatus:string})=>void; actions: COStatusAction[], history: JSX.Element, title?: string}): JSX.Element => {
    if (!changeOrder.id) return <></>;
    return (
        <Box className={styles.documentActionsRoot}>
            <Typography component='h5' className={styles.actionsHeader}>{title || 'Available Actions '}{history}</Typography>
            <Box>
                {actions.map((action) => (
                    <Action action={action} key={action.action} changeOrder={changeOrder} handleAction={handleAction}/>
                ))
                }
            </Box>
        </Box>
    );
}

export default COActions;

COActions.defaultProps = {
    title: undefined
}

import {useContext} from 'react';
import ScrollToDiv from '~/components/generics/ScrollToDiv';
import SwipeStack from '~/components/generics/SwipeStack';
import CorrespondenceStackContext from '~/components/subsite/summary/correspondenceStack/CorrespondenceStackContext';
import NoOpenItemCard from '~/components/subsite/summary/correspondenceStack/NoOpenItemCard';
import TDRCard from '~/components/subsite/summary/TDRCard';
import {useStoreState} from '~/store/storeHooks';

interface TDRStackProps {
    projectLabel?: boolean;
}

TDRStack.defaultProps = {
    projectLabel: false,
};

export default function TDRStack({projectLabel}: TDRStackProps): JSX.Element {
    const {tdrs} = useStoreState((state) => state.correspondence);
    const {showTDR} = useContext(CorrespondenceStackContext);

    if (!showTDR) {
        return null;
    }

    return (
        <>
            <ScrollToDiv id='tdr-stack-scroll-to' top={-60}/>
            {
                tdrs.length === 0
                ?
                <NoOpenItemCard type="tdrs" />
                :
                <SwipeStack id='tdr-stack-root'>
                    {[...tdrs.filter((o)=>o.status !== 'Closed' && !o.descendent).map((td, i) => <TDRCard index={i} tdr={td} key={`tdr:${td.id}`} projectLabel={projectLabel} />)]}
                </SwipeStack>
            } 
        </>
    );
}

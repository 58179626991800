import {useTrackPage} from '~/api';
import ScrollToDiv from '~/components/generics/ScrollToDiv';
import DocumentCountKPI from '~/components/kpi/DocumentCountKPI';
import HSEGradeKPI from '~/components/kpi/HSEGradeKPI';
import KPIContainer from '~/components/kpi/KPIContainer';
import DocumentTable from '~/components/subsite/documentTable/DocumentTablePro';
import HealthReportCard from '~/components/subsite/health/HealthReportCard';
import {useStoreState} from '~/store/storeHooks';
import styles from '~/styles/SubsiteContentPanel.module.scss';

export default function HSEPanel(): JSX.Element {
    const documentCategoryForTitle = useStoreState((state) => state.document.documentCategoryForTitle);
    const hseCategory = documentCategoryForTitle('hse');

    useTrackPage('HSE');

    return (
        <div id="hse-panel-root" className={styles.root}>
            {hseCategory && (
                <>
                    <KPIContainer className={styles.kpiContainer}>
                        <HSEGradeKPI />
                        <DocumentCountKPI
                            categoryTitle="hse"
                            title="Documents"
                            scrollTo="hse-docs-scroll-to"
                            tooltipTitle="Navigate to HSE Documents"
                        />
                    </KPIContainer>
                    <div id="hse-table-root">
                        <ScrollToDiv id="hse-table-scroll-to" />
                        <HealthReportCard category="HSE" />
                    </div>
                    <div id="hse-docs-root">
                        <ScrollToDiv id="hse-docs-scroll-to" />
                        <h4 className={styles.header}>HSE Documents</h4>
                        <DocumentTable categories={hseCategory.id} />
                    </div>
                </>
            )}
        </div>
    );
}

import ImageTitle from './ImageTitle';
import classes from './LoginPage.module.scss';
import UserLogin from './UserLogin';

export default function LoginPage(): JSX.Element {
    return (
        <div className={classes.root}>
            <div>
                <div>
                    <ImageTitle />
                </div>
                <div>
                    <UserLogin />
                </div>
            </div>
        </div>
    );
}

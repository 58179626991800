import {useCallback} from 'react';
import * as React from 'react';
import {scroller} from 'react-scroll/modules';
import styles from '~/styles/ReplyToContainer.module.scss';
import {Comment} from '~/types';

interface ReplyReferenceProps {
    parent: Comment;
}

export default function ReplyReference({parent}: ReplyReferenceProps): JSX.Element {
    const handleClick = useCallback(
        (e: React.UIEvent) => {
            // todo: flash highlight on comment.
            e.stopPropagation();

            if (parent) {
                scroller.scrollTo(`${parent.id}`, {
                    smooth: true,
                    duration: 500,
                    containerId: 'comments-root',
                });
            }
        },
        [parent],
    );

    return (
        parent && (
            <div id="parent-comment-reference-root" className={styles.root}>
                <div className={styles.blockQuote}>
                    <p className={styles.comment}>
                        <button type="button" onClick={handleClick}>
                            &quot;{parent.comment}&quot;
                        </button>
                    </p>
                    <p className={styles.caption}>{parent.createdBy.fullName}</p>
                    <p className={styles.caption}>
                        {new Date(parent.created).toLocaleString([], {
                            month: '2-digit',
                            day: '2-digit',
                            year: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                        })}
                    </p>
                </div>
            </div>
        )
    );
}

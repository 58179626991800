import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Divider} from '@mui/material';
import {useContext, useEffect, useMemo} from 'react';
import {Link} from 'react-router-dom';
import ProjectContext from '~/components/dashboard/project-log/ProjectContext';
import useProjectHealth from '~/hooks/useProjectHealth';
import {useStoreActions, useStoreState} from '~/store/storeHooks';
import styles from '~/styles/SummaryHealthTable.module.scss';

export default function SummaryHSEGrade(): JSX.Element {
    const {project} = useContext(ProjectContext);
    const standardHealthCriteria = useStoreState((state) => state.project.standardHealthCriteria);
    const getStandardHealthCriteria = useStoreActions((actions) => actions.project.getStandardHealthCriteria);
    const {projectHealth, foundationJob} = project;
    const {calculateHSEGrade} = useProjectHealth();

    useEffect(() => {
        if (standardHealthCriteria.length === 0) {
            getStandardHealthCriteria();
        }
    }, [getStandardHealthCriteria, standardHealthCriteria.length]);

    const healthCriteria = useMemo(
        () => (project && project.healthCriteria.length > 0 ? project.healthCriteria : standardHealthCriteria.filter((c) => c.category === 'HSE')),
        [project, standardHealthCriteria],
    );

    const hseGrade = calculateHSEGrade(projectHealth, foundationJob, healthCriteria);

    let gradeText = hseGrade.score;
    if (hseGrade.score < 0) {
        gradeText = 0;
    } else if (hseGrade.score > 10) {
        gradeText = 'MAX';
    }

    return (
        <div id="summary-health-table-root" className={styles.root}>
            <h4 className={styles.healthReportCardTitle}>
                <Link className={styles.link} to={`/project/${project.id}/hse`}>
                    HSE Health
                </Link>
            </h4>
            <div className={styles.card}>
                {/* [TODO:] Commented out to use/copy it later to make a dialog */}
                {/* <HealthReportCardContext.Provider value={{healthCriteria}}>*/}
                {/*    <HealthCriteriaTable />*/}
                {/* </HealthReportCardContext.Provider>*/}
                <div className={styles.scoreRoot}>
                    <div className={styles.resultContainer}>
                        <div className={styles.columnContainer}>
                            <p>Score</p>
                            <div className={styles.score}>{gradeText}</div>
                        </div>
                        <Divider orientation="vertical" flexItem />
                        <div className={styles.columnContainer}>
                            <p>Grade</p>
                            <FontAwesomeIcon icon={['fas', 'circle']} color={hseGrade.indicator.color} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

import {useEffect, useRef, useContext} from 'react';
import {ListChildComponentProps} from 'react-window';
import ProjectLogListContext from './ProjectLogListContext';
import ProjectStack from '~/components/dashboard/project-log/ProjectStack';

const PADDING_TOP_BOTTOM = 8;
export const DEFAULT_ROW_SIZE = 264;

export default function ProjectLogListRow(props: ListChildComponentProps): JSX.Element {
    const {index, style, data} = props;
    const rowRef = useRef<null | HTMLDivElement>(null);
    const {setSize} = useContext(ProjectLogListContext);
    const rowHeight = rowRef.current?.getBoundingClientRect().height ?? DEFAULT_ROW_SIZE;

    useEffect(() => {
        if (rowRef.current) {
            setSize(index, rowHeight);
        }
    }, [index, rowHeight, setSize]);

    return (
        <div
            id={`project-row-${data[index].code}`}
            style={{
                ...style,
                top: `${parseFloat(style.top as string) + PADDING_TOP_BOTTOM}px`,
            }}
            key={index}
        >
            <div ref={rowRef}>
                <ProjectStack project={data[index]} />
            </div>
        </div>
    );
}

import {createContext} from 'react';

type CorrespondenceStackContextType = {
    showActionItem: boolean;
    setShowActionItem: (show: boolean) => void;
    showRFI: boolean;
    setShowRFI: (show: boolean) => void;
    showTDR: boolean;
    setShowTDR: (show: boolean) => void;
    showTQ: boolean;
    setShowTQ: (show: boolean) => void;
    showHold: boolean;
    setShowHold: (show: boolean) => void;
    showNeed: boolean;
    setShowNeed: (show: boolean) => void;
};

const CorrespondenceStackContext = createContext<CorrespondenceStackContextType>({
    showActionItem: false,
    setShowActionItem: () => true,
    showRFI: false,
    setShowRFI: () => true,
    showTDR: false,
    setShowTDR: () => true,
    showTQ: false,
    setShowTQ: () => true,
    showHold: false,
    setShowHold: () => true,
    showNeed: false,
    setShowNeed: () => true,
});

export default CorrespondenceStackContext;

import useMediaQuery from '@mui/material/useMediaQuery';
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid-pro';
import {useMemo} from 'react';
import POCCheckbox from '~/components/subsite/summary/team/POCCheckbox';
import {formatPhoneNumber, OnEnterPressWrapper} from '~/helpers';
import styles from '~/styles/TeamTableColumn.module.scss';
import {ProjectTeamMember} from '~/types';


export default function TeamTableColumns(isHM?: boolean): GridColDef<ProjectTeamMember>[] {
    const xs = useMediaQuery('(max-width: 600px)');
    const sm = useMediaQuery('(max-width: 960px)');

    return useMemo(
        (): GridColDef<ProjectTeamMember>[] => [
            {
                headerName: 'Name',
                field: 'fullName',
                width: 150,
                align: 'left',
                valueGetter: (params: GridValueGetterParams): string => params?.row?.person?.fullName,
            },
            {
                headerName: 'Title',
                field: 'title',
                hide: isHM,
                minWidth: 150,
                flex:1,
                valueGetter: (params: GridValueGetterParams): string => params.row?.title
            },
            {
                headerName: 'Role',
                hide: !isHM,
                field: 'role',
                minWidth: 150,
                flex:1,
                valueGetter: (params: GridValueGetterParams): string => params.row?.role?.title
            },
            {
                headerName: 'Email',
                field: 'email',
                hide: xs || sm,
                width: 200,
                valueGetter: (params: GridValueGetterParams): string => params?.row?.person?.email,
                renderCell: (params: GridRenderCellParams): JSX.Element => {
                    const {person,poc} = params.row;
                    const sendEmail = (event: React.UIEvent): void => {
                        event.stopPropagation();
                        window.location.href = `mailto:${person.email}`;
                    };

                    return (
                        <span className={styles.contact} onClick={sendEmail} onKeyDown={OnEnterPressWrapper(sendEmail)} role="button" tabIndex={0}>
                            {poc || !isHM ? person?.email : null}
                        </span>
                    );
                },
            },
            {
                headerName: 'Phone',
                field: 'phoneNumber',
                hide: xs || sm,
                width: 150,
                valueGetter: (params: GridValueGetterParams): string => {
                    const {person} = params.row;
                    let phoneNumber = '';
                    if (person.cellPhone && person.cellPhone.length > 9) {
                        phoneNumber = person.cellPhone;
                    } else if (person.workPhone && person.workPhone.length > 9) {
                        phoneNumber = person.workPhone;
                    }
                    return phoneNumber;
                },
                renderCell: (params: GridRenderCellParams): JSX.Element => {
                    const {poc} = params.row;
                    const phoneNumber = params.value;

                    const makePhoneCall = (event: React.UIEvent): void => {
                        event.stopPropagation();
                        window.location.href = `tel:${phoneNumber}`;
                    };
                    return (
                        <span
                            className={styles.contact}
                            onClick={makePhoneCall}
                            onKeyDown={OnEnterPressWrapper(makePhoneCall)}
                            role="button"
                            tabIndex={0}
                        >
                            {poc || !isHM ? formatPhoneNumber(phoneNumber) : null}
                        </span>
                    );
                },
            },
            {
                headerName: 'Point of Contact',
                field: 'poc',
                width: 150,
                renderCell: (params: GridRenderCellParams): JSX.Element => <div className={styles.poc}><POCCheckbox row={params.row} isHM={isHM}/></div>,
            },
        ],
        [sm, xs, isHM],
    );
}

import TextField from '@mui/material/TextField';
import {useSnackbar} from 'notistack';
import {useEffect, useState} from 'react';
import {FormProvider, Message, useForm} from 'react-hook-form';
import {useTrackPage} from '~/api';
import BusyButton from '~/components/generics/BusyButton';
import RHFController from '~/components/generics/RHFController';
import PasswordResetControl from '~/components/profile/PasswordResetControl';
import {DisableMobileZoom, EnableMobileZoom} from '~/helpers';
import {useStoreActions, useStoreState} from '~/store/storeHooks';
import styles from '~/styles/ProfilePage.module.scss';
import {Person} from '~/types';

const DEFAULT_USER = {
    firstName: '',
    lastName: '',
    nickname: '',
    fullName: '',
    workPhone: '',
    cellPhone: '',
};

export default function ProfilePage(): JSX.Element {
    const currentUser = useStoreState((state) => state.shared.currentUser);

    const updateProfileInformation = useStoreActions((actions) => actions.shared.updateProfileInformation);
    const methods = useForm<Person>({
        defaultValues: DEFAULT_USER,
    });
    const {handleSubmit, reset} = methods;
    const {enqueueSnackbar} = useSnackbar();
    const [busy, setBusy] = useState(false);

    useTrackPage('User Profile');

    // Disable zooming for this dialog, it's annoying whenever selecting any input
    useEffect(() => {
        DisableMobileZoom();
        return (): void => {
            EnableMobileZoom();
        };
    }, []);

    useEffect(() => {
        let user = DEFAULT_USER;
        if (currentUser) {
            user = {
                ...currentUser,

                // Handle optional properties
                nickname: currentUser?.nickname ?? '',

                workPhone: currentUser?.workPhone ?? '',

                cellPhone: currentUser?.cellPhone ?? '',
            };
        }
        reset(user);
    }, [currentUser, reset]);

    const onSubmit = handleSubmit(async (data: Person): Promise<void> => {
        if (currentUser) {
            setBusy(true);
            const {result, message} = await updateProfileInformation({
                ...currentUser,

                ...data,
            });
            setBusy(false);
            enqueueSnackbar(message, {variant: result});
        }
    });

    return (
        <>
            <div id="profile-page-root" className={styles.root}>
                <h4 className={styles.header}>Update Profile</h4>
                <PasswordResetControl />
                {/* Hide Update Account Email Option for now*/}
                {/* <UpdateAccountEmailControl />*/}
                <FormProvider {...methods}>
                    <form onSubmit={onSubmit}>
                        <div className={styles.userInformationSection}>
                            <h3>User Information</h3>
                            <RHFController
                                render={({field}): JSX.Element => <TextField {...field} label="First Name" required fullWidth variant="standard" />}
                                name="firstName"
                                rules={{
                                    required: 'First name is required' as Message,
                                }}
                            />
                            <RHFController
                                render={({field}): JSX.Element => <TextField {...field} label="Last Name" required fullWidth variant="standard" />}
                                name="lastName"
                                rules={{
                                    required: 'Last name is required' as Message,
                                }}
                            />
                            <RHFController
                                render={({field}): JSX.Element => <TextField {...field} label="Nickname" fullWidth variant="standard" />}
                                name="nickname"
                            />
                            <RHFController
                                render={({field}): JSX.Element => <TextField {...field} label="Work Phone" fullWidth variant="standard" />}
                                name="workPhone"
                            />
                            <RHFController
                                render={({field}): JSX.Element => <TextField {...field} label="Cell Phone" fullWidth variant="standard" />}
                                name="cellPhone"
                            />
                            <BusyButton busy={busy} type="submit" className="save-button" id="save-updated-profile-button">
                                Save
                            </BusyButton>
                        </div>
                    </form>
                </FormProvider>
            </div>
        </>
    );
}

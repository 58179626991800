
import useMediaQuery from '@mui/material/useMediaQuery';
import { GridColDef, GridRenderCellParams, getGridNumericOperators, getGridDateOperators, GridValueGetterParams } from '@mui/x-data-grid-pro';
import {useMemo} from 'react';
import {getGridStringArrayAutocompleteOperators} from '~/components/generics/MUIDataGridCustomOperators';
import { defaultMediaSizes } from '~/helpers';
import {TDR} from '~/types';

export default function TDRTableCols(): GridColDef<TDR>[] {
    const mediaSizes = defaultMediaSizes;
    const xxs = useMediaQuery(mediaSizes.xxs);
    const xs = useMediaQuery(mediaSizes.xs);
    const sm = useMediaQuery(mediaSizes.sm);
    const md = useMediaQuery(mediaSizes.md);
    const lg = useMediaQuery(mediaSizes.lg);

    return useMemo(
        (): GridColDef<TDR>[] => [
            {
                headerName: 'Number',
                field: 'number',
                minWidth: 120,
                flex:1,
            },
            {
                headerName: 'Rev',
                field: 'rev',
                flex:1,
                minWidth: 80,
                hide: xxs || xs,
                filterOperators: getGridNumericOperators(),
            },
            {
                headerName: 'Type',
                field: 'type',
                flex:1,
                minWidth: 120,
            },
            {
                headerName: 'Status',
                field: 'status',
                flex:1,
                minWidth: 120,
                filterOperators: getGridStringArrayAutocompleteOperators([
                    'Closed',
                    'Open',
                ]),
            },
            {
                headerName: 'Originator',
                field: 'originator',
                hide: xxs || xs,
                flex:1,
                minWidth: 120,
            },
            {
                headerName: 'Issued',
                field: 'created',
                renderCell: (params: GridRenderCellParams): string => (params.value  ? new Date(params.value ).toLocaleDateString() : ''),
                hide: xxs || xs || sm,
                flex:1,
                minWidth: 120,
                filterOperators: getGridDateOperators(),
            },
            {
                headerName: 'Answered',
                field: 'responded',
                renderCell: (params: GridRenderCellParams): string => (params.value  ? new Date(params.value ).toLocaleDateString() : ''),
                hide: xxs || xs || sm,
                flex:1,
                minWidth: 120,
                filterOperators: getGridDateOperators(),
            },
            {
                headerName: 'Answered By',
                field: 'answeredBy',
                hide: xxs || xs || sm || md,
                width: 150,
            },
            {
                headerName: 'Attachments',
                field: 'attachments',
                renderCell: (params: GridRenderCellParams): string => (params.value?.length > 0 ? `${params.value.length} files` : ''),
                hide: xxs || xs || sm || md || lg,
                disableExport: true,
                width: 120,
            },
            {
                headerName: 'PM Approved',
                type: 'singleSelect',
                field: 'pmApproved',
                valueGetter: (params: GridValueGetterParams): string => params?.value ? 'Yes' : 'No',
                hide: xxs || xs || sm || md,
                flex:1,
                minWidth: 120,
                valueOptions: [
                    'Yes',
                    'No'
                ],
            },
        ],
        [md, sm, xs, xxs, lg],
    );
}

import {Link} from 'react-router-dom';
import styles from '~/styles/SettingsPage.module.scss';

export default function SettingsPage(): JSX.Element {
    return (
        <>
            <div id="settings-page-root" className={styles.root}>
                <h4 className={styles.header}>Settings</h4>
                <div>
                    <Link to="/settings/notifications" className={styles.anchor}>
                        Notification Settings
                    </Link>
                </div>
            </div>
        </>
    );
}

import {useEffect, useState} from 'react';
import SwipeStack from '~/components/generics/SwipeStack';
import SwipeStackCard from '~/components/generics/SwipeStackCard';
import InvoiceStatusChart from '~/components/visualizations/InvoiceStatusChart';
import LoadingComponent from '~/components/visualizations/LoadingComponent';
import {useStoreActions, useStoreState} from '~/store/storeHooks';
import styles from '~/styles/ProjectFinancialStack.module.scss';

export default function ProjectFinancialStack(): JSX.Element {
    const getProjectInvoices = useStoreActions((actions) => actions.accounting.getProjectInvoices);
    const project = useStoreState((state) => state.subsite.project);
    const isTM = project && project.foundationJob && project.foundationJob.tm;
    const [fetchingInvoices, setFetchingInvoices] = useState(false);

    useEffect(() => {
        if (project) {
            (async function fetchData(): Promise<void> {
                setFetchingInvoices(true);
                await getProjectInvoices(project.id);
                setFetchingInvoices(false);
            })();
        }
        return (): void => {
            setFetchingInvoices(false);
        };
    }, [getProjectInvoices, project]);

    return (
        <SwipeStack>
            <SwipeStackCard cardIndex={0}>
                <div id="invoice-card-root" className={styles.invoiceCardRoot}>
                    <h3 className={styles.title}>{`${isTM ? 'T&M Cost' : 'Fixed Cost'}`}</h3>
                    {fetchingInvoices ? <LoadingComponent title="Fetching Invoices" /> : <InvoiceStatusChart activeOnly={false} />}
                </div>
            </SwipeStackCard>
        </SwipeStack>
    );
}

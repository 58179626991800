import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, DatePicker} from '@material-ui/pickers';
import {Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Zoom} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useForm, FormProvider} from 'react-hook-form';
import {useParams} from 'react-router-dom';
import SunEditor from 'suneditor-react';
import BusyButton from '~/components/generics/BusyButton';
import RHFController from '~/components/generics/RHFController';
import {CleanSunEditorHTML, DisableMobileZoom, EnableMobileZoom, GetOptionLabel, IsOptionEqualToValue} from '~/helpers';
import {useStoreActions, useStoreState} from '~/store/storeHooks';
import styles from '~/styles/CorrespondenceDialog.module.scss';
import {Need, Project} from '~/types';

interface NewNeedDialogProps {
    open: boolean;
    handleClose: ()=> void;
    project: Project;
}

let scrollPos = 0;

export default function NewNeedDialog({open, handleClose, project}: NewNeedDialogProps): JSX.Element {
    const getProjectTeam = useStoreActions((actions) => actions.subsite.getProjectTeam);
    const {projectId} = useParams<{projectId: string}>();
    const currentUser = useStoreState((state) => state.shared.currentUser);
    const projectTeam = useStoreState((state) => state.subsite.projectTeam);
    const [toClient,setToClient] = useState(false);
    const DEFAULT_NEW_NEED: Need = useMemo(
        () => ({
            id: -1,
            requestor: currentUser.id,
            created: new Date(),
            dueDate: new Date( Date.now() + (6.048e+8 * 2) ) ,
            comments: '',
            number: '',
            projectId: projectId ? Number(projectId) : undefined,
            project: projectId ? Number(projectId) : undefined,
            description: '',
            hmAssigneeName: '',
            hmAssignee: undefined,
            clientAssignee: '',
            whoIsAssignedTo: 'H+M',
            assignedToClient: false,
            completed: undefined,
            statusId: 2,
            status: 2  // 1 Deleted, 2 Open, 3 Closed
        }),
        [projectId,currentUser],
    );
    const methods = useForm<Need>({
        defaultValues: DEFAULT_NEW_NEED,
    });

    
    const insertNeed = useStoreActions((actions) => actions.correspondence.insertNeed);
    const [busy, setBusy] = useState(false);


    const {handleSubmit, setValue, reset} = methods;
    const {enqueueSnackbar} = useSnackbar();


    useEffect(() => {
        setBusy(true);
        (async function dataRequest(): Promise<void> {
            await getProjectTeam(parseInt(projectId, 10));
        })();
        setBusy(false);

        return (): void => {
            setBusy(false);
        };
    }, [getProjectTeam, projectId]);

    const hmAssigneeOptions = useMemo(()=>{
        const options: {id: number; title: string;}[] = [];
        projectTeam.forEach((person)=> {
            if (person.client === null) {
                options.push({id: person.person.id, title: person.person.fullName})
            }
        })
        return options.sort((a,b)=>a.title>b.title ? 1 : -1)
    },[projectTeam])

    // Disable zooming for this dialog, it's annoying whenever selecting any input
    useEffect(() => {
        DisableMobileZoom();
        return (): void => {
            EnableMobileZoom();
        };
    }, []);

    // Stop scrolling when the action item dialog is open
    useEffect(() => {
        if (open) {
            scrollPos = window.pageYOffset;
            document.body.style.position = 'fixed';
            document.body.style.top = `-${scrollPos}px`;
            setTimeout(()=>{document.body.style.overflow = 'visible'},0);
        } else if (scrollPos > 0) {
            document.body.style.position = '';
            document.body.style.top = '';
            window.scrollTo(0,scrollPos);
        }
    }, [open]);

    // reset to default state each time dialog is open
    useEffect(() => {
        setToClient(false)
        if (open) reset(DEFAULT_NEW_NEED);
    }, [DEFAULT_NEW_NEED, reset,open, setToClient]);


    const onSubmit = handleSubmit(async (data): Promise<void> => {
        setBusy(true);
        const response = await insertNeed(
            {
                ...data,
                hmAssignee: data.assignedToClient ? null : data.hmAssignee,
                clientAssignee: data.assignedToClient ? data.clientAssignee : null,
                completedBy: data.completed ? currentUser : null
            }
        );
        const {result, message} = response;
        setBusy(false);
        
        enqueueSnackbar(message, {variant: result});
        if (result === 'success') {
            handleClose();
        }
    });

    const handleWhoAssignedToChange = useCallback(
        (e: React.ChangeEvent<{}>, value: string): void => {
            setValue('assignedToClient', value !== 'H+M');
            setToClient(value !== 'H+M');
            setValue('whoIsAssignedTo',value)
        },
        [setValue,setToClient],
    );

    const handleHmAssigneeChange = useCallback(
        (e: React.ChangeEvent<{}>, value: {id: number; title: string}): void => {
            if (value) {
                setValue('hmAssigneeName', value.title);
                setValue('hmAssignee', value.id);
            }
        },
        [setValue],
    );

    return (
        <Dialog open={open} onClose={handleClose} id="new-need-dialog-root" className={styles.root} TransitionComponent={Zoom}>
            <DialogTitle>
                <div className={styles.title}>
                    <h3>New Project Need</h3>
                </div>
            </DialogTitle>
            <FormProvider {...methods}>
                <form onSubmit={onSubmit}>
                    <DialogContent>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <RHFController
                                        render={({field}): JSX.Element => (
                                            <div className={styles.sunEditorContainer}>
                                                <p>What is needed? *</p>
                                                <input required value={field.value} type='input' tabIndex={-1} style={{position: 'absolute', top:22,left:14,opacity: 0}} onChange={field.onChange}/>
                                                <SunEditor
                                                    required
                                                    height="100%"
                                                    setContents={CleanSunEditorHTML(field.value)}
                                                    autoFocus
                                                    hideToolbar
                                                    onChange={field.onChange}
                                                    setOptions={{
                                                        showPathLabel: false,
                                                    }}
                                                />
                                            </div>
                                        )}
                                        name="description"
                                        rules={{
                                            required: true,
                                        }}
                                        defaultValue={DEFAULT_NEW_NEED.description ?? ''}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <RHFController
                                        render={({field}): JSX.Element => <TextField {...field} label="Need Reference Number"  autoComplete='off' variant="standard" required title='Internal Reference Number'/>}
                                        name="number"
                                        defaultValue={DEFAULT_NEW_NEED.number}
                                        rules={{
                                            required: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <RHFController
                                        render={({field}): JSX.Element => (
                                            <Autocomplete
                                                {...field}
                                                options={[
                                                    "H+M",
                                                    project.client.name
                                                ]}
                                                getOptionLabel={GetOptionLabel}
                                                isOptionEqualToValue={IsOptionEqualToValue}
                                                renderInput={(params): JSX.Element => <TextField label="Need From" {...params} variant="standard" required/>}
                                                onChange={handleWhoAssignedToChange}
                                            />
                                        )}
                                        name="whoIsAssignedTo"
                                        rules={{
                                            required: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {
                                        toClient
                                        ?
                                        <RHFController
                                            key='clientAssignee'
                                            render={
                                                ({field}): JSX.Element => {
                                                    const fieldObj = {...field};
                                                    return (<TextField 
                                                        {...fieldObj} 
                                                        autoComplete='off'
                                                        key='clientAssignee'
                                                        label={`Assigned To @ ${project.client.name}`}
                                                        variant="standard" />
                                                    )
                                                }
                                            }
                                            name='clientAssignee'
                                            defaultValue={DEFAULT_NEW_NEED.clientAssignee}
                                        />
                                        :
                                        <RHFController
                                            key='hmAssigneeName'
                                            render={({field}): JSX.Element => (
                                                <Autocomplete
                                                    {...field}
                                                    options={hmAssigneeOptions}
                                                    getOptionLabel={GetOptionLabel}
                                                    isOptionEqualToValue={IsOptionEqualToValue}
                                                    renderInput={(params): JSX.Element => <TextField label="Assigned To @ H+M" {...params} variant="standard" required key='hmAssignee'/>}
                                                    onChange={handleHmAssigneeChange}
                                                />
                                            )}
                                            name="hmAssigneeName"
                                            rules={{
                                                required: true,
                                            }}
                                        />
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    <RHFController
                                        render={({field}): JSX.Element => (
                                            <>
                                                <input required value={field.value ? field.value : ''} tabIndex={-1} type='input' style={{position: 'absolute', top:22,left:14, opacity: 0, zIndex: -1}} onChange={field.onChange}/>
                                                <DatePicker
                                                    label="Due Date"
                                                    variant="dialog"
                                                    format="MM/dd/yyyy"
                                                    autoOk
                                                    showTodayButton
                                                    required
                                                    clearable
                                                    onChange={field.onChange}
                                                    value={field.value}
                                                />
                                            </>
                                        )}
                                        name="dueDate"
                                        defaultValue={DEFAULT_NEW_NEED.dueDate ?? null}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <RHFController
                                        render={({field}): JSX.Element => (
                                            <div className={styles.sunEditorContainer}>
                                                <p>Comments</p>
                                                <SunEditor
                                                    disable={false} 
                                                    height="100%"
                                                    setContents={CleanSunEditorHTML(field.value)}
                                                    autoFocus={false}
                                                    hideToolbar
                                                    onChange={field.onChange}
                                                    setOptions={{
                                                        showPathLabel: false,
                                                    }}
                                                />
                                            </div>
                                        )}
                                        name="comments"
                                        defaultValue={DEFAULT_NEW_NEED.comments ?? ''}
                                    />
                                </Grid>
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </DialogContent>
                    <DialogActions>
                        <Button className="close-button" onClick={handleClose}>
                            Close
                        </Button>
                        <BusyButton className="save-button"  busy={busy} type="submit" id="save-new-need-button">
                            Save
                        </BusyButton>
                    </DialogActions>
                </form>
            </FormProvider>
            
        </Dialog>
    );
}

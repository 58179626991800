import {useLocation} from 'react-router';

interface State {
    from: Location;
}

export function useGetStateFromPath(defaultPath: string) {
    const location = useLocation();

    const state = location?.state as State;

    if (!state?.from) {
        return defaultPath;
    }

    const {
        from: {pathname, search},
    } = state;

    return `${pathname}${search}` ?? defaultPath;
}

import {action, Action, thunk, Thunk} from 'easy-peasy';
import {axios} from '~/api';
import {AxiosTryCatchBlock} from '~/helpers';
import type {Phase, ProgressTableDataResponse, ProgressTableRow} from '~/types';

const defaultTimeout = 30000;
export interface ProgressModel {
    phases: Phase[];
    progressZoom: {min?: number; max?: number};
    setProgressZoom: Action<ProgressModel, {min?: number; max?: number}>;
    progressTableRows: ProgressTableRow[];
    setProgressTableData: Action<ProgressModel, {phases: Phase[]; rows: ProgressTableDataResponse[]}>;
    getProgressTableData: Thunk<ProgressModel, number>;
}

const progressModel: ProgressModel = {
    phases: [],
    progressTableRows: [],
    progressZoom: {},
    setProgressZoom: action((draftState,zoom) => {
        draftState.progressZoom = zoom;
    }),
    setProgressTableData: action((draftState, data) => {
        const {phases, rows} = data;
        let zoomedRows = rows.filter((row)=>row.entries?.length);
        if (draftState?.progressZoom?.min > -1 && draftState?.progressZoom?.max > -1) {
            zoomedRows = zoomedRows.slice(draftState.progressZoom.min,draftState.progressZoom.max+1);
        }
        draftState.phases = phases;
        draftState.progressTableRows = zoomedRows.reduce(
            (a: {row: ProgressTableRow[]; cumulativePlan: number; cumulativeActual: number; cumulativeForecast: number}, r, i) => {
                const {weekending, entries} = r;
                const combined = entries.reduce(
                    (b: {periodPlan: number; periodActual: number; periodForecast: number}, e) => {
                        const phase = e.phase.toLowerCase();
                        return {
                            ...b,
                            [`${phase}Plan`]: e.plan,
                            [`${phase}Actual`]: e.actual,
                            [`${phase}Forecast`]: e.forecast,
                            periodPlan: b.periodPlan + e.periodPlan,
                            periodActual: b.periodActual + e.periodActual,
                            periodForecast: b.periodForecast + e.periodForecast,
                        };
                    },
                    {periodPlan: 0, periodActual: 0, periodForecast: 0},
                );

                const cumulativePlan = a.cumulativePlan + combined.periodPlan;
                const cumulativeActual = a.cumulativeActual + combined.periodActual;
                const cumulativeForecast = a.cumulativeForecast + combined.periodForecast;

                return {
                    row: a.row.concat({
                        period: i + 1,
                        weekending,
                        ...combined,
                        cumulativePlan,
                        cumulativeActual,
                        cumulativeForecast,
                    }),
                    cumulativePlan,
                    cumulativeActual,
                    cumulativeForecast,
                };
            },
            {row: [], cumulativePlan: 0, cumulativeActual: 0, cumulativeForecast: 0},
        ).row;
    }),
    getProgressTableData: thunk((actions, projectId) =>
        AxiosTryCatchBlock(async () => {
            const {data} = await axios.get(`/ProjectProgressTable?projectId=${projectId}`, {
                timeout: defaultTimeout,
            });
            actions.setProgressTableData(data);
        }),
    ),
};

export default progressModel;

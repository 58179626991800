import {useMediaQuery} from '@mui/material';
import {memo, useEffect, useState} from 'react';
import CPPSpecialist from '../feedback/CPPSpecialist';
import LoadingComponent from '../visualizations/LoadingComponent';
import ProjectSearch from './project-log/ProjectSearch';
import Docket from './todo/Docket';
import {trackPage, trackProject} from '~/api';
import ProjectLog from '~/components/dashboard/project-log/ProjectLog';
import DashboardDesktop from '~/components/dashboard/summary-stack/DashboardDesktop';
import DashboardFinancialStack from '~/components/dashboard/summary-stack/DashboardFinancialStack';
import ProjectCorrespondenceStack from '~/components/subsite/summary/ProjectCorrespondenceStack';
import {useStoreActions, useStoreState} from '~/store/storeHooks';
import styles from '~/styles/Dashboard.module.scss';

function Dashboard(): JSX.Element {
    const [busy, setBusy] = useState(true);
    const getInitialData = useStoreActions((actions) => actions.dashboard.getInitialData);
    const {userOptions, currentUser} = useStoreState((state) => state.shared);
    const mobile = useMediaQuery('(max-width: 600px)');
    const isHm = currentUser?.email.includes('hm-ec.com');
    useEffect(() => {
        (async function dashboardInit(): Promise<void> {
            setBusy(true);
            await getInitialData({includeArchived: userOptions?.projectTableOptions?.includeArchived});
            setBusy(false);
        })();
        return (): void => setBusy(false);
    }, [getInitialData, userOptions?.projectTableOptions?.includeArchived]);

    useEffect(() => {
        trackPage('Dashboard');
        trackProject(null);
    }, []);

    return (
        <>
            <div className={styles.dashboardContent}>
                {busy ? (
                    <LoadingComponent title="Loading Dashboard" />
                ) : (
                    <>
                        <div className={styles.flexContainer}>
                            <Docket />
                        </div>
                        {mobile ? (
                            <div>
                                <ProjectSearch />
                                <DashboardFinancialStack />
                                <ProjectCorrespondenceStack projectLabel />
                            </div>
                        ) : (
                            <DashboardDesktop />
                        )}
                        <ProjectLog />
                        {!isHm && (
                            <CPPSpecialist open={userOptions?.feedbackOptions?.defaultCPPSpecialistFeedback ?? true} />
                        )}
                    </>
                )}
            </div>
        </>
    );
}

export default memo(Dashboard);

import {posthog} from 'posthog-js';
import {PostHogProvider} from 'posthog-js/react';
import AppRoutes from './AppRoutes';
import AppStore from './AppStore';
import AppStyle from './AppStyle';
import {AuthProvider} from '~/api';

export default function App() {
    return (
        <PostHogProvider client={posthog}>
            <AppStyle>
                <AuthProvider>
                    <AppStore>
                        <AppRoutes />
                    </AppStore>
                </AuthProvider>
            </AppStyle>
        </PostHogProvider>
    );
}

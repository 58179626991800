import {Button} from '@material-ui/core';
import HistoryIcon from '@mui/icons-material/History';
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import {useState} from 'react';
import styles from '~/styles/ChangeOrderDialog.module.scss';


export default function COWorkflowHistory({workflow}: {workflow: {initiate: {at: string, by: string}, metadata: {history: Array<object>}}}) {
    const [open,setOpen] = useState(false);

    const history = workflow?.metadata?.history;

    return (
            history?.length
            ?
            <Box className={styles.historyRoot}>
                <Box className={styles.historyButtonDiv} onClick={()=>setOpen(true)}>
                    <HistoryIcon className={styles.historyButtonIcon}/>
                    History
                </Box>
                <Dialog open={open} maxWidth='md' className={styles.root} fullWidth onClose={()=>setOpen(false)}>
                        <DialogTitle className={styles.historyTitle}>Workflow History</DialogTitle>
                        <DialogContent className={styles.historyContent}>
                            <TableContainer component={Paper} className={styles.historyTable}>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Date</TableCell>
                                            <TableCell>Event</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody >
                                        <TableRow hover>
                                            <TableCell>{workflow?.initiate?.at}</TableCell>
                                            <TableCell>Initiated by {workflow?.initiate?.by}</TableCell>
                                        </TableRow>
                                        {history.filter((h:any)=>h.event !== 'step auto completed' && h.event !== 'step enabled' && h.event !== 'step disabled').map((h: any, i: any)=>(
                                            // eslint-disable-next-line react/no-array-index-key
                                            <TableRow hover>
                                                <TableCell>{h.date}</TableCell>
                                                <TableCell>{h.note}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </DialogContent>
                        <DialogActions>
                            <Button color='inherit' onClick={()=>setOpen(false)}> 
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
            </Box>
            : 
            <></>
    );
}
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';
import {useContext, useEffect, useMemo, useState} from 'react';
import ProjectContext from '~/components/dashboard/project-log/ProjectContext';
import KPIGrid from '~/components/kpi/KPIGrid';
import ProjectHealthDetailDialog from '~/components/subsite/health/ProjectHealthDetailDialog';
import useProjectHealth from '~/hooks/useProjectHealth';
import {useStoreActions, useStoreState} from '~/store/storeHooks';
import styles from '~/styles/KPI.module.scss';

interface HealthGradeKPIProps {
    type: 'overall' | 'hse' | 'budget' | 'schedule';
    tooltipTitle?: string;
}

HealthGradeKPI.defaultProps = {
    tooltipTitle: '',
};
export default function HealthGradeKPI({type, tooltipTitle}: HealthGradeKPIProps): JSX.Element {
    const {project} = useContext(ProjectContext);
    const [detailDialogOpen, setDetailDialogOpen] = useState<boolean>(false);
    const standardHealthCriteria = useStoreState((state) => state.project.standardHealthCriteria);
    const getStandardHealthCriteria = useStoreActions((actions) => actions.project.getStandardHealthCriteria);
    const {projectHealth, foundationJob} = project;
    const {calculateOverallGrade, calculateHSEGrade, calculateScheduleScore, calculateBudgetScore} = useProjectHealth();

    useEffect(() => {
        if (standardHealthCriteria.length === 0) {
            getStandardHealthCriteria();
        }
    }, [getStandardHealthCriteria, standardHealthCriteria]);

    const data = useMemo((): {title: string; color: string; score: number | string} => {
        switch (type) {
            case 'overall': {
                const overallGrade = calculateOverallGrade(project);
                return {title: 'Overall Health', color: overallGrade.indicator.color, score: overallGrade.score};
            }
            case 'hse': {
                const hseGrade = calculateHSEGrade(projectHealth, foundationJob, project.healthCriteria ?? []);
                return {title: 'HSE', color: hseGrade.indicator.color, score: hseGrade.score};
            }
            case 'budget': {
                const budgetScore = calculateBudgetScore(project);
                return {title: 'Budget - CPI', color: budgetScore.indicator.color, score: budgetScore.score};
            }
            case 'schedule': {
                const scheduleScore = calculateScheduleScore(project);
                return {title: 'Schedule - SPI', color: scheduleScore.indicator.color, score: scheduleScore.score};
            }
            default:
                return {
                    title: 'KPI',
                    color: '#474747',
                    score: 0,
                };
        }
    }, [type, calculateHSEGrade, calculateOverallGrade, calculateBudgetScore, calculateScheduleScore, project, foundationJob, projectHealth]);

    const active = useMemo(() => type !== 'overall' || project?.foundationJob?.tm, [type, project]);

    return (
        <Tooltip title={detailDialogOpen ? "" : tooltipTitle} arrow>
            <div>
                <KPIGrid
                    clickable={active}
                    onClick={(): void => {
                        if (active) {
                            setDetailDialogOpen(true);
                        }
                    }}
                >
                    <div className={`${styles.container}`}>
                        <h5 className={styles.title}>{data.title}</h5>
                        <FontAwesomeIcon
                            icon={['fas', 'circle']}
                            color={data.color}
                            className={`${styles.icon} ${type !== 'overall' && styles.clickable}`}
                        />
                    </div>
                    <ProjectHealthDetailDialog
                        open={detailDialogOpen}
                        category={type}
                        title={data.title}
                        result={data}
                        onCloseCallback={(): void => setDetailDialogOpen(false)}
                    />
                </KPIGrid>
            </div>
        </Tooltip>
    );
}

import { Button } from '@mui/material';
import {useSnackbar} from 'notistack';
import {useCallback, useState} from 'react';
import * as React from 'react';
import {useParams} from 'react-router-dom';
import {useStoreActions} from '~/store/storeHooks';

export default function ClearAllNotificationsButton(): JSX.Element {
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [busy, setBusy] = useState(false);
    const clearNotifications = useStoreActions((actions) => actions.activity.clearNotifications);
    const {enqueueSnackbar} = useSnackbar();
    const {projectId} = useParams<{projectId: string}>();

    const handleConfirm = (e: React.MouseEvent): void => {
        e.stopPropagation();
        if (confirmDelete) {
            handleDelete().then(() => {
                setConfirmDelete(false);
            });
        } else {
            setConfirmDelete(true);
        }
    };

    const handleDelete = useCallback(async () => {
        setBusy(true);
        const {result, message} = await clearNotifications(projectId ? parseInt(projectId, 10) || -1 : -1);
        setBusy(false);
        if (result === 'error') {
            enqueueSnackbar(message, {variant: result});
        }
    }, [clearNotifications, enqueueSnackbar, projectId]);

    return (
        <Button id="clear-all-notifications-button" disabled={busy} onClick={handleConfirm} variant='text' color='inherit'>
            <p>{`${confirmDelete ? 'Confirm Clear' : 'Clear'}`}</p>
        </Button>
    );
}

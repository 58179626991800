import { useTrackPage } from '~/api';
import CustomerServiceCard from '~/components/contact/CustomerServiceCard';
import EPCServiceCard from '~/components/contact/EPCServiceCard';
import SummaryCard from '~/components/generics/SummaryCard';
import styles from '~/styles/ServicePage.module.scss';

export const DEFAULT_CONTACT = {
    corporate: {
        title: 'Corporate',
        address1: '5820 Center Street',
        address2: 'Pasadena, TX 77505',
        phone: '281-930-8750',
        email: 'info@hm-ec.com',
    },
    craft: {
        title: 'Craft Recruitment',
        address1: '1613 Center Street',
        address2: 'Deer Park, TX 77536',
        phone: '281-884-8565',
    },
    woodlands: {
        title: 'The Woodlands',
        address1: '1330 Lake Robbins Drive Suite 220',
        address2: 'The Woodlands, TX 77380',
        phone: '281-930-8750',
    },
};

export default function ServicePage(): JSX.Element {
    useTrackPage('Customer Service');
    
    return (
        <>
            <div id="customer-service-page-root" className={styles.serviceContent}>
                <SummaryCard>
                    <CustomerServiceCard />
                </SummaryCard>
                <SummaryCard>
                    <EPCServiceCard contact={DEFAULT_CONTACT.corporate} />
                </SummaryCard>
            </div>
        </>
    );
}

import {action, Action, thunk, Thunk} from 'easy-peasy';
import {axios} from '~/api';
import {ChangeOrder} from '~/types';

const defaultTimeout = 30000;

export interface ChangeManagementModel {
    changeOrders: ChangeOrder[];
    setChangeOrders: Action<ChangeManagementModel, ChangeOrder[]>;
    getChangeOrders: Thunk<ChangeManagementModel, number>;
    updateChangeOrder: Thunk<ChangeManagementModel, any>;
}

const changeManagementModel: ChangeManagementModel = {
    changeOrders: [],

    setChangeOrders: action((draftState, changeOrders) => {
        draftState.changeOrders = changeOrders;
    }),

    getChangeOrders: thunk(async (actions, projectId) => {
        try {
            const {data} = await axios.get(`/ChangeOrders?projectId=${projectId}`, {
                timeout: defaultTimeout,
            });
            actions.setChangeOrders(data);
            return data;
        } catch(e: any) {
         return {result: 'error', message: `${e?.name  } ${  e?.message}`}
        };
    }),

    updateChangeOrder: thunk(async (actions, payload) => {
        try {
            const {data} = await axios.post('/UpdateChangeOrder', payload, {
                timeout: defaultTimeout,
            });
            actions.setChangeOrders(data);
            return {result: 'success', message: 'Successfully updated change order', data};
        }
        catch(e: any) {
            return {result: 'error', message: `${e?.name  } ${  e?.message}`}
        };
    })
};

export default changeManagementModel;

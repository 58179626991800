import {useMediaQuery} from '@mui/material';
import {useCallback, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useTrackPage} from '~/api';
import ChatHistory from '~/components/chat/ChatHistory';
import ChatHistoryContext from '~/components/chat/ChatHistoryContext';
import ChatInput from '~/components/chat/ChatInput';
import {useStoreActions, useStoreState} from '~/store/storeHooks';
import styles from '~/styles/ChatMobilePage.module.scss';

export default function ChatMobilePage(): JSX.Element {
    const currentUser = useStoreState((state) => state.shared.currentUser);
    const updateCommentsRead = useStoreActions((actions) => actions.comment.updateCommentsRead);
    const [selectedComment, setSelectedComment] = useState(null);
    const mobile = useMediaQuery('(max-width: 600px)');
    const {projectId} = useParams<{projectId: string}>();
    const comments = useStoreState((state) => state.comment.comments);

    useTrackPage('Chat');

    const onLoadedCallback = useCallback((): void => {
        updateCommentsRead({
            projectId: parseInt(projectId, 10),
            docId: null,
        });
    }, [projectId, updateCommentsRead]);

    const chatHistoryContext = useMemo(
        () => ({
            currentUser: currentUser.id,
            selectedComment,
            setSelectedComment,
            mobile,
            target: 'global',
        }),
        [currentUser.id, mobile, selectedComment],
    );

    return (
        <ChatHistoryContext.Provider value={chatHistoryContext}>
            <div className={styles.root} id="comments-root">
                <ChatHistory comments={comments} onLoadedCallback={onLoadedCallback} />
                <div className={styles.chatInputContainer}>
                    <ChatInput />
                </div>
            </div>
        </ChatHistoryContext.Provider>
    );
}

import {GridRowClassNameParams, GridRowParams} from '@mui/x-data-grid-pro';
import {useCallback, useEffect, useState} from 'react';
import {useParams, useSearchParams} from 'react-router-dom';
import DataGridProTable from '~/components/generics/DataGridProTable';
import ActionItemDialog from '~/components/subsite/correspondence/actionItem/ActionItemDialog';
import ActionItemsTableCols from '~/components/subsite/correspondence/actionItem/ActionItemsTableColsPro';
import LoadingComponent from '~/components/visualizations/LoadingComponent';
import {useStoreActions, useStoreState} from '~/store/storeHooks';
import styles from '~/styles/DataGridPro.module.scss';

const tableCacheVersion = 3;

export default function ActionItemsTable(): JSX.Element {
    const {projectId} = useParams<{projectId: string}>();
    const [, setSearchParams] = useSearchParams();
    const project = useStoreState((state) => state.subsite.project);
    const getActionItems = useStoreActions((actions) => actions.correspondence.getActionItems);
    const actionItems = useStoreState((state) => state.correspondence.actionItems);
    const [busy, setBusy] = useState(false);
    const columns = ActionItemsTableCols(project);

    useEffect(() => {
        if (projectId) {
            setBusy(true);
            (async function fetchData(): Promise<void> {
                await getActionItems(parseInt(projectId, 10));
            })();
            setBusy(false);
        }
        return (): void => {
            setBusy(false);
        };
    }, [getActionItems, projectId]);

    const handleRowClick = useCallback(
        (params: GridRowParams): void => {
            setSearchParams({action: String(params.row.id)});
        },
        [setSearchParams],
    );

    const getRowClassName = (params: GridRowClassNameParams) => {
        const {status, clientAssigned} = params.row;
        let cn = styles.row;
        if (status === 'Open' && clientAssigned) {
            cn = `${cn} ${styles.actionRequired}`;
        } else if (status === 'Open') {
            cn = `${cn} ${styles.actionable}`;
        } else {
            cn = `${cn} ${styles.readOnly}`;
        }
        if (params.row.descendent) cn = `${cn} ${styles.treeBranch}`;
        return cn;
    };

    return (
        <div id="Action-item-table-root">
            {busy ? (
                <LoadingComponent title="Loading Action Item Data" contained />
            ) : (
                <>
                    <DataGridProTable
                        columns={columns}
                        data={actionItems}
                        optionKey="actionItemsTable"
                        csvFileName={`${project?.code ?? 'Project'} Action Item Log.csv`}
                        cacheVersion={tableCacheVersion}
                        handleRowClick={handleRowClick}
                        getRowClassName={getRowClassName}
                        excelOptions={{tableName: 'actionItems', projectId: parseInt(projectId, 10), projectCode: project?.code}}
                    />
                    <ActionItemDialog />
                </>
            )}
        </div>
    );
}

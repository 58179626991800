import styles from '~/styles/AccessDenied.module.scss';

export default function AccessDenied(): JSX.Element {
    return (
        <div className={styles.root}>
            <div>
                <h2>Access Denied</h2>
                <p>If you believe this is a mistake, please contact Technical Support for assistance</p>
                <a href={`mailto:helpdesk@hm-ec.com?subject=Capital Projects Portal Support Request&body=Access Request for ${window.location.href}`}>
                    helpdesk@hm-ec.com
                </a>
            </div>
        </div>
    );
}

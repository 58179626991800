import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PersonIcon from '@mui/icons-material/Person';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import {Avatar, CircularProgress, Fade} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import {useCallback, useContext, useMemo, useState} from 'react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import {Element} from 'react-scroll';
import ChatHistoryContext from '~/components/chat/ChatHistoryContext';
import ChatReaction from '~/components/chat/ChatReaction';
import ReactionHistory from '~/components/chat/ReactionHistory';
import ReplyReference from '~/components/chat/ReplyReference';
import {OnEnterPressWrapper} from '~/helpers';
import styles from '~/styles/ChatBubble.module.scss';
import {Comment} from '~/types';

interface ChatBubbleProps {
    comment: Comment;
    name: string;
}

export default function ChatBubble({comment, name}: ChatBubbleProps): JSX.Element {
    const {currentUser, setSelectedComment, selectedComment, target} = useContext(ChatHistoryContext);
    const {createdBy, parent, responded, url, document} = comment;
    const [showReaction,setShowReaction] = useState(false);
    const currentUserMsg = currentUser === createdBy.id;

    const initials = useMemo(() => {
        if (typeof createdBy.initials === 'string') {
            return createdBy.initials.toUpperCase();
        }
        if (createdBy.firstName && createdBy.lastName) {
            return `${createdBy.firstName.charAt(0)}${createdBy.lastName.charAt(0)}`.toUpperCase();
        }
        return <PersonIcon />;
    }, [createdBy.firstName, createdBy.initials, createdBy.lastName]);

    const handleCommentClick = useCallback(
        (e: React.UIEvent): void => {
            e.stopPropagation();
            setSelectedComment(selectedComment ? null : comment);
        },
        [comment, selectedComment, setSelectedComment],
    );

    let sourceLink: JSX.Element | null = null;
    if (document) {
        sourceLink = <Link to={`${url}?doc=${document.id}&chat=true`} className={styles.docLink}>{document.title}<TextSnippetIcon fontSize='small' className={styles.docIcon}/></Link>;
    }

    return (
        <Element name={name}>
            <div className={`${styles.root} ${currentUserMsg ? styles.currentUserMsg : ''}`} onMouseEnter={()=>setShowReaction(true)} onMouseLeave={()=>setShowReaction(false)}>
                {!currentUserMsg && (
                    <Tooltip title={`${createdBy.fullName}`}>
                        <div id="chat-avatar" className={styles.avatar}>
                            <Avatar alt={`${createdBy.fullName}-msg`}>{initials}</Avatar>
                        </div>
                    </Tooltip>
                )}
                <div>
                    <Fade in={!currentUserMsg && showReaction} mountOnEnter unmountOnExit>
                        <div className={styles.reactionContainer}>
                            <ChatReaction comment={comment} />
                        </div>
                    </Fade>
                    <div className={styles.commentContainer}>
                        <div
                            className={styles.chatBubble}
                            id="chat-bubble"
                            onClick={handleCommentClick}
                            onKeyDown={OnEnterPressWrapper(handleCommentClick)}
                            role="button"
                            tabIndex={0}
                        >
                            <ReactionHistory commentReactions={comment.commentReactions} />
                            <ReplyReference parent={parent} />
                            {comment.comment}
                        </div>
                        <div className={styles.dateSection}>
                            {
                                // id of -1 indicates temporary comment displayed in history while comment is being 
                                // saved to database, so give use a spinning wheel while message is saving
                                comment.id === -1 && <div><CircularProgress size={14}/></div>
                            }
                            <div id="chat-timestamp" className={styles.timestamp}>
                                {new Date(comment.created).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})}
                            </div>
                        </div>
                        {responded && <FontAwesomeIcon icon={['far', 'check']} className={styles.respondedCheckbox} />}
                    </div>
                    {target === 'global' && <div className={styles.sourceLinkContainer}>{sourceLink}</div>}
                </div>
            </div>
        </Element>
    );
}

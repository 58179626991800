import {useMemo} from 'react';
import * as React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {OnEnterPressWrapper} from '~/helpers';
import {useStoreState} from '~/store/storeHooks';
import styles from '~/styles/ProjectLog.module.scss';

interface ProjectTitleColumnProps {
    id: number;
    title: string;
    surveyAvailable: boolean;
}

export default function ProjectTitleColumn({id, title, surveyAvailable}: ProjectTitleColumnProps): JSX.Element {
    const {defaultOpenInNewTab} = useStoreState((state) => state.shared.userOptions.projectTableOptions);
    const nav = useNavigate();
    const link = useMemo(() => {
        const href = `/project/${id}${surveyAvailable ? `/closeout` : `/summary`}`;

        return {
            href,
            handleClick: (e: React.UIEvent): void => {
                if (defaultOpenInNewTab) {
                    e.stopPropagation();
                    e.preventDefault();
                    const win = window.open(href, '_blank');
                    win.focus();
                } else {
                    e.stopPropagation();
                    e.preventDefault();
                    nav(href);
                }
            },
        };
    }, [id, surveyAvailable, defaultOpenInNewTab, nav]);

    return (
        <Link
            to={link.href}
            className={styles.projectLogTableTitleColumn}
            onClick={link.handleClick}
            onKeyDown={OnEnterPressWrapper(link.handleClick)}
        >
            {title}
        </Link>
    );
}

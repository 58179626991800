import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Zoom} from '@mui/material';
import styles from '~/styles/ConfirmationDialog.module.scss';

interface ConfirmationDialogProps {
    open: boolean;
    title: string;
    message: string;
    onConfirm: () => void;
    onCancel: () => void;
}

export default function ConfirmationDialog({open, title, message, onConfirm, onCancel}: ConfirmationDialogProps): JSX.Element {
    return (
        <Dialog
            open={open}
            id="confirmation-dialog-root"
            onClose={onCancel}
            maxWidth="md"
            fullWidth
            className={styles.root}
            TransitionComponent={Zoom}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <p>{message}</p>
            </DialogContent>
            <DialogActions>
                <Button id="confirmation-cancel-button" className="close-button" onClick={onCancel}>
                    Cancel
                </Button>
                <Button id="confirmation-accept-button" className="save-button" onClick={onConfirm}>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
}

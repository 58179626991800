import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Fab, FabProps} from '@mui/material';
import {useCallback} from 'react';
import {animateScroll} from 'react-scroll';
import styles from '~/styles/ScrollToTopButton.module.scss';

export default function ScrollToTopButton(): JSX.Element {
    const handleClick = useCallback<FabProps['onClick']>(() => {
        animateScroll.scrollToTop();
    }, []);

    return (
        <Fab className={styles.root} id="scroll-to-top-button" size="large" onClick={handleClick}>
            <FontAwesomeIcon icon={['fad', 'chevron-double-up']} size="lg" />
        </Fab>
    );
}

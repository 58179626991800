import {useContext} from 'react';
import {useParams} from 'react-router-dom';
import AttachmentListItem from '~/components/generics/AttachmentListItem';
import DocumentTableContext from '~/components/subsite/documentTable/DocumentTableContext';
import {useStoreActions} from '~/store/storeHooks';
import styles from '~/styles/DocumentDialog.module.scss';

export default function DocumentAttachments(): JSX.Element {
    const {document} = useContext(DocumentTableContext);
    const {projectId} = useParams<{projectId: string}>();

    const {attachments} = document;
    const deleteDocumentAttachment = useStoreActions((actions) => actions.document.deleteDocumentAttachment);

    return (
        <div id="document-attachments-container" className={styles.documentAttachmentsRoot}>
            <h5>Attachments</h5>
            <div>
                {attachments.map((a) => {
                    const handleDelete = async (): Promise<{result: string; message: string}> => {
                        const {result, message} = await deleteDocumentAttachment({
                            projectId: parseInt(projectId, 10),
                            docId: document.id,
                            id: a.id,
                        });
                        return {result, message};
                    };
                    return (
                        <AttachmentListItem
                            key={a.id}
                            file={a}
                            editable={a.type === 'Client' && document.status.actionList?.length > 0}
                            onDeleteCallback={handleDelete}
                        />
                    );
                })}
            </div>
        </div>
    );
}

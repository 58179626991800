import {useContext, useState} from 'react';
import ScopeAttachment from './ScopeAttachment';
import ProjectContext from '~/components/dashboard/project-log/ProjectContext';
import NoDataPlaceholder from '~/components/generics/NoDataPlaceholder';
import LoadingComponent from '~/components/visualizations/LoadingComponent';
import styles from '~/styles/ScopeAttachments.module.scss';

export default function ScopeAttachmentList(): JSX.Element {
    const {project} = useContext(ProjectContext);
    const {scopeAttachments} = project;
    const [busy, setBusy] = useState(false);

    if (busy) {
        return <LoadingComponent />;
    }

    if (!scopeAttachments?.length) {
        return <NoDataPlaceholder title="No Scope Attachment" />;
    }

    return (
        <div className={styles.root} id="scope-attachment-list-root">
            <ul className={styles.scopeAttachmentList}>
                {scopeAttachments.map((file) => (
                    <ScopeAttachment key={file.id} file={file} setBusy={setBusy} />
                ))}
            </ul>
        </div>
    );
}

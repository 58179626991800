import {IconName} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Chip} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {GridColDef, GridRenderCellParams, GridValueGetterParams, getGridDateOperators, getGridNumericOperators } from '@mui/x-data-grid-pro';
import {useMemo} from 'react';
import {getGridStringArrayAutocompleteOperators} from '~/components/generics/MUIDataGridCustomOperators';
import { defaultMediaSizes } from '~/helpers';
import styles from '~/styles/DataGridPro.module.scss';
import {Document} from '~/types';

export default function DocumentTableColumns(isTreeData: boolean): GridColDef<Document>[] {
    const mediaSizes = defaultMediaSizes;
    const xxs = useMediaQuery(mediaSizes.xxs);
    const xs = useMediaQuery(mediaSizes.xs);
    const sm = useMediaQuery(mediaSizes.sm);
    const md = useMediaQuery(mediaSizes.md);
    const lg = useMediaQuery(mediaSizes.lg);

    return useMemo(
        (): GridColDef<Document>[] => {
            const columns: GridColDef<Document>[] = [
            {
                headerName: 'Title',
                field: 'title',
                width: xxs || xs || sm ? 200 : 250,
            },
            {
                headerName: 'Description',
                field: 'description',
                width: 350,
                hide: xxs || xs || sm || md,
            },
            {
                headerName: 'Rev',
                field: 'rev',
                align: 'center',
                hide: xxs || xs || sm,
                headerAlign: 'center',
                filterOperators: getGridNumericOperators(),
            },
            {
                headerName: 'Status',
                field: 'status',
                align: 'center',
                width: 150,
                headerAlign: 'center',
                // customSort: (a, b): number => a.status.title.localeCompare(b.status.title),
                valueGetter: (params: GridValueGetterParams): string => params.value?.title,
                renderCell: (params: GridRenderCellParams): JSX.Element => {
                    const title = params?.value;
                    let background = '#424242';
                    let color = '#FFF';

                    switch (title) {
                        case 'Approved':
                            background = '#507251';
                            break;
                        case 'Pending':
                            background = '#90caf9';
                            color = '#000';
                            break;
                        case 'Revise & Resubmit':
                        case 'IFA':
                            // background = '#cfc96c';
                            background = '#90caf9';
                            color = '#000';
                            break;
                        case 'Rejected':
                            background = '#b15555';
                            break;
                        default:
                            break;
                    }

                    return <Chip style={{color, background}} label={title} className={styles.statusChip}  size="small" />;
                },
            },
            {
                headerName: 'File Type',
                field: 'fileType',
                minWidth: 110, 
                maxWidth: 110,
                hide: xxs || xs,
                align: 'center',
                headerAlign: 'center',
                renderCell: (params: GridRenderCellParams): JSX.Element => {
                    const fileType = params?.value;
                    let icon;
                    let color = 'gray';
                    switch (fileType && fileType.toUpperCase()) {
                        case 'PDF':
                            icon = 'file-pdf';
                            color = 'tomato';
                            break;
                        case 'DOCX':
                        case 'DOC':
                            icon = 'file-word';
                            color = '#2D9FFE';
                            break;
                        case 'XLSX':
                        case 'XLSM':
                        case 'XLS':
                            icon = 'file-excel';
                            color = 'limegreen';
                            break;
                        case 'CSV':
                            icon = 'file-csv';
                            color = 'limegreen';
                            break;
                        case 'ZIP':
                            icon = 'file-archive';
                            color = 'khaki';
                            break;
                        case 'JPG':
                        case 'PNG':
                        case 'BMP':
                        case 'JPEG':
                        case 'SVG':
                        case 'IMG':
                            icon = 'file-image';
                            color = 'slateblue';
                            break;
                        default:
                            icon = 'file-alt';
                            break;
                    }
                    return <FontAwesomeIcon icon={['fad', `${icon}` as IconName]} size="2x" color={color} />;
                },
            },
            {
                headerName: 'Source',
                field: 'company',
                width: 175,
                hide: xxs || xs,
                align: 'center',
                headerAlign: 'center',
                renderCell: (params: GridRenderCellParams): JSX.Element => {
                    const company = params?.value;
                    const color = '#fff';
                    let background = '#2a445b';

                    if (company !== 'H+M') {
                        background = '#2875a4';
                    }
                    return <Chip style={{background, color}} label={company} className={styles.statusChip} size="small" />;
                },
            },
            {
                headerName: 'Modified',
                field: 'modifiedOn',
                align: 'center',
                resizable: false,
                width: 140,
                headerAlign: 'center',
                hide: xxs || xs || sm || md,
                valueGetter: (params: any): Date | null | undefined => params.row?.modifiedOn,
                valueFormatter: (params: any): string => {
                    if (!params.value) {
                        return '';
                    }
                    try {
                        return `${(params.value as Date).toLocaleDateString('en-US', {
                            day: '2-digit',
                            month: '2-digit',
                            year: '2-digit',
                        })}`;
                    }
                    catch(e) {
                        return '';
                    }
                },
                filterOperators: getGridDateOperators(),
  
            },
            {
                headerName: 'Modified By',
                minWidth: 150, 
                maxWidth: 200,
                field: 'modifiedBy',
                align: 'center',
                headerAlign: 'center',
                hide: xxs || xs || sm || md || lg,
            },
            {
                headerName: 'Document State',
                field: 'status.actionList',
                align: 'center',
                width: 170,
                headerAlign: 'center',
                valueGetter: (params: any): string  =>{
                    const {status,editable,descendent} = params.row;
                    let value = 'Read Only';
                    if (descendent) return value;
                    const actionRequired = status?.title === 'IFA';
                    if (status?.actionList?.length) {
                        if (actionRequired) {
                            value = 'Action Required';
                        } else if (editable) {
                            value = 'Action & Edit';
                        } else {
                            value = 'Actionable';
                        }
                    } else if (editable) {
                        value = 'Editable';
                    } 
                    return value;
                },
                renderCell: (params: GridRenderCellParams): JSX.Element => {
                    const title = params?.value;
                    let background = '#424242';
                    let color = 'rgb(189, 180, 180)';

                    switch (title) {
                        case 'Action Required':
                            background = '#90caf9';
                            color = '#000';
                            break;
                        case 'Action & Edit':
                            color = '#90caf9';
                            break;
                        case 'Actionable':
                            color = '#90caf9';
                            break;
                        case 'Editable':
                            color = '#FFF';
                            break;
                        default:
                            break;
                    }

                    return <Chip style={{color, background}} label={title} variant="outlined"  size="small" />;
                },
                filterOperators: getGridStringArrayAutocompleteOperators([
                    'Action & Edit',
                    'Actionable',
                    'Editable',
                    'Read Only'
                ]),
            }]
            if (!isTreeData) {
                columns.splice(0,0,{
                    headerName: 'Number',
                    field: 'number',
                    width: xxs || xs || sm ? 85 : 100,
                })
            }
            return columns;
        },
        [lg, md, sm, xs, xxs, isTreeData],
    );
}

import {useRef, useCallback, useMemo} from 'react';
import Particles from 'react-tsparticles';
import type {Container, ISourceOptions} from 'tsparticles';
import {OnEnterPressWrapper} from '~/helpers';

interface Props {
    height: number;
    width: number;
}

const particleOptions: ISourceOptions = {
    fullScreen: {
        enable: false,
        zIndex: 1,
    },
    emitters: [
        {
            position: {
                x: 25,
                y: 75,
            },
            rate: {
                quantity: 15,
            },
            direction: 'top-right',
            life: {
                count: 1,
                duration: 1.5,
                delay: 1,
                wait: true,
            },
        },
        {
            position: {
                x: 75,
                y: 75,
            },
            rate: {
                quantity: 15,
            },
            direction: 'top-left',
            life: {
                count: 1,
                duration: 1.5,
                delay: 1,
                wait: true,
            },
        },
    ],
    particles: {
        color: {
            value: ['#FAD77B', '#8db2cc', '#ffea00', '#C6CCD0', '#343A45'],
        },
        move: {
            decay: 0.05,
            direction: 'top',
            enable: true,
            gravity: {
                enable: true,
                maxSpeed: 150,
            },
            outModes: {
                top: 'none',
                default: 'destroy',
            },
            speed: {min: 25, max: 30},
        },
        number: {
            value: 0,
        },
        opacity: {
            value: 1,
        },
        rotate: {
            value: {
                min: 0,
                max: 360,
            },
            direction: 'random',
            animation: {
                enable: true,
                speed: 100,
            },
        },
        tilt: {
            direction: 'random',
            enable: true,
            value: {
                min: 0,
                max: 360,
            },
            animation: {
                enable: true,
                speed: 30,
            },
        },
        size: {
            value: 3,
        },
        roll: {
            darken: {
                enable: true,
                value: 25,
            },
            enable: true,
            speed: {
                min: 5,
                max: 15,
            },
        },
        wobble: {
            distance: 30,
            enable: true,
            speed: {
                min: -7,
                max: 7,
            },
        },
        shape: {
            type: ['circle', 'square', 'polygon'],
            options: {
                polygon: [
                    {
                        sides: 5,
                    },
                    {
                        sides: 6,
                    },
                ],
            },
        },
    },
};

export default function ConfettiEffect({height, width}: Props): JSX.Element {
    const tspContainer = useRef<Container>(null);

    const particlesLoaded = async (container: Container): Promise<void> => {
        tspContainer.current = container;
    };

    const handleClick = useCallback((): void => {
        tspContainer.current?.refresh();
    }, []);

    const containerStyle = useMemo<any>(
        () => ({
            width,
            height,
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            cursor: 'pointer',
        }),
        [height, width],
    );

    const particlesStyle = useMemo<any>(() => ({position: 'absolute', left: 0, top: 0, transform: 'translate(-25%, -25%)'}), []);

    return (
        <div
            id="confetti-effect-container"
            style={containerStyle}
            onClick={handleClick}
            onKeyDown={OnEnterPressWrapper(handleClick)}
            role="button"
            tabIndex={0}
        >
            <Particles
                style={particlesStyle}
                loaded={particlesLoaded}
                width="200%"
                height="200%"
                container={tspContainer}
                options={particleOptions}
            />
        </div>
    );
}

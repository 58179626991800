import {motion} from 'framer-motion';
import {useCallback} from 'react';
import {Link, useParams} from 'react-router-dom';
import {usePermissions} from '~/hooks/usePermissions';
import {useStoreState} from '~/store/storeHooks';
import {listItemVariants} from '~/styles/animations/__animationVariants';

interface ProjectPageNavMenuOptionsProps {
    handleToggle: () => void;
}

export default function ProjectPageNavMenuOptions({handleToggle}: ProjectPageNavMenuOptionsProps): JSX.Element {
    const {projectId} = useParams<{projectId: string}>();
    const project = useStoreState((state) => state.subsite.project);
    const view = usePermissions();

    const handleSelection = useCallback((): void => {
        handleToggle();
    }, [handleToggle]);

    return (
        <>
            <motion.li variants={listItemVariants} id="dashboard-li">
                <Link to="/" onClick={handleSelection}>
                    <h2>Dashboard</h2>
                </Link>
            </motion.li>
            {project?.surveyAvailable ? (
                <motion.li variants={listItemVariants} id="closeout-li">
                    <Link to={`project/${projectId}/closeout`} onClick={handleSelection}>
                        <h2>Survey</h2>
                    </Link>
                </motion.li>
            ) : (
                ''
            )}
            <motion.li variants={listItemVariants} id="summary-li">
                <Link to={`/project/${projectId}/summary`} onClick={handleSelection}>
                    <h2>Summary</h2>
                </Link>
            </motion.li>
            <motion.li variants={listItemVariants} id="hse-li">
                <Link to={`/project/${projectId}/hse`} onClick={handleSelection}>
                    <h2>HSE</h2>
                </Link>
            </motion.li>
            <motion.li variants={listItemVariants} id="progress-li">
                <Link to={`/project/${projectId}/progress`} onClick={handleSelection}>
                    <h2>Progress</h2>
                </Link>
            </motion.li>
            <motion.li variants={listItemVariants} id="deliverables-li">
                <Link to={`/project/${projectId}/deliverables`} onClick={handleSelection}>
                    <h2>Deliverables</h2>
                </Link>
            </motion.li>
            {view && (
                <motion.li variants={listItemVariants} id="change-li">
                    <Link to={`/project/${projectId}/change`} onClick={handleSelection}>
                        <h2>Change Management</h2>
                    </Link>
                </motion.li>
            )}
            <motion.li variants={listItemVariants} id="correspondence-li">
                <Link to={`/project/${projectId}/correspondence`} onClick={handleSelection}>
                    <h2>Correspondence</h2>
                </Link>
            </motion.li>
        </>
    );
}

import DateFnsUtils from '@date-io/date-fns';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {MuiPickersUtilsProvider, DatePicker} from '@material-ui/pickers';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Zoom} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useForm, FormProvider} from 'react-hook-form';
import {useSearchParams} from 'react-router-dom';
import SunEditor from 'suneditor-react';
import {trackEvent} from '~/api';
import BusyButton from '~/components/generics/BusyButton';
import NoDataPlaceholder from '~/components/generics/NoDataPlaceholder';
import RHFController from '~/components/generics/RHFController';
import {CleanSunEditorHTML, DisableMobileZoom, EnableMobileZoom} from '~/helpers';
import {useStoreActions, useStoreState} from '~/store/storeHooks';
import styles from '~/styles/CorrespondenceDialog.module.scss';
import {ActionItem} from '~/types';

let scrollPos = 0;

export default function ActionItemDialog(): JSX.Element {
    const [searchParams, setSearchParams] = useSearchParams();
    const currentUser = useStoreState((state) => state.shared.currentUser);
    const actionItems = useStoreState((state) => state.correspondence.actionItems);
    const updateActionItem = useStoreActions((actions) => actions.correspondence.updateActionItem);
    const [busy, setBusy] = useState(false);

    const actionId = searchParams.get('action');

    const actionItem = useMemo(() => actionItems.find((o) => actionId && o.id === +actionId), [actionId, actionItems]);

    const methods = useForm<ActionItem>({
        defaultValues: actionItem ?? {},
    });
    const {reset, handleSubmit} = methods;
    const {enqueueSnackbar} = useSnackbar();

    // Stop scrolling when the action item dialog is open
    useEffect(() => {
        if (actionId) {
            scrollPos = window.pageYOffset;
            document.body.style.position = 'fixed';
            document.body.style.top = `-${scrollPos}px`;
            setTimeout(() => {
                document.body.style.overflow = 'visible';
            }, 0);
        } else {
            document.body.style.position = '';
            document.body.style.top = '';
            window.scrollTo(0, scrollPos);
        }
    }, [actionId]);

    // Reset RHF state when tdr changes
    useEffect(() => {
        reset(actionItem);
    }, [actionItem, reset]);

    // Disable zooming for this dialog, it's annoying whenever selecting any input
    useEffect(() => {
        DisableMobileZoom();
        return (): void => {
            EnableMobileZoom();
        };
    }, []);

    const onSubmit = handleSubmit(async (data) => {
        setBusy(true);
        trackEvent('update action item', {id: data.id, number: data.number});

        // let uploadedFiles: UploadedFile[] = [];
        // if (files.length > 0 && project?.projectNumber) {
        //     uploadedFiles = await uploadFiles({destination: `/Development/Project/${project.projectNumber}/RFI/`, files});
        // }
        const {result, message} = await updateActionItem({
            ...data,
            completedBy: data.completed && !actionItem.completedBy ? currentUser : actionItem.completedBy,
        });

        setBusy(false);
        enqueueSnackbar(message, {variant: result});

        if (result === 'success') {
            handleClose();
        }
    });

    const handleClose = useCallback(() => {
        setSearchParams({});
    }, [setSearchParams]);

    return (
        <Dialog open={actionId != null} onClose={handleClose} id="action-item-dialog-root" className={styles.root} TransitionComponent={Zoom}>
            <DialogTitle>
                <div className={styles.title}>
                    <h3>Action Item</h3>
                    {!actionItem?.assignedToClient && <FontAwesomeIcon icon={['fal', 'lock-alt']} color="tomato" />}
                </div>
            </DialogTitle>
            <DialogContent>
                <FormProvider {...methods}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <form>
                            {actionItem == null ? (
                                <NoDataPlaceholder title="No Action Data Available" />
                            ) : (
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <RHFController
                                            render={({field}): JSX.Element => <TextField {...field} label="Requestor" disabled variant="standard" />}
                                            name="requestor"
                                            defaultValue={actionItem.requestor ?? ''}
                                            rules={{
                                                required: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <RHFController
                                            render={({field}): JSX.Element => <TextField {...field} label="Status" disabled variant="standard" />}
                                            name="status"
                                            defaultValue={actionItem.status ?? ''}
                                            rules={{
                                                required: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <RHFController
                                            render={({field}): JSX.Element => <TextField {...field} label="Number" disabled variant="standard" />}
                                            name="number"
                                            defaultValue={actionItem.number ?? ''}
                                            rules={{
                                                required: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <DatePicker
                                                    label="Issued"
                                                    variant="dialog"
                                                    format="MM/dd/yyyy"
                                                    autoOk
                                                    showTodayButton
                                                    clearable
                                                    onChange={field.onChange}
                                                    value={field.value}
                                                    disabled
                                                />
                                            )}
                                            name="created"
                                            defaultValue={actionItem.created ?? null}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <div className={styles.sunEditorContainer}>
                                                    <p>Action Required</p>
                                                    <SunEditor
                                                        height="100%"
                                                        disable
                                                        setContents={CleanSunEditorHTML(field.value)}
                                                        autoFocus={false}
                                                        hideToolbar
                                                        setOptions={{
                                                            showPathLabel: false,
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            name="description"
                                            defaultValue={actionItem.description ?? ''}
                                            rules={{required: true}}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RHFController
                                            render={({field}): JSX.Element => {
                                                const fieldObj = {...field, value: field?.value?.fullName ? field.value.fullName : field.value};
                                                return (
                                                    <TextField
                                                        {...fieldObj}
                                                        label="Assigned"
                                                        disabled={!actionItem?.assignedToClient}
                                                        variant="standard"
                                                    />
                                                );
                                            }}
                                            name={actionItem.clientAssignee ? 'clientAssignee' : 'hmAssignee'}
                                            defaultValue={actionItem.clientAssignee ? actionItem.clientAssignee : actionItem.hmAssignee}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <DatePicker
                                                    label="Due Date"
                                                    variant="dialog"
                                                    format="MM/dd/yyyy"
                                                    autoOk
                                                    showTodayButton
                                                    clearable
                                                    onChange={field.onChange}
                                                    value={field.value}
                                                    disabled
                                                />
                                            )}
                                            name="dueDate"
                                            defaultValue={actionItem.dueDate ?? null}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <DatePicker
                                                    label="Completed Date"
                                                    variant="dialog"
                                                    format="MM/dd/yyyy"
                                                    autoOk
                                                    showTodayButton
                                                    clearable
                                                    onChange={field.onChange}
                                                    value={field.value}
                                                    disabled={!actionItem?.assignedToClient}
                                                />
                                            )}
                                            name="completed"
                                            defaultValue={actionItem.completed ?? null}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <div className={styles.sunEditorContainer}>
                                                    <p>Comments</p>
                                                    <SunEditor
                                                        height="100%"
                                                        disable={!actionItem?.assignedToClient}
                                                        setContents={CleanSunEditorHTML(field.value)}
                                                        autoFocus={false}
                                                        hideToolbar
                                                        onChange={field.onChange}
                                                        setOptions={{
                                                            showPathLabel: false,
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            name="comments"
                                            defaultValue={actionItem.comments ?? ''}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </form>
                    </MuiPickersUtilsProvider>
                </FormProvider>
            </DialogContent>
            <DialogActions>
                <Button className="close-button" onClick={handleClose}>
                    Close
                </Button>
                {actionItem?.status !== 'Closed' && actionItem?.assignedToClient ? (
                    <BusyButton className="save-button" onClick={onSubmit} busy={busy}>
                        Save
                    </BusyButton>
                ) : (
                    <></>
                )}
            </DialogActions>
        </Dialog>
    );
}

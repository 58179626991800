import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import {Accordion, AccordionSummary, AccordionDetails, Grow, ListItem, ListItemText, Divider, List, Typography, Tooltip, Box, Badge} from '@mui/material';
import { GridInitialState } from '@mui/x-data-grid-pro';
import {useSnackbar} from 'notistack';
import {useEffect, useState, useMemo, useCallback} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import completedImg from '~/assets/img/completed-img.svg';
import ConfettiEffect from '~/components/generics/ConfettiEffect';
import NoDataPlaceholder from '~/components/generics/NoDataPlaceholder';
import LoadingComponent from '~/components/visualizations/LoadingComponent';
import { usePermissions } from '~/hooks/usePermissions';
import {useStoreActions, useStoreState} from '~/store/storeHooks';
import styles from '~/styles/Docket.module.scss';
import {ToDoItem} from '~/types';

type SummaryType = {
    unreadComments: number;
    openRFI: number;
    openTQ: number;
    openTDR: number;
    openHolds: number;
    openNeeds: number;
    openActionItems: number;
    deliverablesAwaitingApproval: number;
    changeOrdersAwaitingApproval: number;
    vendorDataAwaitingApproval: number;
    allComplete: boolean;
};

interface ToDoTypeColumnProps {
    type: string;
    subtotal: number;
    todos: ToDoItem[];
}

function CreateHref(type: string, projectId: number): string {
    switch (type) {
        case 'Open RFI':
            return `/project/${projectId}/correspondence?scrollTo=rfis-scroll-to`;
        case 'Open TQ':
            return `/project/${projectId}/correspondence?scrollTo=tqs-scroll-to`;
        case 'Open TDR':
            return `/project/${projectId}/correspondence?scrollTo=tdrs-scroll-to`;
        case 'Open Holds':
            return `/project/${projectId}/correspondence?scrollTo=holds-scroll-to`;
        case 'Open Needs':
            return `/project/${projectId}/correspondence?scrollTo=needs-scroll-to`;
        case 'Open Action Items':
            return `/project/${projectId}/correspondence?scrollTo=action-items-scroll-to`;
        case 'Deliverables Awaiting Approval':
        case 'Vendor Data Awaiting Approval':
            return `/project/${projectId}/deliverables`;
        case 'Change Orders Awaiting Approval':
            return `/project/${projectId}/change`;
        default:
            return `/project/${projectId}/summary`;
    }
}

const ToDoTypeColumn = ({type, subtotal, todos}: ToDoTypeColumnProps): JSX.Element => {
    const nav = useNavigate();
    const defaultOpenInNewTab = useStoreState((state) => state.shared.userOptions?.projectTableOptions?.defaultOpenInNewTab);
    const handleLinkClick: (href: string, e: React.UIEvent)=>void = useCallback((href: string, e: React.UIEvent) => {
        if (defaultOpenInNewTab) {
            e.stopPropagation();
            e.preventDefault();
            const win = window.open(href, '_blank');
            win.focus();
        } else {
            e.stopPropagation();
            e.preventDefault();
            nav(href);
        }
    }, [defaultOpenInNewTab, nav]);

    return (
        <div className={styles.toDoColumnContainer}>
            <div className={styles.columnSubtotal}>{subtotal}</div>
            <Accordion>
                <AccordionSummary>{type}</AccordionSummary>
                <AccordionDetails>
                    <table className={styles.detailTable}>
                        <thead>
                            <tr>
                                <th style={{textAlign: 'left'}}>Project</th>
                                <th>Count</th>
                            </tr>
                        </thead>
                        <tbody>
                            {todos.map((o) => (
                                <tr key={o.projectId}>
                                    <td>
                                        <Link to={CreateHref(type, o.projectId)} onClick={(e: React.UIEvent)=>handleLinkClick(CreateHref(type, o.projectId),e)}>
                                            {o.projectCode}
                                        </Link>
                                    </td>
                                    <td style={{textAlign: 'center'}}>{o.count}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

const getFilterTitle: (
    gridState: GridInitialState, 
    filterOn: boolean, 
    filterHeaderLookup: {[field: string]: string}
) => JSX.Element = (gridState, filterOn, filterHeaderLookup) => {
    if (gridState?.filter?.filterModel?.items) {
        const {items, linkOperator} = gridState.filter.filterModel;
        const title: JSX.Element[] = [];
        items.forEach((item, index)=>{
            const {columnField, operatorValue, value} = item;
            title.push(
                <ListItem key={`li${index+1}`}>
                    <ListItemText>
                        {`${filterHeaderLookup && filterHeaderLookup[columnField] ? filterHeaderLookup[columnField] : columnField} ${operatorValue} ${value}`}
                    </ListItemText>
                </ListItem>
            );
            if (linkOperator && index < (items.length-1)) title.push(<Divider key={`ld${index+1}`}>{linkOperator}</Divider>)
        })
        return (
            <List>
                <ListItem disableGutters key='li0'>
                    <ListItemText>
                        <Typography variant='subtitle1'>{`${items.length} ${filterOn ? 'active' : 'inactive'} filter${items.length > 1 ? 's' : ''}`}</Typography>
                        <Typography variant='subtitle1'>{`Click icon to ${filterOn ? 'de' : ''}activate filter`}</Typography>
                    </ListItemText>
                </ListItem>
                <Divider key='d1'/>
                {title}
            </List>
        )
    } 
    return <></>;
}

export default function Docket(): JSX.Element {
    const {projectLogTableInitialState, filterDocketOn, includeArchived} = useStoreState((state) => state.shared.userOptions.projectTableOptions);
    const {setUserOptions} = useStoreActions((actions) => actions.shared);
    const {filterHeaderLookup} = useStoreState((state) => state.project);
    const getToDoItems = useStoreActions((actions) => actions.dashboard.getToDoItems);
    const toDoItems = useStoreState((state) => state.dashboard.toDoItems);
    const {projectSubset, projects} = useStoreState((state) => state.project);
    const { applyFilterToDocket } = useStoreState((state)=> state.shared.userOptions.projectTableOptions);
    const {enqueueSnackbar} = useSnackbar();
    const [busy, setBusy] = useState<boolean>(false);
    const view = usePermissions();

    const projectList = applyFilterToDocket && filterDocketOn ? projectSubset : projects;

    const dashboardFiltering: boolean = applyFilterToDocket && projectSubset.length && projectSubset.length < projects.length ;

    const filterCount = projectLogTableInitialState?.filter?.filterModel?.items?.length;

    useEffect(() => {
        (async function fetchData(): Promise<void> {
            if (projectList.length > 0) {
                setBusy(true);
                const {result, message} = await getToDoItems({includeArchived});
                if (result === 'error') {
                    enqueueSnackbar(message, {variant: result});
                }
                setBusy(false);
            }
        })();
        return (): void => setBusy(false);
    }, [enqueueSnackbar, getToDoItems, projectList, includeArchived]);

    const sortedSubsetTodos = useMemo(
        () => toDoItems.filter((o) => projectList.some((p) => p.id === o.projectId)).sort((a, b) => a.projectCode.localeCompare(b.projectCode)),
        [projectList, toDoItems],
    );

    const summary: SummaryType = useMemo(() => {
        const unreadComments = sortedSubsetTodos.filter((o) => o.type === 'Unread Comments').reduce((total, r) => total + r.count, 0);
        const openRFI = sortedSubsetTodos.filter((o) => o.type === 'Open RFI').reduce((total, r) => total + r.count, 0);
        const openTQ = sortedSubsetTodos.filter((o) => o.type === 'Open TQ').reduce((total, r) => total + r.count, 0);
        const openTDR = sortedSubsetTodos.filter((o) => o.type === 'Open TDR').reduce((total, r) => total + r.count, 0);
        const openHolds = sortedSubsetTodos.filter((o) => o.type === 'Open Holds').reduce((total, r) => total + r.count, 0);
        const openNeeds = sortedSubsetTodos.filter((o) => o.type === 'Open Needs').reduce((total, r) => total + r.count, 0);
        const openActionItems = sortedSubsetTodos.filter((o) => o.type === 'Open Action Items').reduce((total, r) => total + r.count, 0);
        const deliverablesAwaitingApproval = sortedSubsetTodos
            .filter((o) => o.type === 'Deliverables Awaiting Approval')
            .reduce((total, r) => total + r.count, 0);
        const changeOrdersAwaitingApproval = sortedSubsetTodos
            .filter((o) => o.type === 'Change Orders Awaiting Approval')
            .reduce((total, r) => total + r.count, 0);
        const vendorDataAwaitingApproval = sortedSubsetTodos
            .filter((o) => o.type === 'Vendor Data Awaiting Approval')
            .reduce((total, r) => total + r.count, 0);

        return {
            unreadComments,
            openRFI,
            openTQ,
            openTDR,
            openHolds,
            openNeeds,
            openActionItems,
            deliverablesAwaitingApproval,
            changeOrdersAwaitingApproval,
            vendorDataAwaitingApproval,
            allComplete:
                unreadComments === 0 &&
                openRFI === 0 &&
                openTQ === 0 &&
                openTDR === 0 &&
                openHolds === 0 &&
                openNeeds === 0 &&
                openActionItems === 0 &&
                deliverablesAwaitingApproval === 0 &&
                changeOrdersAwaitingApproval === 0 &&
                vendorDataAwaitingApproval === 0,
        };
    }, [sortedSubsetTodos]);

    let columnList: JSX.Element | null = null;
    if (busy) {
        columnList = <LoadingComponent />;
    } else if (!projectList.length) {
        columnList = <NoDataPlaceholder/>
    } else if (summary.allComplete) {
        columnList = (
            <>
                <ConfettiEffect height={255} width={475} />
                <div className={styles.imageContainer}>
                    <Grow in timeout={2000}>
                        <img src={completedImg} alt="completed-all-todos-img" />
                    </Grow>
                </div>
            </>
        );
    } else {
        columnList = (
            <>
                {summary.unreadComments > 0 && (
                    <ToDoTypeColumn
                        type="Unread Comments"
                        subtotal={summary.unreadComments}
                        todos={sortedSubsetTodos.filter((o) => o.type === 'Unread Comments')}
                    />
                )}
                {summary.openRFI > 0 && (
                    <ToDoTypeColumn type="Open RFI" subtotal={summary.openRFI} todos={sortedSubsetTodos.filter((o) => o.type === 'Open RFI')} />
                )}
                {summary.openTQ > 0 && (
                    <ToDoTypeColumn type="Open TQ" subtotal={summary.openTQ} todos={sortedSubsetTodos.filter((o) => o.type === 'Open TQ')} />
                )}
                {summary.openTDR > 0 && (
                    <ToDoTypeColumn type="Open TDR" subtotal={summary.openTDR} todos={sortedSubsetTodos.filter((o) => o.type === 'Open TDR')} />
                )}
                {summary.openHolds > 0 && (
                    <ToDoTypeColumn type="Open Holds" subtotal={summary.openHolds} todos={sortedSubsetTodos.filter((o) => o.type === 'Open Holds')} />
                )}
                {summary.openNeeds > 0 && (
                    <ToDoTypeColumn type="Open Needs" subtotal={summary.openNeeds} todos={sortedSubsetTodos.filter((o) => o.type === 'Open Needs')} />
                )}
                {summary.openActionItems > 0 && (
                    <ToDoTypeColumn
                        type="Open Action Items"
                        subtotal={summary.openActionItems}
                        todos={sortedSubsetTodos.filter((o) => o.type === 'Open Action Items')}
                    />
                )}
                {summary.deliverablesAwaitingApproval > 0 && (
                    <ToDoTypeColumn
                        type="Deliverables Awaiting Approval"
                        subtotal={summary.deliverablesAwaitingApproval}
                        todos={sortedSubsetTodos.filter((o) => o.type === 'Deliverables Awaiting Approval')}
                    />
                )}
                {summary.changeOrdersAwaitingApproval > 0 && view && (
                    <ToDoTypeColumn
                        type="Change Orders Awaiting Approval"
                        subtotal={summary.changeOrdersAwaitingApproval}
                        todos={sortedSubsetTodos.filter((o) => o.type === 'Change Orders Awaiting Approval')}
                    />
                )}
                {summary.vendorDataAwaitingApproval > 0 && (
                    <ToDoTypeColumn
                        type="Vendor Data Awaiting Approval"
                        subtotal={summary.vendorDataAwaitingApproval}
                        todos={sortedSubsetTodos.filter((o) => o.type === 'Vendor Data Awaiting Approval')}
                    />
                )}
            </>
        );
    }

    const handleFilterListOn: () => void = useCallback(()=> { 
        setUserOptions({ 
            option: 'projectTableOptions',
            key: 'filterDocketOn', 
            value: !filterDocketOn
        });
    },[setUserOptions,filterDocketOn])

    const filterTitle: JSX.Element = useMemo(
        () => getFilterTitle(projectLogTableInitialState,filterDocketOn,filterHeaderLookup),
        [projectLogTableInitialState,filterDocketOn,filterHeaderLookup]
    );

    return (
        <Grow in>
            <div className={styles.root}>
                <div className={styles.titleContainer}>
                    <h2>
                        <div className={styles.title}>
                            Docket
                            {
                                dashboardFiltering
                                ?
                                <div className={styles.filterIndicator}>
                                    <Box>
                                        <Box onClick={handleFilterListOn}>
                                            <Tooltip title={filterTitle} key='tt1'>
                                                <Badge badgeContent={filterCount} color={filterDocketOn ? 'primary' : 'default'}>
                                                    {
                                                        filterDocketOn
                                                        ?
                                                        <FilterListIcon color='primary' fontSize='large'/>
                                                        :
                                                        <FilterListOffIcon color='disabled'  fontSize='large'/>
                                                    }
                                                </Badge>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                </div>
                                :
                                <></>
                            }
                        </div>
                    </h2>
                </div>
                <div className={styles.columnContainer}>{columnList}</div>
            </div>
        </Grow>
    );
}

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useEffect, useMemo} from 'react';
import * as React from 'react';
import useProjectHealth from '~/hooks/useProjectHealth';
import {useStoreActions, useStoreState} from '~/store/storeHooks';

export default function HSEGrade(props: React.HTMLAttributes<SVGElement>): JSX.Element {
    const {healthCriteria, projectHealth, foundationJob} = useStoreState((state) => state.subsite.project) ?? {};
    const standardHealthCriteria = useStoreState((state) => state.project.standardHealthCriteria);
    const {calculateHSEGrade} = useProjectHealth();

    const getStandardHealthCriteria = useStoreActions((actions) => actions.project.getStandardHealthCriteria);

    useEffect(() => {
        if (standardHealthCriteria.length === 0) {
            getStandardHealthCriteria();
        }
    }, [getStandardHealthCriteria, standardHealthCriteria.length]);

    const criteria = useMemo(
        () => (healthCriteria && healthCriteria.length > 0 ? healthCriteria : standardHealthCriteria.filter((c) => c.category === 'hse')),
        [healthCriteria, standardHealthCriteria],
    );

    const hseGrade = calculateHSEGrade(projectHealth, foundationJob, criteria);

    return <FontAwesomeIcon icon={['fas', 'circle']} color={hseGrade.indicator.color} {...props} />;
}

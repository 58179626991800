import ExploreOffOutlinedIcon from '@mui/icons-material/ExploreOffOutlined';
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined';
import Tooltip from '@mui/material/Tooltip';
import {useCallback, useContext, useMemo} from 'react';
import * as React from 'react';
import KPIGrid from '~/components/kpi/KPIGrid';
import CorrespondenceStackContext from '~/components/subsite/summary/correspondenceStack/CorrespondenceStackContext';
import {useStoreState} from '~/store/storeHooks';
import styles from '~/styles/KPI.module.scss';

interface CorrespondenceCountKPIProps {
    type: 'rfis' | 'tqs' | 'tdrs' | 'holds' | 'actionItems' | 'needs';
    tooltipTitle?: string;
    scrollTo?: string;
}

SummaryCorrespondenceKPI.defaultProps = {
    tooltipTitle: '',
    scrollTo: ''
};

export default function SummaryCorrespondenceKPI({type, tooltipTitle, scrollTo}: CorrespondenceCountKPIProps): JSX.Element {
    const rfis = useStoreState((state) => state.correspondence.rfis);
    const tqs = useStoreState((state) => state.correspondence.tqs);
    const tdrs = useStoreState((state) => state.correspondence.tdrs);
    const holds = useStoreState((state) => state.correspondence.holds);
    const needs = useStoreState((state) => state.correspondence.needs);
    const actionItems = useStoreState((state) => state.correspondence.actionItems);
    const {setShowTQ, setShowRFI, setShowTDR, setShowActionItem, setShowHold, setShowNeed, showActionItem, showRFI, showTDR, showTQ, showHold, showNeed} =
        useContext(CorrespondenceStackContext);

    const data = useMemo((): {title: string; count: number} => {
        switch (type) {
            case 'rfis':
                return {title: 'RFI', count: rfis.filter((o) => !o.descendent && o.status !== 'Closed').length};
            case 'tqs':
                return {title: 'TQ', count: tqs.filter((o) => !o.descendent && o.status !== 'Closed').length};
            case 'tdrs':
                return {title: 'Deviations', count: tdrs.filter((o) => !o.descendent && o.status !== 'Closed').length};
            case 'holds':
                return {
                    title: 'Holds',
                    count: holds.filter((o) => o.status !== 'Closed').length,
                };
            case 'needs':
                return {
                    title: 'Needs',
                    count: needs.filter((o: any) => o.status !== 'Closed').length,
                };
            case 'actionItems':
                return {
                    title: 'Action Items',
                    count: actionItems.filter((o) => o.status !== 'Closed').length,
                };
            default:
                return {
                    title: 'KPI',
                    count: 0,
                };
        }
    }, [actionItems, holds, needs, rfis, tdrs, tqs, type]);

    const exploreOn: boolean = useMemo(()=>{
        switch (type) {
            case 'rfis':
                return showRFI;
            case 'tqs':
                return showTQ;
            case 'actionItems':
                return showActionItem;
            case 'holds':
                return showHold;
            case 'needs':
                return showNeed;
            case 'tdrs':
                return showTDR;
            default:
                return false;
        }

    },[type, showRFI, showTQ, showActionItem, showHold, showNeed, showTDR])

    const showObject: {storageProp: string, contextValue: boolean, contextMethod: any} = useMemo(() => {
        const obj: {storageProp: string;
            contextValue: boolean;
            contextMethod: any;
        } = {storageProp:'',contextValue:false,contextMethod:()=>{}};
        switch (type) {
            case 'rfis':
                obj.storageProp = 'cppShowRFI';
                obj.contextValue = showRFI;
                obj.contextMethod = setShowRFI;
                break;
            case 'tqs':
                obj.storageProp = 'cppShowTQ';
                obj.contextValue = showTQ;
                obj.contextMethod = setShowTQ;
                break;
            case 'needs':
                obj.storageProp = 'cppShowNeed';
                obj.contextValue = showNeed;
                obj.contextMethod = setShowNeed;
                break;
            case 'actionItems':
                obj.storageProp = 'cppShowActionItem';
                obj.contextValue = showActionItem;
                obj.contextMethod = setShowActionItem;
                break;
            case 'tdrs':
                obj.storageProp = 'cppShowTDR';
                obj.contextValue = showTDR;
                obj.contextMethod = setShowTDR;
                break;
            default:
                obj.storageProp = 'cppShowHold';
                obj.contextValue = showHold;
                obj.contextMethod = setShowHold;
        }
        return obj;
    },[type, showRFI, setShowRFI, showTQ, setShowTQ, showNeed, setShowNeed, showActionItem, setShowActionItem, showTDR, setShowTDR, showHold, setShowHold])

    const handleGridClick: (e: React.UIEvent)=> void = useCallback((e)=> {
        e.preventDefault();
        e.stopPropagation();
        if (!showObject.contextValue) {
            showObject.contextMethod(true);
            localStorage[showObject.storageProp] = 'true';
        } else {
            showObject.contextMethod(false);
            localStorage[showObject.storageProp] = 'false';
        }
    },[showObject]);

    const handleIconClick: (e: React.UIEvent)=> void = useCallback((e)=> {
        e.preventDefault();
        e.stopPropagation();
        showObject.contextMethod(!showObject.contextValue);
        localStorage[showObject.storageProp] = !showObject.contextValue ? 'true' : 'false';
    },[showObject])

    return (
        <Tooltip title={tooltipTitle} arrow>
            <div>
                <KPIGrid onClick={handleGridClick} clickable>
                    <div className={`${styles.container}`}>
                        <h5 className={styles.title}>{data.title}</h5>
                        <div className={styles.exploreIcon}>
                        {
                            exploreOn
                            ?
                            <ExploreOutlinedIcon fontSize='inherit' color='inherit' onClick={handleIconClick}/>
                            :
                            <ExploreOffOutlinedIcon fontSize='inherit' color='disabled' onClick={handleIconClick}/>
                        }
                        </div>
                        <h1 className={styles.count}>{data.count}</h1>
                    </div>
                </KPIGrid>
            </div>
        </Tooltip>
    );
}

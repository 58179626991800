import {createContext} from 'react';
import {HealthCriteria} from '~/types';

type HealthReportCardContextType = {
    healthCriteria: HealthCriteria[];
};

const HealthReportCardContext = createContext<HealthReportCardContextType>({
    healthCriteria: [],
});

export default HealthReportCardContext;

import {useContext} from 'react';
import ProjectContext from '~/components/dashboard/project-log/ProjectContext';
import SwipeStack from '~/components/generics/SwipeStack';
import SwipeStackCard from '~/components/generics/SwipeStackCard';
import MilestoneInfoCard from '~/components/subsite/summary/milestone/MilestoneInfoCard';
import styles from '~/styles/MilestoneStack.module.scss';

export default function MilestoneStack(): JSX.Element {
    const {project} = useContext(ProjectContext);
    const {milestones} = project;

    return (
        <div className={styles.root}>
            {milestones?.length > 0 && (
                <SwipeStack id="milestone-stack-root">
                    {milestones.map((milestone, index) => (
                        <SwipeStackCard cardIndex={index} key={milestone.id}>
                            <MilestoneInfoCard milestone={milestone} />
                        </SwipeStackCard>
                    ))}
                </SwipeStack>
            )}
        </div>
    );
}

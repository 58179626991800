import posthog from 'posthog-js';
import {useEffect} from 'react';
import {Person, Project} from '~/types';

export function setupAnalytics() {
    if (!window.location.host.includes('127.0.0.1') && !window.location.host.includes('localhost')) {
        posthog.init(process.env.REACT_APP_POSTHOG_KEY, {
            api_host: 'https://app.posthog.com',
            autocapture: false,
        });
    }
}

export function setAnaliticsIdentity(user: Person) {
    if (!user?.id) {
        posthog?.reset();
        return;
    }

    const {id, email, fullName, company} = user;
    posthog?.identify(`${id}`, {email, name: fullName, company});

    if (company) {
        posthog?.group('company', company);
    }
}

export function setUserProperties(properties: Record<string, any>) {
    if (properties) {
        posthog?.setPersonProperties(properties);
    }
}

export function trackPage(page: string, properties?: Record<string, any> | undefined) {
    posthog?.capture('$pageview', {...properties, page});
}

export function trackEvent(event: string, properties?: Record<string, any> | undefined) {
    posthog?.capture(event, properties);
}

export function trackProject(project: Project | null | undefined) {
    if (project) {
        posthog?.register_for_session({
            client: project.client?.name,
            project: project.code,
        });
    } else {
        posthog?.unregister('project');
    }
}

export function useTrackPage(page: string) {
    useEffect(() => {
        trackPage(page);
    }, [page]);
}

export function useTrackProject(project: Project | null | undefined) {
    useEffect(() => {
        trackProject(project);
    }, [project]);
}

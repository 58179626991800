import {thunk, Thunk, Action, action} from 'easy-peasy';
import {axios} from '~/api';
import {AxiosTryCatchBlock} from '~/helpers';
import {ToDoItem} from '~/types';

const defaultTimeout = 30000;
export interface DashboardModel {
    getInitialData: Thunk<DashboardModel, {includeArchived?: boolean}>;
    toDoItems: ToDoItem[];
    setToDoItems: Action<DashboardModel, ToDoItem[]>;
    getToDoItems: Thunk<DashboardModel, {includeArchived?: boolean}>;
}

const dashboardModel: DashboardModel = {
    toDoItems: [],
    getInitialData: thunk((actions, {includeArchived}) =>
        AxiosTryCatchBlock(async () => {
            const {data} = await axios.get(`/DashboardInitialData?includeArchived=${includeArchived ? 'true' : 'false'}`, {timeout: 60000});
            return data;
        }),
    ),
    setToDoItems: action((draftState, toDoItems) => {
        draftState.toDoItems = toDoItems;
    }),
    getToDoItems: thunk(async (actions, {includeArchived}) => {
        try {
            const {data} = await axios.get(`/GetToDoItems?includeArchived=${includeArchived ? 'true' : 'false'}`, {
                timeout: defaultTimeout,
            });
            actions.setToDoItems(data);
            return {
                result: 'success',
                message: 'Retrieved to do data',
            };
        } catch (e) {
            return {
                result: 'error',
                message: (e as unknown as Error).message,
            };
        }
    }),
};

export default dashboardModel;

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconButton} from '@mui/material';
import {useContext} from 'react';
import ChatHistoryContext from '~/components/chat/ChatHistoryContext';
import styles from '~/styles/ReplyToContainer.module.scss';

export default function ReplyToContainer(): JSX.Element {
    const {selectedComment, setSelectedComment} = useContext(ChatHistoryContext);
    const {comment, createdBy, created} = selectedComment;

    return (
        <div id="reply-to-container-root" className={styles.root}>
            <div className={styles.blockQuote}>
                <p className={styles.comment}>&quot;{comment}&quot;</p>
                <p className={styles.caption}>{createdBy.fullName}</p>
                <p className={styles.caption}>{new Date(created).toLocaleString()}</p>
            </div>
            <IconButton className={styles.clearButton} onClick={(): void => setSelectedComment(null)}>
                <FontAwesomeIcon icon={['fal', 'times']} />
            </IconButton>
        </div>
    );
}

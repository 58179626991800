import { GridRowParams } from '@mui/x-data-grid-pro';
import {ReactElement, useCallback, useMemo, useState} from 'react';
import DataGridProTable from '~/components/generics/DataGridProTable';
import EditTeamMemberDialog from '~/components/subsite/summary/team/EditTeamMemberDialog';
import TeamTableColumns from '~/components/subsite/summary/team/TeamTableColumnsPro';
import {useStoreState} from '~/store/storeHooks';

interface TeamTableProps {
    isHM: boolean;
    addButton: ReactElement;
}

const tableCacheVersion = 3;

export default function TeamTable({isHM,addButton}: TeamTableProps): JSX.Element {
    const projectTeam = useStoreState((state) => state.subsite.projectTeam);
    const [selectedMember, setSelectedMember] = useState(null);

    const data = useMemo(() => {
        if (isHM) {
            return projectTeam.filter((o) => o.client?.initials !== 'HM' && o.person?.email?.includes('hm-ec'));
        }
        return projectTeam.filter((o) => o.client !== null);
    }, [isHM, projectTeam]);

    const handleRowClick = useCallback(
        (params: GridRowParams, ): void => {
            setSelectedMember(params.row);
        },
        [],
    );

    const handleClose = useCallback((): void => {
        setSelectedMember(null);
    }, []);
    
    const optionKey = `${isHM ? 'hm' : 'client'}TeamTable`;

    return (
        <div id={optionKey}>
            <DataGridProTable
                columns={TeamTableColumns(isHM)}
                data={data}
                optionKey={optionKey}
                csvFileName={`${isHM ? 'H+M' : 'Client'}TeamMembers.csv`}
                cacheVersion={tableCacheVersion}
                handleRowClick={handleRowClick}
                addButton={addButton}
                toolbarHide={{searchBar: true}}
                noAutoHeight
            />
            {selectedMember && <EditTeamMemberDialog open handleClose={handleClose} selectedMember={selectedMember} isHM={isHM}/>}
        </div>
    );
}

import {Button, Checkbox, FormControlLabel, TextField, Typography} from '@mui/material';
import parse from 'html-react-parser';
import {useCallback, useEffect, useMemo, useRef} from 'react';
import {useParams} from 'react-router';
import {useNavigate} from 'react-router-dom';
import SignaturePad from 'react-signature-canvas';
import hmLogo from '../../../../assets/img/hm-logo-dark.svg';
import cppLogo from '~/assets/img/cpp-logo-light-mode.svg';
import {useGetStateFromPath} from '~/hooks/useGetStateFromPath';
import {useStoreState} from '~/store/storeHooks';
import styles from '~/styles/Print.module.scss';

export default function TQPrint(): JSX.Element {
    const navigate = useNavigate();
    const {projectId, tqId} = useParams<{projectId: string; tqId: string}>();
    const from = useGetStateFromPath(`/project/${projectId}`);
    const tqs = useStoreState((state) => state.correspondence.tqs);

    const project = useStoreState((state) => state.subsite.project);
    const canvasRef = useRef<SignaturePad>(null);

    useEffect(()=>{
        document.body.style.position = '';
        document.body.style.top = '';
    },[]);


    const {
        recipient,
        number,
        title,
        description,
        status,
        poNumber,
        rev,
        initiatedBy,
        initiated,
        responseRequired,
        redlineRequired,
        sketchAttached,
        picturesAttached,
        impactType,
        response,
        revisedDeliverableAttached,
        answered,
        answeredBy,
        answeredBySignature,
        pmApproved,
        pmApprovedDate,
        disciplines,
        affectedDocuments,
    } = useMemo(() => tqs.find((o) => tqId && o.id === +tqId), [tqId, tqs]);

    useEffect(() => {
        canvasRef.current.fromDataURL(answeredBySignature);
        canvasRef.current.off();
    }, [answeredBySignature]);

    const handlePrevious = useCallback(() => {
        navigate(from);
    }, [navigate, from]);

    const handlePrint = (): void => {
        window.print();
    };

    return (
        <div className={styles.root}>
            <div className={styles.controls}>
                <div>
                    <Button variant="outlined" onClick={handlePrevious}>
                        Previous
                    </Button>
                    <Button variant="outlined" onClick={handlePrint}>
                        Print
                    </Button>
                </div>
            </div>
            <div className={styles.paper}>
                <div className={styles.header}>
                    <img src={cppLogo} alt="CPP Logo Light Mode" className={styles.logo} />
                    <Typography>Technical Query</Typography>
                    <img src={hmLogo} alt="H+M Logo in blue" className={styles.logo} />
                </div>
                <div className={styles.content}>
                    <div className={styles.row}>
                        <TextField value={number} label="TQ Number" style={{width: 300}} variant="standard" />
                        <TextField value={rev} label="Rev" style={{width: 75}} variant="standard" />
                        <TextField value={status} label="Status" style={{width: 75}} variant="standard" />
                        <TextField value={recipient} label="Recipient" style={{flexGrow: 1}} variant="standard" />
                    </div>
                    <div className={styles.row}>
                        <TextField value={title} label="Subject" style={{flexGrow: 1}} variant="standard" />
                    </div>
                    <div className={styles.row}>
                        <TextField
                            value={disciplines.map((discipline: string) => discipline).join(', ')}
                            label="Disciplines"
                            style={{flexGrow: 1}}
                            variant="standard"
                        />
                        <TextField value={poNumber} label="PO Number" style={{flexGrow: 1}} variant="standard" />
                    </div>
                    <div className={styles.row}>
                        <TextField value={project?.code} label="Project Code" style={{flexGrow: 1}} variant="standard" />
                    </div>
                    <div className={styles.sectionDivider}>
                        <Typography>Part I - By H+M</Typography>
                    </div>
                    <div className={styles.row}>
                        <TextField value={initiatedBy} label="Initiated By" style={{width: 300}} variant="standard" />
                        <TextField value={new Date(initiated).toLocaleDateString()} label="Date Initiated" style={{width: 180}} variant="standard" />
                        <TextField
                            value={responseRequired ? new Date(responseRequired).toLocaleDateString() : ''}
                            label="Response Required By"
                            style={{width: 200}}
                            variant="standard"
                        />
                    </div>
                    <div className={styles.row}>
                        <TextField value={affectedDocuments.join(', ')} label="Affected Drawings" style={{flexGrow: 1}} variant="standard" />
                    </div>
                    <div className={styles.row}>
                        <div className={styles.checkBoxGroup}>
                            <Typography className={styles.formControlLabel}>Redline Required</Typography>
                            <FormControlLabel labelPlacement="end" label="Yes" control={<Checkbox checked={redlineRequired} />} />
                            <FormControlLabel labelPlacement="end" label="No" control={<Checkbox checked={!redlineRequired} />} />
                        </div>
                    </div>
                    <div className={styles.row} style={{flexDirection: 'column'}}>
                        <Typography className={styles.formControlLabel} style={{marginBottom: 8}}>
                            H+M Request / Question
                        </Typography>
                        <div className={`${styles.textArea} sun-editor-editable`}>{parse(description.replaceAll(/contenteditable="\w*"/gm, ''))}</div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.checkBoxGroup}>
                            <Typography className={styles.formControlLabel}>Sketch Attached</Typography>
                            <FormControlLabel labelPlacement="end" label="Yes" control={<Checkbox checked={sketchAttached} />} />
                            <FormControlLabel labelPlacement="end" label="No" control={<Checkbox checked={!sketchAttached} />} />
                        </div>
                        <div className={styles.checkBoxGroup}>
                            <Typography className={styles.formControlLabel}>Pictures Attached</Typography>
                            <FormControlLabel labelPlacement="end" label="Yes" control={<Checkbox checked={picturesAttached} />} />
                            <FormControlLabel labelPlacement="end" label="No" control={<Checkbox checked={!picturesAttached} />} />
                        </div>
                    </div>
                    <div className={styles.row}>
                        <Typography className={styles.formControlLabel}>Possible impact for H+M depending on client response</Typography>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.checkBoxGroup}>
                            <Typography className={styles.formControlLabel}>Type of Impact</Typography>
                            <FormControlLabel
                                labelPlacement="end"
                                label="Engineering"
                                control={<Checkbox checked={impactType ? impactType.includes('Engineering') : false} />}
                            />
                            <FormControlLabel
                                labelPlacement="end"
                                label="Fabrication"
                                control={<Checkbox checked={impactType ? impactType.includes('Fabrication') : false} />}
                            />
                            <FormControlLabel
                                labelPlacement="end"
                                label="Construction"
                                control={<Checkbox checked={impactType ? impactType.includes('Construction') : false} />}
                            />
                            <FormControlLabel
                                labelPlacement="end"
                                label="Vendor"
                                control={<Checkbox checked={impactType ? impactType.includes('Vendor') : false} />}
                            />
                        </div>
                    </div>
                    <div className={styles.sectionDivider}>
                        <Typography>Part II - By Client</Typography>
                    </div>
                    <div className={styles.row} style={{flexDirection: 'column'}}>
                        <Typography className={styles.formControlLabel} style={{marginBottom: 8}}>
                            Response / Action
                        </Typography>
                        <div className={`${styles.textAreaCondensed} sun-editor-editable`}>{parse(response.replaceAll(/contenteditable="\w*"/gm, ''))}</div>
                    </div>
                    <div className={styles.checkBoxGroup}>
                        <Typography className={styles.formControlLabel}>Revised Deliverable Attached</Typography>
                        <FormControlLabel labelPlacement="end" label="Yes" control={<Checkbox checked={revisedDeliverableAttached} />} />
                        <FormControlLabel labelPlacement="end" label="No" control={<Checkbox checked={!revisedDeliverableAttached} />} />
                    </div>
                    <div className={styles.canvasContainer}>
                        <SignaturePad
                            penColor="blue"
                            canvasProps={{
                                width: 900,
                                height: 100,
                            }}
                            ref={(ref): void => {
                                canvasRef.current = ref;
                            }}
                        />
                    </div>
                    <div className={styles.row}>
                        <TextField value={answeredBy} label={`Responder's Name`} style={{width: 300}} variant="standard" />
                        <TextField
                            value={answered ? new Date(answered).toLocaleDateString() : ''}
                            label="Date Responded"
                            style={{width: 180}}
                            variant="standard"
                        />
                    </div>
                    <div className={styles.sectionDivider}>
                        <Typography>Part III - By H+M</Typography>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.checkBoxGroup}>
                            <Typography className={styles.formControlLabel}>PM Approved</Typography>
                            <FormControlLabel labelPlacement="end" label="Yes" control={<Checkbox checked={pmApproved} />} />
                            <FormControlLabel labelPlacement="end" label="No" control={<Checkbox checked={!pmApproved} />} />
                        </div>
                        <TextField
                            value={pmApprovedDate ? new Date(pmApprovedDate).toLocaleDateString() : ''}
                            label="Date Approved"
                            style={{width: 180}}
                            variant="standard"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

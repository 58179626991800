import {Button} from '@mui/material';
import {motion} from 'framer-motion';
import * as React from 'react';

const Path = (props: any): JSX.Element => <motion.path fill="transparent" strokeWidth="2" stroke="#FFF" strokeLinecap="round" {...props} />;

export const MenuToggle = ({toggle}: {toggle: () => void}): JSX.Element => (
    <Button onClick={toggle} data-testid="toggle-menu-button">
        <svg width="32" height="32" viewBox="0 0 32 32">
            <Path
                variants={{
                    closed: {d: 'M 3 6 L 29 6'},
                    open: {d: 'M 6 6 L 29 29'},
                }}
            />
            <Path
                d="M 3 16 L 29 16"
                variants={{
                    closed: {opacity: 1},
                    open: {opacity: 0},
                }}
                transition={{duration: 0.1}}
            />
            <Path
                variants={{
                    closed: {d: 'M 3 26 L 29 26'},
                    open: {d: 'M 6 29 L 29 6'},
                }}
            />
        </svg>
    </Button>
);

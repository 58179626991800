import DateFnsUtils from '@date-io/date-fns';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {MuiPickersUtilsProvider, DatePicker} from '@material-ui/pickers';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Radio, RadioGroup, TextField, Zoom} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import {useSnackbar} from 'notistack';
import {useCallback, useEffect, useMemo, useState} from 'react';
import * as React from 'react';
import {useForm, FormProvider, Message} from 'react-hook-form';
import {useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom';
import SunEditor from 'suneditor-react';
import { trackEvent } from '~/api';
import AttachmentListItem from '~/components/generics/AttachmentListItem';
import BusyButton from '~/components/generics/BusyButton';
import NoDataPlaceholder from '~/components/generics/NoDataPlaceholder';
import RHFController from '~/components/generics/RHFController';
import SignatureControl from '~/components/generics/SignatureControl';
import {CleanSunEditorHTML, DisableMobileZoom, EnableMobileZoom} from '~/helpers';
import {useStoreActions, useStoreState} from '~/store/storeHooks';
import styles from '~/styles/CorrespondenceDialog.module.scss';
import {CorrespondenceAttachment, TDR} from '~/types';

let scrollPos = 0;

export default function TDRDialog(): JSX.Element {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const {projectId} = useParams<{projectId: string}>();
    const [busy, setBusy] = useState(false);
    const tdrs = useStoreState((state) => state.correspondence.tdrs);
    const updateTDR = useStoreActions((actions) => actions.correspondence.updateTDR);

    const tdrId = searchParams.get('tdr');

    const tdr = useMemo(() => tdrs.find((o) => tdrId && o.id === +tdrId), [tdrId, tdrs]);

    const methods = useForm<TDR>({
        defaultValues: tdr ?? {},
    });
    const {reset, handleSubmit} = methods;

    const requestorAttachments = useMemo((): CorrespondenceAttachment[] => tdr?.attachments?.filter((a) => a.type === 'H+M') ?? [], [tdr]);

    const {enqueueSnackbar} = useSnackbar();

    // Stop scrolling when the action item dialog is open
    useEffect(() => {
        if (tdrId) {
            scrollPos = window.pageYOffset;
            document.body.style.position = 'fixed';
            document.body.style.top = `-${scrollPos}px`;
            setTimeout(()=>{document.body.style.overflow = 'visible'},0);
        } else {
            document.body.style.position = '';
            document.body.style.top = '';
            window.scrollTo(0,scrollPos);
        }
    }, [tdrId]);

    // Reset RHF state when tdr changes
    useEffect(() => {
        reset(tdr);
    }, [tdr, reset]);

    // Disable zooming for this dialog, it's annoying whenever selecting any input
    useEffect(() => {
        DisableMobileZoom();
        return (): void => {
            EnableMobileZoom();
            setBusy(false);
        };
    }, []);

    const handleClose = useCallback(() => {
        setSearchParams({});
    }, [setSearchParams]);

    const handlePrintPreview = useCallback(() => {
        navigate(`/print/${projectId}/tdr/${tdrId}`, {state: {from: location}});
    }, [location, navigate, projectId, tdrId]);

    const radioGroupBooleanOnChange = (e: React.ChangeEvent<HTMLInputElement>, v: string, onChange: (...event: any[]) => void): void => {
        onChange(v === 'true');
    };

    const onSubmit = handleSubmit(async (data) => {
        setBusy(true);

        trackEvent('update TDR', {id: data.id, number: data.number});
        const {result, message} = await updateTDR({tdr: data});
        setBusy(false);

        enqueueSnackbar(message, {variant: result});
        if (result === 'success') {
            handleClose();
        }
    });

    return (
        <Dialog open={tdrId != null} onClose={handleClose} id="tdr-dialog-root" className={styles.root} maxWidth="md" TransitionComponent={Zoom}>
            <DialogTitle>
                <div className={styles.title}>
                    <h3>Deviation Request</h3>
                    {!tdr?.editable && <FontAwesomeIcon icon={['fal', 'lock-alt']} color="tomato" />}
                </div>
            </DialogTitle>
            <DialogContent>
                <FormProvider {...methods}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <form onSubmit={onSubmit}>
                            {tdr == null ? (
                                <NoDataPlaceholder title="No TDR Data Available" />
                            ) : (
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <RHFController
                                            render={({field}): JSX.Element => <TextField {...field} label="Originator" disabled variant="standard" />}
                                            name="originator"
                                            defaultValue={tdr.originator ?? ''}
                                            rules={{
                                                required: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <RHFController
                                            render={({field}): JSX.Element => <TextField {...field} label="Status" disabled variant="standard" />}
                                            name="status"
                                            defaultValue={tdr.status ?? ''}
                                            rules={{
                                                required: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <RHFController
                                            render={({field}): JSX.Element => <TextField {...field} label="Number" disabled variant="standard" />}
                                            name="number"
                                            defaultValue={tdr.number ?? ''}
                                            rules={{
                                                required: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <RHFController
                                            render={({field}): JSX.Element => <TextField {...field} label="Rev" disabled variant="standard" />}
                                            name="rev"
                                            defaultValue={tdr.rev ?? ''}
                                            rules={{
                                                required: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <DatePicker
                                                    label="Issued"
                                                    variant="dialog"
                                                    format="MM/dd/yyyy"
                                                    autoOk
                                                    showTodayButton
                                                    clearable
                                                    onChange={field.onChange}
                                                    value={field.value}
                                                    disabled
                                                />
                                            )}
                                            name="created"
                                            defaultValue={tdr.created ?? null}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <TextField {...field} label="Code, Project Standard or Contract" disabled variant="standard" />
                                            )}
                                            name="type"
                                            defaultValue={tdr.type ?? ''}
                                            rules={{
                                                required: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <Autocomplete
                                                    {...field}
                                                    options={tdr.disciplines}
                                                    multiple
                                                    renderInput={(params): JSX.Element => (
                                                        <TextField {...params} label="Disciplines" disabled variant="standard" />
                                                    )}
                                                    ChipProps={{size: 'small'}}
                                                    disabled
                                                />
                                            )}
                                            name="disciplines"
                                            defaultValue={tdr.disciplines ?? []}
                                        />
                                    </Grid>
                                    <div className={styles.sectionDivider}>
                                        <h5>Part I - By H+M</h5>
                                    </div>
                                    <Grid item xs={8}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <Autocomplete
                                                    {...field}
                                                    options={tdr.affectedDocuments}
                                                    multiple
                                                    renderInput={(params): JSX.Element => (
                                                        <TextField {...params} label="Drawing Numbers" disabled variant="standard" />
                                                    )}
                                                    ChipProps={{size: 'small'}}
                                                    disabled
                                                />
                                            )}
                                            name="affectedDocuments"
                                            defaultValue={tdr.affectedDocuments ?? []}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <TextField
                                                    {...field}
                                                    label="Respond By"
                                                    disabled
                                                    value={field.value ? new Date(field.value).toLocaleDateString() : ''}
                                                    inputProps={{style: {height: '18px'}}}
                                                    variant="standard"
                                                />
                                            )}
                                            name="responseRequired"
                                            defaultValue={tdr.responseRequired ?? ''}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <div className={styles.sunEditorContainer}>
                                                    <p>Deviation Request Description</p>
                                                    <SunEditor
                                                        disable
                                                        height="100%"
                                                        setContents={CleanSunEditorHTML(field.value)}
                                                        autoFocus={false}
                                                        hideToolbar
                                                        setOptions={{
                                                            showPathLabel: false,
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            name="description"
                                            defaultValue={tdr.description ?? ''}
                                            rules={{required: true}}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <div className={styles.radioGroup}>
                                                    <p>Sketch Attached</p>
                                                    <RadioGroup
                                                        {...field}
                                                        row
                                                        onChange={(e, v): void => radioGroupBooleanOnChange(e, v, field.onChange)}
                                                    >
                                                        <FormControlLabel value control={<Radio disabled />} label="Yes" labelPlacement="top" />
                                                        <FormControlLabel
                                                            value={false}
                                                            control={<Radio disabled />}
                                                            label="No"
                                                            labelPlacement="top"
                                                        />
                                                    </RadioGroup>
                                                </div>
                                            )}
                                            name="sketchAttached"
                                            defaultValue={tdr.sketchAttached ?? null}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <div className={styles.radioGroup}>
                                                    <p>Pictures Attached</p>
                                                    <RadioGroup
                                                        {...field}
                                                        row
                                                        onChange={(e, v): void => radioGroupBooleanOnChange(e, v, field.onChange)}
                                                    >
                                                        <FormControlLabel value control={<Radio disabled />} label="Yes" labelPlacement="top" />
                                                        <FormControlLabel
                                                            value={false}
                                                            control={<Radio disabled />}
                                                            label="No"
                                                            labelPlacement="top"
                                                        />
                                                    </RadioGroup>
                                                </div>
                                            )}
                                            name="picturesAttached"
                                            defaultValue={tdr.picturesAttached ?? null}
                                        />
                                    </Grid>
                                    {requestorAttachments.length > 0 && (
                                        <Grid item xs={12} id="requestor-attachments">
                                            <div className={styles.attachmentList}>
                                                <p>Attachments</p>
                                                <ul>
                                                    {requestorAttachments.map(
                                                        (f): JSX.Element => (
                                                            <AttachmentListItem file={f} key={f.id} />
                                                        ),
                                                    )}
                                                </ul>
                                            </div>
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <div className={styles.sunEditorContainer}>
                                                    <p>Justification of requested deviation</p>
                                                    <SunEditor
                                                        disable
                                                        height="100%"
                                                        setContents={CleanSunEditorHTML(field.value)}
                                                        autoFocus={false}
                                                        hideToolbar
                                                        setOptions={{
                                                            showPathLabel: false,
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            name="justification"
                                            defaultValue={tdr.justification ?? ''}
                                            rules={{required: true}}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <div className={styles.sunEditorContainer}>
                                                    <p>Possible impact for H+M depending on client response</p>
                                                    <SunEditor
                                                        disable
                                                        height="100%"
                                                        setContents={CleanSunEditorHTML(field.value)}
                                                        autoFocus={false}
                                                        hideToolbar
                                                        setOptions={{
                                                            showPathLabel: false,
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            name="impact"
                                            defaultValue={tdr.impact ?? ''}
                                        />
                                    </Grid>
                                    <div className={styles.sectionDivider}>
                                        <h5>Part II - By Client</h5>
                                    </div>
                                    <Grid item xs={12}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <div className={styles.sunEditorContainer}>
                                                    <p className={styles.required}>Disposition of Request</p>
                                                    <SunEditor
                                                        setContents={CleanSunEditorHTML(field.value)}
                                                        required
                                                        height="100%"
                                                        autoFocus={false}
                                                        setOptions={{
                                                            showPathLabel: false,
                                                            buttonList: [
                                                                ['undo', 'redo', 'bold', 'underline', 'italic', 'strike', 'fontColor', 'table'],
                                                            ],
                                                        }}
                                                        onChange={field.onChange}
                                                    />
                                                </div>
                                            )}
                                            name="clientDisposition"
                                            defaultValue={tdr.clientDisposition ?? ''}
                                            rules={{
                                                required: 'Response is required' as Message,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <div className={styles.radioGroup}>
                                                    <p className={styles.required}>Request is accepted</p>
                                                    <RadioGroup
                                                        {...field}
                                                        row
                                                        onChange={(e, v): void => radioGroupBooleanOnChange(e, v, field.onChange)}
                                                    >
                                                        <FormControlLabel value control={<Radio required/>} label="Yes" labelPlacement="top" />
                                                        <FormControlLabel value={false} control={<Radio required/>} label="No" labelPlacement="top" />
                                                    </RadioGroup>
                                                </div>
                                            )}
                                            name="accepted"
                                            defaultValue={tdr.accepted ?? null}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <TextField
                                                    {...field}
                                                    label="Responder's Name"
                                                    required
                                                    className={styles.required}
                                                    value={field.value ?? ''}
                                                    variant="standard"
                                                />
                                            )}
                                            name="responderName"
                                            defaultValue={tdr.responderName ?? ''}
                                            rules={{
                                                required: 'Name is required' as Message,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <DatePicker
                                                    label="Date"
                                                    variant="dialog"
                                                    format="MM/dd/yyyy"
                                                    autoOk
                                                    required
                                                    showTodayButton
                                                    clearable
                                                    onChange={field.onChange}
                                                    value={field.value}
                                                    className={styles.required}
                                                />
                                            )}
                                            name="responded"
                                            defaultValue={tdr.responded ?? null}
                                            rules={{
                                                required: 'Date is required' as Message,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RHFController
                                            render={({field}): JSX.Element => <SignatureControl field={field} title="Responder Signature" required />}
                                            name="clientSignature"
                                            defaultValue={tdr.clientSignature ?? null}
                                            rules={{
                                                required: 'Signature is required' as Message,
                                            }}
                                        />
                                    </Grid>
                                    <div className={styles.sectionDivider}>
                                        <h5>Part III - By H+M</h5>
                                    </div>
                                    <Grid item xs={7} sm={6} md={3}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <div className={styles.radioGroup}>
                                                    <p>PM Approved</p>
                                                    <RadioGroup
                                                        {...field}
                                                        row
                                                        onChange={(e, v): void => radioGroupBooleanOnChange(e, v, field.onChange)}
                                                    >
                                                        <FormControlLabel value control={<Radio disabled />} label="Yes" labelPlacement="top" />
                                                        <FormControlLabel
                                                            value={false}
                                                            control={<Radio disabled />}
                                                            label="No"
                                                            labelPlacement="top"
                                                        />
                                                    </RadioGroup>
                                                </div>
                                            )}
                                            name="pmApproved"
                                            defaultValue={tdr.pmApproved ?? null}
                                        />
                                    </Grid>
                                    <Grid item xs={5} sm={6} md={3}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <DatePicker
                                                    label="Date"
                                                    variant="dialog"
                                                    format="MM/dd/yyyy"
                                                    autoOk
                                                    showTodayButton
                                                    clearable
                                                    onChange={field.onChange}
                                                    value={field.value}
                                                    disabled
                                                />
                                            )}
                                            name="pmApprovedDate"
                                            defaultValue={tdr.pmApprovedDate ?? null}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </form>
                    </MuiPickersUtilsProvider>
                </FormProvider>
            </DialogContent>
            <DialogActions>
                <Button className="print-button" onClick={handlePrintPreview} disabled={parseInt(tdrId, 10) < 0}>
                    Print
                </Button>
                <Button className="close-button" onClick={handleClose}>
                    Close
                </Button>
                <BusyButton className="save-button" onClick={onSubmit} busy={busy}>
                    Save
                </BusyButton>
            </DialogActions>
        </Dialog>
    );
}

// import {isBefore, isAfter} from 'date-fns';
import round from 'lodash/round';
import moment from 'moment';
import {useMemo} from 'react';
import KPIGrid from '~/components/kpi/KPIGrid';
import {useStoreState} from '~/store/storeHooks';
import styles from '~/styles/KPI.module.scss';

export default function PercentCompleteKPI(): JSX.Element {
    const progressTableRows = useStoreState((state) => state.progress.progressTableRows);
    // const startOfWeek = useMemo(() => moment().startOf('week'), []);

    // const currentWeekData = useMemo((): {
    //     cumulativePlan: number;
    //     cumulativeActual: number;
    //     delta: number;
    // } => {
    //     let current = {cumulativePlan: 0, cumulativeActual: 0, delta: 0};
    //     for (let i: number = 0; i < progressTableRows.length; i++) {
    //         const {cumulativePlan, cumulativeActual, weekending} = progressTableRows[i];
    //         // First entry is past current start of week, break, no planned or actual
    //         if (i === 0 && startOfWeek.isBefore(weekending, 'day')) {
    //             break;
    //         }

    //         // Reached last entry or current entry is same as or before current start of week
    //         if (i === progressTableRows.length - 1 || startOfWeek.isSameOrBefore(weekending, 'day')) {
    //             current = {
    //                 cumulativePlan: round(cumulativePlan * 100, 0),
    //                 cumulativeActual: round(cumulativeActual * 100, 0),
    //                 delta: cumulativeActual - cumulativePlan,
    //             };
    //             break;
    //         }

    //         // Check if the current iteration is the last entry or if it's Wednesday
    //         if (i === progressTableRows.length - 1 || startOfWeek.isSameOrBefore(weekending, 'day')) {
    //             current = {
    //                 cumulativePlan: round(cumulativePlan * 100, 0),
    //                 cumulativeActual: round(cumulativeActual * 100, 0),
    //                 delta: cumulativeActual - cumulativePlan,
    //             };
    //             break;
    //         }
    //     }
    //     return current;
    // }, [progressTableRows, startOfWeek]);

    const currentWeekData = useMemo((): {
        cumulativePlan: number;
        cumulativeActual: number;
        delta: number;
    } => {
        let current = {cumulativePlan: 0, cumulativeActual: 0, delta: 0};
        const today = moment();
        const startOfCurrentWeek = today.clone().startOf('week').day(0); // Assuming Sunday is the start of the week
        const middleOfCurrentWeek = today.clone().startOf('week').day(3); // Assuming Wednesday is the middle of the week

        const filteredRows = progressTableRows.filter((row) =>
            today.isSameOrAfter(middleOfCurrentWeek, 'day')
                ? moment(row.weekending).isSameOrBefore(startOfCurrentWeek, 'day')
                : moment(row.weekending).isBefore(startOfCurrentWeek, 'day'),
        );

        if (filteredRows.length > 0) {
            const {cumulativePlan, cumulativeActual} = filteredRows[filteredRows.length - 1];
            current = {
                cumulativePlan: round(cumulativePlan * 100, 0),
                cumulativeActual: round(cumulativeActual * 100, 0),
                delta: cumulativeActual - cumulativePlan,
            };
        }

        return current;
    }, [progressTableRows]);

    let actualStyle = '';
    if (currentWeekData.delta !== 0) {
        actualStyle = currentWeekData.delta > 0 ? styles.over : styles.under;
    }

    return (
        <div>
            <KPIGrid>
                <div className={styles.splitContainer}>
                    <h5 className={`${styles.title} ${styles.topTitle}`}>Planned</h5>
                    <h1 className={`${styles.top}`}>{currentWeekData.cumulativePlan}</h1>
                    <h5 className={`${styles.title} ${styles.bottomTitle}`}>Actual</h5>
                    <h1 className={`${styles.bottom} ${actualStyle}`}>{currentWeekData.cumulativeActual}</h1>
                </div>
            </KPIGrid>
        </div>
    );
}

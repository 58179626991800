import SwipeStack from '~/components/generics/SwipeStack';
import SwipeStackCard from '~/components/generics/SwipeStackCard';
import SummaryBudgetGrade from '~/components/subsite/summary/healthStack/SummaryBudgetGrade';
import SummaryHSEGrade from '~/components/subsite/summary/healthStack/SummaryHSEGrade';
import SummaryScheduleGrade from '~/components/subsite/summary/healthStack/SummaryScheduleGrade';

export default function ProjectHealthStack(): JSX.Element {
    return (
        <SwipeStack id="summary-health-stack-root">
            <SwipeStackCard cardIndex={0}>
                <SummaryHSEGrade />
            </SwipeStackCard>
            <SwipeStackCard cardIndex={1}>
                <SummaryBudgetGrade />
            </SwipeStackCard>
            <SwipeStackCard cardIndex={2}>
                <SummaryScheduleGrade />
            </SwipeStackCard>
        </SwipeStack>
    );
}

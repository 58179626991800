import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Badge, Button, Collapse, IconButton} from '@mui/material';
import {useCallback, useEffect, useState, useRef, memo, ReactNode, useContext} from 'react';
import {useIdleTimer} from 'react-idle-timer';
import {useParams} from 'react-router-dom';
import NavBarContext from '../navbar/NavBarContext';
import NotificationIcon from '~/assets/img/notification-icon.svg';
import ClearAllNotificationsButton from '~/components/app/notification/ClearAllNotificationsButton';
import NotificationListItem from '~/components/app/notification/NotificationListItem';
import LoadingComponent from '~/components/visualizations/LoadingComponent';
import {useStoreActions, useStoreState} from '~/store/storeHooks';
import styles from '~/styles/NotificationButton.module.scss';


function NotificationButton(): JSX.Element {
    const getNotifications = useStoreActions((actions) => actions.activity.getNotifications);
    const notifications = useStoreState((state) => state.activity.notifications);
    const {expanded, setExpanded} = useContext(NavBarContext);
    const [busy, setBusy] = useState(false);
    const [unAcked,setUnAcked] = useState(0);
    const {projectId} = useParams<{projectId: string}>();
    const intervalRef = useRef<NodeJS.Timeout>();

    const toggleExpanded = useCallback((): void => {
        setExpanded(expanded === 'notification' ? '' : 'notification');
    }, [setExpanded,expanded]);

    const unexpand = useCallback((): void => {
        setExpanded('');
    }, [setExpanded]);

    // explicitly close on unmount if they switch to dashboard without closing and resetting scroll bar
    useEffect(() => ()=> {
        setExpanded('');
    }, [setExpanded]);

    useEffect(() => {
        const notificationGetter = async (): Promise<void> => {
            setBusy(true);
            const p = parseInt(projectId, 10) || -1;
            await getNotifications(p);
            setBusy(false);
        };

        notificationGetter();
        intervalRef.current = setInterval(notificationGetter, 30000);

        return (): void => {
            clearInterval(intervalRef.current);
        };
    }, [getNotifications, projectId]);

    useIdleTimer({
        timeout: 1000 * 60 * 30,
        onIdle: (): void => {
            // eslint-disable-next-line no-console
            console.log('User is idle, cancelling requests');
            clearInterval(intervalRef.current);
        },
        onActive: (): void => {
            window.location.reload();
        },
    });

    const updateAckedCount = useCallback((i: number)=>{
        setUnAcked(unAcked+i)
    },[unAcked])

    let notificationList: ReactNode | null = null;
    if (busy && !notifications?.length) {
        notificationList = <LoadingComponent title="Retrieving Notifications" />;
    } else if (!notifications || notifications?.length === 0) {
        notificationList = (
            <div className={styles.noNotifications}>
                <p>No Notifications</p>
            </div>
        );
    } else {
        notificationList = notifications.map((n) => <NotificationListItem key={n.id} notification={n} updateAckedCount={updateAckedCount}/>);
    }

    useEffect(() => {
        let i = 0;
        notifications.forEach((el)=>{
            if (!el.acknowledged) i ++;
        })
        setUnAcked(i);
    },[notifications])

    return (
        <div className={styles.root}>
            <IconButton id="notification-button-root" onClick={toggleExpanded}>
                <Badge badgeContent={unAcked} color="error" max={100}>
                    <img src={NotificationIcon} alt="notification-icon" aria-label="notification list" className={styles.notificationIcon} />
                </Badge>
            </IconButton>
            <Collapse in={expanded === 'notification'} className={styles.notificationCollapse} timeout={800}>
                <div className={styles.header}>
                    <h6>Notification Center</h6>
                    <div className={styles.buttonContainer}>
                        {notifications?.length > 0 && <ClearAllNotificationsButton />}
                        <Button id="close-notifications-button" onClick={unexpand} variant='text' color='inherit'>
                            <p>Close</p>
                        </Button>
                    </div>
                </div>
                <div className={styles.content}>
                    <div className={styles.activitiesContainer}>{notificationList}</div>     
                    <IconButton onClick={toggleExpanded} className={styles.closeButton} aria-label="close">
                        <FontAwesomeIcon icon={['fad', 'chevron-double-up']} size="lg" />
                    </IconButton>
                </div>
            </Collapse>
        </div>
    );
}

export default memo(NotificationButton);

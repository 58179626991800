import {useContext, useEffect, useState} from 'react';
import ProjectContext from '~/components/dashboard/project-log/ProjectContext';
import ProjectInfoCard from '~/components/dashboard/project-log/ProjectInfoCard';
import SummaryCard from '~/components/generics/SummaryCard';
import LoadingComponent from '~/components/visualizations/LoadingComponent';
import {useStoreActions} from '~/store/storeHooks';

export default function SummaryProjectInfo(): JSX.Element {
    const {project} = useContext(ProjectContext);
    const getStandardHealthCriteria = useStoreActions((actions) => actions.project.getStandardHealthCriteria);
    const [fetchingHealthCriteria, setFetchingHealthCriteria] = useState(false);

    useEffect(() => {
        if (project) {
            (async function fetchData(): Promise<void> {
                setFetchingHealthCriteria(true);
                await getStandardHealthCriteria();
                setFetchingHealthCriteria(false);
            })();
        }
        return (): void => {
            setFetchingHealthCriteria(false);
        };
    }, [getStandardHealthCriteria, project]);

    return <SummaryCard>{fetchingHealthCriteria ? <LoadingComponent title="Fetching Project Data" contained/> : <ProjectInfoCard />}</SummaryCard>;
}

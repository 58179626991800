import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Divider, Fab, FabProps, useMediaQuery} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import {useCallback, useState} from 'react';
import UserFeedbackDialog from '~/components/app/feedbackFAB/UserFeedbackDialog';
import styles from '~/styles/UserFeedbackFabButton.module.scss';

export default function UserFeedbackFabButton(): JSX.Element {
    const mobile = useMediaQuery('(max-width: 600px)');
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [feedbackCategory, setFeedbackCategory] = useState('I like something');

    const handleClick = useCallback<FabProps['onClick']>(() => {
        setDrawerOpen(!drawerOpen);
    }, [drawerOpen]);

    const handleButtonClick = (category: string): void => {
        setFeedbackCategory(category);
        setDialogOpen(true);
    };

    const handleDialogClose = (): void => {
        setDialogOpen(false);
    };

    const handleDrawerClose = (): void => {
        setDrawerOpen(false);
    };

    return (
        <>
            <Fab className={styles.root} id="user-feedback-fab-button" size="large" onClick={handleClick}>
                <FontAwesomeIcon icon={['fas', 'thumbs-up']} size="lg" />
            </Fab>
            <Drawer anchor={mobile ? 'bottom' : 'right'} open={drawerOpen} onClose={handleDrawerClose}>
                <Box className={styles.zoomCard}>
                    <List
                        sx={{width: '100%', bgcolor: 'background.paper'}}
                        className={styles.feedbackList}
                        subheader={
                            <ListSubheader component="div" id="nested-list-subheader" className={styles.listSubheader}>
                                Feedback
                            </ListSubheader>
                        }
                    >
                        <Button onClick={(): void => handleButtonClick('I like something')}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar sx={{bgcolor: 'transparent'}}>
                                        <FontAwesomeIcon icon={['far', 'smile']} size="lg" className={styles.smileFaceIcon} />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={<p className={styles.primaryText}>I like something</p>}
                                    secondary="We like to hear what we're doing right."
                                />
                            </ListItem>
                        </Button>
                        <Divider />
                        <Button onClick={(): void => handleButtonClick(`I do not like something`)}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar sx={{bgcolor: 'transparent'}}>
                                        <FontAwesomeIcon icon={['far', 'frown']} size="lg" className={styles.frownFaceIcon} />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={<p className={styles.primaryText}>{`I don't like something`}</p>}
                                    secondary="If something's not right, we want to know about it."
                                />
                            </ListItem>
                        </Button>
                        <Divider />
                        <Button onClick={(): void => handleButtonClick('I have a suggestion')}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar sx={{bgcolor: 'transparent'}}>
                                        <FontAwesomeIcon icon={['far', 'paper-plane']} size="lg" className={styles.paperPlaneIcon} />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={<p className={styles.primaryText}>I have a suggestion</p>}
                                    secondary="Share an idea for improvement."
                                />
                            </ListItem>
                        </Button>
                    </List>
                </Box>
            </Drawer>
            <UserFeedbackDialog category={feedbackCategory} open={dialogOpen} handleClose={handleDialogClose} categoryChange={setFeedbackCategory} />
        </>
    );
}

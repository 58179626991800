import styles from '~/styles/MilestoneStack.module.scss';
import {Milestone} from '~/types';

interface MilestoneInfoCardProps {
    milestone: Milestone;
}

export default function MilestoneInfoCard({milestone}: MilestoneInfoCardProps): JSX.Element {
    const {description, type, dueDate, completed, completedDate} = milestone;

    return (
        <div className={styles.cardRoot}>
            <h3 className={styles.title}>Milestone</h3>
            <h3>{description}</h3>
            {type && <p>{`Type | ${type}`}</p>}
            <p>{`Due Date | ${new Date(dueDate).toLocaleDateString()}`}</p>
            {completed ? <p>{`Completed Date | ${new Date(completedDate).toLocaleDateString()}`}</p> : null}
            <div className={styles.completedContainer}>
                <p>Status</p>
                <div className={styles.completedIndicator} style={{backgroundColor: `${completed ? '#83cb85' : '#eaba72'}`}}>
                    <p>{completed ? 'COMPLETE' : 'INCOMPLETE'}</p>
                </div>
            </div>
        </div>
    );
}

import DateFnsUtils from '@date-io/date-fns';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    TextField,
    Zoom,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { toPng } from 'html-to-image';
import {useSnackbar} from 'notistack';
import {useCallback, useEffect, useMemo, useState} from 'react';
import * as React from 'react';
import {FormProvider, Message, useForm} from 'react-hook-form';
import {useNavigate, useParams, useSearchParams, useLocation} from 'react-router-dom';
import SunEditor from 'suneditor-react';
import COActions from './COActions';
import COAttachmentHistory from './COAttachmentHistory';
import COWorkflowHistory from './COWorkflowHistory';
import {trackEvent} from '~/api';
import AttachmentListItem from '~/components/generics/AttachmentListItem';
import BusyButton from '~/components/generics/BusyButton';
import RHFController from '~/components/generics/RHFController';
import SignatureControl from '~/components/generics/SignatureControl';
import LoadingComponent from '~/components/visualizations/LoadingComponent';
import {CleanSunEditorHTML, DisableMobileZoom, EnableMobileZoom} from '~/helpers';
import {useStoreActions, useStoreState} from '~/store/storeHooks';
import styles from '~/styles/ChangeOrderDialog.module.scss';
import {COStatusAction, ChangeOrder} from '~/types';

let scrollPos = 0;

const REJECT_COLOR = '#bf392f';
const DEFAULT_COLOR = 'rgba(0, 0, 0, 0.87)';
const REVISE_COLOR = 'var(--font-color-secondary)';
const HOLD_COLOR = '#1e3799';

export default function CODialog(): JSX.Element {
    const location = useLocation();
    const currentUser = useStoreState((state) => state.shared.currentUser);
    const {projectId} = useParams<{projectId: string}>();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [busy, setBusy] = useState(false);
    // const changeOrders = useStoreState((state) => state.changeOrder.changeOrders);
    const changeOrders = useStoreState((state)=> state.change.changeOrders);
    const updateChangeOrder = useStoreActions((actions) => actions.change.updateChangeOrder);

    const changeOrderId = searchParams.get('order');

    const changeOrder = useMemo(() => changeOrders.find((o) => changeOrderId && o.id === +changeOrderId), [changeOrderId, changeOrders]);

    const workflowObj: any = useMemo(()=>{
        let obj = {};
        let workflow = ''; 
        if (changeOrder?.workflow?.length) workflow = changeOrder.workflow;
        if (workflow?.length) {
            obj = JSON.parse(workflow);
        }
        return obj;
    },[changeOrder])

    const methods = useForm<ChangeOrder>({
        defaultValues: {},
    });

    const {reset,handleSubmit,setValue,watch,getValues} = methods;
    const {finalActionStatus, romActionStatus} = watch();

    const {enqueueSnackbar} = useSnackbar();

    /*
    setDefaultComplete({
            key:'clientApproveROM',
            note:`Client ${formData.clientContact ? `${formData.clientContact} ` : ''} requested hold for final estimate (recorded by ${currentUser.fullName})`,
            event: 'hold requested',
            stepState: 'hold',
            color: HOLD_COLOR
        });
        if (workflowState.peEstimateFinal.completed && workflowState.cmEstimateFinal.completed) {
            enableStep({key:'pmApproveFinal'});
            if (isChangeOrder) setFormValue('status','Pending');
        } 
        setFormValue('clientDispositionDate', new Date());
        setFormValue('clientApproveROMDate', new Date());
        setFormValue('clientDisposition', 'Hold, submit detailed est. for approval');*/

    const romActions: COStatusAction[] = useMemo(()=>{
        switch (romActionStatus) {
            case "Approved": 
                return [
                    {
                        action: "Reject",
                        updateStatusTo: "Rejected",
                        description: "Not approved, do not proceed"
                    },
                    {
                        action: "Hold",
                        updateStatusTo: "Holding",
                        description: "Hold, submit detailed est. for approval"
                    }
                ]
            case "Rejected": 
                return [
                    {
                        action: "Proceed",
                        updateStatusTo: "Approved",
                        description: "Approved, proceed & submit detailed est."
                    },
                    {
                        action: "Hold",
                        updateStatusTo: "Holding",
                        description: "Hold, submit detailed est. for approval"
                    }
                ]
            case "Holding": 
                return [
                    {
                        action: "Proceed",
                        updateStatusTo: "Approved",
                        description: "Approved, proceed & submit detailed est."
                    },
                    {
                        action: "Reject",
                        updateStatusTo: "Rejected",
                        description: "Not approved, do not proceed"
                    },
                ]
            default: 
                return [
                    {
                        action: "Proceed",
                        updateStatusTo: "Approved",
                        description: "Approved, proceed & submit detailed est."
                    },
                    {
                        action: "Reject",
                        updateStatusTo: "Rejected",
                        description: "Not approved, do not proceed"
                    },
                    {
                        action: "Hold",
                        updateStatusTo: "Holding",
                        description: "Hold, submit detailed est. for approval"
                    }
                ]
        }
    },[romActionStatus]); 

    const finalActions: COStatusAction[] = useMemo(()=>{
        switch (finalActionStatus) {
            case "Approved": 
                return [
                    {
                        action: "Reject",
                        updateStatusTo: "Rejected",
                        description: "Rejected"
                    },
                    {
                        action: "Revise & Resubmit",
                        updateStatusTo: "Revise & Resubmit",
                        description: "Revise & Resubmit"
                    }
                ]
            case "Rejected": 
                return [
                    {
                        action: "Approve",
                        updateStatusTo: "Approved",
                        description: "Approved"
                    },
                    {
                        action: "Revise & Resubmit",
                        updateStatusTo: "Revise & Resubmit",
                        description: "Revise & Resubmit"
                    }
                ]
            case "Revise & Resubmit": 
                return [
                    {
                        action: "Approve",
                        updateStatusTo: "Approved",
                        description: "Approved"
                    },
                    {
                        action: "Reject",
                        updateStatusTo: "Rejected",
                        description: "Rejected"
                    },
                ]
            default: 
                return [
                    {
                        action: "Approve",
                        updateStatusTo: "Approved",
                        description: "Approved"
                    },
                    {
                        action: "Reject",
                        updateStatusTo: "Rejected",
                        description: "Rejected"
                    },
                    {
                        action: "Revise & Resubmit",
                        updateStatusTo: "Revise & Resubmit",
                        description: "Revise & Resubmit"
                    }
                ]
        }
    },[finalActionStatus]); 


    // Stop scrolling when the action item dialog is open
    useEffect(() => {
        if (changeOrderId) {
            scrollPos = window.pageYOffset;
            document.body.style.position = 'fixed';
            document.body.style.top = `-${scrollPos}px`;
            setTimeout(() => {
                document.body.style.overflow = 'visible';
            }, 0);
        } else {
            document.body.style.position = '';
            document.body.style.top = '';
            window.scrollTo(0, scrollPos);
        }
    }, [changeOrderId]);

    // Reset RHF state when changeOrder changes
    useEffect(() => {
        reset(changeOrder);
    }, [changeOrder, reset]);

    useEffect(()=>{
        if (workflowObj?.clientApproveROM?.completed !== undefined) {
            const {rejected,completed,comment,state} = workflowObj.clientApproveROM;
            if (completed && state === 'hold') {
                setValue('romActionStatus','Holding');
            } else if (rejected) {
                setValue('romActionStatus','Rejected');
            } else if (completed) {
                setValue('romActionStatus','Approved');
            }
            setValue('romActionComment',comment);
            setValue('romDispositionInCppComment',comment);
        }
        if (workflowObj?.clientApproveFinal?.completed !== undefined) {
            const {rejected,completed,comment,revise} = workflowObj.clientApproveFinal;
            if (completed) {
                setValue('finalActionStatus','Approved');
            } else if (rejected) {
                setValue('finalActionStatus','Rejected');
            } else if (revise) {
                setValue('finalActionStatus','Revise & Resubmit');
            }
            setValue('finalActionComment',comment);
        }
    },[setValue, workflowObj,changeOrder]);

    // Disable zooming for this dialog, it's annoying whenever selecting any input
    useEffect(() => {
        DisableMobileZoom();
        return (): void => {
            EnableMobileZoom();
            setBusy(false);
        };
    }, []);

    const handleClose = useCallback(() => {
        navigate(`/project/${projectId}/change`);
    }, [navigate, projectId]);

    const handlePrintPreview = () => {
        navigate(`/print/${projectId}/change/${changeOrderId}`, {state: {from: location}});
    };

    const radioGroupBooleanOnChange = (e: React.ChangeEvent<HTMLInputElement>, v: string, onChange: (...event: any[]) => void): void => {
        onChange(v === 'true');
    };

    const attachmentHistory = useMemo(()=>changeOrder?.attachments?.filter(a=>a.wasPublished && !a.published),[changeOrder])

    const onSubmit = handleSubmit(async (data) => {
        setBusy(true);
        const postData = {
            id: data.id,
            projectId: data.projectId,
            clientContact: currentUser.fullName,
            status: data.status,
            responseDate: data.responseDate ?? undefined,
            approvedBy: data.approvedBy,
            approvedBySignature: data.approvedBySignature,
            approvedDate: data.approvedDate ?? undefined,
            clientDisposition: data.clientDisposition,
            clientDispositionDate: data.clientDispositionDate,
            clientApproveROMDate: data.clientApproveROMDate,
            romDispositionInCpp: data.romDispositionInCpp,
            romDispositionInCppBy: data.romDispositionInCppBy,
            romDispositionInCppDate: data.romDispositionInCppDate,
            romDispositionInCppComment: data.romDispositionInCppComment,
            workflow: ''
        }
        if (data.romActionStatus?.length) {
            switch (data.romActionStatus) {
                case 'Approved':
                    workflowObj.clientApproveROM.completed = true;
                    workflowObj.clientApproveROM.rejected = false;
                    workflowObj.clientApproveROM.revise = false;
                    workflowObj.clientApproveROM.color = DEFAULT_COLOR;
                    workflowObj.clientApproveROM.state = 'Completed';
                    postData.clientApproveROMDate = data.clientApproveROMDate ?? new Date();
                    postData.clientDisposition = "Approved, proceed & submit detailed est.";
                    postData.romDispositionInCpp = "Completed";
                    break;
                case 'Rejected':
                    workflowObj.clientApproveROM.completed = false;
                    workflowObj.clientApproveROM.rejected = true;
                    workflowObj.clientApproveROM.revise = false;
                    workflowObj.clientApproveROM.color = REJECT_COLOR;
                    workflowObj.clientApproveROM.state = 'Rejected';
                    postData.clientApproveROMDate = null;
                    postData.clientDisposition = "Not approved, do not proceed";
                    postData.romDispositionInCpp = "Rejected";
                    break;
                default:
                    workflowObj.clientApproveROM.completed = true;
                    workflowObj.clientApproveROM.rejected = false;
                    workflowObj.clientApproveROM.revise = false;
                    workflowObj.clientApproveROM.color = HOLD_COLOR;
                    workflowObj.clientApproveROM.state = 'hold';
                    postData.clientApproveROMDate = data.clientApproveROMDate ?? new Date();
                    postData.clientDisposition = "Hold, submit detailed est. for approval";
                    postData.romDispositionInCpp = "hold";
            }
            workflowObj.clientApproveROM.comment = data.romActionComment;
            workflowObj.clientApproveROM.by = data.romDispositionInCppBy ?? currentUser.fullName;
            workflowObj.clientApproveROM.at = data.clientApproveROMDate?.toLocaleString() ?? new Date().toLocaleString();  
            postData.romDispositionInCppDate = data.romDispositionInCppDate ?? new Date();
            postData.romDispositionInCppComment = data.romDispositionInCppComment ?? data.romActionComment;
            postData.romDispositionInCppBy = data.romDispositionInCppBy ?? currentUser.fullName;
        }
        if (data.finalActionStatus?.length) {
            switch (data.finalActionStatus) {
                case 'Approved':
                    workflowObj.clientApproveFinal.completed = true;
                    workflowObj.clientApproveFinal.rejected = false;
                    workflowObj.clientApproveFinal.revise = false;
                    workflowObj.clientApproveFinal.color = DEFAULT_COLOR;
                    workflowObj.clientApproveFinal.state = 'Completed';
                    break;
                case 'Rejected':
                    workflowObj.clientApproveFinal.completed = false;
                    workflowObj.clientApproveFinal.rejected = true;
                    workflowObj.clientApproveFinal.revise = false;
                    workflowObj.clientApproveFinal.color = REJECT_COLOR;
                    workflowObj.clientApproveFinal.state = 'Rejected';
                    break;
                default:
                    workflowObj.clientApproveFinal.completed = false;
                    workflowObj.clientApproveFinal.rejected = false;
                    workflowObj.clientApproveFinal.revise = true;
                    workflowObj.clientApproveFinal.color = REVISE_COLOR;
                    workflowObj.clientApproveFinal.state = 'Revise';
            }
            workflowObj.clientApproveFinal.comment = data.finalActionComment;
            workflowObj.clientApproveFinal.by = data.approvedBy ?? currentUser.fullName;
            workflowObj.clientApproveFinal.at = data.approvedDate?.toLocaleString() ?? new Date().toLocaleString();
        }
        postData.workflow = workflowObj?.clientApproveFinal?.state ? JSON.stringify(workflowObj) : "";
        trackEvent('update Change Order', {id: changeOrderId, number: changeOrder.changeOrderNumber});
        const {result, message} = await updateChangeOrder(postData);
        setBusy(false);
        enqueueSnackbar(message, {variant: result});
        if (result === 'success') {
             handleClose();
        }
    });

    const generateSignature = async ()=>{
        const sigP = document.getElementById('imgToPng');
        sigP.style.display = 'block';
        sigP.innerHTML =currentUser.fullName;
        const dataUrl: any = await toPng(sigP);
        return dataUrl;
    }

    const handleRomAction = async (data: {actionStatus: string; comment: string;})=> {
        const {romDispositionInCppDate, romDispositionInCppBy, clientApproveROMDate} = getValues();
        setValue('romActionComment',data.comment);
        setValue('romDispositionInCppComment',data.comment);
        setValue('romActionStatus',data.actionStatus);
        switch (data.actionStatus) {
            case "Rejected":
                setValue('status','Rejected');
                setValue('romDispositionInCppBy',currentUser.fullName);
                setValue('romDispositionInCppDate',new Date());
                setValue('romDispositionInCpp','Rejected');
                setValue('romActionStatus','Rejected');
                break;
            default:  // hold and proceed are both ROM approvals, just different status for proceeding with work at same time
                setValue('status','Pending');
                if (!romDispositionInCppBy) setValue('romDispositionInCppBy',currentUser.fullName);
                if (!romDispositionInCppDate) setValue('romDispositionInCppDate',new Date());
                if (!clientApproveROMDate) setValue('clientApproveROMDate', new Date());
                setValue('romDispositionInCpp',data.actionStatus === 'Holding' ? "hold" : "Completed");
                break;
        }
        onSubmit();
    }

    const handleFinalAction = async (data: {actionStatus: string; comment: string;})=> {
        const {approvedBySignature, approvedDate, approvedBy} = getValues();
        setValue('finalActionComment',data.comment);
        setValue('finalActionStatus',data.actionStatus);
        switch (data.actionStatus) {
            case "Approved":
                setValue('status','Approved');
                if (!approvedBy) setValue('approvedBy',currentUser.fullName);
                if (!approvedDate) setValue('approvedDate',new Date());
                if (!approvedBySignature) {
                    const signature = await generateSignature();
                    setValue('approvedBySignature',signature);
                }
                break;
            case "Rejected":
                setValue('status','Rejected');
                setValue('approvedBy','');
                setValue('approvedDate',null);
                setValue('approvedBySignature','');
                break;
            default:
                setValue('status','Revise & Resubmit');
                setValue('approvedBy','');
                setValue('approvedDate',null);
                setValue('approvedBySignature','');
        }
        setValue('responseDate',new Date());
        onSubmit();
    }

    const getCostTable: any = () => (
        <>
            <TableContainer sx={{width:'max-content'}}>
                <Table sx={{border: '1px solid rgba(81, 81, 81, 1)'}} size='small'>
                    <TableHead>
                        <TableRow sx={{backgroundColor: '#2d2d2d'}}>
                            <TableCell colSpan={2} align='center'>
                                Summary
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableHead>
                        <TableRow>
                            <TableCell width={190}/>
                            <TableCell align='right' width={150}>
                                CO Value
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                Engineering Labor
                            </TableCell>
                            <TableCell align='right'>
                                $ {changeOrder.engineeringLaborCost ?? '0.00'}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Construction Labor
                            </TableCell>
                            <TableCell align='right'>
                                 $ {changeOrder.constructionLaborCost ?? '0.00'}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Materials
                            </TableCell>
                            <TableCell align='right'>
                                $ {changeOrder.materialCost ?? '0.00'}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Equipment
                            </TableCell>
                            <TableCell align='right'>
                                $ {changeOrder.equipmentCost ?? '0.00'} 
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Subcontract
                            </TableCell>
                            <TableCell align='right'>
                                $ {changeOrder.subcontractorCost ?? '0.00'}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Other
                            </TableCell>
                            <TableCell align='right'>
                                $ {changeOrder.otherCost ?? '0.00'}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                    <TableFooter>
                        <TableRow style={{backgroundColor: '#2d2d2d'}}>
                            <TableCell sx={{fontSize: '15px', color: 'white'}}>Total</TableCell>
                            <TableCell sx={{fontSize: '15px', color: 'white'}} align='right'>
                                $ {changeOrder.totalCost ?? '0.00'}
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </>
    )

    return (
        <Dialog
            open={changeOrderId != null}
            onClose={handleClose}
            id="change-order-dialog-root"
            className={styles.root}
            maxWidth='lg'
            fullWidth
            TransitionComponent={Zoom}>
            <DialogTitle className={styles.header}>
                <div>
                    <h3>Change Order</h3>
                </div>
            </DialogTitle>
            <DialogContent>
                <FormProvider {...methods}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <form onSubmit={onSubmit}>
                            {changeOrder === undefined || changeOrder === null ? (
                                <LoadingComponent title="Loading Change Order" contained />
                            ) : (
                                <>
                                    <Grid container spacing={1} gap={3} p={0}>
                                        <div className={styles.sectionDivider}>
                                            <h5>Part I - By Requestor</h5>
                                        </div>
                                        <Grid item xs={12} md={5.5} lg={2}>
                                            <RHFController
                                                render={({field}): JSX.Element => (
                                                    <TextField {...field} label="Initiating Entity" disabled variant="standard" />
                                                )}
                                                name="initiatingEntity"
                                                defaultValue={changeOrder.initiatingEntity ?? ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4} lg={3}>
                                            <RHFController
                                                render={({field}): JSX.Element => (
                                                    <TextField {...field} label="Initiated By" disabled variant="standard" fullWidth/>
                                                )}
                                                name="initiator"
                                                defaultValue={changeOrder.initiatedByHm ? changeOrder.initiatedByHm.fullName : changeOrder.initiatedByNonHm}
                                            />
                                        </Grid>
                                        <Grid item xs={5.5} md={1.5}>
                                            <RHFController
                                                render={({field}): JSX.Element => (
                                                    <DatePicker
                                                        label="Date Initiated"
                                                        variant="dialog"
                                                        format="MM/dd/yyyy"
                                                        autoOk
                                                        showTodayButton
                                                        clearable
                                                        disabled
                                                        onChange={()=>{}}
                                                        value={changeOrder.initiatedDate ?? null}
                                                        fullWidth
                                                    />
                                                )}
                                                name="initiatedDate"
                                            />
                                        </Grid>
                                        <Grid item xs={5.5} md={2}>
                                            <RHFController
                                                render={({field}): JSX.Element => (
                                                    <TextField {...field} label="Classification" disabled variant="standard" fullWidth/>
                                                )}
                                                name="classification"
                                                defaultValue={changeOrder.classification ?? ''}
                                            />
                                        </Grid>
                                        <Grid item xs={5.5} md={2.5}>
                                            <RHFController
                                                render={({field}): JSX.Element => (
                                                    <TextField {...field} label="Reason For Change" disabled variant="standard" fullWidth/>
                                                )}
                                                name="reasonForChange"
                                                defaultValue={changeOrder.reasonForChange ?? ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={9.2}>
                                            <RHFController
                                                render={({field}): JSX.Element => (
                                                    <TextField {...field} label="Description of Change" disabled variant="standard" fullWidth/>
                                                )}
                                                name="shortDescription"
                                                defaultValue={changeOrder.shortDescription ?? ''}
                                            />
                                        </Grid>
                                        <Grid item xs={5.5} md={1.5}>
                                            <RHFController
                                                render={({field}): JSX.Element => (
                                                    <TextField {...field} label="Approval Status" disabled variant="standard" fullWidth/>
                                                )}
                                                name="status"
                                                defaultValue={changeOrder.status}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <RHFController
                                                render={({field}): JSX.Element => (
                                                    <div className={styles.sunEditorContainer}>
                                                        <p>Detailed Description</p>
                                                        <SunEditor
                                                            disable
                                                            height="100%"
                                                            setContents={CleanSunEditorHTML(field.value)}
                                                            autoFocus={false}
                                                            hideToolbar
                                                            setOptions={{
                                                                showPathLabel: false
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                                name="detailedDescription"
                                                defaultValue={changeOrder.detailedDescription ?? ''}
                                            />
                                        </Grid>
                                        <Grid item xs={5.5} md={1.3}>
                                            <RHFController
                                                render={({field}): JSX.Element => (
                                                    <TextField {...field} label="CO Contract Terms" disabled variant="standard" fullWidth/>
                                                )}
                                                name="cOContractTerms"
                                                defaultValue={changeOrder.cOContractTerms}
                                            />
                                        </Grid>
                                        <Grid item xs={5.5} md={1}>
                                            <RHFController
                                                render={({field}): JSX.Element => (
                                                    <TextField {...field} label="Work Status" disabled variant="standard" fullWidth/>
                                                )}
                                                name="workStatus"
                                                defaultValue={changeOrder.workStatus ?? ''}
                                            />
                                        </Grid>
                                        <Grid item xs={5.5} md={2.2}>
                                            <RHFController
                                                render={({field}): JSX.Element => (
                                                    <div className={styles.radioGroup}>
                                                        <p>Schedule Impact?</p>
                                                        <RadioGroup
                                                            {...field}
                                                            row
                                                            onChange={(e, v): void => radioGroupBooleanOnChange(e, v, field.onChange)}>
                                                            <FormControlLabel value control={<Radio disabled />} label="Yes" labelPlacement="top" />
                                                            <FormControlLabel
                                                                value={false}
                                                                control={<Radio disabled />}
                                                                label="No"
                                                                labelPlacement="top"
                                                            />
                                                        </RadioGroup>
                                                    </div>
                                                )}
                                                name="impactsSchedule"
                                                defaultValue={changeOrder.impactsSchedule ?? null}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <RHFController
                                                render={({field}): JSX.Element => (
                                                    <TextField {...field} label="Impact Duration (Working Days)" disabled variant="standard" fullWidth/>
                                                )}
                                                name="impactsScheduleDurationDays"
                                                defaultValue={changeOrder.impactsScheduleDurationDays ?? ''}
                                            />
                                        </Grid>
                                        <Grid item xs={5.5} md={2} lg={2}>
                                            <RHFController
                                                render={({field}): JSX.Element => (
                                                    <TextField {...field} label="Type Of Change" disabled variant="standard" fullWidth/>
                                                )}
                                                name="departmentIssuing"
                                                defaultValue={changeOrder.departmentIssuing ?? ''}
                                            />
                                        </Grid>
                                        <Grid item xs={11.9}>
                                            <RHFController
                                                defaultValue={changeOrder.disciplines.sort((a, b) =>
                                                    `${a.department}${a.disciplineName}`.localeCompare(`${b.department}${b.disciplineName}`),
                                                )}
                                                render={({field}): JSX.Element => (
                                                    <Autocomplete
                                                        {...field}
                                                        options={[]}
                                                        disabled
                                                        multiple
                                                        getOptionLabel={(o: any) => `${o.department} ${o.disciplineName}`}
                                                        isOptionEqualToValue={(o: any, value: any) => o.discipline === value.discipline}
                                                        renderInput={(params): JSX.Element => <TextField label="Affected Discplines'" {...params} variant="standard" disabled/>}
                                                        ChipProps={{
                                                            size: 'small',
                                                        }}
                                                    />
                                                )}
                                                name="disciplines"
                                            />
                                        </Grid> 

                                        {/* <Grid item xs={5.5} md={3.75} lg={2.5}>
                                            <RHFController
                                                render={({field}): JSX.Element => (
                                                    <TextField {...field} label="PM Initial Disposition" disabled variant="standard" fullWidth/>
                                                )}
                                                name="pMInitialDisposition"
                                                defaultValue={changeOrder.pMInitialDisposition ?? ''}
                                            />
                                        </Grid>
                                        <Grid item xs={5.5} md={3.75} lg={3}>
                                            <RHFController
                                                    render={({field}): JSX.Element => (
                                                        <DatePicker
                                                            label="Date of Initial Disposition"
                                                            variant="dialog"
                                                            format="MM/dd/yyyy"
                                                            autoOk
                                                            showTodayButton
                                                            clearable
                                                            disabled
                                                            onChange={()=>{}}
                                                            value={changeOrder.pMDispositionDate ?? null}
                                                            fullWidth
                                                        />
                                                    )}
                                                    name="pMDispositionDate"
                                                />
                                        </Grid>
                                        <Grid item xs={5.5} md={3.5} lg={3}>
                                            <RHFController
                                                    render={({field}): JSX.Element => (
                                                        <DatePicker
                                                            label="ROM Sent To Client Date"
                                                            variant="dialog"
                                                            format="MM/dd/yyyy"
                                                            autoOk
                                                            showTodayButton
                                                            clearable
                                                            disabled
                                                            onChange={()=>{}}
                                                            value={changeOrder.romSentToClientDate ?? null}
                                                            fullWidth
                                                        />
                                                    )}
                                                    name="romSentToClientDate"
                                                />
                                        </Grid>  */}

                                        {/* <Grid item xs={5.5} md={3.75} lg={3}>
                                            <RHFController
                                                    render={({field}): JSX.Element => (
                                                        <DatePicker
                                                            label="Date Detailed CO Issued to PM"
                                                            variant="dialog"
                                                            format="MM/dd/yyyy"
                                                            autoOk
                                                            showTodayButton
                                                            clearable
                                                            disabled
                                                            onChange={()=>{}}
                                                            value={changeOrder.detailedIssuedToPMDate ?? null}
                                                            fullWidth
                                                        />
                                                    )}
                                                    name="detailedIssuedToPMDate"
                                                />
                                        </Grid>
                                        <Grid item xs={5.5} md={3.75} lg={2.75}>
                                            <RHFController
                                                    render={({field}): JSX.Element => (
                                                        <DatePicker
                                                            label="Date Issued to Client"
                                                            variant="dialog"
                                                            format="MM/dd/yyyy"
                                                            autoOk
                                                            showTodayButton
                                                            clearable
                                                            disabled
                                                            onChange={()=>{}}
                                                            value={changeOrder.publishedDate ?? null}
                                                            fullWidth
                                                        />
                                                    )}
                                                    name="publishedDate"
                                                />
                                        </Grid>
                                        <Grid item xs={5.5} md={3.75} lg={2.5}>
                                            <RHFController
                                                    render={({field}): JSX.Element => (
                                                        <DatePicker
                                                            label="Client Response Date"
                                                            variant="dialog"
                                                            format="MM/dd/yyyy"
                                                            autoOk
                                                            showTodayButton
                                                            clearable
                                                            disabled
                                                            onChange={()=>{}}
                                                            value={changeOrder.responseDate ?? null}
                                                            fullWidth
                                                        />
                                                    )}
                                                    name="responseDate"
                                                />
                                        </Grid> */}
                                        <Grid item xs={12}>
                                            <RHFController
                                                    render={({field}): JSX.Element => (
                                                        <div className={styles.sunEditorContainer}>
                                                            <p>Comments</p>
                                                            <SunEditor
                                                                disable
                                                                height="100%"
                                                                setContents={CleanSunEditorHTML(field.value)}
                                                                autoFocus={false}
                                                                hideToolbar
                                                                setOptions={{
                                                                    showPathLabel: false
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                    name="comments"
                                                    defaultValue={changeOrder.comments ?? ''}
                                                />
                                        </Grid>
                                        <Grid item xs={12} id="requestor-attachments">
                                            <div className={styles.attachmentList}>
                                                <p>Attachments {attachmentHistory?.length ? <COAttachmentHistory attachments={attachmentHistory}/> : ''}</p>
                                                <ul>
                                                    {changeOrder?.attachments?.filter?.(c=>c.published)?.map(
                                                        (f: any): JSX.Element => (
                                                            <AttachmentListItem file={f} key={f.id} editable={false}/>
                                                        ),
                                                    )}
                                                </ul>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <div className={styles.sectionDivider}>
                                        <h5>Part II - ROM Disposition</h5>
                                    </div>
                                    <Grid container spacing={1} gap={3} p={2}>
                                    <Grid item xs={12} md={3} >
                                            <RHFController
                                                render={({field}): JSX.Element => (
                                                    <TextField {...field} label="Client Contact" variant="standard" autoComplete='off' fullWidth/>
                                                )}
                                                name="clientContact"
                                                defaultValue={changeOrder.clientContact}
                                            />
                                        </Grid>
                                        <Grid item xs={5.5} md={3.75} lg={1.75}>
                                            <RHFController
                                                render={({field}): JSX.Element => (
                                                    <TextField {...field} label="Estimated ROM Value" disabled variant="standard" fullWidth/>
                                                )}
                                                name="estimatedRomValue"
                                                defaultValue={changeOrder.estimatedRomValue ?? ''}
                                            />
                                        </Grid>
                                        <Grid item xs={5.5} md={4}>
                                            <RHFController
                                                render={({field}): JSX.Element => (
                                                    <TextField {...field} label="Client Disposition to ROM" disabled variant="standard" fullWidth/>
                                                )}
                                                name="clientDisposition"
                                                defaultValue={changeOrder.clientDisposition ?? ''}
                                            />
                                        </Grid>
                                        <Grid item xs={5.5} md={2} lg={2}>
                                            <RHFController
                                                    render={({field}): JSX.Element => (
                                                        <DatePicker
                                                            label="Date of Disposition"
                                                            variant="dialog"
                                                            format="MM/dd/yyyy"
                                                            autoOk
                                                            showTodayButton
                                                            clearable
                                                            disabled
                                                            onChange={()=>{}}
                                                            value={changeOrder.clientDispositionDate ?? null}
                                                            fullWidth
                                                        />
                                                    )}
                                                    name="clientDispositionDate"
                                                />
                                        </Grid>
                                        {
                                            !changeOrder.pMApproveFinalDate &&
                                            <Grid item xs={12}>
                                                <COActions changeOrder={changeOrder} handleAction={handleRomAction} actions={romActions} history={<></>} title="ROM Disposition"/> 
                                                <RHFController
                                                    render={(): JSX.Element => (
                                                        <></>
                                                    )}
                                                    name="romActionStatus"
                                                />
                                                <RHFController
                                                    render={(): JSX.Element => (
                                                        <></>
                                                    )}
                                                    defaultValue=""
                                                    name="romActionComment"
                                                />
                                                <RHFController
                                                    render={(): JSX.Element => (
                                                        <></>
                                                    )}
                                                    defaultValue=""
                                                    name="romDispositionInCppComment"
                                                />
                                                <RHFController
                                                    render={(): JSX.Element => (
                                                        <></>
                                                    )}
                                                    name="romDispositionInCppBy"
                                                />
                                                <RHFController
                                                    render={(): JSX.Element => (
                                                        <></>
                                                    )}
                                                    defaultValue=""
                                                    name="romDispositionInCppDate"
                                                />
                                                <RHFController
                                                    render={(): JSX.Element => (
                                                        <></>
                                                    )}
                                                    defaultValue=""
                                                    name="romDispositionInCpp"
                                                />
                                                <RHFController
                                                    render={(): JSX.Element => (
                                                        <></>
                                                    )}
                                                    defaultValue=""
                                                    name="clientApproveROMDate"
                                                />
                                            </Grid>
                                        }
                                    </Grid>
                                    {
                                        changeOrder?.pMApproveFinalDate &&
                                        <>
                                            <div className={styles.sectionDivider}>
                                                <h5>Part III - Estimate Detail</h5>
                                            </div>
                                            <Grid container spacing={0} p={2} justifyContent='center' alignContent='center' direction="column">
                                                <Grid item xs={12} >
                                                    {getCostTable()}
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1} gap={3} p={1}>
                                            <div className={styles.sectionDivider}>
                                                <h5>Part IV - Final Estimate Response</h5>
                                            </div>
                                            </Grid>
                                            <Grid container spacing={1} gap={3} p={2}>
                                                <Grid item xs={6} md={3.75}>
                                                    <RHFController
                                                        render={({field}): JSX.Element => (
                                                            <TextField
                                                                {...field}
                                                                label="Approved By"
                                                                required={finalActionStatus === 'Approved'}
                                                                className={finalActionStatus === 'Approved' && styles.required}
                                                                value={field.value ?? ''}
                                                                variant="standard"
                                                                fullWidth
                                                            />
                                                        )}
                                                        name="approvedBy"
                                                        defaultValue={changeOrder.approvedBy ?? ''}
                                                        rules={{
                                                            required: finalActionStatus === 'Approved' && 'Name is required' as Message,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} md={3.75}>
                                                    <RHFController
                                                        render={({field}): JSX.Element => (
                                                            <DatePicker
                                                                label="Approval Date"
                                                                variant="dialog"
                                                                format="MM/dd/yyyy"
                                                                autoOk
                                                                showTodayButton
                                                                clearable
                                                                required={finalActionStatus === 'Approved'}
                                                                className={finalActionStatus === 'Approved' && styles.required}
                                                                onChange={field.onChange}
                                                                value={field.value}
                                                                fullWidth
                                                            />
                                                        )}
                                                        name="approvedDate"
                                                        defaultValue={changeOrder.approvedDate ?? null}
                                                        rules={{
                                                            required: finalActionStatus === 'Approved' && 'Date is required' as Message,
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={0} p={2}>
                                                <Grid item xs={12}>
                                                    <RHFController
                                                        render={({field}): JSX.Element => <SignatureControl field={field} title="Approver Signature" required={finalActionStatus === 'Approved'}/>}
                                                        name="approvedBySignature"
                                                        defaultValue={changeOrder.approvedBySignature ?? null}
                                                        rules={{
                                                            required: finalActionStatus === 'Approved' && 'Signature is required' as Message,
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <COActions changeOrder={changeOrder} handleAction={handleFinalAction} actions={finalActions} history={<COWorkflowHistory workflow={workflowObj}/>} title="Final Disposition"/> 
                                            <RHFController
                                                render={(): JSX.Element => (
                                                    <></>
                                                )}
                                                name="finalActionStatus"
                                            />
                                            <RHFController
                                                render={(): JSX.Element => (
                                                    <></>
                                                )}
                                                defaultValue=""
                                                name="finalActionComment"
                                            />
                                        </>
                                    }
                                </>
                            )}
                        </form>
                    </MuiPickersUtilsProvider>
                </FormProvider>
            </DialogContent>
            <DialogActions>
                <Button className="print-button" onClick={handlePrintPreview}>
                    Print
                </Button>
                <Button className="close-button" onClick={handleClose}>
                    Close
                </Button>
                <BusyButton className="save-button" onClick={onSubmit} busy={busy}>
                    Save
                </BusyButton>
            </DialogActions>
            <p id='imgToPng' style={{fontFamily:'cursive',fontSize:22,padding: 20, display:'none',position:'absolute', zIndex: -10, width: 400, color: '#eb2f06', textAlign: 'center'}}/>
        </Dialog>
    );
}

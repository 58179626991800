import startCase from 'lodash/startCase';
import {useEffect, useMemo} from 'react';
import HealthReportCardContext from './HealthReportCardContext';
import HealthCriteriaTable from '~/components/subsite/health/HealthCriteriaTable';
import HSEScore from '~/components/subsite/health/HSEScore';
import {useStoreState, useStoreActions} from '~/store/storeHooks';
import styles from '~/styles/HealthReportCard.module.scss';

interface HealthReportCardProps {
    category: string;
}

export default function HealthReportCard({category}: HealthReportCardProps): JSX.Element {
    const project = useStoreState((state) => state.subsite.project);
    const standardHealthCriteria = useStoreState((state) => state.project.standardHealthCriteria);
    const getStandardHealthCriteria = useStoreActions((actions) => actions.project.getStandardHealthCriteria);

    useEffect(() => {
        if (standardHealthCriteria.length === 0) {
            getStandardHealthCriteria();
        }
    }, [getStandardHealthCriteria, standardHealthCriteria.length]);

    const healthReportCardContext = useMemo(
        () => ({
            healthCriteria:
                project && project.healthCriteria.length > 0 ? project.healthCriteria : standardHealthCriteria.filter((c) => c.category === category),
        }),
        [category, project, standardHealthCriteria],
    );

    return (
        <div id="health-report-card-root" className={styles.root}>
            <h4 className={styles.healthReportCardTitle}>{`${startCase(category)} Health`}</h4>
            <div className={styles.card}>
                <HealthReportCardContext.Provider value={healthReportCardContext}>
                    <HealthCriteriaTable />
                    {category === 'HSE' && <HSEScore />}
                </HealthReportCardContext.Provider>
            </div>
        </div>
    );
}

import { useMediaQuery } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import * as React from 'react';
import { useMemo, useState } from 'react';
import AccountNav from './AccountNav';
import ContactNav from './ContactNav';
import ResourcesNav from './ResourcesNav';
import {useStoreState} from '~/store/storeHooks';
import styles from '~/styles/UserMenu.module.scss';


export default function UserMenu() {
    const currentUser = useStoreState((state) => state.shared.currentUser);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const largeWidth = useMediaQuery('(min-width: 675px)');
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const initials = useMemo(() => {
        if (typeof currentUser.initials === 'string') {
            return currentUser.initials.toUpperCase();
        }
        if (currentUser.firstName && currentUser.lastName) {
            return `${currentUser.firstName.charAt(0)}${currentUser.lastName.charAt(0)}`.toUpperCase();
        }
        return null;
    }, [currentUser.firstName, currentUser.lastName, currentUser.initials]);

    return (
        <div className={styles.root}>
            <Box className={styles.menuButton}>
                <Tooltip title="">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        aria-controls={open ? 'user-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                    <Avatar className={styles.userAvatar}>{initials}</Avatar>
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="user-menu"
                open={open}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: largeWidth ? 16 : 8,
                            width: 10,
                            height: 10,
                            bgcolor: '#212121',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem>
                    <ContactNav handleClose={handleClose}/>     
                </MenuItem>
                <MenuItem >
                    <ResourcesNav handleClose={handleClose}/>
                </MenuItem>
                <MenuItem >
                    <AccountNav handleClose={handleClose}/>
                </MenuItem>
            </Menu>
        </div>
    );
}

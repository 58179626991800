import {action, Action, ActionOn, actionOn, computed, Computed, thunk, Thunk} from 'easy-peasy';
import {axios} from '~/api';
import {AxiosTryCatchBlock} from '~/helpers';
import {DocumentCategory, DocumentStatus, Document, StoreModel, DocumentNewRev, NewDocument, UploadedFile} from '~/types';

const defaultTimeout = 30000;
export interface DocumentModel {
    documents: Document[];
    documentSearchText: {[key: string]:string};
    documentSubset: Document[];
    currentDocument: Document;
    categories: DocumentCategory[];
    statuses: DocumentStatus[];
    setDocumentSearchText: Action<DocumentModel, {key: string, searchText: string}>;
    setDocumentInitialData: ActionOn<DocumentModel, StoreModel>;
    getProjectDocuments: Thunk<DocumentModel, number>;
    setDocuments: Action<DocumentModel, Document[]>;
    setSearchResults: Action<DocumentModel, Document[]>;
    setCurrentDocument: Action<DocumentModel, Document>;

    postDocument: Thunk<DocumentModel, NewDocument>;
    updateDocument: Thunk<DocumentModel, Document | DocumentNewRev>;
    deleteDocument: Thunk<DocumentModel, Document>;
    documentCategoryForTitle: Computed<DocumentModel, (title: string) => DocumentCategory>;
    postDocumentAttachments: Thunk<DocumentModel, {projectId: number; docId: number; attachments: UploadedFile[]}>;
    deleteDocumentAttachment: Thunk<DocumentModel, {projectId: number; docId: number; id: number}>;
}

const documentModel: DocumentModel = {
    documents: [],
    documentSearchText: {},
    documentSubset: [],
    categories: [],
    statuses: [],
    currentDocument: null,
    setCurrentDocument: action((draftState, document) => {
        draftState.currentDocument = document;
    }),
    setDocumentSearchText: action((draftState, {key,searchText}) => {
        draftState.documentSearchText[key] = searchText;
    }),
    setDocumentInitialData: actionOn(
        (a, storeActions) => storeActions.subsite.getSubsiteInitialData.successType,
        (draftState, target) => {
            const {documentCategory, documentStatus} = target.result;
            if (documentCategory && documentCategory.length > 0) {
                draftState.categories = documentCategory;
            } else {
                draftState.categories = [];
            }
            if (documentStatus && documentStatus.length > 0) {
                draftState.statuses = documentStatus;
            } else {
                draftState.statuses = [];
            }
        },
    ),
    setDocuments: action((draftState, documents) => {
        draftState.documents = documents;
        draftState.documentSubset = documents;
    }),
    getProjectDocuments: thunk((actions, projectId) =>
        AxiosTryCatchBlock(async () => {
            const {data} = await axios.get(`/ProjectDocuments?projectId=${projectId}`, {
                timeout: defaultTimeout,
            });
            if (data && data.length > 0) {
                actions.setDocuments(data);
            } else {
                actions.setDocuments([]);
            }
            return data;
        }),
    ),
    setSearchResults: action((draftState, searchResults) => {
        draftState.documentSubset = searchResults;
    }),
    postDocument: thunk(async (actions, document) => {
        try {
            const {data} = await axios.post('/PostDocument', document, {});
            const {documents} = data;
            if (documents) {
                actions.setDocuments(documents);
            }
            return data;
        } catch (e) {
            return {
                result: 'error',
                message: 'Document post request failed - Please contact tech support',
            };
        }
    }),
    updateDocument: thunk(async (actions, document) => {
        try {
            const {data} = await axios.post('/UpdateDocument', document, {});
            const {documents} = data;
            if (documents) {
                actions.setDocuments(documents);
            }
            return data;
        } catch (e) {
            return {
                result: 'error',
                message: 'Document update request failed - Please contact tech support',
            };
        }
    }),
    deleteDocument: thunk(async (actions, document) => {
        try {
            const {data} = await axios.post('/DeleteDocument', document, {
                timeout: defaultTimeout,
            });
            const {documents} = data;
            if (documents && documents.length > 0) {
                actions.setDocuments(documents);
            }
            return data;
        } catch (e) {
            return {
                result: 'error',
                message: 'Request to delete document failed - Please contact tech support',
            };
        }
    }),
    documentCategoryForTitle: computed((state) => (title: string): DocumentCategory => {
        const lowered = title.toLowerCase();
        return state.categories.find((o) => o.title && o.title.toLocaleLowerCase() === lowered) ?? null;
    }),
    postDocumentAttachments: thunk(async (actions, payload) => {
        try {
            const {data} = await axios.post('/PostDocumentAttachments', payload, {
                timeout: defaultTimeout,
            });
            const {documents} = data;
            if (documents && documents.length > 0) {
                actions.setDocuments(documents);
            }
            return data;
        } catch (e) {
            return {
                result: 'error',
                message: (e as unknown as Error).message,
            };
        }
    }),
    deleteDocumentAttachment: thunk(async (actions, payload) => {
        try {
            const {data} = await axios.post('/DeleteDocumentAttachment', payload, {
                timeout: defaultTimeout,
            });
            const {documents} = data;
            if (documents && documents.length > 0) {
                actions.setDocuments(documents);
            }
            return data;
        } catch (e) {
            return {
                result: 'error',
                message: (e as unknown as Error).message,
            };
        }
    }),
};

export default documentModel;

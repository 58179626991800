import {useContext} from 'react';
import ScrollToDiv from '~/components/generics/ScrollToDiv';
import SwipeStack from '~/components/generics/SwipeStack';
import CorrespondenceStackContext from '~/components/subsite/summary/correspondenceStack/CorrespondenceStackContext';
import NoOpenItemCard from '~/components/subsite/summary/correspondenceStack/NoOpenItemCard';
import HoldCard from '~/components/subsite/summary/HoldCard';
import {useStoreState} from '~/store/storeHooks';

interface HoldStackProps {
    projectLabel?: boolean;
}

HoldStack.defaultProps = {
    projectLabel: false,
};

export default function HoldStack({projectLabel}: HoldStackProps): JSX.Element {
    const {holds} = useStoreState((state) => state.correspondence);
    const {showHold} = useContext(CorrespondenceStackContext);

    if (!showHold) {
        return null;
    }

    return (
        <>
            <ScrollToDiv id="hold-stack-scroll-to"/>
            {
                holds.length === 0
                ?
                <NoOpenItemCard type="holds" />
                :
                <SwipeStack id="hold-stack-root">
                    {[...holds.filter((o)=>o.status !== 'Closed' && !o.descendent).map((hd, i) => <HoldCard index={i} hold={hd} key={`hold:${hd.id}`} projectLabel={projectLabel} />)]}
                </SwipeStack>
            }
        </>
    );
}

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {Dialog, Tab, Zoom} from '@mui/material';
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import DocumentComments from '~/components/subsite/documentTable/DocumentComments';
import DocumentHistory from '~/components/subsite/documentTable/DocumentHistory';
import DocumentInfoContent from '~/components/subsite/documentTable/DocumentInfoContent';
import styles from '~/styles/DocumentDialog.module.scss';

let scrollPos = 0;
interface EditDocumentDialogProps {
    open: boolean;
    handleClose: () => void;
}

export default function EditDocumentDialog({open, handleClose}: EditDocumentDialogProps): JSX.Element {
    const [tabValue, setTableValue] = React.useState<string>('1'); 
    const [searchParams] = useSearchParams();
    const chat = searchParams.get('chat');


    const handleTabChange = (e: React.ChangeEvent<{}>, newValue: string): void => {
        setTableValue(newValue);
    };

    // Stop scrolling when the action item dialog is open
    React.useEffect(() => {
        scrollPos = window.pageYOffset;
        document.body.style.position = 'fixed';
        document.body.style.top = `-${scrollPos}px`;
        setTimeout(()=>{document.body.style.overflow = 'visible'},0);
        
        return ()=> {
            document.body.style.position = '';
            document.body.style.top = '';
            window.scrollTo(0,scrollPos);
        }
    }, []);

    React.useEffect(()=>{
        if (chat) {
            setTimeout(()=>{setTableValue("2")});
        }
    },[chat]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="md"
            fullWidth
            id="edit-document-dialog-root"
            className={styles.root}
            TransitionComponent={Zoom}
        >
            <TabContext value={tabValue}>
                <TabPanel value="1">
                    <DocumentInfoContent />
                </TabPanel>
                <TabPanel value="2">
                    <DocumentComments />
                </TabPanel>
                <TabPanel value="3">
                    <DocumentHistory />
                </TabPanel>
                <TabList
                    orientation="vertical"
                    onChange={handleTabChange}
                    TabIndicatorProps={{
                        style: {
                            display: 'none',
                        },
                    }}
                >
                    <Tab icon={<FontAwesomeIcon icon={['fas', 'info']} color="#FFF" size="lg" />} value="1" />
                    <Tab icon={<FontAwesomeIcon icon={['fas', 'comment']} color="#FFF" size="lg" />} value="2" />
                    <Tab icon={<FontAwesomeIcon icon={['far', 'history']} color="#FFF" size="lg" />} value="3" />
                </TabList>
            </TabContext>
        </Dialog>
    );
}

import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, DatePicker} from '@material-ui/pickers';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Zoom} from '@mui/material';
import {useCallback, useEffect, useMemo} from 'react';
import {useForm, FormProvider} from 'react-hook-form';
import {useParams, useSearchParams} from 'react-router-dom';
import SunEditor from 'suneditor-react';
import NoDataPlaceholder from '~/components/generics/NoDataPlaceholder';
import RHFController from '~/components/generics/RHFController';
import {CleanSunEditorHTML, DisableMobileZoom, EnableMobileZoom} from '~/helpers';
import {useStoreActions, useStoreState} from '~/store/storeHooks';
import styles from '~/styles/CorrespondenceDialog.module.scss';
import {Hold} from '~/types';

let scrollPos = 0;

export default function HoldDialog(): JSX.Element {
    const {projectId} = useParams<{projectId: string}>();
    const [searchParams, setSearchParams] = useSearchParams();
    const holds = useStoreState((state) => state.correspondence.holds);
    const getProjectTeam = useStoreActions((actions) => actions.subsite.getProjectTeam);

    const holdId = searchParams.get('hold');

    const hold = useMemo(() => holds.find((o) => holdId && o.id === +holdId), [holdId, holds]);

    const methods = useForm<Hold>({
        defaultValues: hold ?? {},
    });
    const {reset} = methods;

    // Stop scrolling when the action item dialog is open
    useEffect(() => {
        if (holdId) {
            scrollPos = window.pageYOffset;
            document.body.style.position = 'fixed';
            document.body.style.top = `-${scrollPos}px`;
            setTimeout(()=>{document.body.style.overflow = 'visible'},0);
        } else {
            document.body.style.position = '';
            document.body.style.top = '';
            window.scrollTo(0,scrollPos);
        }
    }, [holdId]);

    // Get project team
    useEffect(() => {
        if (projectId) {
            (async function fetchData(): Promise<void> {
                await getProjectTeam(parseInt(projectId, 10));
            })();
        }
    }, [getProjectTeam, projectId]);

    // Reset RHF state when tdr changes
    useEffect(() => {
        reset(hold);
    }, [hold, reset]);

    // Disable zooming for this dialog, it's annoying whenever selecting any input
    useEffect(() => {
        DisableMobileZoom();
        return (): void => {
            EnableMobileZoom();
        };
    }, []);


    const handleClose = useCallback(() => {
        setSearchParams({});
    }, [setSearchParams]);

    return (
        <Dialog open={holdId != null} onClose={handleClose} id="hold-dialog-root" className={styles.root} TransitionComponent={Zoom}>
            <DialogTitle>
                <div className={styles.title}>
                    <h3>Hold</h3>
                </div>
            </DialogTitle>
            <DialogContent>
                <FormProvider {...methods}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <form>
                            {hold == null ? (
                                <NoDataPlaceholder title="No Hold Data Available" />
                            ) : (
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <RHFController
                                            render={({field}): JSX.Element => <TextField {...field} label="Requestor" disabled variant="standard" />}
                                            name="requestor"
                                            defaultValue={hold.requestor ?? ''}
                                            rules={{
                                                required: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <RHFController
                                            render={({field}): JSX.Element => <TextField {...field} label="Status" disabled variant="standard" />}
                                            name="status"
                                            defaultValue={hold.status ?? ''}
                                            rules={{
                                                required: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <RHFController
                                            render={({field}): JSX.Element => <TextField {...field} label="Number" disabled variant="standard" />}
                                            name="number"
                                            defaultValue={hold.number ?? ''}
                                            rules={{
                                                required: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <DatePicker
                                                    label="Issued"
                                                    variant="dialog"
                                                    format="MM/dd/yyyy"
                                                    autoOk
                                                    showTodayButton
                                                    clearable
                                                    onChange={field.onChange}
                                                    value={field.value}
                                                    disabled
                                                />
                                            )}
                                            name="created"
                                            defaultValue={hold.created ?? null}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <div className={styles.sunEditorContainer}>
                                                    <p>Hold Description</p>
                                                    <SunEditor
                                                        disable
                                                        height="100%"
                                                        setContents={CleanSunEditorHTML(field.value)}
                                                        autoFocus={false}
                                                        hideToolbar
                                                        setOptions={{
                                                            showPathLabel: false,
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            name="description"
                                            defaultValue={hold.description ?? ''}
                                            rules={{required: true}}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <DatePicker
                                                    label="Due Date"
                                                    variant="dialog"
                                                    format="MM/dd/yyyy"
                                                    autoOk
                                                    showTodayButton
                                                    clearable
                                                    onChange={field.onChange}
                                                    value={field.value}
                                                    disabled
                                                />
                                            )}
                                            name="dueDate"
                                            defaultValue={hold.dueDate ?? null}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <DatePicker
                                                    label="Completed Date"
                                                    variant="dialog"
                                                    format="MM/dd/yyyy"
                                                    autoOk
                                                    showTodayButton
                                                    clearable
                                                    onChange={field.onChange}
                                                    value={field.value}
                                                    disabled
                                                />
                                            )}
                                            name="completed"
                                            defaultValue={hold.completed ?? null}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <div className={styles.sunEditorContainer}>
                                                    <p>Comments</p>
                                                    <SunEditor
                                                        disable
                                                        height="100%"
                                                        setContents={CleanSunEditorHTML(field.value)}
                                                        autoFocus={false}
                                                        hideToolbar
                                                        onChange={field.onChange}
                                                        setOptions={{
                                                            showPathLabel: false,
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            name="comments"
                                            defaultValue={hold.comments ?? ''}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </form>
                    </MuiPickersUtilsProvider>
                </FormProvider>
            </DialogContent>
            <DialogActions>
                <Button className="close-button" onClick={handleClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import numeral from 'numeral';
import {useContext} from 'react';
import {Link} from 'react-router-dom';
import ProjectContext from '~/components/dashboard/project-log/ProjectContext';
import NoDataPlaceholder from '~/components/generics/NoDataPlaceholder';
import InvoiceStatusBarChart from '~/components/visualizations/InvoiceStatusBarChart';
import {useStoreState} from '~/store/storeHooks';
import styles from '~/styles/ProjectStack.module.scss';

export default function CostCard(): JSX.Element {
    const {project} = useContext(ProjectContext);
    const foundationJobs = useStoreState((state) => state.accounting.foundationJobs);
    const {originalContract, totalApprovedIncomeAdj} = foundationJobs.find((o) => o.id === project.foundationCode) || {};
    const href: string = `/project/${project.id}/summary`;
    const samePage: boolean = href === window.location.pathname;
    return (
        <div className={styles.costCardRoot}>
            <h3 className={styles.title}>
            {
                    samePage
                    ?
                    `${project.code} Cost`
                    :
                    <Link to={href}>{`${project.code} Cost`}</Link>
                }
                
            </h3>
            <div className={styles.costCardContent}>
                {project.foundationCode && originalContract ? (
                    <>
                        <div className={styles.contractValueContainer}>
                            <div className={styles.valueGroup}>
                                <h1>{`${numeral(originalContract).format(originalContract > 1000000 ? '$0,0.0a' : '$0,0a')}`}</h1>
                                <p>Original Contract</p>
                            </div>
                            <div className={styles.valueGroup}>
                                <h1>
                                    <FontAwesomeIcon
                                        icon={['fad', 'caret-up']}
                                        size="lg"
                                        style={{color: totalApprovedIncomeAdj > 0 ? 'chartreuse' : 'tomato', marginRight: 8}}
                                    />
                                    {`${
                                        totalApprovedIncomeAdj
                                            ? numeral(totalApprovedIncomeAdj).format(totalApprovedIncomeAdj > 1000000 ? '$0,0.0a' : '$0,0a')
                                            : 0
                                    }`}
                                </h1>
                                <p>Approved Adjustment</p>
                            </div>
                        </div>
                        <InvoiceStatusBarChart foundationCode={project.foundationCode} />
                    </>
                ) : (
                    <NoDataPlaceholder />
                )}
            </div>
        </div>
    );
}

import {MenuItem} from '@mui/material';
import {GridCsvExportMenuItem, GridPrintExportMenuItem, GridToolbarExportContainer} from '@mui/x-data-grid-pro';
import {useStoreActions} from '~/store/storeHooks';

export function DataGridProCustomExport(props: any) {
    const {csvOptions, excelOptions} = props;

    return (
        <GridToolbarExportContainer {...props}>
            <GridCsvExportMenuItem options={csvOptions} />
            <CustomExcelExportMenuItem excelOptions={excelOptions} />
            <GridPrintExportMenuItem />
        </GridToolbarExportContainer>
    );
}

export function CustomExcelExportMenuItem(props: any) {
    const getActionItems = useStoreActions((actions) => actions.correspondence.getActionItemsExcelExport);
    const getClientNeeds = useStoreActions((actions) => actions.correspondence.getClientNeedsExcelExport);
    const getRFIs = useStoreActions((actions) => actions.correspondence.getRFIsExcelExport);
    const getTQs = useStoreActions((actions) => actions.correspondence.getTQsExcelExport);

    switch (props.excelOptions.tableName) {
        case 'actionItems':
            return (
                <MenuItem onClick={() => getActionItems({projectId: props.excelOptions.projectId, projectCode: props.excelOptions.projectCode})}>
                    Action Items Report
                </MenuItem>
            );
        case 'clientNeeds':
            return (
                <MenuItem onClick={() => getClientNeeds({projectId: props.excelOptions.projectId, projectCode: props.excelOptions.projectCode})}>
                    Needs Report
                </MenuItem>
            );
        case 'rfis':
            return (
                <MenuItem onClick={() => getRFIs({projectId: props.excelOptions.projectId, projectCode: props.excelOptions.projectCode})}>
                    RFIs Report
                </MenuItem>
            );
        case 'tqs':
            return (
                <MenuItem onClick={() => getTQs({projectId: props.excelOptions.projectId, projectCode: props.excelOptions.projectCode})}>
                    TQs Report
                </MenuItem>
            );
        default:
            return null;
    }

    // return <MenuItem>Download as Excel</MenuItem>;
}

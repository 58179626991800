import {library} from '@fortawesome/fontawesome-svg-core';
import {
    faCaretUp,
    faCaretDown,
    faChevronDoubleUp,
    faCloudUpload,
    faCommentsAlt,
    faDownload,
    faFileAlt,
    faFileArchive,
    faFileCsv,
    faFileExcel,
    faFileImage,
    faFilePdf,
    faFileWord,
    faFlag,
    faLaughSquint,
    faSurprise,
    faFrownOpen,
    faAngry,
    faList,
    faSave,
    faTrash,
} from '@fortawesome/pro-duotone-svg-icons';
import {
    faChevronDown,
    faPlus,
    faSearch,
    faTimes,
    faPrint,
    faLockAlt,
    faEllipsisV,
    faCompress,
    faExpand,
    faInfoCircle,
    faMountain,
} from '@fortawesome/pro-light-svg-icons';
import {faCheck, faHistory, faBars, faFlagAlt, faFrown, faPaperPlane, faSmile} from '@fortawesome/pro-regular-svg-icons';
import {faCircle, faComment, faInfo, faThumbsUp, faHeart} from '@fortawesome/pro-solid-svg-icons';
import {LicenseInfo} from '@mui/x-data-grid-pro';
import {once} from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import {setupAnalytics} from './api/analytics';

const SetupExternal = once((): void => {
    numeral.register('locale', 'us', {
        delimiters: {
            thousands: ' ',
            decimal: '.',
        },
        abbreviations: {
            thousand: 'K',
            million: 'M',
            billion: 'B',
            trillion: 'T',
        },
        ordinal(number: number) {
            return number === 1 ? 'er' : 'ème';
        },
        currency: {
            symbol: '$',
        },
    });

    numeral.locale('us');

    moment.updateLocale('en', {
        calendar: {
            lastDay: '[Yesterday at] LT',
            sameDay: 'LT',
            nextDay: '[Tomorrow at] LT',
            lastWeek: '[Last] dddd [at] LT',
            nextWeek: 'dddd [at] LT',
            sameElse: 'L',
        },
    });

    library.add(
        faAngry,
        faBars,
        faCaretDown,
        faCaretUp,
        faCheck,
        faChevronDoubleUp,
        faChevronDown,
        faCircle,
        faCloudUpload,
        faComment,
        faCommentsAlt,
        faCompress,
        faDownload,
        faEllipsisV,
        faExpand,
        faFileAlt,
        faFileArchive,
        faFileCsv,
        faFileExcel,
        faFileImage,
        faFilePdf,
        faFileWord,
        faFlag,
        faFlagAlt,
        faFrown,
        faFrownOpen,
        faHeart,
        faHistory,
        faInfo,
        faInfoCircle,
        faLaughSquint,
        faList,
        faLockAlt,
        faMountain,
        faPaperPlane,
        faPlus,
        faPrint,
        faSave,
        faSearch,
        faSmile,
        faSurprise,
        faThumbsUp,
        faTimes,
        faTrash,
    );

    LicenseInfo.setLicenseKey(`${process.env.REACT_APP_MUI_X_PRO_KEY}`);
    
    setupAnalytics();
});

export default SetupExternal;

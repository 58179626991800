import {Button, Checkbox, Dialog, DialogActions, DialogContent, FormControlLabel, TextField, Zoom} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import {useSnackbar} from 'notistack';
import {useCallback, useEffect, useMemo, useState} from 'react';
import * as React from 'react';
import {FormProvider, Message, useForm} from 'react-hook-form';
import {useParams} from 'react-router-dom';
import { trackEvent } from '~/api';
import BusyButton from '~/components/generics/BusyButton';
import RHFController from '~/components/generics/RHFController';
import LoadingComponent from '~/components/visualizations/LoadingComponent';
import {DisableMobileZoom, EnableMobileZoom, GetOptionLabel, IsOptionEqualToValue} from '~/helpers';
import {useStoreActions, useStoreState} from '~/store/storeHooks';
import styles from '~/styles/ProjectTeamMemberDialog.module.scss';
import {ClientEmployee, NewProjectTeamMember, Role} from '~/types';


interface NewTeamMemberDialogProps {
    open: boolean;
    handleClose: () => void;
}

export default function NewTeamMemberDialog({open, handleClose}: NewTeamMemberDialogProps): JSX.Element {
    const {projectId} = useParams<{projectId: string}>();
    const getClientEmployees = useStoreActions((actions) => actions.subsite.getClientEmployees);
    const clientEmployees = useStoreState((state) => state.subsite.clientEmployees);
    const getProjectTeamRoles = useStoreActions((actions) => actions.subsite.getProjectTeamRoles);
    const teamRoles = useStoreState((state) => state.subsite.projectTeamRoles);
    const postTeamMember = useStoreActions((actions) => actions.subsite.postTeamMember);
    const [isNewPerson, setIsNewPerson] = useState(false);

    const DEFAULT_NEW_TEAM_MEMBER: NewProjectTeamMember = useMemo(
        () => ({
            clientEmployee: null,
            clientEmployeeId: null,
            projectId: parseInt(projectId, 10),
            poc: false,
            role: null,
            roleId: null,
            newContact: {
                id: -1,
                firstName: '',
                lastName: '',
                nickname: '',
                fullName: '',
                email: '',
                workPhone: '',
                title: '',
            },
        }),
        [projectId],
    );

    const methods = useForm<NewProjectTeamMember>({
        defaultValues: DEFAULT_NEW_TEAM_MEMBER,
    });
    const {setValue, handleSubmit, reset} = methods;
    const {enqueueSnackbar} = useSnackbar();
    const [busy, setBusy] = useState(false);

    useEffect(() => {
        setBusy(true);
        (async function dataRequest(): Promise<void> {
            await getClientEmployees(parseInt(projectId, 10));
            await getProjectTeamRoles();
        })();
        setBusy(false);

        return (): void => {
            setBusy(false);
        };
    }, [getClientEmployees, getProjectTeamRoles, projectId]);

    const handleCancel = React.useCallback(() => {
        reset(DEFAULT_NEW_TEAM_MEMBER);
        setIsNewPerson(false);
        handleClose();
    }, [reset, setIsNewPerson, handleClose, DEFAULT_NEW_TEAM_MEMBER]);

    // Disable zooming for this dialog, it's annoying whenever selecting any input
    useEffect(() => {
        DisableMobileZoom();
        return (): void => {
            EnableMobileZoom();
        };
    }, []);

    const handleClientEmployeeChange = useCallback(
        (e: React.ChangeEvent<{}>, value: string | ClientEmployee): void => {
            let selectedClientEmployee = value;
            if (typeof selectedClientEmployee === 'string') {
                selectedClientEmployee = clientEmployees.find((o) => o.person.fullName === selectedClientEmployee);
            }
            if (selectedClientEmployee) {
                setValue('clientEmployee', selectedClientEmployee);
                setValue('clientEmployeeId', selectedClientEmployee.id);
            }
        },
        [clientEmployees, setValue],
    );

    const handleRoleChange = useCallback(
        (e: React.ChangeEvent<{}>, value: string | Role): void => {
            let newRole = value;
            if (typeof newRole === 'string') {
                newRole = teamRoles.find((o) => o.title === newRole);
            }
            if (newRole) {
                setValue('role', newRole);
                setValue('roleId', newRole.id);
            }
        },
        [teamRoles, setValue],
    );

    const onSubmit = handleSubmit(async (data: NewProjectTeamMember): Promise<void> => {
        setBusy(true);
        trackEvent('add team member', {
            name: `${data.newContact.firstName} ${data.newContact.lastName}`, 
            email: isNewPerson ? data.newContact.email : data.clientEmployee.person.email
        });
        
        const {result, message} = await postTeamMember({
            ...data,
            email: isNewPerson ? data.newContact.email : data.clientEmployee.person.email,
            firstName: data.newContact.firstName,
            lastName: data.newContact.lastName,
            nickname: data.newContact.nickname,
            workPhone: data.newContact.workPhone,
            title: data.newContact.title,
        });
        setBusy(false);
        enqueueSnackbar(message, {variant: result});
        if (result !== 'error') {
            handleCancel();
        }
    });

    const clickNewPerson = React.useCallback(
        (e: React.ChangeEvent<{}>, value: boolean): void => {
            setIsNewPerson(value);
            setValue('clientEmployee', null);
            setValue('clientEmployeeId', null);
        },
        [setIsNewPerson, setValue],
    );

    if (busy) {
        return <LoadingComponent title="Loading New Team Member Form" />;
    }

    const getEmployeeLabel= (option: any)=> `${option?.person?.formalName} - ${option?.title}`;

    return (
        <Dialog
            open={open}
            onClose={handleCancel}
            maxWidth="md"
            fullWidth
            id="new-team-member-dialog-root"
            className={styles.root}
            TransitionComponent={Zoom}
        >
            <FormProvider {...methods}>
                <form onSubmit={onSubmit}>
                    <div id="new-team-member-dialog-title" className={styles.header}>
                        <h4>Add Team Member</h4>
                        <p>* required field</p>
                    </div>
                    <DialogContent id="new-team-member-dialog-content">
                        {!isNewPerson && (
                            <RHFController
                                render={({field}): JSX.Element => (
                                    <Autocomplete
                                        {...field}
                                        options={clientEmployees}
                                        getOptionLabel={getEmployeeLabel}
                                        isOptionEqualToValue={IsOptionEqualToValue}
                                        renderInput={(params): JSX.Element => (
                                            <TextField label="Employee" fullWidth {...params} variant="standard" required/>
                                        )}
                                        onChange={handleClientEmployeeChange}
                                    />
                                )}
                                name="clientEmployee"
                                rules={{
                                    required: 'Employee is required' as Message,
                                }}
                            />
                        )}
                        <RHFController
                            render={({field}): JSX.Element => (
                                <Autocomplete
                                    {...field}
                                    options={teamRoles}
                                    getOptionLabel={GetOptionLabel}
                                    isOptionEqualToValue={IsOptionEqualToValue}
                                    renderInput={(params): JSX.Element => <TextField label="Role" fullWidth {...params} variant="standard" required/>}
                                    onChange={handleRoleChange}
                                />
                            )}
                            name="role"
                            rules={{
                                // required: 'Project role is required' as Message,
                                required: true,
                            }}
                        />
                        <FormControlLabel
                            label="Add new contact?"
                            labelPlacement="start"
                            onChange={clickNewPerson}
                            control={<Checkbox checked={isNewPerson} disabled={busy} />}
                            className={styles.newContact}
                        />
                        {isNewPerson && (
                            <>
                                <RHFController
                                    render={({field}): JSX.Element => <TextField {...field} fullWidth label="First Name" variant="standard" required/>}
                                    name="newContact.firstName"
                                    rules={{
                                        required: 'First name is required' as Message,
                                    }}
                                />
                                <RHFController
                                    render={({field}): JSX.Element => <TextField {...field} fullWidth label="Last Name" variant="standard" required/>}
                                    name="newContact.lastName"
                                    rules={{
                                        required: 'Last name is required' as Message,
                                    }}
                                />
                                <RHFController
                                    render={({field}): JSX.Element => <TextField {...field} fullWidth label="Nick Name" variant="standard" />}
                                    name="newContact.nickname"
                                    rules={{
                                        required: false,
                                    }}
                                />
                                <RHFController
                                    render={({field}): JSX.Element => <TextField {...field} fullWidth label="Title" variant="standard" required/>}
                                    name="newContact.title"
                                    rules={{
                                        required: 'Job title is required' as Message,
                                    }}
                                />
                                <RHFController
                                    render={({field}): JSX.Element => <TextField {...field} fullWidth label="Email" variant="standard" required/>}
                                    name="newContact.email"
                                    rules={{
                                        required: 'Email is required' as Message,
                                    }}
                                />
                                <RHFController
                                    render={({field}): JSX.Element => <TextField {...field} fullWidth label="Work Phone" variant="standard" required/>}
                                    name="newContact.workPhone"
                                    rules={{
                                        required: 'Phone number is required' as Message,
                                    }}
                                />
                            </>
                        )}
                    </DialogContent>
                    <DialogActions id="new-team-member-dialog-actions">
                        <Button onClick={handleCancel} className="close-button" id="cancel-new-team-member-button">
                            Cancel
                        </Button>
                        <BusyButton busy={busy} type="submit" className="save-button" id="save-new-team-member-button">
                            Save
                        </BusyButton>
                    </DialogActions>
                </form>
            </FormProvider>
        </Dialog>
    );
}

import {useContext, ReactNode} from 'react';
import {StackContext} from './SwipeStack';
import styles from '~/styles/SwipeStackCard.module.scss';

interface SwipeStackCardProps {
    cardIndex: number;
    children: ReactNode;
}

export default function SwipeStackCard({children, cardIndex}: SwipeStackCardProps): JSX.Element {
    const activeIndex = useContext(StackContext);
    return (
        <div className="swiper-slide">
            <div className={`${styles.root}`}>{activeIndex === cardIndex && children}</div>
        </div>
    );
}

/* eslint-disable react-hooks/exhaustive-deps */
import AddIcon from '@mui/icons-material/Add';
import {Button, useMediaQuery} from '@mui/material';
import {DataGridPro, useGridApiRef, DataGridProProps, GRID_TREE_DATA_GROUPING_FIELD, GridValueGetterParams, GridRowClassNameParams, GridState} from '@mui/x-data-grid-pro';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import DocumentTableContext from './DocumentTableContext';
import { DocumentTableToolbar } from './DocumentTableToolbar';
import NoDataPlaceholder from '~/components/generics/NoDataPlaceholder';
import DocumentTableColumnsPro from '~/components/subsite/documentTable/DocumentTableColumnsPro';
import EditDocumentDialog from '~/components/subsite/documentTable/EditDocumentDialog';
import NewDocumentDialog from '~/components/subsite/documentTable/NewDocumentDialog';
import { defaultMediaSizes } from '~/helpers';
import {useStoreState, useStoreActions} from '~/store/storeHooks';
import styles from '~/styles/DataGridPro.module.scss';
import {Document, GridInitialStateWithDensity} from '~/types';

interface DocumentTableProps {
    categories: number | number[];
    addDocument?: boolean;
}

DocumentTable.defaultProps = {
    addDocument: false,
};

const docOptionsCacheVersion = 4;

let saveOptionsTimeout: ReturnType<typeof setTimeout> = null;

export default function DocumentTable({categories, addDocument}: DocumentTableProps): JSX.Element {
    const {documentSubset,documentSearchText} = useStoreState((state) => state.document);
    const categorySet = useStoreState((state) => state.document.categories);
    const project = useStoreState((state) => state.subsite.project);
    const {userOptions} = useStoreState((state) => state.shared);
    const {setUserOptions,resetUserOptions} = useStoreActions((actions) => actions.shared);
    const setCurrentDocument = useStoreActions((actions) => actions.document.setCurrentDocument);
    const [selectedDocument, setSelectedDocument] = useState<Document>(null);
    const [newDocumentDialogOpen, setNewDocumentDialogOpen] = useState<boolean>(false);
    const [tableVersion,setTableVersion] = useState(0);
    const [pinChange,setPinChange] = useState(false);
    const [resetting, setResetting] = useState(false);
    const mediaSizes = defaultMediaSizes;
    const xxs = useMediaQuery(mediaSizes.xxs);
    const xs = useMediaQuery(mediaSizes.xs);
    const sm = useMediaQuery(mediaSizes.sm);
    const md = useMediaQuery(mediaSizes.md);
    const lg = useMediaQuery(mediaSizes.lg);

    const tableSizeKey = useMemo(()=>{
        if (xxs) return 'xxSmallTable';
        if (xs) return 'extraSmallTable';
        if (sm) return 'smallTable';
        if (md) return 'mediumTable';
        if (lg) return 'largeTable';
        return 'extraLargeTable';
    },[xxs,xs,sm,md,lg])

    

    const [searchParams, setSearchParams] = useSearchParams();
    const apiRef = useGridApiRef();

    const docId = searchParams.get('doc');

    const data = useMemo(
        () =>
            documentSubset.filter(({categoryId}) => {
                if (typeof categories === 'number') {
                    return categoryId === categories;
                }
                return categories.includes(categoryId);
            }),
        [categories, documentSubset],
    );

    const isTreeData: boolean = useMemo(
        ()=> {
            let isTree = false;
            let possibleTree = false;
            // Due to data inconsistency with parent being deleted adn children being orphaned, for now
            // protecting from orphans on client side, need to fix this at the source though
            const idLookup: any = {};
            data.forEach((row)=>{
                idLookup[row.id] = true
                if (row.descendent) possibleTree = true;
            });
            if (possibleTree) {
                data.forEach((row,index)=>{
                    if (row.descendent) {
                        if (idLookup[row.descendent]) {
                            isTree = true;
                        } else {
                            data[index].descendent = null;
                        }
                    }
                })
            }
            return isTree;
        }, [data]
    )

    const columnsPro = DocumentTableColumnsPro(isTreeData);

    const category = useMemo(
        () => {
            if (categorySet.length > 0 && categories) {
                if (typeof categories === 'number') {
                    return categorySet.find((o) => o.id === categories) ?? null;
                }
            }
            return null;
        }, [categories,categorySet]
    )

    const optionKey = useMemo(
        () => {
            if (category?.title) {
                return`${category.title.replace(/\s/g,'')}InitialState`;
            }
            return null;
        }, [category]
    )

    const fixLeftColumn = (initState: any) => {
        if (!initState?.columns || !initState?.pinnedColumns) return initState;
        const {pinnedColumns} = initState;
        const index = isTreeData ? 1 : 0;
        if (pinnedColumns?.left?.[index] && initState?.columns?.orderedFields?.length) {
            const updatedInitState = initState;
            const leftColumn = pinnedColumns?.left?.[index];
            updatedInitState.columns.orderedFields = initState.columns.orderedFields.filter((c: string)=>c !== leftColumn)
            updatedInitState.columns.orderedFields.splice(index,0,leftColumn);
            return updatedInitState;
        }
        return initState;
    }

    const fixTreeData = (initState: any) => {
        const updatedInitState = initState;
        if (isTreeData) {
            if (updatedInitState?.pinnedColumns?.left?.length && updatedInitState.pinnedColumns.left[0] !== GRID_TREE_DATA_GROUPING_FIELD) {
                updatedInitState.pinnedColumns.left.splice(0,0,GRID_TREE_DATA_GROUPING_FIELD); 
            } else if (!updatedInitState?.pinnedColumns?.left?.length) {
                if (!updatedInitState.pinnedColumns) updatedInitState.pinnedColumns = {};
                updatedInitState.pinnedColumns.left = [GRID_TREE_DATA_GROUPING_FIELD];
            }
            if (updatedInitState?.columns?.orderedFields?.length && updatedInitState.columns?.orderedFields?.[0] !== GRID_TREE_DATA_GROUPING_FIELD) {
                if (!updatedInitState?.columns?.orderedFields) updatedInitState.columns.orderedFields = [];
                updatedInitState.columns.orderedFields.splice(0,0,GRID_TREE_DATA_GROUPING_FIELD);
            }
        }
        return updatedInitState
    }

    let initialState = useMemo(
        () => {
            let initState: any = {};
            if (resetting) return {};
            if (category && optionKey) {
                
                const {documentTableOptions} = userOptions;
                if (documentTableOptions && documentTableOptions?.cacheVersion === docOptionsCacheVersion) {
                    if (documentTableOptions[optionKey]) {
                        initState = documentTableOptions[optionKey];
                    }
                } 

                if (initState?.[tableSizeKey]?.columns) {
                    initState.columns = initState[tableSizeKey].columns;
                }

                if (initState?.[tableSizeKey]?.pinnedColumns?.left) {
                    initState.pinnedColumns = initState[tableSizeKey].pinnedColumns;
                } else if (initState.pinnedColumns) {
                    initState.pinnedColumns = null;
                }
                
                
                initState = fixTreeData(initState);
                initState = fixLeftColumn(initState);
                
            }
            return initState;  
        }, [userOptions,tableSizeKey,isTreeData,resetting]
    )
    
    const [savedDensity,setSavedDensity] = useState(initialState?.density ?? 'standard');

    useEffect(() => {
        if (documentSubset.length > 0) {
            const document = documentSubset.find((o) => o.id === parseInt(docId, 10)) ?? null;
            setSelectedDocument(document);

            // For dev purposes
            setCurrentDocument(document);
        }
    }, [documentSubset, docId, setCurrentDocument]);

    const handleRowClick = useCallback(
        (params: any): void => {
            setSearchParams({doc: String(params.row.id)});
        },
        [setSearchParams],
    );

    const handleClose = useCallback(() => {
        setSelectedDocument(null);
        setSearchParams({});
        setNewDocumentDialogOpen(false);
    }, [setSearchParams]);

    const documentTableContext = useMemo(
        () => ({document: selectedDocument, validCategories: categories, handleClose}),
        [categories, handleClose, selectedDocument],
    );

    useEffect(()=>{
        if (userOptions?.documentTableOptions?.cacheVersion !== docOptionsCacheVersion) {
            resetUserOptions({ 
                option: 'documentTableOptions',
                value: {cacheVersion: docOptionsCacheVersion}
            }); 
        }

    },[userOptions,resetUserOptions])



    const saveTableChange = useCallback(
        (trigger: string): void => {
            if (resetting) return;
            if (apiRef?.current?.exportState && category?.title) {
                const exportedState: GridInitialStateWithDensity = apiRef.current.exportState();
                if (trigger === 'newTableSizeToSave') {
                    const defObj = getDefaultGridState();
                    exportedState.columns = defObj.columns;
                    exportedState.pinnedColumns = defObj.pinnedColumns;
                }

                // known issue with exportState and columnVisibility not working right
                // so explicity set it before saving to localStorage
                if (exportedState?.columns && !exportedState?.columns?.columnVisibilityModel && apiRef.current.state?.columns?.columnVisibilityModel) {
                    exportedState.columns = {
                        ...exportedState.columns,
                        columnVisibilityModel: apiRef.current.state.columns.columnVisibilityModel
                    } 
                }
                exportedState.density = apiRef.current.state?.density?.value;
                if (exportedState.preferencePanel) delete exportedState.preferencePanel; 
                let value = {
                    ...initialState,
                    ...exportedState,
                    density: apiRef?.current?.state?.density?.value,
                    [tableSizeKey]: {
                        columns: exportedState.columns,
                        pinnedColumns: exportedState.pinnedColumns
                    }
                }
                value = fixTreeData(value);
                value = fixLeftColumn(value);
                initialState = value;
                if (trigger === 'newTableSizeToSave') {
                    setUserOptions({ 
                        option: 'documentTableOptions',
                        key: optionKey, 
                        value
                    });  
                } else {
                    // debounce / delay saves of state change as the change is triggered rapidly while user is dragging around a column or default settings are restored trigger 4 rapid state changes
                    if (saveOptionsTimeout) clearTimeout(saveOptionsTimeout);
                    saveOptionsTimeout = setTimeout(()=>{
                        saveOptionsTimeout = null;
                        setUserOptions({ 
                            option: 'documentTableOptions',
                            key: optionKey, 
                            value
                        });  
                    },2000);
                }
                const index = isTreeData ? 1 : 0;
                const pinnedColumnLeft = initialState?.columns?.pinnedColumns?.left?.[index];
                const leftColumn = initialState?.columns?.orderedFields?.[index];
                if (trigger === 'pinned' || (pinnedColumnLeft && pinnedColumnLeft !== leftColumn) ) {
                    // pinning a column also changes its order but the order change is not caught with pinned column change and column order change is not triggered
                    // so triggering another state change after the pinned change and then it will catch the new column order in a state change
                    setPinChange(true);
                }
            }   
        },
        [apiRef,category,optionKey,setUserOptions,tableSizeKey,initialState],
    );

    useEffect(()=>{
        // using tableversions as a key in the div wrapping table so screen size changes will trigger data grid table to render as a new component
        // which will pick up changes to the columns that are based on screen size
        let newTableVersion = tableVersion;
        if (lg) newTableVersion += 3;
        if (md) newTableVersion += 4;
        if (sm) newTableVersion += 5;        
        if (xs) newTableVersion += 6;
        if (xxs) newTableVersion += 7;
        if (data?.length) {
            newTableVersion = tableVersion + data.length + 20;
        } else {
            newTableVersion += 10;
        }
        setTableVersion(newTableVersion);
        // // eslint-disable-next-line react-hooks/exhaustive-deps
    },[xxs, xs,sm,md,lg,data]);

    useEffect(()=>{
       
        if (userOptions?.documentTableOptions?.[optionKey]?.columns && initialState?.columns && !initialState?.[tableSizeKey] && apiRef?.current?.exportState && !resetting) {
            // we've never saved options for this table size so save them to lock them in to current options
            saveTableChange('newTableSizeToSave');
        }
        // // eslint-disable-next-line react-hooks/exhaustive-deps
    },[tableSizeKey,initialState,userOptions,resetting]);

    const handleStateChange = useCallback(
        (state: GridState): void => {
            if (resetting) {
                setTimeout(()=>setResetting(false),0);
                return;
            }
            if (pinChange) {
                setPinChange(false);
                saveTableChange('afterPinChange');
            }
            // density doesn't have a specific state change handle 
            // and doesn't get saved in exportState as of yet
            // so have to look for changes here in every state change
            if (state?.density?.value !== savedDensity) {
                setSavedDensity(state.density.value);
                saveTableChange('density');
            }
        },
        [savedDensity,saveTableChange,resetting],
    );

    // eslint-disable-next-line no-nested-ternary
    const maxRowsAllowed = savedDensity === 'compact' ? 11 : savedDensity === 'standard' ? 8 : 6;

    // eslint-disable-next-line no-unneeded-ternary
    const tableAutoHeight = (data?.length && data.length < maxRowsAllowed) ? true : false;

    const addButton = (
        <Button onClick={(): void => setNewDocumentDialogOpen(true)} id="add-new-document-button">
            <AddIcon/> Add Doc
        </Button>
    )

    const getTreeDataPath: DataGridProProps['getTreeDataPath'] = (row) => row.descendent ? [row.descendent,row.id] : [row.id];

    const getDefaultGridState: (reason?: string) => GridInitialStateWithDensity =  useCallback(
        (reason) => {
            // need to save default state for when user wants to restore table to default state
            // dataGrid pro restoreState doesn't currently support restoring to default but requires
            // explicit state passed in as argument so here we are saving the default state.
            const defaultTableState: GridInitialStateWithDensity = {
                filter: {
                    filterModel: {
                        items:[]
                    }
                },
                sorting: {
                    sortModel: []
                },
                pinnedColumns:  {
                    left:  isTreeData ? [GRID_TREE_DATA_GROUPING_FIELD] : [],
                    right: []
                },
                columns: {
                    orderedFields: [],
                    dimensions: {},
                    columnVisibilityModel: {}
                },
                density: 'standard'
            };
            defaultTableState.density = 'standard';
            // Using values from ProjectLogColumns definition above to set the default state object values
            columnsPro.forEach((col)=>{
                defaultTableState.columns.orderedFields.push(col.field);
                defaultTableState.columns.columnVisibilityModel[col.field] = !col.hide;
                const minWidth: number = col.minWidth ? col.minWidth : col.width;
                const width: number = col.width ? col.width : minWidth
                defaultTableState.columns.dimensions[col.field] = {
                    width: width !== undefined ? width : 100,
                    maxWidth: col.maxWidth ? col.maxWidth : -1,
                    minWidth: minWidth !== undefined ? minWidth : 100
                }
            })
            if (reason === 'resetting') {
                setResetting(true);
            }
            return defaultTableState;
        },
        [columnsPro,tableSizeKey]
    )

    const groupingColDef: DataGridProProps['groupingColDef'] = {
        headerName: 'Number',
        valueGetter: (params: GridValueGetterParams): string => params.row.descendent ? '' : params.row.number,
        width: 200
    };

    const getRowClassName = (params: GridRowClassNameParams)=> {
        let docState = 'Read Only';
        const {descendent,status,editable} = params.row;
        if (!descendent) {
            const actionRequired = status?.title === 'IFA';
            if (status?.actionList?.length) {
                if (actionRequired) {
                    docState = 'Action Required';
                } else if (editable) {
                    docState = 'Action & Edit';
                } else {
                    docState = 'Actionable';
                }
            } else if (editable) {
                docState = 'Editable';
            } 
        }
        let cn = styles.row;
        switch (docState) {
            case 'Action Required':
                cn = `${cn} ${styles.actionRequired}`;
                break;
            case 'Read Only':
                cn = `${cn} ${styles.readOnly}`;
                break;
            case 'Actionable':
                cn = `${cn} ${styles.actionable}`;
                break;
            case 'Editable':
                cn = `${cn} ${styles.editable}`;
                break;
            case 'Action & Edit':
                cn = `${cn} ${styles.actionableEditable}`;
                break;
            default:
                break;  
        }
        if (descendent) cn = `${cn} ${styles.treeBranch}`;
        return cn;
    }

    const searchText = documentSearchText?.[optionKey]?.toLowerCase();

    const searchDocuments = (d: Document) => {
        if (!searchText) return true;
        let docState = 'Read Only';
        const {descendent,status,editable} = d;
        if (!descendent) {
            const actionRequired = status?.title === 'IFA';
            if (status?.actionList?.length) {
                if (actionRequired) {
                    docState = 'Action Required';
                } else if (editable) {
                    docState = 'Action & Edit';
                } else {
                    docState = 'Actionable';
                }
            } else if (editable) {
                docState = 'Editable';
            } 
        }
        return (
            d.title?.toLowerCase().indexOf(searchText) > -1  ||
            d.description?.toLowerCase().indexOf(searchText) > -1 ||
            d.number?.toLowerCase().indexOf(searchText) > -1 ||
            d.status?.title?.toLowerCase().indexOf(searchText) > -1 ||
            docState?.toLowerCase().indexOf(searchText) > -1 ||
            d.modifiedBy?.toLowerCase().indexOf(searchText) > -1 ||
            d.fileType?.toLowerCase().indexOf(searchText) > -1 ||
            d.company?.toLowerCase().indexOf(searchText) > -1
        )
    }

    const visibleRows = useMemo(()=>{
        if (!searchText?.length || !data?.length) return data;
        return data.filter(searchDocuments);
    },[data,searchText])

    return (
        <DocumentTableContext.Provider value={documentTableContext}>
            <div className={`${styles.tableRoot} ${!data?.length ? styles.nodata : !tableAutoHeight && styles.bigdata}`} key={`v${tableVersion.toString()}`}>
                {
                    category?.title
                    ?
                    <DataGridPro
                        initialState={initialState}
                        rows={visibleRows}
                        columns={columnsPro}
                        treeData={isTreeData}
                        getTreeDataPath={isTreeData ? getTreeDataPath : undefined}
                        groupingColDef={isTreeData ? groupingColDef: undefined}
                        autoHeight={tableAutoHeight}
                        density={savedDensity}
                        components={{
                            Toolbar: DocumentTableToolbar,
                            NoRowsOverlay: NoDataPlaceholder,
                        }}
                        componentsProps={{
                            toolbar: {csvOptions: {fileName: `${project?.code} ${category?.title} .csv`}, 
                            addButton: addDocument ? addButton : undefined,
                            getDefaultGridState,
                            optionKey,
                            showSearchBar: data?.length > 1
                        }}}
                        getRowClassName={getRowClassName}
                        onRowClick={handleRowClick}
                        disableMultipleSelection
                        apiRef={apiRef}
                        onStateChange={handleStateChange}
                        onColumnOrderChange={()=>saveTableChange('columnOrder')}
                        onColumnWidthChange={()=>saveTableChange('columnWidth')}
                        onSortModelChange={()=>saveTableChange('sort')}
                        onFilterModelChange={()=>saveTableChange('filter')}
                        onPinnedColumnsChange={()=>saveTableChange('pinned')}
                        onColumnVisibilityModelChange={()=>saveTableChange('visible')}
                        onPreferencePanelClose={()=>saveTableChange('panelClose')}
                        hideFooter={tableAutoHeight || !data?.length}  
                    />
                    :
                    ''
                }
            </div>
            {data.some((d) => d.id === selectedDocument?.id) && <EditDocumentDialog handleClose={handleClose} open />}
            <NewDocumentDialog open={newDocumentDialogOpen} />
        </DocumentTableContext.Provider>
    );
}

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import GroupIcon from '@mui/icons-material/Group';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import {IconButton} from '@mui/material';
import moment from 'moment';
import {useSnackbar} from 'notistack';
import {useState, useCallback} from 'react';
import * as React from 'react';
import {Link, useParams} from 'react-router-dom';
import TeamAcknowledgedInfoDialog from './TeamAcknowledgedInfoDialog';
import NotificationIcon from '~/assets/img/notification-icon.svg';
import { HtmlToString, htmlDecode } from '~/helpers';
import {useStoreActions} from '~/store/storeHooks';
import styles from '~/styles/NotificationListItem.module.scss';
import {Activity} from '~/types';




interface NotificationListItemProps {
    notification: Activity;
    updateAckedCount: (i: number) => void;
}

export default function NotificationListItem({notification, updateAckedCount}: NotificationListItemProps): JSX.Element {
    const {action, category, created, createdBy, project, subject, document, notificationId, acknowledged, teamAcknowledged } = notification;
    const {removeNotification,toggleAcknowledged} = useStoreActions((actions) => actions.activity);
    const [busy, setBusy] = useState(false);
    const [ackBusy, setAckBusy] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const {projectId} = useParams<{projectId: string}>();
    const [infoDialogOpen, setInfoDialogOpen] = useState<boolean>(false);

    const handleRemoveNotification = useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
            e.stopPropagation();
            setBusy(true);
            const {result, message} = await removeNotification({projectId: projectId ? parseInt(projectId, 10) || -1 : -1, notificationId});
            setBusy(false);
            if (result === 'error') {
                enqueueSnackbar(message, {variant: result});
            }
        },
        [enqueueSnackbar, notificationId, projectId, removeNotification],
    );

    const handleBellClick = useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
            e.stopPropagation();
            updateAckedCount(notification.acknowledged ? 1 : -1);
            setAckBusy(true);
            const {result, message} = await toggleAcknowledged({notification,projectId: projectId || "-1"});
            setAckBusy(false);
            if (result === 'error') {
                updateAckedCount(notification.acknowledged ? -1 : 1);
                enqueueSnackbar(message, {variant: result});
            }
        },
        [enqueueSnackbar, notification, toggleAcknowledged, updateAckedCount,projectId],
    );

    const handleDivClick = useCallback(
        async (e: any): Promise<void> => {
            if (!notification.acknowledged) {
                updateAckedCount(notification.acknowledged ? 1 : -1);
                const {result, message} = await toggleAcknowledged({notification,projectId: projectId || "-1"});
                setAckBusy(false);
                if (result === 'error') {
                    updateAckedCount(notification.acknowledged ? -1 : 1);
                    enqueueSnackbar(message, {variant: result});
                }
            }
        },
        [enqueueSnackbar, notification, toggleAcknowledged, updateAckedCount,projectId],
    );

    let sourceLink: JSX.Element | null = null;
    if (document) {
        sourceLink = (
            <a href={document.publicLink} target="_blank" rel="noreferrer">
                 {document.title} <TextSnippetIcon fontSize='small' className={styles.docIcon}/>
            </a>
        );
    }

    const href = `/project/${project.id}/summary`;

    const getCategoryLink: () => JSX.Element = useCallback(() => {
        
        switch(category) {
            case 'Messages':
                return (
                    <>
                        &nbsp;|&nbsp; 
                        <Link to={`${href}?openmessages=true`}>
                            {category}
                        </Link>
                    </>
                )
            // TODO add links to other notification types
            case 'Deliverable':
                if (!document) return <> |  {category}</>
                return (
                    <>
                        &nbsp;|&nbsp; 
                        <Link to={`/project/${document.project}/deliverables?doc=${document.id}`}>
                            {category}
                        </Link>
                    </>
                )
            default: 
                return <> |  {category}</>
        }
    },[category,href,document]);

    const bellIconClass = acknowledged ? styles.ackedNotificationIcon : styles.notificationIcon;
    const bellTitle = acknowledged ? 'Unacknowledge and reset bell notification' : 'Acknowledge and clear bell notification';
    const sanitizedSubject = subject?.length ? HtmlToString(htmlDecode(subject)) : '';

    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div className={styles.root} onClick={handleDivClick}>
            <IconButton className={styles.clear} disabled={busy} onClick={handleRemoveNotification}>
                <FontAwesomeIcon icon={['fal', 'times']} />
            </IconButton>
            <div className={styles.titleContainer}>
                <p className={styles.title}>
                    {
                        projectId 
                        ?
                        project.code
                        :
                        
                        <Link to={href} className={styles.sourceLinkContainer}>
                            {project.code}
                        </Link>
                    }
                    {category ? getCategoryLink() : ''}
                    <IconButton  disabled={ackBusy} title={bellTitle} onClick={handleBellClick}>
                        <img src={NotificationIcon} alt="notification-icon" aria-label="notification list" className={bellIconClass} />
                    </IconButton>
                    {
                        teamAcknowledged
                        ?
                        <IconButton sx={{marginLeft:20}} onClick={()=>setInfoDialogOpen(true)}>
                            <GroupIcon className={styles.ackedTeamIcon} fontSize='small' color='success'/>
                            <InfoOutlinedIcon className={styles.ackedTeamInfoIcon} fontSize='small' color='primary'/>
                        </IconButton>
                        :
                        <></>
                    }
                </p>
                <p className={styles.timestamp}>{moment(created).calendar()}</p>
            </div>
            <p className={styles.description}>{`${createdBy.fullName} ${action} ${sanitizedSubject?.length > 200 ? '' : sanitizedSubject}`}</p>
            <div className={styles.sourceLinkContainer}>{sourceLink}</div>
            <TeamAcknowledgedInfoDialog
                open={infoDialogOpen}
                notification={notification}
                onCloseCallback={(): void => setInfoDialogOpen(false)}
            />
        </div>
    );
}

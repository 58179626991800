import {useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useTrackPage} from '~/api';
import ScrollToDiv from '~/components/generics/ScrollToDiv';
import DocumentCountKPI from '~/components/kpi/DocumentCountKPI';
import KPIContainer from '~/components/kpi/KPIContainer';
import PercentCompleteKPI from '~/components/kpi/PercentCompleteKPI';
import DocumentTable from '~/components/subsite/documentTable/DocumentTablePro';
import PhotoGrid from '~/components/subsite/progress/PhotoGrid';
import ProgressTable from '~/components/subsite/progress/ProgressTable';
import LoadingComponent from '~/components/visualizations/LoadingComponent';
import ProgressAreaChart from '~/components/visualizations/ProgressAreaChart';
import {useStoreActions, useStoreState} from '~/store/storeHooks';
import styles from '~/styles/SubsiteContentPanel.module.scss';

export default function ProgressPanel(): JSX.Element {
    const documentCategoryForTitle = useStoreState((state) => state.document.documentCategoryForTitle);
    const projects = useStoreState((state) => state.project.projects);
    const getProjectProgress = useStoreActions((actions) => actions.project.getProjectProgress);
    const progressCategory = documentCategoryForTitle('progress report');
    const scheduleCategory = documentCategoryForTitle('schedule');
    const {projectId} = useParams<{projectId: string}>();
    const [fetchingProgressRecords, setFetchingProgressRecords] = useState(false);

    useTrackPage('Project Progress');

    useEffect(() => {
        if (projectId) {
            setFetchingProgressRecords(true);
            (async function fetchData(): Promise<void> {
                await getProjectProgress(+projectId);
            })();
            setFetchingProgressRecords(false);
        }

        return (): void => {
            setFetchingProgressRecords(false);
        };
    }, [getProjectProgress, projectId]);

    const trackerJob = useMemo(() => {
        const project = projects?.find((p) => p.id === Number(projectId));
        return project?.trackerJob;
    }, [projects, projectId]);

    return (
        <div id="progress-panel-root" className={styles.root}>
            <KPIContainer className={styles.kpiContainer}>
                <PercentCompleteKPI />
                <DocumentCountKPI
                    categoryTitle="progress report"
                    title="Reports"
                    scrollTo="progress-report-table-scroll-to"
                    tooltipTitle="Navigate to Progress Reports"
                />
                <DocumentCountKPI
                    categoryTitle="schedule"
                    title="Schedules"
                    scrollTo="schedule-table-scroll-to"
                    tooltipTitle="Navigate to Schedules"
                />
                <DocumentCountKPI categoryTitle="photo" title="Photos" scrollTo="photo-grid-scroll-to" tooltipTitle="Navigate to Progress Photos" />
            </KPIContainer>
            {!trackerJob && (
                <div className={styles.progressChartContainer}>
                    <div id="progress-chart-root">
                        <ScrollToDiv id="progress-chart-scroll-to" />
                        <h4 className={styles.header}>Progress Curve</h4>
                        <div className={`${styles.card} ${styles.topRoundedOnly}`}>
                            {fetchingProgressRecords ? (
                                <LoadingComponent title="Fetching Progress Data" />
                            ) : (
                                <ProgressAreaChart projectId={parseInt(projectId, 10)} projectCode={projects[0]?.code ?? ''} includeDates />
                            )}
                        </div>
                        <div id="progress-table-root">
                            <ScrollToDiv id="progress-table-scroll-to" />
                            <ProgressTable />
                        </div>
                    </div>
                </div>
            )}
            {progressCategory && (
                <div id="progress-report-table-root">
                    <ScrollToDiv id="progress-report-table-scroll-to" top={-40} />
                    <h4 className={styles.header}>Progress Reports</h4>
                    <DocumentTable categories={progressCategory.id} />
                </div>
            )}
            {scheduleCategory && (
                <div id="schedule-table-root">
                    <ScrollToDiv id="schedule-table-scroll-to" />
                    <h4 className={styles.header}>Schedules</h4>
                    <DocumentTable categories={scheduleCategory.id} />
                </div>
            )}
            <div id="photo-grid">
                <ScrollToDiv id="photo-grid-scroll-to" />
                <PhotoGrid />
            </div>
        </div>
    );
}

import {AxiosInstance} from 'axios';
import {fbApp} from '../firebase';
import {ConfigureAxios} from './ConfigureAxios';

export interface AxiosContextType {
    axios: AxiosInstance;
}

export const axios = ConfigureAxios({
    baseURL: 'https://teamhub.hm-ec.com/_layouts/15/CPPService/Service.svc',
    getIdToken: () => fbApp.currentUser?.getIdToken(),
});

import {DialogActions, DialogContent, useMediaQuery} from '@mui/material';
import {useCallback, useMemo, useState} from 'react';
import {useParams, useSearchParams} from 'react-router-dom';
import ChatHistory from '~/components/chat/ChatHistory';
import ChatHistoryContext from '~/components/chat/ChatHistoryContext';
import ChatInput from '~/components/chat/ChatInput';
import {useStoreState, useStoreActions} from '~/store/storeHooks';
import styles from '~/styles/DocumentDialog.module.scss';
import {Comment} from '~/types';

export default function DocumentComments(): JSX.Element {
    const comments: Comment[] = useStoreState((state) => state.comment.comments);
    const {id} = useStoreState((state) => state.shared.currentUser);
    const updateCommentsRead = useStoreActions((actions) => actions.comment.updateCommentsRead);
    const {projectId} = useParams<{projectId: string}>();
    const [searchParams] = useSearchParams();
    const [selectedComment, setSelectedComment] = useState(null);
    const mobile = useMediaQuery('(max-width: 600px)');

    const docId = searchParams.get('doc');

    const docComments = useMemo(() => {
        const i = parseInt(docId, 10);
        return comments.filter((o) => o.document?.id === i);
    }, [comments, docId]);

    const onLoadedCallback = useCallback((): void => {
        updateCommentsRead({
            projectId: parseInt(projectId, 10),
            docId: parseInt(docId, 10),
        });
    }, [docId, projectId, updateCommentsRead]);

    const chatHistoryContext = useMemo(
        () => ({currentUser: id, selectedComment, setSelectedComment, mobile, target: 'document'}),
        [id, mobile, selectedComment, setSelectedComment],
    );

    return (
        <>
            <div className={styles.header}>Comments</div>
            <ChatHistoryContext.Provider value={chatHistoryContext}>
                <DialogContent id="comments-root" className={styles.commentContent}>
                    <ChatHistory comments={docComments} onLoadedCallback={onLoadedCallback} />
                </DialogContent>
                <DialogActions className={styles.commentActions}>
                    <ChatInput />
                </DialogActions>
            </ChatHistoryContext.Provider>
        </>
    );
}

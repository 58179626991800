import {ReactNode, useMemo} from 'react';
import {useParams} from 'react-router';
import AccessDenied from '../generics/AccessDenied';
import {useStoreState} from '~/store/storeHooks';

export function RequireSubsiteAccess({children}: {children: ReactNode}) {
    const {projectId} = useParams<{projectId: string}>();
    const currentUser = useStoreState((state) => state.shared.currentUser);

    const valid = useMemo(() => {
        if (currentUser?.isAdmin) {
            return true;
        }

        if (!currentUser?.projects) {
            return false;
        }

        const pid = parseInt(projectId, 10);
        return currentUser.projects.includes(pid);
    }, [currentUser, projectId]);

    return !valid ? <AccessDenied /> : <>{children}</>;
}

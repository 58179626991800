import {useMediaQuery} from '@mui/material';
import {ApexOptions} from 'apexcharts';
import Chart from 'react-apexcharts';
import resetIcon from '../../assets/img/reset-icon.svg';
import NoDataPlaceholder from '../generics/NoDataPlaceholder';
import {useStoreActions, useStoreState} from '~/store/storeHooks';


interface ProgressAreaChartProps {
    projectId: number;
    projectCode: string;
    includeDates?: boolean;
}

const MOBILE_HEIGHT = '100%';

ProgressAreaChart.defaultProps = {
    includeDates: false
}

export default function ProgressAreaChart({projectId, projectCode, includeDates}: ProgressAreaChartProps): JSX.Element {
    const mobile = useMediaQuery('(max-width: 600px)');
    const progressChartSeries = useStoreState((state) => state.project.progressChartSeries);
    const {setProgressZoom,getProgressTableData} = useStoreActions((actions) => actions.progress);
    const {categories, series} = progressChartSeries(+projectId);

    const options: ApexOptions = {
        chart: {
            width: '100%',
            background: '#212121',
            toolbar: {
                show: !mobile,
                tools: {
                    zoomin: includeDates,
                    zoomout: includeDates,
                    zoom: includeDates,
                    pan: false,
                    selection: includeDates,
                    reset: includeDates && `<img src="${resetIcon}" width="23"/>`,
                },
                export: {
                    csv: {
                        filename: `${projectCode} Progress Chart Data`,
                    },
                    svg: {
                        filename: `${projectCode} Progress Chart`,
                    },
                    png: {
                        filename: `${projectCode} Progress Chart`,
                    },
                },
            },
            height: '100%',
            parentHeightOffset: 0,
            fontFamily: 'Gotham Book, Roboto, Helvetica, sans-serif',
            events: {
                zoomed: (chartContext, state: {xaxis: {min: number; max: number}}) => {
                    if (includeDates) {
                        if (state?.xaxis?.min && state?.xaxis?.max) {
                            setProgressZoom({min: state.xaxis.min -1, max: state.xaxis.max - 1})
                        } else {
                            setProgressZoom({});
                        }
                        setTimeout(()=>getProgressTableData(projectId),1);
                    }
                }
            }
        },
        dataLabels: {
            enabled: false,
        },
        xaxis: {
            categories,
            labels: {
                show: includeDates,
            },
            axisTicks: {
                show: includeDates,
            },
        },
        yaxis: {
            tickAmount: 5,
            min: 0,
            max: 100,
            labels: {
                style: {
                    fontSize: '10px',
                },
            },
            title: {
                text: 'Percent Complete',
            },
        },
        stroke: {
            curve: 'straight',
            lineCap: 'round',
            width: 1.5,
        },
        grid: {
            borderColor: '#4e4e4e',
        },
        legend: {
            show: true,
            position: 'top',
            fontSize: '11px',
        },
        theme: {
            mode: 'dark',
        },
    };
    return series.length === 0 ? <NoDataPlaceholder /> : <Chart type="area" series={series} options={options} height={MOBILE_HEIGHT}/>;
}

export const listItemVariants = {
    open: {
        y: 0,
        opacity: 1,
        transition: {
            y: {stiffness: 1000, velocity: -100},
        },
    },
    closed: {
        y: 150,
        opacity: 0,
        transition: {
            y: {
                stiffness: 1000,
                velocity: -100,
            },
        },
    },
};

export const listVariants = {
    open: {
        transition: {staggerChildren: 0.1, delayChildren: 0.1},
    },
    closed: {
        transition: {
            staggerChildren: 0.05,
            staggerDirection: -1,
        },
    },
};

export const drawerVariants = {
    open: (height = 2500): object => ({
        clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
        transition: {
            type: 'spring',
            stiffness: 20,
            restDelta: 2,
        },
    }),
    closed: {
        clipPath: 'circle(0px at 16px 16px)',
        transition: {
            delay: 0.5,
            type: 'spring',
            stiffness: 400,
            damping: 40,
        },
    },
};

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Divider} from '@mui/material';
import round from 'lodash/round';
import {useContext, useEffect, useMemo} from 'react';
import ProjectContext from '~/components/dashboard/project-log/ProjectContext';
import useProjectHealth from '~/hooks/useProjectHealth';
import {useStoreActions, useStoreState} from '~/store/storeHooks';
import styles from '~/styles/SummaryHealthTable.module.scss';

export default function SummaryBudgetGrade(): JSX.Element {
    const {project} = useContext(ProjectContext);
    const {calculateBudgetScore} = useProjectHealth();
    const standardHealthCriteria = useStoreState((state) => state.project.standardHealthCriteria);
    const getStandardHealthCriteria = useStoreActions((actions) => actions.project.getStandardHealthCriteria);

    useEffect(() => {
        if (standardHealthCriteria.length === 0) {
            getStandardHealthCriteria();
        }
    }, [getStandardHealthCriteria, standardHealthCriteria.length]);

    const budgetScore = calculateBudgetScore(project);
    const score = useMemo(() => {
        let value = 0;
        if (typeof budgetScore.score === 'string') {
            if (budgetScore.score === 'NA') {
                return 'N/A';
            }

            value = parseFloat(budgetScore.score);
        } else if (typeof budgetScore.score === 'number') {
            value = budgetScore.score;

            if (Number.isNaN(value)) {
                return 'N/A';
            }
        }

        value = round(value, 2);

        if (value < 0) {
            return '0';
        }

        if (value > 10) {
            return 'MAX';
        }

        return String(value);
    }, [budgetScore]);

    return (
        <div id="summary-budget-grade-root" className={styles.root}>
            <h4 className={styles.healthReportCardTitle}>Budget Health - CPI</h4>
            <div className={styles.card}>
                <div className={styles.scoreRoot}>
                    <div className={styles.resultContainer}>
                        <div className={styles.columnContainer}>
                            <p>Score</p>
                            <div className={styles.score}>{score}</div>
                        </div>
                        <Divider orientation="vertical" flexItem />
                        <div className={styles.columnContainer}>
                            <p>Grade</p>
                            <FontAwesomeIcon icon={['fas', 'circle']} color={budgetScore.indicator.color} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

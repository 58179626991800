import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import {useSnackbar} from 'notistack';
import {useCallback, useState} from 'react';
import * as React from 'react';
import {trackEvent, useAuth} from '~/api';
import {OnEnterPressWrapper} from '~/helpers';
import styles from '~/styles/PasswordResetControl.module.scss';

interface Props {
    title?: string;
    className?: string;
}

PasswordResetControl.defaultProps = {
    title: 'Reset Password',
    className: '',
};
export default function PasswordResetControl({title, className}: Props): JSX.Element {
    const auth = useAuth();
    const [resetPasswordDialogOpen, setResetPasswordDialogOpen] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    const {enqueueSnackbar} = useSnackbar();

    const toggleResetPasswordDialog = useCallback(() => {
        setResetPasswordDialogOpen((prev) => !prev);
    }, []);

    const handleSubmit = useCallback(async () => {
        try {
            await auth.app.sendPasswordResetEmail(email);
            trackEvent('reset password', {email});

            enqueueSnackbar('A link to reset your password will be emailed to you shortly - Please check your email', {
                variant: 'success',
                anchorOrigin: {vertical: 'top', horizontal: 'center'},
            });
            setResetPasswordDialogOpen(false);
        } catch (err) {
            const [type, message] = auth.app.handleAuthError(err);

            if (type === 'unknown') {
                enqueueSnackbar('Error while trying to send password reset email - Please contact Technical Support', {variant: 'error'});
            } else {
                enqueueSnackbar(message, {variant: 'error'});
            }
        }
    }, [auth.app, email, enqueueSnackbar]);

    return (
        <div className={className}>
            <div
                className={styles.clickable}
                onClick={toggleResetPasswordDialog}
                onKeyDown={OnEnterPressWrapper(toggleResetPasswordDialog)}
                role="button"
                tabIndex={0}>
                {title}
            </div>
            <Dialog
                open={resetPasswordDialogOpen}
                maxWidth="sm"
                onClose={(): void => setResetPasswordDialogOpen(false)}
                className={styles.dialogRoot}>
                <DialogTitle>Confirm Account Email</DialogTitle>
                <DialogContent>
                    Please enter the email associated with your CPP account and you will be sent instructions on how to reset your password.
                    <TextField
                        value={email}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setEmail(e.target.value)}
                        label="Email"
                        className={styles.emailField}
                    />
                </DialogContent>
                <DialogActions>
                    <Button className="close-button" onClick={(): void => setResetPasswordDialogOpen(false)}>
                        Cancel
                    </Button>
                    <Button className="save-button" onClick={handleSubmit}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

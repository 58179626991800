import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Divider} from '@mui/material';
import round from 'lodash/round';
import {useContext, useEffect, useMemo} from 'react';
import {Link} from 'react-router-dom';
import ProjectContext from '~/components/dashboard/project-log/ProjectContext';
import useProjectHealth from '~/hooks/useProjectHealth';
import {useStoreActions, useStoreState} from '~/store/storeHooks';
import styles from '~/styles/SummaryHealthTable.module.scss';

export default function SummaryScheduleGrade(): JSX.Element {
    const {project} = useContext(ProjectContext);
    const {calculateScheduleScore} = useProjectHealth();
    const standardHealthCriteria = useStoreState((state) => state.project.standardHealthCriteria);
    const getStandardHealthCriteria = useStoreActions((actions) => actions.project.getStandardHealthCriteria);

    useEffect(() => {
        if (standardHealthCriteria.length === 0) {
            getStandardHealthCriteria();
        }
    }, [getStandardHealthCriteria, standardHealthCriteria.length]);

    const scheduleScore = calculateScheduleScore(project);

    const score = useMemo(() => {
        if (typeof scheduleScore.score === 'string') {
            if (scheduleScore.score === 'NA') {
                return 'N/A';
            }
            return round(parseFloat(scheduleScore.score), 2);
        }
        if (typeof scheduleScore.score === 'number') {
            if (Number.isNaN(scheduleScore.score)) {
                return 'N/A';
            }
        }
        return round(scheduleScore.score, 2);
    }, [scheduleScore]);

    let scoreText: number | string = score;
    if (score < 0) {
        scoreText = 0;
    } else if (score > 10) {
        scoreText = 'MAX';
    }

    return (
        <div id="summary-schedule-grade-root" className={styles.root}>
            <h4 className={styles.healthReportCardTitle}>
                <Link to={`/project/${project.id}/progress`} className={styles.link}>
                    Schedule Health - SPI
                </Link>
            </h4>
            <div className={styles.card}>
                <div className={styles.scoreRoot}>
                    <div className={styles.resultContainer}>
                        <div className={styles.columnContainer}>
                            <p>Score</p>
                            <div className={styles.score}>{scoreText}</div>
                        </div>
                        <Divider orientation="vertical" flexItem />
                        <div className={styles.columnContainer}>
                            <p>Grade</p>
                            <FontAwesomeIcon icon={['fas', 'circle']} color={scheduleScore.indicator.color} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

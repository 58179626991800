import { Add } from '@mui/icons-material';
import {Button, ImageList, ImageListItem, ImageListItemBar} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import moment from 'moment';
import {useCallback, useMemo, useState, useEffect} from 'react';
import * as React from 'react';
import {useSearchParams} from 'react-router-dom';
import { trackEvent } from '~/api';
import NoDataPlaceholder from '~/components/generics/NoDataPlaceholder';
import DocumentTableContext from '~/components/subsite/documentTable/DocumentTableContext';
import NewDocumentDialog from '~/components/subsite/documentTable/NewDocumentDialog';
import PhotoDetail from '~/components/subsite/progress/PhotoDetail';
import {useStoreState} from '~/store/storeHooks';
import styles from '~/styles/PhotoGrid.module.scss';


export default function PhotoGrid(): JSX.Element {
    const xs = useMediaQuery('(max-width: 600px)');
    const sm = useMediaQuery('(max-width: 960px)');
    const md = useMediaQuery('(max-width: 1200px)');

    const documents = useStoreState((state) => state.document.documents);
    const documentCategoryForTitle = useStoreState((state) => state.document.documentCategoryForTitle);
    const [searchParams, setSearchParams] = useSearchParams();
    const photoCategory = documentCategoryForTitle('photo');
    const [newPhotoDialogOpen, setNewPhotoDialogOpen] = useState(false);
    const [selectedPhoto, setSelectedPhoto] = useState(null);

    const photoId = searchParams.get('photo');

    useEffect(() => {
        if (photoId) {
            setSelectedPhoto(documents.find((o) => o.id === parseInt(photoId, 10)));
        }
    }, [documents, photoId]);

    const photos = useMemo(
        () =>
            documents.filter((d) => d.category.title === 'Photo').sort((a, b) => new Date(b.modifiedOn).valueOf() - new Date(a.modifiedOn).valueOf()),
        [documents],
    );

    const cols = useMemo((): number => {
        if (xs) {
            return 2;
        }
        if (sm) {
            return 3;
        }
        if (md) {
            return 4;
        }
        return 5;
    }, [md, sm, xs]);

    const handleClick = useCallback(
        (e: React.MouseEvent, id: number) => {
            trackEvent('view photo', {id});
            setSearchParams({photo: String(id)});
        },
        [setSearchParams],
    );

    const handleClose = useCallback(() => {
        setSearchParams({});
        setSelectedPhoto(null);
        setNewPhotoDialogOpen(false);
    }, [setSearchParams]);

    const documentTableContext = useMemo(
        () => ({
            validCategories: photoCategory?.id,
            document: selectedPhoto,
            handleClose,
        }),
        [handleClose, photoCategory?.id, selectedPhoto],
    );

    return (
        <div id="photo-grid-root" className={styles.root}>
            <h4 className={styles.header}>Progress Photos</h4>
            <DocumentTableContext.Provider value={documentTableContext}>
                <Button onClick={(): void => setNewPhotoDialogOpen(true)} id="add-new-photo-button" color='primary' className="save-button">
                    <Add/> Add Photo
                </Button>
                {photos.length === 0 ? (
                    <NoDataPlaceholder title="No Photos" />
                ) : (
                    <ImageList cols={cols} rowHeight="auto" gap={8}>
                        {photos.map((p) => (
                            <ImageListItem key={p.url} onClick={(e): void => handleClick(e, p.id)}>
                                <img src={p.url} alt={p.title} />
                                <ImageListItemBar
                                    title={p.title}
                                    subtitle={
                                        <div>
                                            <p className={styles.description}>{p.description}</p>
                                            <p className={styles.timestamp}>{moment(p.modifiedOn).calendar()}</p>
                                        </div>
                                    }
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                )}
                <PhotoDetail />
                {photoCategory && <NewDocumentDialog open={newPhotoDialogOpen} />}
            </DocumentTableContext.Provider>
        </div>
    );
}

import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Zoom} from '@mui/material';
import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import {useStoreActions} from '~/store/storeHooks';
import styles from '~/styles/TeamAcknowledgedInfoDialog.module.scss';
import {Activity} from '~/types';

interface TeamAcknowledgedInfoDialogProps {
    open: boolean;
    notification: Activity;
    onCloseCallback: () => void;
}

export default function TeamAcknowledgedInfoDialog({open, notification, onCloseCallback}: TeamAcknowledgedInfoDialogProps): JSX.Element {
    const {updateAcknowledgedComment} = useStoreActions((actions) => actions.activity);
    const {acknowledgedBy, acknowledgedOn,acknowledgedComment, created} = notification
    const [notes,setNotes] = useState(acknowledgedComment);

    const handleClose = useCallback(
        (e: React.UIEvent): void => {
            e.stopPropagation();
            onCloseCallback();
            setNotes(acknowledgedComment)
        },
        [onCloseCallback,setNotes,acknowledgedComment],
    );

    const handleSubmit = useCallback(
        (e: React.UIEvent): void => {
            e.stopPropagation();
            updateAcknowledgedComment({...notification, acknowledgedComment: notes});
            onCloseCallback();
        },[onCloseCallback,updateAcknowledgedComment,notes,notification]
    )

    const timeToAck: string = useMemo(()=>{
        const diffInMinutes = moment(acknowledgedOn).diff(created,'minute');
        if (diffInMinutes > 1439) {
            return `${Math.round(diffInMinutes / 1440)} days`;
        } 
        if (diffInMinutes > 59) {
            return `${Math.round(diffInMinutes / 60)} hours`;
        }
        return `${diffInMinutes} minutes`;
    },[created,acknowledgedOn])

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="lg"
            fullWidth
            id="team-acknowledged-info-dialog-root"
            className={styles.root}
            TransitionComponent={Zoom}
        >
            <DialogTitle id="team-acknowledged-info-dialog-title">Team Acknowledged Info</DialogTitle>
            <DialogContent id="team-acknowledged-info-dialog-content">
                <Box component="form" sx={{'& > :not(style)': { m: 1, width: '33ch' }}}>
                    <TextField fullWidth label="Notifed On" variant="outlined" value={moment(created).calendar()} disabled/>
                    <TextField fullWidth label="First Acknowledged By" variant="outlined" value={acknowledgedBy?.fullName} disabled />
                    <TextField fullWidth label="First Acknowledged At" variant="outlined" value={moment(acknowledgedOn).calendar()} disabled/>
                    <TextField fullWidth label="Time To Acknowledged" variant="outlined" value={timeToAck} disabled/>
                </Box>
                <br/>
                <TextField fullWidth label="Team Notes" multiline variant="filled" rows={6} autoFocus value={notes} onChange={(e)=>setNotes(e.target.value)}/>
            </DialogContent>
            <DialogActions id="team-acknowledged-info-dialog-actions">
                <Button onClick={handleClose} className="close-button" id="team-acknowledged-info-close-button">
                    Close
                </Button>
                <Button onClick={handleSubmit} className="save-button" id="team-acknowledged-info-save-button">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

import AddIcon from '@mui/icons-material/Add';
import {Button} from '@mui/material';
import {GridRowClassNameParams, GridRowParams} from '@mui/x-data-grid-pro';
import {useCallback, useEffect, useState} from 'react';
import {useParams, useSearchParams} from 'react-router-dom';
import NewNeedDialog from './NewNeedDialog';
import DataGridProTable from '~/components/generics/DataGridProTable';
import NeedDialog from '~/components/subsite/correspondence/need/NeedDialog';
import NeedsTableCols from '~/components/subsite/correspondence/need/NeedsTableColsPro';
import LoadingComponent from '~/components/visualizations/LoadingComponent';
import {useStoreActions, useStoreState} from '~/store/storeHooks';
import styles from '~/styles/DataGridPro.module.scss';
import {DataGridProCustomExport} from '~/components/generics/DataGridProCustomExport';

const tableCacheVersion = 3;

export default function NeedsTable(): JSX.Element {
    const {projectId} = useParams<{projectId: string}>();
    const [, setSearchParams] = useSearchParams();
    const project = useStoreState((state) => state.subsite.project);
    const getNeeds = useStoreActions((actions) => actions.correspondence.getNeeds);
    const needs = useStoreState((state) => state.correspondence.needs);
    const [newNeedDialogOpen, setNewNeedDialogOpen] = useState<boolean>(false);
    const [busy, setBusy] = useState(false);
    const columns = NeedsTableCols(project);

    useEffect(() => {
        if (projectId) {
            setBusy(true);
            (async function fetchData(): Promise<void> {
                await getNeeds(parseInt(projectId, 10));
            })();
            setBusy(false);
        }
        return (): void => {
            setBusy(false);
        };
    }, [getNeeds, projectId]);

    const handleRowClick = useCallback(
        (params: GridRowParams): void => {
            setNewNeedDialogOpen(false);
            setSearchParams({need: String(params.row.id)});
        },
        [setSearchParams],
    );

    const getRowClassName = (params: GridRowClassNameParams) => {
        const {status, clientAssigned} = params.row;
        let cn = styles.row;
        if (status === 'Open' && clientAssigned) {
            cn = `${cn} ${styles.actionRequired}`;
        } else if (status === 'Open') {
            cn = `${cn} ${styles.actionable}`;
        } else {
            cn = `${cn} ${styles.readOnly}`;
        }
        if (params.row.descendent) cn = `${cn} ${styles.treeBranch}`;
        return cn;
    };

    const handleClose = () => {
        setNewNeedDialogOpen(false);
        setSearchParams({});
    };

    const addButton = (
        <Button onClick={(): void => setNewNeedDialogOpen(true)} id="add-new-need-button">
            <AddIcon /> Add Need
        </Button>
    );

    return (
        <div id="NEEDS-table-root">
            {busy ? (
                <LoadingComponent title="Loading Needs Data" contained />
            ) : (
                <>
                    <DataGridProTable
                        columns={columns}
                        data={needs}
                        optionKey="needsTable"
                        csvFileName={`${project?.code ?? 'Project'} Needs List.csv`}
                        cacheVersion={tableCacheVersion}
                        handleRowClick={handleRowClick}
                        getRowClassName={getRowClassName}
                        addButton={addButton}
                        excelOptions={{tableName: 'clientNeeds', projectId: parseInt(projectId, 10), projectCode: project?.code}}
                    />
                    <NeedDialog handleClose={handleClose} project={project} />
                    <NewNeedDialog open={newNeedDialogOpen} project={project} handleClose={handleClose} />
                </>
            )}
        </div>
    );
}

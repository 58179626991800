import {action, Action, thunk, Thunk} from 'easy-peasy';
import {axios} from '~/api';
import {AxiosTryCatchBlock} from '~/helpers';
import {Feedback} from '~/types';

export const DEFAULT_FEEDBACK: Feedback = {
    id: -1,
    linkNumber: null,
    projectCode: null,
    projectTitle: null,
    type: 'Feedback',
    reason: null,
    comments: null,
    featureRequested: null,
    clientRating: 10,
    clientComments: null,
    clientSatisfied: true,
    clientName: null,
};

const defaultTimeout = 30000;

export interface FeedbackModel {
    feedback: Feedback;
    setFeedback: Action<FeedbackModel, Feedback>;
    getFeedback: Thunk<FeedbackModel, string>;
    postFeedback: Thunk<FeedbackModel, Feedback>;
}

const feedbackModel: FeedbackModel = {
    feedback: DEFAULT_FEEDBACK,
    setFeedback: action((draftState, feedback) => {
        draftState.feedback = feedback;
    }),

    getFeedback: thunk((actions, guid) =>
        AxiosTryCatchBlock(async () => {
            const {data} = await axios.get(`/Feedback/{guid}`, {
                timeout: defaultTimeout,
            });
            if (data?.id) {
                actions.setFeedback(data);
            }
        }),
    ),

    postFeedback: thunk(async (actions, survey) => {
        try {
            const {data} = await axios.post('/PostFeedback', survey, {
                timeout: defaultTimeout,
            });
            const {feedback} = data;
            if (feedback) {
                actions.setFeedback(feedback);
            }
            return data;
        } catch (e) {
            return {
                result: 'error',
                message: 'Error while attempting to submit feedback survey - Please contact tech support',
            };
        }
    }),
};

export default feedbackModel;

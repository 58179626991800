import {createContext} from 'react';

type TimelineContextType = {
    setSize: (index: number, size: number) => void;
};

const TimelineContextTypeDefaultValue = {
    setSize: (): void => null,
};

const TimelineContext = createContext<TimelineContextType>(TimelineContextTypeDefaultValue);

export default TimelineContext;

import FileOpenOutlinedIcon from '@mui/icons-material/FileOpenOutlined';
import {Button, Chip, DialogActions, DialogContent, TextField} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import moment from 'moment';
import {useSnackbar} from 'notistack';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import * as React from 'react';
import {useForm, FormProvider} from 'react-hook-form';
import DocumentAttachments from './DocumentAttachments';
import { trackEvent } from '~/api';
import BusyButton from '~/components/generics/BusyButton';
import FileDropZone from '~/components/generics/FIleDropZone';
import RHFController from '~/components/generics/RHFController';
import DeleteDocumentButton from '~/components/subsite/documentTable/DeleteDocumentButton';
import DocumentActions from '~/components/subsite/documentTable/DocumentActions';
import DocumentTableContext from '~/components/subsite/documentTable/DocumentTableContext';
import {DisableMobileZoom, EnableMobileZoom, GetOptionLabel, getProjectDirectory, IsOptionEqualToValue} from '~/helpers';
import {useStoreActions, useStoreState} from '~/store/storeHooks';
import styles from '~/styles/DocumentDialog.module.scss';
import {Document, DocumentCategory, DocumentNewRev, DocumentStatus} from '~/types';

export default function DocumentInfoContent(): JSX.Element {
    const {document, validCategories, handleClose} = useContext(DocumentTableContext);
    const {url, modifiedBy, modifiedOn, editable, rev, attachments} = document;
    const methods = useForm<Document>({
        defaultValues: document,
    });
    const {handleSubmit, setValue, watch} = methods;

    const statuses = useStoreState((state) => state.document.statuses);
    const categories = useStoreState((state) => state.document.categories);
    const uploadFile = useStoreActions((actions) => actions.shared.uploadFile);
    const updateDocument = useStoreActions((actions) => actions.document.updateDocument);
    const [files, setFiles] = useState<File[]>([]);
    const [busy, setBusy] = useState(false);

    const watchNewRev = watch('rev');
    const {enqueueSnackbar} = useSnackbar();

    const categoryOptions = useMemo((): DocumentCategory[] => {
        if (typeof validCategories === 'number') {
            return categories.filter((o) => o.id === validCategories);
        }
        return categories.filter((o) => validCategories.includes(o.id));
    }, [categories, validCategories]);

    const statusOptions = useMemo((): DocumentStatus[] => {
        if (statuses.some((o) => o.categoryId === document.categoryId)) {
            return statuses.filter((o) => document.categoryId === o.categoryId);
        }
        return statuses.filter((o) => !o.categoryId);
    }, [document.categoryId, statuses]);

    // Disable zooming for this dialog, it's annoying whenever selecting any input
    useEffect(() => {
        DisableMobileZoom();
        return (): void => {
            EnableMobileZoom();
            setBusy(false);
        };
    }, []);

    const handleCategoryChange = useCallback(
        (e: React.ChangeEvent<{}>, value: string | DocumentCategory): void => {
            let newCategory = value;
            if (typeof newCategory === 'string') {
                newCategory = categoryOptions.find((o) => o.title === newCategory);
            }
            if (newCategory) {
                setValue('category', newCategory);
                setValue('categoryId', newCategory.id);
            }
        },
        [categoryOptions, setValue],
    );

    const handleStatusChange = useCallback(
        (e: React.ChangeEvent<{}>, value: string | DocumentStatus): void => {
            let newStatus = value;
            if (typeof newStatus === 'string') {
                newStatus = statusOptions.find((o) => o.title === newStatus);
            }
            if (newStatus) {
                setValue('status', newStatus);
                setValue('statusId', newStatus.id);
            }
        },
        [statusOptions, setValue],
    );

    const onSubmit = handleSubmit(async (data) => {
        let documentPayload: Document | DocumentNewRev = data;
        setBusy(true);
        if (data.rev !== document.rev) {
            // Handle new rev
            if (files.length > 0) {
                trackEvent('upload new document revision', {id: data.id, number: data.number, file: files[0].name});
                const projectDirectory = getProjectDirectory(data?.project?.projectNumber ,data?.project?.code);
                const uploadedFile = await uploadFile({destination: `/Development/Project/${projectDirectory}/`, file: files[0]});
                documentPayload = {
                    ...documentPayload,
                    ...uploadedFile,
                };
            }
        }
        
        trackEvent('update document', {id: data.id, number: data.number});
        const {result, message} = await updateDocument(documentPayload);
        
        setBusy(false);
        enqueueSnackbar(message, {variant: result});
        if (result !== 'error') {
            handleClose();
        }
    });

    const handleFileSelection = useCallback((f: File[]): void => {
        setFiles(f);
    }, []);

    return (
        <FormProvider {...methods}>
            <form onSubmit={onSubmit}>
                <div className={styles.header}>
                    <div>
                        <h3>Information</h3>
                        {!editable && <Chip label="Read Only" size="small" variant="outlined" />}
                    </div>
                    <p>All fields required</p>
                </div>
                <DialogContent id="document-info-dialog-root" className={styles.infoContent}>
                    <RHFController
                        render={({field}): JSX.Element => <TextField {...field} fullWidth label="Number" disabled={!editable} variant="standard" />}
                        name="number"
                        rules={{
                            required: 'Document number is required',
                        }}
                    />
                    <RHFController
                        render={({field}): JSX.Element => (
                            <TextField {...field} fullWidth label="Title" multiline disabled={!editable} variant="standard" />
                        )}
                        name="title"
                        rules={{
                            required: true,
                        }}
                    />
                    <RHFController
                        render={({field}): JSX.Element => <TextField {...field} fullWidth label="Revision" disabled={!editable} variant="standard" />}
                        name="rev"
                        rules={{
                            required: true,
                        }}
                    />
                    <RHFController
                        render={({field}): JSX.Element => (
                            <TextField {...field} fullWidth label="Description" multiline disabled={!editable} variant="standard" />
                        )}
                        name="description"
                        rules={{
                            required: true,
                        }}
                    />
                    <RHFController
                        render={({field}): JSX.Element => (
                            <Autocomplete<DocumentCategory>
                                {...field}
                                options={categoryOptions}
                                getOptionLabel={GetOptionLabel}
                                isOptionEqualToValue={IsOptionEqualToValue}
                                renderInput={(params): JSX.Element => <TextField label="Category" fullWidth variant="standard" {...params} />}
                                onChange={handleCategoryChange}
                                disabled={!editable}
                            />
                        )}
                        name="category"
                        rules={{
                            required: true,
                        }}
                    />
                    <RHFController
                        render={({field}): JSX.Element => (
                            <Autocomplete
                                {...field}
                                options={statusOptions}
                                getOptionLabel={GetOptionLabel}
                                isOptionEqualToValue={IsOptionEqualToValue}
                                renderInput={(params): JSX.Element => <TextField label="Status" fullWidth variant="standard" {...params} />}
                                onChange={handleStatusChange}
                                disabled={!editable}
                            />
                        )}
                        name="status"
                        rules={{
                            required: true,
                        }}
                    />
                    <FileDropZone callback={handleFileSelection} disabled={!watchNewRev || watchNewRev === rev} />
                    <p className={styles.lastModified}>{`Last modified ${moment(modifiedOn).calendar()} by ${modifiedBy}`}</p>
                    {attachments.length > 0 && <DocumentAttachments />}
                    <DocumentActions />
                </DialogContent>
                <DialogActions>
                    <div className={styles.iconButtonContainer}>
                        {url && (
                            <Button className="save-button" href={url} target="_blank" startIcon={<FileOpenOutlinedIcon/>}>
                                VIEW Document
                            </Button>
                        )}
                        <DeleteDocumentButton />
                    </div>
                    <Button onClick={handleClose} className="close-button" id="cancel-document-edit-button">
                        Cancel
                    </Button>
                    {editable && (
                        <BusyButton busy={busy} type="submit" className="save-button" id="save-document-edit-button">
                            Save
                        </BusyButton>
                    )}
                </DialogActions>
            </form>
        </FormProvider>
    );
}

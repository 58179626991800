import feedbackModel from './feedbackModel';
import changeManagementModel from './ChangeManagementModel';
import accountingModel from '~/store/accountingModel';
import activityModel from '~/store/activityModel';
import closeOutModel from '~/store/closeoutModel';
import commentModel from '~/store/commentModel';
import correspondenceModel from '~/store/correspondenceModel';
import dashboardModel from '~/store/dashboardModel';
import documentModel from '~/store/documentModel';
import progressModel from '~/store/progressModel';
import projectModel from '~/store/projectModel';
import sharedModel from '~/store/sharedModel';
import subsiteModel from '~/store/subsiteModel';
import {StoreModel} from '~/types';

const model: StoreModel = {
    shared: sharedModel,
    dashboard: dashboardModel,
    project: projectModel,
    accounting: accountingModel,
    document: documentModel,
    activity: activityModel,
    subsite: subsiteModel,
    comment: commentModel,
    progress: progressModel,
    correspondence: correspondenceModel,
    closeOut: closeOutModel,
    feedback: feedbackModel,
    change: changeManagementModel,
};

export default model;

import {GridInitialState } from '@mui/x-data-grid-pro';
import {action, Action, actionOn, ActionOn, computed, Computed, thunk, Thunk} from 'easy-peasy';
import isEqual from 'lodash/isEqual';
import round from 'lodash/round';
import {axios} from '~/api';
import {AxiosTryCatchBlock, CPPValidStatus} from '~/helpers';

import type {ApexChartSeries, HealthCriteria, ProgressRecord, Project, StoreModel, ScopeType, ProjectsList} from '~/types';

const defaultTimeout = 30000;

let filterHeaderLookup: {[field: string]: string} = {};
let initialProjectsList: ProjectsList[] = [];
let initialProjectsFilteredList: ProjectsList[] = [];

if (localStorage.cppProjectLogHeaderLookup) {
    try {
        filterHeaderLookup = JSON.parse(localStorage.cppProjectLogHeaderLookup);
    } catch (e) {
        delete localStorage.cppProjectLogHeaderLookup;
    }
}

if (localStorage.cppProjectsList) {
    try {
        initialProjectsList = JSON.parse(localStorage.cppProjectsList);
        if (localStorage.cppProjectsFilteredList) {
            initialProjectsFilteredList = JSON.parse(localStorage.cppProjectsFilteredList);
        }
    } catch (e) {
        delete localStorage.cppProjectsList;
        delete localStorage.cppProjectsFilteredList;
    }
}

const getProjectList: (pl: Project[]) => ProjectsList[] = (pl) => {
    const projectsList: ProjectsList[] = []; 
    pl.forEach((p: Project)=>{
        projectsList.push({
            project:p.clientProjectCode ? `${p.clientProjectCode} / ${p.code}` : p.code,
            title: p.title, 
            id:p.id
        });
    });
    return projectsList;
}
export interface ProjectModel {
    projects: Project[];
    projectSubset: Project[];
    projectsList: ProjectsList[];
    projectsFilteredList: ProjectsList[];
    projectSearchText: string;
    filterHeaderLookup: {[field: string]: string};
    scopeTypes: ScopeType[];
    progressRecords: ProgressRecord[]; // TODO: Move this to progress model
    setProjects: ActionOn<ProjectModel, StoreModel>;
    setProjectSearchText: Action<ProjectModel, string>;
    setProjectSubsiteData: ActionOn<ProjectModel, StoreModel>;
    standardHealthCriteria: HealthCriteria[];
    progressChartSeries: Computed<ProjectModel, (projectId: number) => {categories: string[]; series: ApexChartSeries[]}>; // TODO: Move this to progress model
    setProjectSubset: Action<ProjectModel, Project[]>;
    projectValueByStatusChartData: Computed<ProjectModel, {labels: string[]; series: number[]}>;
    activeProjectValueByTypeChartData: Computed<ProjectModel, {labels: string[]; series: number[]}>;
    setProjectProgress: Action<ProjectModel, ProgressRecord[]>; // TODO: Move this to progress model
    getProjectProgress: Thunk<ProjectModel, number>; // TODO: Move this to progress model
    setProjectList: Action<ProjectModel, Project[]>;
    getProjectsList: Thunk<ProjectModel, {includeArchived?: boolean}>;
    setStandardHealthCriteria: Action<ProjectModel, HealthCriteria[]>;
    getStandardHealthCriteria: Thunk<ProjectModel>;
    setScopeTypes: Action<ProjectModel, ScopeType[]>;
    getScopeTypes: Thunk<ProjectModel>;
    saveProjectLogHeaderLookup: Action<ProjectModel, {[field: string]: string}>;
    projectLogTableDefaultState: GridInitialState;
    setProjectLogTableDefaultState: Action<ProjectModel, GridInitialState>;
}



const projectModel: ProjectModel = {
    projects: [],
    projectSubset: [],
    projectsList: initialProjectsList,
    projectsFilteredList: initialProjectsFilteredList,
    projectSearchText: '',
    filterHeaderLookup,
    progressRecords: [],
    standardHealthCriteria: [],
    projectLogTableDefaultState: {},
    scopeTypes: [],
    setProjects: actionOn(
        (a, storeActions) => storeActions.dashboard.getInitialData.successType,
        (draftState, target) => {
            const {projects, progressRecords, standardHealthCriteria} = target.result;

            // Sort Asc
            const sortedProgressRecords = (progressRecords as ProgressRecord[])?.sort((a, b) => a.weekending.valueOf() - b.weekending.valueOf());

            // Transform
            const pTransform = (projects as Project[]).map((p) => ({
                ...p,
                percentComplete: round((sortedProgressRecords?.filter((a) => a.projectId === p.id).pop()?.cumulativeActual ?? 0) * 100, 1),
            }));

            const projectsList: ProjectsList[] = getProjectList(pTransform); 

            if (projects && projects.length > 0) {
                draftState.projects = pTransform;
                draftState.projectSubset = pTransform; 
                draftState.projectsList = projectsList;
                setTimeout(()=>{
                    try {
                        localStorage.cppProjectsList = JSON.stringify(projectsList)
                    }
                    catch(e) {
                        delete localStorage.cppProjectsList;
                    }
                },1);
            } else {
                draftState.projects = [];
                draftState.projectSubset = []; 
                draftState.projectsList = initialProjectsList;
            }
            if (progressRecords && progressRecords.length > 0) {
                draftState.progressRecords = sortedProgressRecords;
            } else {
                draftState.progressRecords = [];
            }
            if (standardHealthCriteria && standardHealthCriteria.length > 0) {
                draftState.standardHealthCriteria = standardHealthCriteria;
            } else {
                draftState.standardHealthCriteria = [];
            }
        },
    ),
    setProjectSubsiteData: actionOn(
        (a, storeActions) => storeActions.subsite.getSubsiteInitialData.successType,
        (draftState, target) => {
            const {project} = target.result;
            if (project) {
                draftState.projects = [project];
                draftState.projectSubset = [project];
            } 
        },
    ),
    setProjectSearchText: action((draftState, searchText) => {
        draftState.projectSearchText = searchText;
    }),
    setProjectSubset: action((draftState, projectSubset) => {
        // Check equality
        let update;
        if (projectSubset.length !== draftState.projectSubset.length) {
            update = true;
        } else {
            const currentIds = draftState.projectSubset.map((o) => o.id).sort();
            const newIds = projectSubset.map((o) => o.id).sort();
            update = !isEqual(currentIds, newIds);
        }
        if (update) {
            draftState.projectSubset = projectSubset;
            const updatedProjectsFilteredList: ProjectsList[] = getProjectList(projectSubset); 
            // If update was clearing a filter then subset length will match projectsList length
            if (updatedProjectsFilteredList?.length === draftState.projectsList?.length) {
                draftState.projectsFilteredList = [];
                setTimeout(()=>{
                    delete localStorage.cppProjectsFilteredList;
                },1);
            } else {
                draftState.projectsFilteredList = updatedProjectsFilteredList;
                setTimeout(()=>{
                    try {
                        localStorage.cppProjectsFilteredList = JSON.stringify(updatedProjectsFilteredList)
                    }
                    catch(e) {
                        delete localStorage.cppProjectsFilteredList;
                    }
                },1);
            }
        }
    }),
    progressChartSeries: computed((state) => (projectId: number): {categories: string[]; series: ApexChartSeries[]} => {
        const projectProgressRecords = state.progressRecords
            .filter((o) => o.projectId === projectId)
            .sort((a, b) => a.weekending.valueOf() - b.weekending.valueOf());
        const categories = projectProgressRecords.map((o) => new Date(o.weekending).toLocaleDateString());
        const actualCumulativeSeries: ApexChartSeries = {
            name: 'Actual',
            data: projectProgressRecords.map(({cumulativeActual}) => round(cumulativeActual * 100, 0)),
            type: 'area',
        };
        const planCumulativeSeries: ApexChartSeries = {
            name: 'Plan',
            data: projectProgressRecords.map(({cumulativePlan}) => round(cumulativePlan * 100, 0)),
            type: 'area',
        };
        const forecastCumulativeSeries: ApexChartSeries = {
            name: 'Forecast',
            data: projectProgressRecords.map(({cumulativeForecast}) => round(cumulativeForecast * 100, 0)),
            type: 'area',
        };

        const noData = actualCumulativeSeries.data.length === 0 && planCumulativeSeries.data.length === 0;
        return {
            categories,
            series: noData ? [] : [planCumulativeSeries, actualCumulativeSeries, forecastCumulativeSeries],
        };
    }),
    projectValueByStatusChartData: computed((state) => {
        const result = state.projectSubset.reduce((total, p): Map<string, number> => {
            const {status, foundationJob, proposalValue} = p;
            const statusName = CPPValidStatus(status.status);

            let val = 0;
            if (statusName === 'Pre Bid' || statusName === 'Proposal Submitted') {
                val = proposalValue || 0;
            } else if (foundationJob) {
                val = (foundationJob.originalContract || 0) + (foundationJob.totalApprovedIncomeAdj || 0);
            }

            if (val > 0) {
                total.set(statusName, (total.get(statusName) || 0) + val);
            }
            return total;
        }, new Map<string, number>());

        return {
            labels: Array.from(result.keys()),
            series: Array.from(result.values()),
        };
    }),
    activeProjectValueByTypeChartData: computed((state) => {
        const result = state.projectSubset
            .filter((o) => o.status.status === 'Active')
            .reduce((total, p): Map<string, number> => {
                const {category, foundationJob} = p;
                const categoryName = category.categoryGroup;
                if (foundationJob) {
                    const {originalContract, totalApprovedIncomeAdj} = foundationJob;
                    const totalValue = (originalContract || 0) + (totalApprovedIncomeAdj || 0);
                    total.set(categoryName, (total.get(categoryName) || 0) + totalValue);
                }
                return total;
            }, new Map<string, number>());
        return {
            labels: Array.from(result.keys()),
            series: Array.from(result.values()),
        };
    }),
    setProjectProgress: action((draftState, progressRecords) => {
        draftState.progressRecords = progressRecords;
    }),
    getProjectProgress: thunk((actions, projectId) =>
        AxiosTryCatchBlock(async () => {
            const {data} = await axios.get(`/ProjectProgress?projectId=${projectId}`, {timeout: defaultTimeout});
            actions.setProjectProgress(data);
        }),
    ),
    setStandardHealthCriteria: action((draftState, standardHealthCriteria) => {
        draftState.standardHealthCriteria = standardHealthCriteria;
    }),
    getStandardHealthCriteria: thunk((actions) =>
        AxiosTryCatchBlock(async () => {
            const {data} = await axios.get('/StandardHealthCriteria', {timeout: defaultTimeout});
            actions.setStandardHealthCriteria(data);
        }),
    ),
    setProjectList: action((draftState, projects) => {
        const projectsList: ProjectsList[] = getProjectList(projects); 
        if (projects && projects.length > 0) {
            draftState.projectsList = projectsList;
            setTimeout(()=>{
                try {
                    localStorage.cppProjectsList = JSON.stringify(projectsList)
                }
                catch(e) {
                    delete localStorage.cppProjectsList;
                }
            },1);
        } else {
            draftState.projectsList = initialProjectsList;
        }
    }),
    getProjectsList: thunk((actions, {includeArchived}) =>
        AxiosTryCatchBlock(async () => {
            const {data} = await axios.get(`/ProjectList?includeArchived=${includeArchived ? 'true' : 'false'}`, {timeout: defaultTimeout});
            actions.setProjectList(data);
        }),
    ),
    setScopeTypes: action((draftState, scopeTypes) => {
        draftState.scopeTypes = scopeTypes;
    }),
    getScopeTypes: thunk((actions) =>
        AxiosTryCatchBlock(async () => {
            const {data} = await axios.get('/ScopeTypes', {timeout: defaultTimeout});
            actions.setScopeTypes(data);
        }),
    ),
    saveProjectLogHeaderLookup: action((draftState, headerLookup) => {
        if (headerLookup) draftState.filterHeaderLookup = headerLookup;
        setTimeout(()=>{
            try {
                localStorage.cppProjectLogHeaderLookup = JSON.stringify({headerLookup});
            } catch (e) {
                delete localStorage.cppProjectLogHeaderLookup;
            }
        },1); 
    }),
    setProjectLogTableDefaultState: action((draftState, defaultState) => {
        draftState.projectLogTableDefaultState = defaultState;
    }),
};

export default projectModel;

import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useCallback} from 'react';
import * as React from 'react';
import {useStoreActions} from '~/store/storeHooks';
import styles from '~/styles/ChatReaction.module.scss';
import {Comment} from '~/types';

export const Reactions: Array<{key: string; icon: IconProp; color: string; action: string}> = [
    {
        key: 'thumbs-up',
        icon: ['fas', 'thumbs-up'],
        color: '#b5d5ee',
        action: 'liked',
    },
    {
        key: 'heart',
        icon: ['fas', 'heart'],
        color: '#FACBE0',
        action: 'loved',
    },
    {
        key: 'laugh-squint',
        icon: ['fad', 'laugh-squint'],
        color: '#f6d594',
        action: 'laughed at',
    },
    {
        key: 'surprise',
        icon: ['fad', 'surprise'],
        color: '#f6d594',
        action: 'surprised by',
    },
    {
        key: 'frown-open',
        icon: ['fad', 'frown-open'],
        color: '#f6d594',
        action: 'saddened by',
    },
    {
        key: 'angry',
        icon: ['fad', 'angry'],
        color: '#f69f79',
        action: 'angry at',
    },
];

interface ChatReactionProps {
    comment: Comment;
}

export default function ChatReaction({comment}: ChatReactionProps): JSX.Element {
    const reactToComment = useStoreActions((actions) => actions.comment.reactToComment);

    const handleClick = useCallback(
        async (e: React.MouseEvent, reaction: string): Promise<void> => {
            e.stopPropagation();
            await reactToComment({
                ...comment,
                reaction,
            });
        },
        [reactToComment,comment],
    );

    return (
        <div id="chat-reaction-root" className={styles.root}>
            {Reactions.map(({key, icon, color}) => (
                <FontAwesomeIcon
                    icon={icon}
                    color={color}
                    key={key}
                    className={styles.chatReaction}
                    size="2x"
                    onClick={(e): Promise<void> => handleClick(e, key)}
                />
            ))}
        </div>
    );
}

import {action, Action, thunk, Thunk} from 'easy-peasy';
import {axios} from '~/api';
import {AxiosTryCatchBlock} from '~/helpers';
import type {Activity, NotificationCategory, NotificationDeliveryMethod} from '~/types';

const defaultTimeout = 30000;

export interface ActivityModel {
    activities: Activity[];
    notifications: Activity[];
    notificationCategories: NotificationCategory[];
    notificationDeliveryMethod: NotificationDeliveryMethod;
    setActivities: Action<ActivityModel, Activity[]>;
    getActivities: Thunk<ActivityModel, number>;
    setNotifications: Action<ActivityModel, Activity[]>;
    setAcknowledgedComment: Action<ActivityModel, Activity>;
    updateAcknowledgedComment: Thunk<ActivityModel, Activity>;
    getNotifications: Thunk<ActivityModel, number>;
    setAcknowledged: Action<ActivityModel, Activity>;
    toggleAcknowledged: Thunk<ActivityModel,{notification: Activity; projectId: string} >;
    removeNotification: Thunk<ActivityModel, {projectId: number; notificationId: number}>;
    clearNotifications: Thunk<ActivityModel, number>;
    getNotificationSettings: Thunk<ActivityModel>;
    setNotificationSettings: Action<
        ActivityModel,
        {notificationDeliveryMethod: NotificationDeliveryMethod; notificationCategories: NotificationCategory[]}
    >;
    updateCategoryNotification: Thunk<ActivityModel, string>;
    updateDeliveryMethod: Thunk<ActivityModel, NotificationDeliveryMethod>;
}

const activityModel: ActivityModel = {
    activities: [],
    notifications: [],
    notificationCategories: [],
    notificationDeliveryMethod: {email: true, push: false},
    setActivities: action((draftState, activities) => {
        draftState.activities = activities;
    }),
    getActivities: thunk((actions, projectId) =>
        AxiosTryCatchBlock(async () => {
            const {data} = await axios.get(`/Activities?projectId=${projectId}`, {
                timeout: defaultTimeout,
            });
            actions.setActivities(data);
        }),
    ),
    setNotifications: action((draftState, notifications) => {
        draftState.notifications = notifications;
    }),
    setAcknowledgedComment: action((draftState, notification) => {
        const updatedNotifications = draftState.notifications;
        for (let i=0; i<updatedNotifications.length; i++) {
            if (notification.id === updatedNotifications[i].id) {
                updatedNotifications[i].acknowledgedComment = notification.acknowledgedComment;
                break;
            }
        }
        draftState.notifications = updatedNotifications;
    }),
    updateAcknowledgedComment: thunk((actions, notification) => {
        AxiosTryCatchBlock(async () => {
            actions.setAcknowledgedComment(notification)
            const { project, activityId, acknowledgedComment } = notification
            const projectId = project.id;
            const url = `/UpdateAcknowledgedComment`;
            const postData = {
                projectId,
                activityId,
                ackComments: acknowledgedComment
            }
            const {data} = await axios.post(url, postData, { timeout: defaultTimeout });
            const {notifications} = data;
            if (notifications?.length) actions.setNotifications(notifications);
            return data;
        })
    }),
    setAcknowledged: action((draftState, notification) => {
        const updatedNotifications = draftState.notifications
        for (let i=0; i<updatedNotifications?.length; i++) {
            if (updatedNotifications[i].id === notification.id) {
                updatedNotifications[i] = notification;
                break;
            }
        }
        draftState.notifications = updatedNotifications;
    }),
    toggleAcknowledged: thunk((actions, props) =>
        AxiosTryCatchBlock(async () => {
            const {notification,projectId} = props;
            const acknowledged = !notification.acknowledged;
            actions.setAcknowledged({ ...notification, acknowledged })
            const url: string = `/AcknowledgeNotification?projectId=${projectId}&notificationId=${notification.id}&acknowledged=${acknowledged}`;
            const postData = {
                projectId,
                notificationId: notification.id,
                acknowledged
            };
            const {data} = await axios.post(url, postData, { timeout: defaultTimeout });
            const {notifications} = data;
            if (notifications?.length) actions.setNotifications(notifications);
            return data;
        }),
    ),
    getNotifications: thunk((actions, projectId) =>
        AxiosTryCatchBlock(async () => {
            const {data} = await axios.get(`/Notifications?projectId=${projectId}`, {
                timeout: defaultTimeout,
            });
            actions.setNotifications(data);
        }),
    ),
    removeNotification: thunk((actions, {projectId, notificationId}) =>
        AxiosTryCatchBlock(async () => {
            const {data} = await axios.get(`/DeleteNotification?projectId=${projectId}&notificationId=${notificationId}`, {
                timeout: defaultTimeout,
            });
            const {notifications} = data;
            actions.setNotifications(notifications);
            return data;
        }),
    ),
    clearNotifications: thunk((actions, projectId) =>
        AxiosTryCatchBlock(async () => {
            const {data} = await axios.get(`/ClearNotifications?projectId=${projectId}`, {
                timeout: defaultTimeout,
            });
            const {notifications} = data;
            actions.setNotifications(notifications);
            return data;
        }),
    ),
    setNotificationSettings: action((draftState, {notificationDeliveryMethod, notificationCategories}) => {
        draftState.notificationDeliveryMethod = notificationDeliveryMethod ?? {email: true, push: false};
        if (notificationCategories && notificationCategories.length > 0) {
            draftState.notificationCategories = notificationCategories;
        }
    }),
    getNotificationSettings: thunk((actions) =>
        AxiosTryCatchBlock(async () => {
            const {data} = await axios.get('/NotificationSettings', {
                timeout: defaultTimeout,
            });
            actions.setNotificationSettings(data);
        }),
    ),
    updateCategoryNotification: thunk((actions, category) =>
        AxiosTryCatchBlock(async () => {
            const {data} = await axios.post(
                '/UpdateCategoryNotification',
                {category},
                {
                    timeout: defaultTimeout,
                },
            );
            actions.setNotificationSettings(data);
        }),
    ),
    updateDeliveryMethod: thunk((actions, deliveryMethod) =>
        AxiosTryCatchBlock(async () => {
            const {data} = await axios.post('/UpdateDeliveryMethod', deliveryMethod, {
                timeout: defaultTimeout,
            });
            actions.setNotificationSettings(data);
        }),
    ),
};

export default activityModel;

import {Button, Typography} from '@mui/material';
import {useCallback} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import cppLogo from '../../../assets/img/cpp-logo-dark-mode.svg';
import hmLogo from '../../../assets/img/hm-logo-white.svg';
import classes from '../../../styles/FourOhFourPage.module.scss';
import {useTrackPage} from '~/api';
import styles from '~/styles/AccessRequestPage.module.scss';

export default function FourOhFourPage(): JSX.Element {
    const navigate = useNavigate();
    const {origin, href} = window.location;
    const withoutOrigin = href.replace(origin, '');

    useTrackPage('404');

    const clickHome = useCallback(() => {
        navigate('/');
    }, [navigate]);

    return (
        <div>
            <div className={styles.logoNavBar}>
                <Button color="primary" onClick={clickHome}>
                    <img src={cppLogo} alt="cpp-logo-dark" className={styles.logo} />
                </Button>
                <Button color="primary" onClick={clickHome}>
                    <img src={hmLogo} alt="hm-logo-white" className={styles.logo} />
                </Button>
            </div>
            <div className={classes.root}>
                <Typography variant="h4" className={classes.url}>
                    {withoutOrigin}
                </Typography>
                <h2>Page Not Found</h2>
                <p>If you believe this is a mistake, please contact technical support for assistance.</p>
                <a
                    href={`mailto:helpdesk@hm-ec.com?subject=Capital Projects Portal Support Request&body=Page Not Found Error for ${window.location.href}`}>
                    helpdesk@hm-ec.com
                </a>
                <Link to="/" className={classes.homeLink}>
                    Go Back
                </Link>
            </div>
        </div>
    );
}

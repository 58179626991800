import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Collapse, TextField} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import * as React from 'react';
import {useParams} from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import {VariableSizeList} from 'react-window';
import NavBarContext from '../app/navbar/NavBarContext';
import TimelineContext from './TimelineContext';
import TimelineEvent from '~/components/timeline/TimelineEvent';
import LoadingComponent from '~/components/visualizations/LoadingComponent';
import useSearch from '~/hooks/useSearch';
import {useStoreActions, useStoreState} from '~/store/storeHooks';
import styles from '~/styles/TimelineButton.module.scss';
import {Activity, Person} from '~/types';


const DEFAULT_ACTIVITY_ITEM_HEIGHT = 70;

function ReduceObjects<T>(arr: Array<string | T>): T[] {
    return arr.reduce((acc, curr) => {
        if (typeof curr === 'string') {
            return acc;
        }

        return [...acc, curr];
    }, []);
}

export default function TimelineButton(): JSX.Element {
    const {page, projectId} = useParams<{page: string; projectId: string}>();
    const getActivities = useStoreActions((actions) => actions.activity.getActivities);
    const activities = useStoreState((state) => state.activity.activities);
    const [selectionSubset, setSelectionSubset] = useState([]);
    const [subset, setSubset] = useState<Activity[]>([]);
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const [selectedPeople, setSelectedPeople] = useState<Person[]>([]);
    const [keywords, setKeywords] = useState('');
    const {expanded, setExpanded} = useContext(NavBarContext);
    const [busy, setBusy] = useState(true);
    const {searchArray} = useSearch();

    const listRef = useRef<VariableSizeList | null>(null);

    const sizeMap = React.useRef<{[key: string]: number}>({});

    const timeline = React.useMemo(
        () => ({
            setSize: (index: number, size: number) => {
                // Performance: Only update the sizeMap and reset cache if an actual value changed
                if (sizeMap.current[index] !== size) {
                    sizeMap.current = {...sizeMap.current, [index]: size};
                    if (listRef.current) {
                        // Clear cached data and rerender
                        listRef.current?.resetAfterIndex(0);
                    }
                }
            },
        }),
        [],
    );

    const getSize = React.useCallback((index: number) => sizeMap.current[index] || DEFAULT_ACTIVITY_ITEM_HEIGHT, []);

    const calcEstimatedSize = React.useCallback(() => {
        const keys = Object.keys(sizeMap.current);
        const estimatedHeight = keys.reduce((p, i) => p + sizeMap.current[i], 0);
        return estimatedHeight / keys.length;
    }, []);

    useEffect(() => {
        listRef.current?.resetAfterIndex(0);
    }, [subset]);

    const personOptions = useMemo(() => sortBy(uniqBy(map(activities, 'createdBy'), 'id'), 'fullName'), [activities]);

    const categoryOptions: string[] = useMemo(() => sortBy(uniq(map(activities, 'category'))), [activities]);

    useEffect(() => {
        (async function dataRequest(): Promise<void> {
            if (expanded === 'timeline') {
                setBusy(true);
                await getActivities(parseInt(projectId, 10));
                setBusy(false);
            }
        })();

        return (): void => {
            setBusy(false);
        };
    }, [expanded, getActivities, projectId]);

    // explicitly close on unmount if they switch to dashboard without closing and resetting scroll bar
    useEffect(() => ()=> {
        setExpanded('');
    }, [setExpanded]);

    useEffect(() => {
        let c: string[] = [];
        if (expanded === 'timeline') {
            switch (page) {
                case 'summary':
                    c = ['Info', 'Scope', 'Milestone', 'Summary'];
                    break;
                case 'hse':
                    c = ['HSE'];
                    break;
                case 'progress':
                    c = ['Progress Report', 'Photo', 'Progress', 'Schedule'];
                    break;
                case 'deliverables':
                    c = ['Deliverable', 'Vendor Data'];
                    break;
                case 'change':
                    c = ['Change Management'];
                    break;
                case 'correspondence':
                    c = ['RFI', 'TQ', 'TDR', 'Hold', 'Action', 'Correspondence', 'Note', 'Client Supplied Document'];
                    break;
                default:
                    break;
            }
            setSelectedCategories(c);
        }
        return (): void => setSelectedCategories([]);
    }, [page, expanded]);

    useEffect(() => {
        let s = activities ?? [];
        if (selectedCategories.length > 0) {
            s = activities.filter((a) => selectedCategories.includes(a.category));
        }
        if (selectedPeople.length > 0) {
            s = s.filter((p) => selectedPeople.some((sp) => sp.id === p.createdBy.id));
        }
        setSelectionSubset(s);
        setSubset(s);
        setKeywords('');
    }, [activities, selectedCategories, selectedPeople]);

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
        setKeywords(e.target.value);
    }, []);

    const handleEnterPressed = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>): void => {
            if (e.key === 'Enter') {
                e.preventDefault();
                const results = searchArray(selectionSubset, keywords);
                setSubset(results);
            }
        },
        [keywords, selectionSubset, searchArray],
    );

    const toggleExpanded = useCallback((): void => {
        setExpanded(expanded === 'timeline' ? '' : 'timeline');
    }, [setExpanded,expanded]);

    const unexpand = useCallback((): void => {
        setExpanded('');
    }, [setExpanded]);

    return (
        <div id="event-timeline-button" className={styles.root}>
            <IconButton id="timeline-button-root" onClick={toggleExpanded}>
                <FontAwesomeIcon icon={['far', 'history']} className={styles.timelineIcon} />
            </IconButton>
            <Collapse in={expanded === 'timeline'} timeout={800} className={styles.timelineCollapse} mountOnEnter unmountOnExit>
                <div className={styles.header}>
                    <h6>Event Timeline</h6>
                    <div className={styles.buttonContainer}>
                        <Button id="close-timeline-button" variant='text' color='inherit' onClick={unexpand}>
                            <p>Close</p>
                        </Button>
                    </div>
                </div>
                <div className={styles.content}>
                    <div className={styles.timelineFilters}>
                        <TextField
                            label="Press enter to search by keyword"
                            fullWidth
                            onChange={handleChange}
                            onKeyPress={handleEnterPressed}
                            size="small"
                            value={keywords}
                            variant="standard"
                        />
                        <div className={styles.selects}>
                            <Autocomplete
                                value={selectedCategories}
                                isOptionEqualToValue={(o, v): boolean => o === v}
                                renderInput={(params): JSX.Element => <TextField {...params} label="Category" variant="standard" />}
                                options={categoryOptions}
                                multiple
                                ChipProps={{size: 'small'}}
                                autoHighlight
                                onChange={(e, v): void => setSelectedCategories(v)}
                                limitTags={3}
                                fullWidth
                            />
                            <Autocomplete<Person, true>
                                getOptionLabel={(obj): string => (typeof obj === 'string' ? obj : obj.fullName)}
                                isOptionEqualToValue={(o, v): boolean => o.id === v.id}
                                renderInput={(params): JSX.Element => <TextField {...params} label="Person" variant="standard" />}
                                options={personOptions}
                                multiple
                                ChipProps={{size: 'small'}}
                                autoHighlight
                                onChange={(e, v): void => setSelectedPeople(ReduceObjects(v))}
                                limitTags={3}
                                fullWidth
                            />
                        </div>
                    </div>
                    <div className={styles.eventContainer}>
                        {busy ? (
                            <LoadingComponent />
                        ) : (
                            <AutoSizer>
                                {({width, height}): JSX.Element => (
                                    <TimelineContext.Provider value={timeline}>
                                        <VariableSizeList
                                            ref={listRef}
                                            height={height}
                                            itemSize={getSize}
                                            width={width}
                                            itemCount={subset.length}
                                            itemData={subset}
                                            estimatedItemSize={calcEstimatedSize()}
                                        >
                                            {TimelineEvent}
                                        </VariableSizeList>
                                    </TimelineContext.Provider>
                                )}
                            </AutoSizer>
                        )}
                    </div>
                    <IconButton onClick={toggleExpanded} className={styles.closeButton} aria-label="close">
                        <FontAwesomeIcon icon={['fad', 'chevron-double-up']} size="lg" />
                    </IconButton>
                </div>
            </Collapse>
        </div>
    );
}

import {memo} from 'react';
import {Navigate, Outlet, Route, Routes} from 'react-router';
import {BrowserRouter} from 'react-router-dom';
import ChatMobilePage from '../chat/ChatMobilePage';
import CloseOutPage from '../closeout/CloseOutPage';
import Dashboard from '../dashboard/Dashboard';
import NoDataPlaceholder from '../generics/NoDataPlaceholder';
import ProfilePage from '../profile/ProfilePage';
import COPrint from '../subsite/change/changeOrders/COPrint';
import ChangePanel from '../subsite/change/ChangePanel';
import CorrespondencePanel from '../subsite/correspondence/CorrespondencePanel';
import DeliverablesPanel from '../subsite/deliverables/DeliverablesPanel';
import HSEPanel from '../subsite/hse/HSEPanel';
import ProgressPanel from '../subsite/progress/ProgressPanel';
import ProjectPage from '../subsite/ProjectPage';
import SummaryPanel from '../subsite/summary/SummaryPanel';
import FeedBackSurvey from '../survey/FeedbackSurvey';
import {AccessRequestPage} from './AccessRequestPage';
import {FourOhFourPage} from './FourOhFourPage';
import {LoginPage} from './LoginPage';
import {PageFramework} from './PageFramework';
import {PrintFramework} from './PrintFramework';
import {RequireSubsiteAccess} from './RequireSubsiteAccess';
import LibraryPage from '~/components/app/Resources/LibraryPage';
import ProjectRequest from '~/components/contact/ProjectRequest';
import ServicePage from '~/components/contact/ServicePage';
import NotificationSettingsPage from '~/components/settings/notifications/NotificationSettingsPage';
import SettingsPage from '~/components/settings/SettingsPage';
import CorrespondenceDataFetch from '~/components/subsite/correspondence/CorrespondenceDataFetch';
import RFIPrint from '~/components/subsite/correspondence/rfi/RFIPrint';
import TDRPrint from '~/components/subsite/correspondence/tdr/TDRPrint';
import TQPrint from '~/components/subsite/correspondence/tq/TQPrint';

function AppRoutes(): JSX.Element {
    return (
        <BrowserRouter>
            <Routes>
                {/** Auth Routes */}
                <Route path="/login" element={<LoginPage />} />
                <Route path="/AccessRequest" element={<AccessRequestPage />} />

                {/** NonCpp Users Client Survey */}
                <Route path="/feedbacksurvey/:guid" element={<FeedBackSurvey />} />

                {/** Temp Re-routes (todo delete) */}
                <Route path="/project/:projectId/correspondence/rfi/:rfiId/print" element={<Navigate to="/print/:projectId/rfi/:rfiId/" />} />
                <Route path="/project/:projectId/correspondence/tq/:tqId/print" element={<Navigate to="/print/:projectId/tq/:tqId/" />} />
                <Route path="/project/:projectId/correspondence/tdr/:tdrId/print" element={<Navigate to="/print/:projectId/tdr/:tdrId/" />} />

                {/** Auth and Init Data Wrapper */}
                <Route
                    path="/"
                    element={
                        <PageFramework>
                            <Outlet />
                        </PageFramework>
                    }>
                    {/** Root Routes */}
                    <Route path="" element={<Dashboard />} />
                    <Route path="dashboard" element={<Navigate to=".." replace />} />
                    <Route path="settings">
                        <Route path="" element={<SettingsPage />} />
                        <Route path="notifications" element={<NotificationSettingsPage />} />
                    </Route>
                    <Route path="profile" element={<ProfilePage />} />
                    <Route path="opportunity" element={<ProjectRequest />} />
                    <Route path="customerService" element={<ServicePage />} />
                    <Route path="library" element={<LibraryPage />} />

                    {/** Project Subsite Routes */}
                    <Route path="project">
                        <Route path="" element={<Navigate to=".." replace />} />
                        <Route path=":projectId">
                            <Route path="" element={<Navigate to="summary" replace />} />
                        </Route>
                        <Route
                            path=":projectId"
                            element={
                                <RequireSubsiteAccess>
                                    <ProjectPage />
                                </RequireSubsiteAccess>
                            }>
                            <Route path="summary" element={<SummaryPanel />} />
                            <Route path="hse" element={<HSEPanel />} />
                            <Route path="progress" element={<ProgressPanel />} />
                            <Route path="deliverables" element={<DeliverablesPanel />} />
                            <Route path="change" element={<ChangePanel />} />
                            <Route path="correspondence" element={<CorrespondencePanel />} />
                            <Route path="closeout" element={<CloseOutPage />} />
                            <Route path="chat" element={<ChatMobilePage />} />
                            <Route path="*" element={<NoDataPlaceholder title="No Content Available" />} />
                        </Route>
                    </Route>
                </Route>

                {/** Print Preview Routes */}
                <Route
                    path="print/:projectId"
                    element={
                        <PrintFramework>
                            <Outlet />
                        </PrintFramework>
                    }>
                    <Route
                        path="change/:coId"
                        element={
                            <COPrint />
                        }
                    />
                    <Route
                        path="rfi/:rfiId"
                        element={
                            <CorrespondenceDataFetch type="rfi">
                                <RFIPrint />
                            </CorrespondenceDataFetch>
                        }
                    />
                    <Route
                        path="tq/:tqId"
                        element={
                            <CorrespondenceDataFetch type="tq">
                                <TQPrint />
                            </CorrespondenceDataFetch>
                        }
                    />
                    <Route
                        path="tdr/:tdrId"
                        element={
                            <CorrespondenceDataFetch type="tdr">
                                <TDRPrint />
                            </CorrespondenceDataFetch>
                        }
                    />
                </Route>

                <Route path="*" element={<FourOhFourPage />} />
            </Routes>
        </BrowserRouter>
    );
}

export default memo(AppRoutes);

import {Button, Checkbox, FormControlLabel, TextField, Typography} from '@mui/material';
import parse from 'html-react-parser';
import {useCallback, useEffect, useMemo, useRef} from 'react';
import {useParams} from 'react-router';
import {useNavigate} from 'react-router-dom';
import SignaturePad from 'react-signature-canvas';
import hmLogo from '../../../../assets/img/hm-logo-dark.svg';
import cppLogo from '~/assets/img/cpp-logo-light-mode.svg';
import {useGetStateFromPath} from '~/hooks/useGetStateFromPath';
import {useStoreState} from '~/store/storeHooks';
import styles from '~/styles/Print.module.scss';

export default function TDRPrint(): JSX.Element {
    const {projectId, tdrId} = useParams<{projectId: string; tdrId: string}>();
    const from = useGetStateFromPath(`/project/${projectId}`);
    const navigate = useNavigate();
    const tdrs = useStoreState((state) => state.correspondence.tdrs);
    const tdr = useMemo(() => tdrs.find((o: {id: number}) => tdrId && o.id === +tdrId), [tdrs, tdrId]);

    const project = useStoreState((state) => state.subsite.project);
    const canvasRef = useRef<SignaturePad>(null);

    useEffect(()=>{
        document.body.style.position = '';
        document.body.style.top = '';
    },[]);


    const {
        originator,
        number,
        created,
        type,
        description,
        rev,
        responseRequired,
        sketchAttached,
        picturesAttached,
        justification,
        impact,
        clientDisposition,
        accepted,
        responderName,
        responded,
        clientSignature,
        pmApproved,
        pmApprovedDate,
        disciplines,
        affectedDocuments,
    } = tdr;

    useEffect(() => {
        canvasRef.current.fromDataURL(clientSignature);
        canvasRef.current.off();
    }, [clientSignature]);

    const handlePrevious = useCallback(() => {
        navigate(from);
    }, [navigate, from]);

    const handlePrint = (): void => {
        window.print();
    };

    return (
        <div className={styles.root}>
            <div className={styles.controls}>
                <div>
                    <Button variant="outlined" onClick={handlePrevious}>
                        Previous
                    </Button>
                    <Button variant="outlined" onClick={handlePrint}>
                        Print
                    </Button>
                </div>
            </div>
            <div className={styles.paper}>
                <div className={styles.header}>
                    <img src={cppLogo} alt="CPP Logo Light Mode" className={styles.logo} />
                    <Typography>Technical Deviation Request</Typography>
                    <img src={hmLogo} alt="H+M Logo in blue" className={styles.logo} />
                </div>
                <div className={styles.content}>
                    <div className={styles.row}>
                        <TextField value={number} label="TDR Number" style={{width: 300}} variant="standard" />
                        <TextField value={rev} label="Rev" style={{width: 75}} variant="standard" />
                        <TextField value={originator} label="Originator" style={{flexGrow: 1}} variant="standard" />
                    </div>
                    <div className={styles.row}>
                        <TextField value={type} label="Code, Project Standard or Contract" style={{flexGrow: 1}} variant="standard" />
                        <TextField
                            value={disciplines.map((discipline: string) => discipline).join(', ')}
                            label="Disciplines"
                            style={{flexGrow: 1}}
                            variant="standard"
                        />
                    </div>
                    <div className={styles.row}>
                        <TextField value={project?.code} label="Project Code" style={{flexGrow: 1}} variant="standard" />
                    </div>
                    <div className={styles.sectionDivider}>
                        <Typography>Part I - By H+M</Typography>
                    </div>
                    <div className={styles.row}>
                        <TextField value={new Date(created).toLocaleDateString()} label="Date Initiated" style={{width: 120}} variant="standard" />
                        <TextField
                            value={responseRequired ? new Date(responseRequired).toLocaleDateString() : ''}
                            label="Response Required By"
                            style={{width: 180}}
                            variant="standard"
                        />
                        <TextField value={affectedDocuments.join(', ')} label="Affected Drawings" style={{flexGrow: 1}} variant="standard" />
                    </div>
                    <div className={styles.row} style={{flexDirection: 'column'}}>
                        <Typography className={styles.formControlLabel} style={{marginBottom: 8}}>
                            Deviation Request Description
                        </Typography>
                        <div className={`${styles.textAreaCondensed} sun-editor-editable`}>{parse(description.replaceAll(/contenteditable="\w*"/gm, ''))}</div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.checkBoxGroup}>
                            <Typography className={styles.formControlLabel}>Sketch Attached</Typography>
                            <FormControlLabel labelPlacement="end" label="Yes" control={<Checkbox checked={sketchAttached} />} />
                            <FormControlLabel labelPlacement="end" label="No" control={<Checkbox checked={!sketchAttached} />} />
                        </div>
                        <div className={styles.checkBoxGroup}>
                            <Typography className={styles.formControlLabel}>Pictures Attached</Typography>
                            <FormControlLabel labelPlacement="end" label="Yes" control={<Checkbox checked={picturesAttached} />} />
                            <FormControlLabel labelPlacement="end" label="No" control={<Checkbox checked={!picturesAttached} />} />
                        </div>
                    </div>
                    <div className={styles.row} style={{flexDirection: 'column'}}>
                        <Typography className={styles.formControlLabel} style={{marginBottom: 8}}>
                            Justification of requested deviation
                        </Typography>
                        <div className={`${styles.textAreaCondensed} sun-editor-editable`}>{parse(justification.replaceAll(/contenteditable="\w*"/gm, ''))}</div>
                    </div>
                    <div className={styles.row} style={{flexDirection: 'column'}}>
                        <Typography className={styles.formControlLabel} style={{marginBottom: 8}}>
                            Possible impact for H+M depending on client response
                        </Typography>
                        <div className={`${styles.textAreaCondensed} sun-editor-editable`}>{parse(impact.replaceAll(/contenteditable="\w*"/gm, ''))}</div>
                    </div>
                    <div className={styles.sectionDivider}>
                        <Typography>Part II - By Client</Typography>
                    </div>
                    <div className={styles.row} style={{flexDirection: 'column'}}>
                        <Typography className={styles.formControlLabel} style={{marginBottom: 8}}>
                            Disposition of request
                        </Typography>
                        <div className={`${styles.textAreaCondensed} sun-editor-editable`}>{parse(clientDisposition.replaceAll(/contenteditable="\w*"/gm, ''))}</div>
                    </div>
                    <div className={styles.checkBoxGroup}>
                        <Typography className={styles.formControlLabel}>Request is accepted</Typography>
                        <FormControlLabel labelPlacement="end" label="Yes" control={<Checkbox checked={accepted} />} />
                        <FormControlLabel labelPlacement="end" label="No" control={<Checkbox checked={!accepted} />} />
                    </div>
                    <div className={styles.canvasContainer}>
                        <SignaturePad
                            penColor="blue"
                            canvasProps={{
                                width: 900,
                                height: 100,
                            }}
                            ref={(ref): void => {
                                canvasRef.current = ref;
                            }}
                        />
                    </div>
                    <div className={styles.row}>
                        <TextField value={responderName} label={`Responder's Name`} style={{width: 300}} variant="standard" />
                        <TextField
                            value={responded ? new Date(responded).toLocaleDateString() : ''}
                            label="Date Responded"
                            style={{width: 180}}
                            variant="standard"
                        />
                    </div>
                    <div className={styles.sectionDivider}>
                        <Typography>Part III - By H+M</Typography>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.checkBoxGroup}>
                            <Typography className={styles.formControlLabel}>PM Approved</Typography>
                            <FormControlLabel labelPlacement="end" label="Yes" control={<Checkbox checked={pmApproved} />} />
                            <FormControlLabel labelPlacement="end" label="No" control={<Checkbox checked={!pmApproved} />} />
                        </div>
                        <TextField
                            value={pmApprovedDate ? new Date(pmApprovedDate).toLocaleDateString() : ''}
                            label="Date Approved"
                            style={{width: 180}}
                            variant="standard"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}


import useMediaQuery from '@mui/material/useMediaQuery';
import { GridColDef, GridRenderCellParams, getGridNumericOperators, getGridDateOperators, GridValueGetterParams } from '@mui/x-data-grid-pro';
import {useMemo} from 'react';
import { getDisciplineValue, getUniqueDisciplines, renderDiscipline } from '../CorrespondencGenerics';
import {getGridStringArrayAutocompleteOperators} from '~/components/generics/MUIDataGridCustomOperators';
import { HtmlToString, defaultMediaSizes } from '~/helpers';
import { useStoreState } from '~/store/storeHooks';
import {RFI} from '~/types';


export default function RFITableCols(): GridColDef<RFI>[] {
    const mediaSizes = defaultMediaSizes;
    const xxs = useMediaQuery(mediaSizes.xxs);
    const xs = useMediaQuery(mediaSizes.xs);
    const sm = useMediaQuery(mediaSizes.sm);
    const md = useMediaQuery(mediaSizes.md);
    const lg = useMediaQuery(mediaSizes.lg);
    const rfis = useStoreState((state) => state.correspondence.rfis);

    return useMemo(
        (): GridColDef<RFI>[] => [
            {
                headerName: 'Number',
                field: 'number',
                flex: 1,
                hide: xxs,
                minWidth: 120,
            },
            {
                headerName: 'Title',
                field: 'title',
                minWidth: 160,
                flex: 2
            },
            {
                headerName: 'Description',
                hide: true,
                field: 'description',
                valueGetter: (params: GridRenderCellParams): string => HtmlToString(params.value)?.replace(/,/g,'')
            },
            {
                headerName: 'Recipient',
                field: 'recipient',
                minWidth: 160,
                hide: xxs || xs,
                flex: 1,
                filterOperators: getGridStringArrayAutocompleteOperators(rfis?.length ? [ ... new Set(rfis.map((r)=> r.recipient))] : [])
            },
            {
                headerName: 'Disciplines',
                field: 'disciplines',
                hide: xxs || xs,
                minWidth: 280,
                valueGetter: (params: GridRenderCellParams): string => getDisciplineValue(params.value),
                renderCell: (params: GridRenderCellParams): any => renderDiscipline(params?.row?.disciplines),
                flex: 1,
                filterOperators: getGridStringArrayAutocompleteOperators(rfis?.length ? getUniqueDisciplines(rfis) : [], true)
            },
            {
                headerName: 'Rev',
                field: 'rev',
                width: 100,
                hide: xxs || xs || sm || md || lg,
                filterOperators: getGridNumericOperators(),
            },
            {
                headerName: 'Status',
                field: 'status',
                flex: 0.75,
                minWidth: 120,
                filterOperators: getGridStringArrayAutocompleteOperators([
                    'Closed',
                    'Open',
                ]),
            },
            {
                headerName: 'Initiated By',
                field: 'initiatedBy',
                hide: xxs || xs,
                flex: 1,
                minWidth: 200,
            },
            {
                headerName: 'Initiated',
                field: 'initiated',
                hide: xxs || xs || sm,
                renderCell: (params: GridRenderCellParams): string => (params.value ? new Date(params.value).toLocaleDateString() : ''),
                width: 120,
                filterOperators: getGridDateOperators(),
            },
            {
                headerName: 'Response Required',
                field: 'responseRequired',
                renderCell: (params: GridRenderCellParams): string => (params.value  ? new Date(params.value ).toLocaleDateString() : ''),
                hide: xxs || xs || sm,
                width: 180,
                filterOperators: getGridDateOperators(),
            },
            {
                headerName: 'Answered',
                field: 'answered',
                renderCell: (params: GridRenderCellParams): string => (params.value  ? new Date(params.value ).toLocaleDateString() : ''),
                hide: xxs || xs || sm || md,
                width: 120,
                filterOperators: getGridDateOperators(),
            },
            {
                headerName: 'Answered By',
                field: 'answeredBy',
                hide: xxs || xs || sm || md,
                width: 150,
            },
            {
                headerName: 'Attachments',
                field: 'attachments',
                renderCell: (params: GridRenderCellParams): string => (params.value?.length > 0 ? `${params.value.length} files` : ''),
                hide: xxs || xs || sm || md || lg,
                disableExport: true,
                width: 140,
            },
            {
                headerName: 'PM Approved',
                type: 'singleSelect',
                field: 'pmApproved',
                valueGetter: (params: GridValueGetterParams): string => params?.value ? 'Yes' : 'No',
                hide: xxs || xs || sm || md,
                width: 120,
                valueOptions: [
                    'Yes',
                    'No'
                ],
            },
        ],
        [md, sm, xs, xxs, lg, rfis],
    );
}

import round from 'lodash/round';
import {CPPValidStatus} from '~/helpers';
import {useStoreState} from '~/store/storeHooks';
import {FoundationJob, HealthCriteria, HealthResult, Project, ProjectHealthRecord} from '~/types';

interface UseProjectHealth {
    calculateOverallGrade: (project: Project) => HealthResult;
    calculateHSEGrade: (projectHealth: ProjectHealthRecord, foundationJob: FoundationJob, healthCriteria: HealthCriteria[]) => HealthResult;
    calculateBudgetScore: (project: Project) => HealthResult;
    calculateScheduleScore: (project: Project) => HealthResult;
    indicator: (grade: string) => {color: string; label: string};
}

const useProjectHealth = (): UseProjectHealth => {
    const standardHealthCriteria = useStoreState((state) => state.project.standardHealthCriteria);

    const indicator = (grade: string): {color: string; label: string} => {
        let color = '#474747';
        let label = 'Pending';
        switch (grade.toUpperCase()) {
            case 'A':
                color = '#83cb85';
                label = 'Healthy';
                break;
            case 'B':
                color = '#eaba72';
                label = 'Concerns';
                break;
            case 'C':
                color = '#d47473';
                label = 'Issues';
                break;
            default:
                break;
        }
        return {
            color,
            label,
        };
    };

    function calculateBudgetScore(project: Project): HealthResult {
        const defaultScore = {
            score: 'NA',
            grade: 'NA',
            indicator: indicator('NA'),
        };

        const {healthCriteria, projectHealth} = project;

        if (!projectHealth) return defaultScore;

        const {originalCost, totalApprovedCostAdj, totalActualCost, percentComplete, totalPreBidCost} = projectHealth;
        let budgetScore = 0;

        // if (project.id === 821 || project.id === 752 || project.id === 863 || project.id === 962) console.log(project.id,originalCost,totalApprovedCostAdj,totalActualCost)
        // Check if any progress has been made at all
        if (percentComplete?.actual > 0) {
            // Check if any hours have been spent
            if (totalActualCost > 0) {
                // Budget CPI => Actual % Complete∗(Original Budget+∑Change Order) / Actual Cost
                // Budget score calc is proportion of completed cost $ / actual $ - pre-bid costs, earned $ = percent complete x (current cost)
                budgetScore = (percentComplete.actual * (originalCost + totalApprovedCostAdj)) / (totalActualCost - (totalPreBidCost ?? 0));
            } else {
                // Would normally calculate to infinity but in this case we'll cap at 99
                budgetScore = 99;
            }
        }

        let i = 0;
        // Default values before loop
        let budgetPoints = 0;
        let budgetGrade = 'C';

        // Check if this project has custom health criteria, otherwise default to standard
        const criteria =
            healthCriteria && healthCriteria.length > 0
                ? healthCriteria.filter((o) => o.category === 'Budget')
                : standardHealthCriteria.filter((o) => o.category === 'Budget');

        // Check if criteria has been queried
        if (criteria.length === 0) {
            return defaultScore;
        }

        // Criteria is only 3 levels deep, that's why i < 2
        while (i < 2) {
            const {lowerLimit, grade, points} = criteria[i];
            // Criteria entries are sorted descending, so Grade A first, Grade C last, if the score is lower than the lower limit the grade progressively goes lower
            if (budgetScore < lowerLimit) {
                i++;
            } else {
                budgetPoints = points;
                budgetGrade = grade;
                break;
            }
        }
        // TEAMHUB-391
        // if (project.id === 821 || project.id === 752 || project.id === 863 || project.id === 962 || project.id === 1249 || project.id === 1250 || project.id === 1261) {
        //     budgetScore = 100;
        //     budgetGrade = 'A';
        //     budgetPoints = 100;
        // }

        return {
            score: budgetScore,
            grade: budgetGrade,
            points: budgetPoints,
            indicator: indicator(budgetGrade),
        };
    }

    //  Schedule SPI => Cumulative Actual Percent Complete / Cumulative Planned Percent Complete

    function calculateScheduleScore(project: Project): HealthResult {
        const defaultScore = {
            score: 'NA',
            grade: 'NA',
            indicator: indicator('NA'),
        };

        const {healthCriteria, projectHealth} = project;

        if (!projectHealth) return defaultScore;

        const {percentComplete} = projectHealth;
        let scheduleScore = 0;
        // Check if any progress has been made at all
        if (percentComplete?.plan > 0 && percentComplete?.actual) {
            // Schedule score is just cumulative percent complete / cumulative planned percent complete
            scheduleScore = percentComplete.actual / percentComplete.plan;
        } else if (percentComplete?.actual > 0) {
            // If cumulative percent complete > 0 while planned = 0, equal to inf but capped at 99
            scheduleScore = 99;
        }

        // Check if this project has custom health criteria, otherwise default to standard
        const criteria =
            healthCriteria && healthCriteria.length > 0
                ? healthCriteria.filter((o) => o.category === 'Schedule')
                : standardHealthCriteria.filter((o) => o.category === 'Schedule');
        let i = 0;

        // Default values before loop
        let schedulePoints = 0;
        let scheduleGrade = 'C';

        // Check if criteria has been queried
        if (criteria.length === 0) {
            return defaultScore;
        }

        // Criteria is only 3 levels deep, that's why i < 2
        while (i < 2) {
            const {lowerLimit, points, grade} = criteria[i];
            // Criteria entries are sorted descending, so Grade A first, Grade C last, if the score is lower than the lower limit the grade progressively goes lower
            if (scheduleScore < lowerLimit) {
                i++;
            } else {
                schedulePoints = points;
                scheduleGrade = grade;
                break;
            }
        }

        // TEAMHUB-391
        // if (project.id === 821 || project.id === 1056 || project.id === 1118 || project.id === 1037 || project.id === 1249 || project.id === 1250 || project.id === 1261 || project.id === 1224 || project.id === 1056) {
        //     scheduleScore = 100;
        //     scheduleGrade = 'A';
        //     schedulePoints = 100;
        // }

        return {
            score: scheduleScore,
            grade: scheduleGrade,
            points: schedulePoints,
            indicator: indicator(scheduleGrade),
        };
    }

    function calculateOverallGrade(project: Project): HealthResult {
        const {healthCriteria, foundationJob, status} = project;
        const isTM = foundationJob?.tm;
        const budgetResults = calculateBudgetScore(project);
        const scheduleResults = calculateScheduleScore(project);
        const overallPoints = isTM === false ? (scheduleResults.points || 0) + 1 : (budgetResults.points || 0) + (scheduleResults.points || 0);
        const criteria =
            healthCriteria && healthCriteria.length > 0
                ? healthCriteria.filter((o) => o.category === 'Overall')
                : standardHealthCriteria.filter((o) => o.category === 'Overall');
        let i = 0;

        // Default values before loop
        let overallGrade = 'C';

        const projectStatus = CPPValidStatus(status.status);

        // Check if criteria has been queried
        if (criteria.length === 0 || projectStatus === 'Pre Bid' || projectStatus === 'Proposal Submitted') {
            return {
                score: 'NA',
                grade: 'NA',
                indicator: indicator('Pending'),
            };
        }

        // Criteria is only 3 levels deep, that's why i < 2
        while (i < 2) {
            const {lowerLimit, grade} = criteria[i];
            // Criteria entries are sorted descending, so Grade A first, Grade C last, if the score is lower than the lower limit the grade progressively goes lower
            if (overallPoints < lowerLimit) {
                i++;
            } else {
                overallGrade = grade;
                break;
            }
        }
        return {
            score: overallPoints,
            grade: overallGrade,
            indicator: indicator(overallGrade),
        };
    }

    function calculateHSEGrade(projectHealth: ProjectHealthRecord, foundationJob: FoundationJob, healthCriteria: HealthCriteria[]): HealthResult {
        const {totalActualMh = 0} = foundationJob ?? {};
        const {
            t0Incidents = 0,
            t1Incidents = 0,
            t2Incidents = 0,
            t3Incidents = 0,
            t0UnwantedEvents = 0,
            t1UnwantedEvents = 0,
            t2UnwantedEvents = 0,
            t3UnwantedEvents = 0,
            goodCatches = 0,
        } = projectHealth ?? {};
        const score =
            (0.02 * t0Incidents +
                0.05 * t1Incidents +
                0.5 * t2Incidents +
                t3Incidents +
                (0.01 * t0UnwantedEvents + 0.025 * t1UnwantedEvents + 0.25 * t2UnwantedEvents + 0.5 * t3UnwantedEvents) -
                0.005 *
                    (t0Incidents +
                        t1Incidents +
                        t2Incidents +
                        t3Incidents +
                        t0UnwantedEvents +
                        t1UnwantedEvents +
                        t2UnwantedEvents +
                        t3UnwantedEvents +
                        goodCatches)) /
            (totalActualMh / 100000);

        let criteria = healthCriteria;
        if (criteria.length === 0) {
            criteria = standardHealthCriteria.filter((c) => c.category === 'HSE');
        }

        let i = 0;

        // Check if criteria's have been queried yet
        if (criteria.length === 0 || totalActualMh === 0) {
            return {
                score: 'NA',
                grade: 'NA',
                indicator: indicator('NA'),
            };
        }
        // Default values before loop
        let hseGrade = 'NA';
        let maxUpperLimit = 0;
        // Criteria is only 3 levels deep, that's why i < 3
        while (i < 3) {
            const {upperLimit, grade} = criteria[i];
            if (maxUpperLimit < upperLimit) {
                maxUpperLimit = upperLimit;
            }
            // Criteria entries are sorted descending, so Grade A first, Grade C last
            if (score > upperLimit) {
                i++;
            } else {
                hseGrade = grade;
                break;
            }
        }

        // Check if the score is greater than maxUpperLimit, and the grade should be 'C' if yes
        if (score > maxUpperLimit) {
            hseGrade = 'C';
        }

        return {
            score: score > maxUpperLimit ? 'MAX' : round(score, 2),
            grade: hseGrade,
            indicator: indicator(hseGrade),
        };
    }

    return {
        calculateOverallGrade,
        calculateHSEGrade,
        calculateBudgetScore,
        calculateScheduleScore,
        indicator,
    };
};

export default useProjectHealth;

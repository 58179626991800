import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {TextField} from '@mui/material';
import {useCallback, useState, useRef} from 'react';
import * as React from 'react';
import useSearch from '~/hooks/useSearch';
import {useStoreActions, useStoreState} from '~/store/storeHooks';
import styles from '~/styles/ProjectSearch.module.scss';

export default function ProjectSearch(): JSX.Element {
    const [keywords, setKeywords] = useState('');
    const [filtering, setFiltering] = useState(false);
    const setProjectSubset = useStoreActions((actions) => actions.project.setProjectSubset);
    const projects = useStoreState((state) => state.project.projects);
    const searchRef = useRef(null);
    const {searchArray} = useSearch();

    const handleSearch = useCallback(
        (searchValue: string): void => {
            const results = searchArray(projects, searchValue);
            setProjectSubset(results);
            if (searchValue?.length && !filtering) {
                setFiltering(true);
            } else if (filtering && !searchValue) {
                setFiltering(false);
            }
        },
        [projects, searchArray, setProjectSubset, filtering, setFiltering],
    );

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
        if (!e.target.value && filtering && keywords?.length) {
            handleSearch(e.target.value);
        }
        setKeywords(e.target.value);
        
    }, [keywords,filtering,handleSearch]);

    const handleEnterPressed = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>): void => {
            if (e.key === 'Enter') {
                e.preventDefault();
                handleSearch(keywords);
            }
        },
        [handleSearch,keywords],
    );

    const handleSearchClick = useCallback((e: React.SyntheticEvent): void => {
        searchRef.current.focus();
        handleSearch(keywords);
    }, [handleSearch,searchRef,keywords]);

    return (
        <TextField
            id="project-search-field"
            type="search"
            
            onChange={handleChange}
            onKeyPress={handleEnterPressed}
            variant="outlined"
            label="Press enter to search projects by keyword"
            fullWidth
            size="small"
            InputProps={{
                endAdornment: <FontAwesomeIcon icon={['fal', 'search']} className={styles.searchButton} onClick={handleSearchClick} />,
                inputProps:{
                    autoComplete:'off',
                    ref: searchRef
                }
            }}
            className={styles.root}
        />
    );
}

import {GridRowClassNameParams, GridRowParams} from '@mui/x-data-grid-pro';
import {useCallback, useEffect, useState} from 'react';
import {useParams, useSearchParams} from 'react-router-dom';
import DataGridProTable from '~/components/generics/DataGridProTable';
import TQDialog from '~/components/subsite/correspondence/tq/TQDialog';
import TQTableCols from '~/components/subsite/correspondence/tq/TQTableColsPro';
import LoadingComponent from '~/components/visualizations/LoadingComponent';
import {useStoreActions, useStoreState} from '~/store/storeHooks';
import styles from '~/styles/DataGridPro.module.scss';

const tableCacheVersion = 6;

export default function TQTable(): JSX.Element {
    const {projectId} = useParams<{projectId: string}>();
    const [, setSearchParams] = useSearchParams();
    const project = useStoreState((state) => state.subsite.project);
    const getTQs = useStoreActions((actions) => actions.correspondence.getTQs);
    const tqs = useStoreState((state) => state.correspondence.tqs);
    const [busy, setBusy] = useState(true);
    const columns = TQTableCols();

    useEffect(() => {
        if (projectId) {
            setBusy(true);
            (async function fetchData(): Promise<void> {
                await getTQs(parseInt(projectId, 10));
            })();
            setBusy(false);
        }
        return (): void => {
            setBusy(false);
        };
    }, [getTQs, projectId]);

    const handleRowClick = useCallback(
        (params: GridRowParams): void => {
            setSearchParams({tq: String(params.row.id)});
        },
        [setSearchParams],
    );

    const getRowClassName = (params: GridRowClassNameParams) => {
        const {status, answered} = params.row;
        let cn = styles.row;
        if (status === 'Open' && !answered) {
            cn = `${cn} ${styles.actionRequired}`;
        } else if (status === 'Open' && answered) {
            cn = `${cn} ${styles.actionable}`;
        } else {
            cn = `${cn} ${styles.readOnly}`;
        }
        if (params.row.descendent) cn = `${cn} ${styles.treeBranch}`;
        return cn;
    };

    return (
        <div id="TQ-table-root">
            {busy ? (
                <LoadingComponent title="Loading TQ Data" contained />
            ) : (
                <>
                    <DataGridProTable
                        columns={columns}
                        data={tqs}
                        optionKey="tqTable"
                        csvFileName={`${project?.code ?? 'Project'} TQ Log.csv`}
                        cacheVersion={tableCacheVersion}
                        handleRowClick={handleRowClick}
                        getRowClassName={getRowClassName}
                        excelOptions={{tableName: 'tqs', projectId: parseInt(projectId, 10), projectCode: project?.code}}
                    />
                    <TQDialog />
                </>
            )}
        </div>
    );
}

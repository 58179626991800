import differenceInDays from 'date-fns/differenceInDays';
import parse from 'html-react-parser';
import {useCallback, useMemo} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import SwipeStackCard from '~/components/generics/SwipeStackCard';
import {CleanSunEditorHTML, OnEnterPressWrapper} from '~/helpers';
import {useStoreState} from '~/store/storeHooks';
import styles from '~/styles/CorrespondenceCard.module.scss';
import {TDR} from '~/types';

interface TDRCardProps {
    index: number;
    tdr: TDR;
    projectLabel: boolean;
}

export default function TDRCard({index, tdr, projectLabel}: TDRCardProps): JSX.Element {
    const navigate = useNavigate();
    const projectSubset = useStoreState((state) => state.project.projectSubset);
    const projectCode = projectSubset.find((p) => p.id === tdr.projectId)?.code ?? 'Unknown Project Code';
    const href: string = useMemo(()=>`/project/${tdr.projectId}/correspondence?tdr=${tdr.id}`,[tdr.id, tdr.projectId])
    const handleClick = useCallback((): void => {
        navigate(href);
    }, [navigate, href]);

    return (
        <SwipeStackCard cardIndex={index}>
            <div className={styles.root}>
                <h4 className={styles.title}>
                    <Link to={href} onKeyDown={OnEnterPressWrapper(handleClick)}>
                        {`${tdr.number}`}
                    </Link>
                </h4>
                {projectLabel && <p className={styles.projectLabel}>{projectCode}</p>}
                <h6 className={styles.subheader}>Type</h6>
                <p>{tdr.type}</p>
                <h6 className={styles.subheader}>Description</h6>
                <div className={styles.description}>{parse(CleanSunEditorHTML(tdr.description))}</div>
                <p className={styles.openDuration}>{`Open ${differenceInDays(new Date(), tdr.created)} Days`}</p>
            </div>
        </SwipeStackCard>
    );
}

import useMediaQuery from '@mui/material/useMediaQuery';
import { GridColDef, GridRenderCellParams, getGridNumericOperators, getGridDateOperators, GridValueGetterParams } from '@mui/x-data-grid-pro';
import {useMemo} from 'react';
import { getDisciplineValue, renderDiscipline, getUniqueDisciplines } from '../CorrespondencGenerics';
import {getGridStringArrayAutocompleteOperators} from '~/components/generics/MUIDataGridCustomOperators';
import { defaultMediaSizes } from '~/helpers';
import { useStoreState } from '~/store/storeHooks';
import {TQ} from '~/types';

export default function TQTableCols(): GridColDef<TQ>[] {
    const mediaSizes = defaultMediaSizes;
    const xxs = useMediaQuery(mediaSizes.xxs);
    const xs = useMediaQuery(mediaSizes.xs);
    const sm = useMediaQuery(mediaSizes.sm);
    const md = useMediaQuery(mediaSizes.md);
    const lg = useMediaQuery(mediaSizes.lg);
    const tqs = useStoreState((state) => state.correspondence.tqs);

    return useMemo(
        (): GridColDef<TQ>[] => [
            {
                headerName: 'Number',
                field: 'number',
                flex: 1,
                minWidth: 120,
            },
            {
                headerName: 'Title',
                field: 'title',
                minWidth: 160,
                flex: 2
            },
            {
                headerName: 'Recipient',
                field: 'recipient',
                minWidth: 160,
                hide: xxs || xs,
                flex: 1,
                filterOperators: getGridStringArrayAutocompleteOperators(tqs?.length ? [ ... new Set(tqs.map((t)=> t.recipient))] : [])
            },
            {
                headerName: 'Disciplines',
                field: 'disciplines',
                hide: xxs || xs,
                minWidth: 280,
                valueGetter: (params: GridRenderCellParams): string => getDisciplineValue(params.value),
                renderCell: (params: GridRenderCellParams): any => renderDiscipline(params?.row?.disciplines),
                flex: 1,
                filterOperators: getGridStringArrayAutocompleteOperators(tqs?.length ? getUniqueDisciplines(tqs) : [], true)
            },
            {
                headerName: 'Rev',
                field: 'rev',
                width: 100,
                hide: xxs || xs || sm,
                filterOperators: getGridNumericOperators(),
            },
            {
                headerName: 'Status',
                field: 'status',
                flex: 0.75,
                minWidth: 120,
                filterOperators: getGridStringArrayAutocompleteOperators([
                    'Closed',
                    'Open',
                ]),
            },
            {
                headerName: 'Initiated By',
                field: 'initiatedBy',
                hide: xxs || xs,
                flex: 1,
                minWidth: 200,
            },
            {
                headerName: 'Initiated',
                field: 'initiated',
                hide: xxs || xs || sm,
                renderCell: (params: GridRenderCellParams): string => (params.value ? new Date(params.value).toLocaleDateString() : ''),
                width: 120,
                filterOperators: getGridDateOperators(),
            },
            {
                headerName: 'Response Required',
                field: 'responseRequired',
                renderCell: (params: GridRenderCellParams): string => (params.value  ? new Date(params.value ).toLocaleDateString() : ''),
                hide: xxs || xs || sm,
                width: 180,
                filterOperators: getGridDateOperators(),
            },
            {
                headerName: 'Answered',
                field: 'answered',
                renderCell: (params: GridRenderCellParams): string => (params.value  ? new Date(params.value ).toLocaleDateString() : ''),
                hide: xxs || xs || sm || md,
                width: 120,
                filterOperators: getGridDateOperators(),
            },
            {
                headerName: 'Answered By',
                field: 'answeredBy',
                hide: xxs || xs || sm || md,
                width: 150,
            },
            {
                headerName: 'Attachments',
                field: 'attachments',
                renderCell: (params: GridRenderCellParams): string => (params.value?.length > 0 ? `${params.value.length} files` : ''),
                hide: xxs || xs || sm || md || lg,
                disableExport: true,
                width: 140,
            },
            {
                headerName: 'PM Approved',
                type: 'singleSelect',
                field: 'pmApproved',
                valueGetter: (params: GridValueGetterParams): string => params?.value ? 'Yes' : 'No',
                hide: xxs || xs || sm || md,
                width: 120,
                valueOptions: [
                    'Yes',
                    'No'
                ],
            },
        ],
        [md, sm, xs, xxs, lg, tqs],
    );
}

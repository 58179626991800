import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, DialogActions, DialogContent, TextField} from '@mui/material';
import {useMemo, useCallback, useContext, useEffect, useRef, useState} from 'react';
import * as React from 'react';
import {useParams, useSearchParams} from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import {VariableSizeList} from 'react-window';
import NoDataPlaceholder from '~/components/generics/NoDataPlaceholder';
import DocumentTableContext from '~/components/subsite/documentTable/DocumentTableContext';
import TimelineContext from '~/components/timeline/TimelineContext';
import TimelineEvent from '~/components/timeline/TimelineEvent';
import LoadingComponent from '~/components/visualizations/LoadingComponent';
import {DisableMobileZoom, EnableMobileZoom} from '~/helpers';
import useSearch from '~/hooks/useSearch';
import {useStoreActions, useStoreState} from '~/store/storeHooks';
import styles from '~/styles/DocumentDialog.module.scss';

const DEFAULT_ACTIVITY_ITEM_HEIGHT = 70;

export default function DocumentHistory(): JSX.Element {
    const {handleClose} = useContext(DocumentTableContext);
    const {projectId} = useParams<{projectId: string}>();
    const [searchParams] = useSearchParams();
    const getActivities = useStoreActions((actions) => actions.activity.getActivities);
    const activities = useStoreState((state) => state.activity.activities);
    const [keywords, setKeywords] = useState('');
    const [documentActivities, setDocumentActivities] = useState([]);
    const [activitySubset, setActivitySubset] = useState(documentActivities);
    const [busy, setBusy] = useState(true);
    const {searchArray} = useSearch();

    const docId = searchParams.get('doc');

    useEffect(() => {
        (async function dataRequest(): Promise<void> {
            setBusy(true);
            await getActivities(parseInt(projectId, 10));
            setBusy(false);
        })();

        return (): void => {
            setBusy(false);
        };
    }, [getActivities, projectId]);

    useEffect(() => {
        const id = parseInt(docId, 10);
        const validActivities = activities.filter((o) => o.document && o.document.id === id);
        setDocumentActivities(validActivities);
        setActivitySubset(validActivities);
        return (): void => setDocumentActivities([]);
    }, [activities, docId]);

    // Disable zooming for this dialog, it's annoying whenever selecting any input
    useEffect(() => {
        DisableMobileZoom();
        return (): void => EnableMobileZoom();
    }, []);

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
        setKeywords(e.target.value);
    }, []);

    const handleEnterPressed = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>): void => {
            if (e.key === 'Enter') {
                e.preventDefault();
                const results = searchArray(documentActivities, keywords);
                setActivitySubset(results);
            }
        },
        [searchArray, documentActivities, keywords],
    );

    const handleClick = useCallback((e: React.MouseEvent<HTMLInputElement>): void => {
        if (e.target) {
            (e.target as HTMLInputElement).select();
        }
    }, []);

    const listRef = useRef<VariableSizeList | null>(null);

    const sizeMap = useRef<{[key: string]: number}>({});

    const timelineContext = useMemo(
        () => ({
            setSize: (index: number, size: number) => {
                // Performance: Only update the sizeMap and reset cache if an actual value changed
                if (sizeMap.current[index] !== size) {
                    sizeMap.current = {...sizeMap.current, [index]: size};
                    if (listRef.current) {
                        // Clear cached data and rerender
                        listRef.current?.resetAfterIndex(0);
                    }
                }
            },
        }),
        [],
    );

    const getSize = useCallback((index: number) => sizeMap.current[index] || DEFAULT_ACTIVITY_ITEM_HEIGHT, []);

    const calcEstimatedSize = useCallback(() => {
        const keys = Object.keys(sizeMap.current);
        const estimatedHeight = keys.reduce((p, i) => p + sizeMap.current[i], 0);
        return estimatedHeight / keys.length;
    }, []);

    useEffect(() => {
        listRef.current?.resetAfterIndex(0);
    }, [activitySubset]);

    let activityList: JSX.Element | null = null;
    if (busy) {
        activityList = <LoadingComponent />;
    } else if (activitySubset.length === 0) {
        activityList = <NoDataPlaceholder title="No Matching History" />;
    } else {
        activityList = (
            <AutoSizer>
                {({width, height}): JSX.Element => (
                    <TimelineContext.Provider value={timelineContext}>
                        <VariableSizeList
                            ref={listRef}
                            height={height}
                            itemSize={getSize}
                            width={width}
                            itemCount={activitySubset.length}
                            itemData={activitySubset}
                            estimatedItemSize={calcEstimatedSize()}
                        >
                            {TimelineEvent}
                        </VariableSizeList>
                    </TimelineContext.Provider>
                )}
            </AutoSizer>
        );
    }

    return (
        <>
            <div className={styles.header}>History</div>
            <DialogContent id="document-history-content-root" className={styles.documentHistoryContent}>
                <TextField
                    id="history-search-field"
                    onChange={handleChange}
                    onKeyPress={handleEnterPressed}
                    onMouseDown={handleClick}
                    label="Press enter to search history by keyword"
                    fullWidth
                    size="small"
                    InputProps={{
                        endAdornment: <FontAwesomeIcon icon={['fal', 'search']} />,
                    }}
                    className={styles.historySearch}
                />
                <div className={styles.eventContainer}>{activityList}</div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} className="close-button" id="cancel-document-edit-button">
                    Cancel
                </Button>
            </DialogActions>
        </>
    );
}

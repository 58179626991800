import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
    Button,
    Checkbox,
    FormControlLabel,
    IconButton,
    InputAdornment,
    TextField,
    TextFieldProps,
    CheckboxProps,
    IconButtonProps,
} from '@mui/material';
import {useCallback, useEffect, useState, SyntheticEvent} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import classes from './UserLogin.module.scss';
import {trackEvent, useAuth} from '~/api';
import cppLogo from '~/assets/img/cpp-logo-dark-mode.svg';
import PasswordResetControl from '~/components/profile/PasswordResetControl';
import {useGetStateFromPath} from '~/hooks/useGetStateFromPath';

export default function UserLogin(): JSX.Element {
    const auth = useAuth();
    const navigate = useNavigate();
    const from = useGetStateFromPath('/');

    const [email, setEmail] = useState(() => localStorage.getItem('last-email') || '');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [rememberMe, setRememberMe] = useState(true);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        if (auth.isAuthenticated) {
            navigate(from, {replace: true});
        }
    }, [auth.isAuthenticated, from, navigate]);

    const handleLogin = useCallback(
        async (event: SyntheticEvent) => {
            event.preventDefault();

            if (!email) {
                setEmailError('Please enter your email.');
                return false;
            }

            if (!password) {
                setPasswordError('Please enter your password.');
                return false;
            }

            try {
                await auth.signIn(email, password, rememberMe, (user) => {
                    trackEvent('sign in', {
                        email: user.email,
                        name: user.displayName,
                    });

                    localStorage.setItem('last-email', email);
                    navigate(from, {replace: true});
                });
            } catch (err) {
                const [type, message] = auth.app.handleAuthError(err);

                if (type === 'password') {
                    setPasswordError(message);
                } else {
                    setEmailError(message);
                }
            }

            return false;
        },
        [email, password, auth, rememberMe, navigate, from],
    );

    const handleEmailChange = useCallback<TextFieldProps['onChange']>((event) => {
        setEmail(event.target.value);
        setEmailError('');
        setPasswordError('');
    }, []);

    const handlePasswordChange = useCallback<TextFieldProps['onChange']>((event) => {
        setPassword(event.target.value);
        setEmailError('');
        setPasswordError('');
    }, []);

    const handleRememberMeClick = useCallback<CheckboxProps['onChange']>((event) => {
        setRememberMe(event.target.checked);
    }, []);

    const handleShowPasswordClick = useCallback<IconButtonProps['onClick']>(() => {
        setShowPassword((value) => !value);
    }, []);

    return (
        <form className={classes.root} onSubmit={handleLogin}>
            <img src={cppLogo} alt="cpp-logo-dark" className={classes.logo} />
            <TextField
                name="email"
                label="Email"
                value={email}
                onChange={handleEmailChange}
                type="text"
                className={classes.input}
                error={!!emailError}
                helperText={emailError}
                fullWidth
                variant="standard"
            />
            <TextField
                name="password"
                label="Password"
                value={password}
                onChange={handlePasswordChange}
                type={showPassword ? 'text' : 'password'}
                className={classes.input}
                error={!!passwordError}
                helperText={passwordError}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton aria-label="toggle password visibility" onClick={handleShowPasswordClick}>
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                fullWidth
                variant="standard"
            />
            <FormControlLabel
                className={classes.input}
                control={<Checkbox checked={rememberMe} onChange={handleRememberMeClick} name="remember" color="primary" />}
                label="Remember me"
            />
            <Button className={classes.loginButton} onClick={handleLogin} type="submit">
                LOGIN
            </Button>
            <PasswordResetControl title="Forgot your password?" className={classes.forgotPassword} />
            <span className={classes.linkLabel}>Not a member?</span>
            <Link to="/AccessRequest" className={classes.link}>
                Request Access
            </Link>
        </form>
    );
}

import Tooltip from '@mui/material/Tooltip';
import {useCallback, useMemo} from 'react';
import {scroller} from 'react-scroll/modules';
import KPIGrid from '~/components/kpi/KPIGrid';
import {useStoreState} from '~/store/storeHooks';
import styles from '~/styles/KPI.module.scss';

interface ChangeManagementKPIProps {
    type: 'co';
    scrollTo?: string;
    tooltipTitle?: string;
    title: string;
}

ChangeManagementKPI.defaultProps = {
    scrollTo: '',
    tooltipTitle: '',
};

export default function ChangeManagementKPI({type, scrollTo, tooltipTitle, title}: ChangeManagementKPIProps): JSX.Element {
    const changeOrders = useStoreState((state) => state.change.changeOrders);

    const data = useMemo((): {title: string; count: number} => {
        switch (type) {
            case 'co':
                return {title, count: changeOrders.length};
            default:
                return {
                    title: 'KPI',
                    count: 0,
                };
        }
    }, [ changeOrders, type, title]);

    const handleClick = useCallback((): void => {
        if (scrollTo) {
            scroller.scrollTo(scrollTo, {
                smooth: true,
                duration: 500,
            });
        }
    }, [scrollTo]);

    return (
        <Tooltip title={tooltipTitle} arrow>
            <div>
                <KPIGrid onClick={handleClick} clickable>
                    <div className={styles.container}>
                        <h5 className={styles.title}>{data.title}</h5>
                        <h1 className={styles.count}>{data.count}</h1>
                    </div>
                </KPIGrid>
            </div>
        </Tooltip>
    );
}

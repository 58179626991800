import noDataLogo from '~/assets/img/data-not-found.svg';
import styles from '~/styles/NoDataPlaceholder.module.scss';

interface NoDataPlaceholderProps {
    title?: string;
}

NoDataPlaceholder.defaultProps = {
    title: 'No Data',
};

export default function NoDataPlaceholder({title}: NoDataPlaceholderProps): JSX.Element {
    return (
        <div className={styles.root}>
            <div>
                <img src={noDataLogo} alt="data-not-found" />
                <p>{title}</p>
            </div>
        </div>
    );
}

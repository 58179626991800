import { TextField } from "@mui/material";
import { Box } from "@mui/system";
import {useStoreActions, useStoreState} from '~/store/storeHooks';
import styles from '~/styles/ProjectLogTable.module.scss';


export function ProjectLogTableSearchBar() {
    const {projectSearchText,projects} = useStoreState((state) => state.project);
    const {setProjectSearchText} = useStoreActions((actions) => actions.project)

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setProjectSearchText(e.target.value);
    }

    if (!projects?.length) return <></>

    return (
        <Box sx={{float: 'right'}}>
            <TextField  
                id='projectLogSearchBar'
                autoComplete="off"
                variant="filled" 
                placeholder='Search projects' 
                spellCheck={false}
                type='search' 
                value={projectSearchText} 
                className={styles.searchContainer} 
                inputProps={{className:styles.searchBar}} 
                size='small' 
                color='primary'
                onChange={handleSearch}/>
        </Box>
    )
}
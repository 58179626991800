import {createRoot} from 'react-dom/client';
import {App} from './components';
import reportWebVitals from './reportWebVitals';
import SetupExternal from './SetupExternal';

SetupExternal();

const root = createRoot(document.getElementById('root')!);
root.render(<App />);

reportWebVitals();

import {useContext, useEffect, useState} from 'react';
import ProgressCard from '~/components/dashboard/project-log/ProgressCard';
import ProjectContext from '~/components/dashboard/project-log/ProjectContext';
import SummaryCard from '~/components/generics/SummaryCard';
import LoadingComponent from '~/components/visualizations/LoadingComponent';
import {useStoreActions} from '~/store/storeHooks';

export default function SummaryProgressCard(): JSX.Element {
    const {project} = useContext(ProjectContext);
    const getProjectProgress = useStoreActions((actions) => actions.project.getProjectProgress);
    const [fetchingProgressRecords, setFetchingProgressRecords] = useState(false);

    useEffect(() => {
        if (project) {
            (async function fetchData(): Promise<void> {
                setFetchingProgressRecords(true);
                await getProjectProgress(project.id);
                setFetchingProgressRecords(false);
            })();
        }
        return (): void => {
            setFetchingProgressRecords(false);
        };
    }, [getProjectProgress, project]);

    if (project?.trackerJob) return <></>;

    return <SummaryCard>{fetchingProgressRecords ? <LoadingComponent title="Fetching Progress Records" contained/> : <ProgressCard />}</SummaryCard>;
}

import ExploreOffOutlinedIcon from '@mui/icons-material/ExploreOffOutlined';
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined';
import { Tooltip } from '@mui/material';
import React, {useCallback, useContext} from 'react';
import MilestoneContext from '../milestone/MilestoneContext';
import ProjectContext from '~/components/dashboard/project-log/ProjectContext';
import KPIGrid from '~/components/kpi/KPIGrid';
import styles from '~/styles/KPI.module.scss';


interface MilestoneCountKPIProps {
    scrollTo?: string;
}

MilestoneCountKPI.defaultProps = {
    scrollTo: ''
};

export default function MilestoneCountKPI({scrollTo}: MilestoneCountKPIProps): JSX.Element {
    const {showMilestones, setShowMilestones} = useContext(MilestoneContext);
    const {project} = useContext(ProjectContext);
    const {milestones} = project;


    const handleGridClick: (e: React.UIEvent)=> void = useCallback((e)=> {
        e.preventDefault();
        e.stopPropagation();
        if (!showMilestones) {
            setShowMilestones(true);
            localStorage.cppShowMilestones = 'true';
        } else {
            setShowMilestones(false);
            localStorage.cppShowMilestones = 'false';
        }
    },[showMilestones,setShowMilestones]);

    const handleIconClick: (e: React.UIEvent)=> void = useCallback((e)=> {
        e.preventDefault();
        e.stopPropagation();
        setShowMilestones(!showMilestones);
        localStorage.cppShowMilestones = !showMilestones ? 'true' : 'false';
    },[showMilestones,setShowMilestones])

    return (
        <Tooltip title={`Click to ${showMilestones ? 'hide' : 'show'} Milestones detail card below`} arrow>
            <div>
                <KPIGrid onClick={handleGridClick} clickable>
                    <div className={styles.container}>
                        <h5 className={styles.title}>Milestone</h5>
                        <div className={styles.exploreIcon}>
                        {
                            showMilestones
                            ?
                            <ExploreOutlinedIcon fontSize='inherit' color='inherit' onClick={handleIconClick}/>
                            :
                            <ExploreOffOutlinedIcon fontSize='inherit' color='disabled' onClick={handleIconClick}/>
                        }
                        </div>
                        <h1 className={styles.count}>{milestones?.length ? milestones.filter((milestone)=>!milestone.completed).length: 0}</h1>
                    </div>
                </KPIGrid>
            </div>
        </Tooltip>
    );
}

import { TextField } from "@mui/material";
import { Box } from "@mui/system";
import {useStoreActions, useStoreState} from '~/store/storeHooks';
import styles from '~/styles/DataGridPro.module.scss';


export function DataGridProSearchBar({optionKey}: {optionKey: string}) {
    const {tableSearchText} = useStoreState((state) => state.shared);
    const {setTableSearchText} = useStoreActions((actions) => actions.shared)

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTableSearchText({key: optionKey, searchText: e.target.value});
    }

    return (
        <Box sx={{float: 'right'}}>
            <TextField  
                id={optionKey}
                variant="filled" 
                placeholder='Search ...' 
                spellCheck={false}
                type='search' 
                autoComplete="off"
                value={tableSearchText?.[optionKey]} 
                className={styles.searchContainer} 
                inputProps={{className:styles.searchBar}} 
                size='small' 
                color='primary'
                onChange={handleSearch}/>
        </Box>
    )
}
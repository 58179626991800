/* eslint-disable no-console */
import {action, Action, thunk, Thunk} from 'easy-peasy';
import {axios, setAnaliticsIdentity} from '~/api';
import {AxiosTryCatchBlock} from '~/helpers';
import {Client, NewAccountRequest, Person, UploadedFile, UserFeedback, UserOptions} from '~/types';

const defaultTimeout = 30000;

const defaultUserOptions: UserOptions = {
    projectTableOptions: {
        defaultOpenInNewTab: false,
        defaultOpenToSummary: true,
        applyFilterToDocket: true,
        filterProjectListOn: true,
        filterDocketOn: true,
        includeArchived: false,
        projectLogTableInitialState: {},
        cacheVersion: 0,
    },
    feedbackOptions: {
        defaultCPPSpecialistFeedback: true,
    },
};
export interface SharedModel {
    currentUser: Person;
    userOptions: UserOptions;
    clients: Client[];
    initUser: Thunk<SharedModel>;
    tableSearchText: {[key: string]: string};
    setTableSearchText: Action<SharedModel, {key: string; searchText: string}>;
    setUserOptions: Action<SharedModel, {option: keyof UserOptions; key: string; value: any}>;
    resetUserOptions: Action<SharedModel, {option: keyof UserOptions; value: any}>;
    setInitialData: Action<SharedModel, {currentUser: Person; clients: Client[]; options: {id: number; options: string}}>;
    getInitialData: Thunk<SharedModel>;
    getAttachment: Thunk<SharedModel, string>;
    downloadFile: Thunk<SharedModel, string>;
    uploadFiles: Thunk<SharedModel, {destination: string; files: File[]}, any, any, UploadedFile[] | Promise<UploadedFile[]>>;
    uploadFile: Thunk<SharedModel, {destination: string; file: File}, any, any, UploadedFile | Promise<UploadedFile>>;
    setCurrentUser: Action<SharedModel, Person>;
    updateProfileInformation: Thunk<SharedModel, Person>;
    postUserFeedback: Thunk<SharedModel, UserFeedback>;
    postNewAccountRequest: Thunk<SharedModel, NewAccountRequest>;
}

const sharedModel: SharedModel = {
    currentUser: null,
    userOptions: defaultUserOptions,
    clients: [],
    tableSearchText: {},
    initUser: thunk(async () => {
        try {
            const {data} = await axios.get('/InitUser', {timeout: defaultTimeout});
            return data;
        } catch (e) {
            return {
                result: 'error',
                message: 'Failed to init user - Please contact tech support',
            };
        }
    }),
    setTableSearchText: action((draftState, {key, searchText}) => {
        draftState.tableSearchText[key] = searchText;
    }),
    setUserOptions: action((draftState, updatedUserOptions) => {
        const {option, key, value} = updatedUserOptions;
        const newUserOptions = {
            ...draftState.userOptions,
            [option]: {
                ...draftState.userOptions[option],
                [key]: value,
            },
        };
        draftState.userOptions = newUserOptions;
        setTimeout(() => {
            try {
                const options = {options: JSON.stringify(newUserOptions)};
                AxiosTryCatchBlock(() => {
                    axios.post('/UpdateCPPOptions', options);
                });
            } catch (e) {
                console.error(e);
            }
        }, 0);
    }),
    resetUserOptions: action((draftState, updatedUserOptions) => {
        const {option, value} = updatedUserOptions;
        const newUserOptions = {
            ...draftState.userOptions,
            [option]: value,
        };
        draftState.userOptions = newUserOptions;
        setTimeout(() => {
            try {
                const options = {options: JSON.stringify(newUserOptions)};
                AxiosTryCatchBlock(() => {
                    axios.post('/UpdateCPPOptions', options);
                });
            } catch (e) {
                console.error(e);
            }
        }, 0);
    }),
    setInitialData: action((draftState, {currentUser, clients, options}) => {
        draftState.currentUser = currentUser;
        setAnaliticsIdentity(currentUser);

        if (clients && clients.length > 0) {
            draftState.clients = clients;
        } else {
            draftState.clients = [];
        }

        let userOptions: UserOptions = defaultUserOptions;
        if (options?.options?.length) {
            try {
                userOptions = JSON.parse(options.options);
            } catch (e) {
                console.error(e);
            }
        }

        draftState.userOptions = userOptions;
    }),
    getInitialData: thunk((actions) =>
        AxiosTryCatchBlock(async () => {
            const {data} = await axios.get('/InitialData', {
                timeout: defaultTimeout,
            });
            actions.setInitialData(data);
        }),
    ),
    getAttachment: thunk((_, url) =>
        AxiosTryCatchBlock(async () => {
            const {data} = await axios.get(`/GetDownloadUri?url=${url}`, {
                timeout: 60000,
            });
            window.open(data, '_blank');
        }),
    ),
    downloadFile: thunk(async (_, url) => {
        try {
            const response = await axios.get(`/DownloadFile`, {
                params: { url },
                responseType: 'blob'
            });

            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const link = document.createElement('a');
            
            const newFilename = url.replace(/^.*?\/\d+_([^/]+)$/,"$1");

            link.href = URL.createObjectURL(blob);
            link.download = newFilename;

            document.body.appendChild(link);

            link.click();
            document.body.removeChild(link);
        }
        catch (e) {
            console.error('Error downloading file:', e);
        }
    }),
    uploadFiles: thunk(async (_, {destination, files}) => {
        if (files.length > 0) {
            try {
                const uploaded: UploadedFile[] = await Promise.all(
                    files.map(async (f) => {
                        const data = new FormData();
                        const fileName = `${Date.now().toString()}_${f.name.replace(/[^a-zA-Z0-9_.]/g, '')}`;
                        const fileType = f.name.substring(f.name.lastIndexOf('.') + 1);
                        const fileUrl = `${destination}${destination.charAt(destination.length - 1) === '/' ? '' : '/'}${fileName}`;

                        data.append('fileUrl', fileUrl);
                        data.append('folderUrl', destination);
                        data.set('file', f, fileName);

                        await axios.post('/UploadFile', data, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        });
                        return {
                            fileUrl,
                            fileName,
                            fileType,
                        };
                    }),
                );
                return uploaded;
            } catch (e) {
                return [
                    {
                        fileUrl: null,
                        fileName: '',
                        fileType: '',
                        result: 'error',
                        message: 'Request to upload attachments failed - Try Again Later',
                    },
                ];
            }
        }
        return [];
    }),
    uploadFile: thunk(async (actions, {destination, file}) => {
        try {
            const data = new FormData();
            const fileName = `${Date.now().toString()}_${file.name.replace(/[^a-zA-Z0-9_.]/g, '')}`;
            const fileType = file.name.substring(file.name.lastIndexOf('.') + 1);
            const fileUrl = `${destination}${destination.charAt(destination.length - 1) === '/' ? '' : '/'}${fileName}`;

            data.append('fileUrl', fileUrl);
            data.append('folderUrl', destination);
            data.set('file', file, fileName);

            await axios.post('/UploadFile', data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return {
                fileUrl,
                fileName,
                fileType,
            };
        } catch (e) {
            return {
                fileUrl: null,
                fileName: '',
                fileType: '',
                result: 'error',
                message: 'Request to upload new file failed - Try Again Later',
            };
        }
    }),
    setCurrentUser: action((draftState, currentUser) => {
        draftState.currentUser = currentUser;
        setAnaliticsIdentity(currentUser);
    }),
    updateProfileInformation: thunk(async (actions, updatedPerson) => {
        try {
            const {data} = await axios.post('/UpdateProfileInformation', updatedPerson, {
                timeout: defaultTimeout,
            });
            const {currentUser} = data;
            actions.setCurrentUser(currentUser);
            return data;
        } catch (err) {
            return {
                result: 'error',
                message: 'Request to update profile information failed - If the issue persists please contact Technical Support',
            };
        }
    }),
    postUserFeedback: thunk(async (actions, userFeedback) => {
        try {
            const {data} = await axios.post('/PostUserFeedback', userFeedback, {
                timeout: defaultTimeout,
            });
            return data;
        } catch (e) {
            return {
                result: 'error',
                message: 'Sending User Feedback request failed - Please contact tech support',
            };
        }
    }),
    postNewAccountRequest: thunk(async (actions, newAccountRequest) => {
        try {
            const {data} = await axios.post('/PostNewAccountRequest', newAccountRequest, {
                timeout: defaultTimeout,
            });
            return data;
        } catch (e) {
            return {
                result: 'error',
                message: 'Sending New User Account Request request failed - Please contact tech support',
            };
        }
    }),
};

export default sharedModel;

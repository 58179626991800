import {Box, Button, TextField, Typography} from '@mui/material';
import {useEffect, useMemo} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {trackEvent, useTrackPage} from '~/api';
import RHFController from '~/components/generics/RHFController';
import {DisableMobileZoom, EnableMobileZoom} from '~/helpers';
import {useStoreState} from '~/store/storeHooks';
import styles from '~/styles/ProjectRequest.module.scss';
import {NewProjectRequest} from '~/types';

export default function ProjectRequest(): JSX.Element {
    const currentUser = useStoreState((state) => state.shared.currentUser);
    const client = useStoreState((state) => state.shared.clients[0]);

    useTrackPage('Project Opportunity');

    const DEFAULT_NEW_PROJECT_REQUEST: NewProjectRequest = useMemo(
        () => ({
            fullName: currentUser?.fullName ?? '',
            company: client?.name ?? '',
            email: currentUser?.email ?? '',
            phone: currentUser?.workPhone ?? '',
            projectDescription: '',
        }),
        [currentUser, client],
    );

    const methods = useForm<NewProjectRequest>({
        defaultValues: DEFAULT_NEW_PROJECT_REQUEST,
    });

    const {handleSubmit, reset} = methods;

    // Disable zooming for this dialog, it's annoying whenever selecting any input
    useEffect(() => {
        reset(DEFAULT_NEW_PROJECT_REQUEST);
        DisableMobileZoom();
        return (): void => {
            EnableMobileZoom();
        };
    }, [DEFAULT_NEW_PROJECT_REQUEST, reset]);

    const onSubmit = handleSubmit(async (data) => {
        const mailto = 'CPPSales@hm-ec.com';
        const subject = 'New Project Request';
        const cc = data.email;
        const bcc = 'cpp@hm-ec.com';
        const body = `New Project Request%0A%0A
        Full Name: ${encodeURIComponent(data.fullName)}%0A
        Company: ${encodeURIComponent(data.company)}%0A
        Email: ${data.email}%0A
        Phone: ${data.phone}%0A
        Project Description: ${data.projectDescription}%0A`;

        trackEvent('request new project', {email: data.email, company: data.company});

        window.location.href = `mailto:${mailto}?subject=${subject}&cc=${cc}&bcc=${bcc}&body=${body}`;
    });

    return (
        <>
            <div id="project-request-page-root" className={styles.root}>
                <h4 className={styles.header}>New Project Request</h4>
                <p className={styles.subheader}>
                    To learn more about our industry leading approach to project execution, or to schedule a consultation, please give us a call or
                    fill out the form below.
                </p>
                <p className={styles.required}>All fields required</p>
                <div>
                    <FormProvider {...methods}>
                        <form onSubmit={onSubmit}>
                            <RHFController
                                render={({field}): JSX.Element => <TextField {...field} fullWidth label="Full Name" variant="standard" />}
                                name="fullName"
                                rules={{
                                    required: true,
                                }}
                            />
                            <RHFController
                                render={({field}): JSX.Element => <TextField {...field} fullWidth label="Company" variant="standard" />}
                                name="company"
                                rules={{
                                    required: true,
                                }}
                            />
                            <RHFController
                                render={({field}): JSX.Element => <TextField {...field} fullWidth label="Email" variant="standard" />}
                                name="email"
                                rules={{
                                    required: true,
                                }}
                            />
                            <RHFController
                                render={({field}): JSX.Element => <TextField {...field} fullWidth label="Phone" variant="standard" />}
                                name="phone"
                                rules={{
                                    required: true,
                                }}
                            />
                            <RHFController
                                render={({field}): JSX.Element => (
                                    <TextField {...field} fullWidth label="Project Description" multiline variant="standard" />
                                )}
                                name="projectDescription"
                                rules={{
                                    required: true,
                                }}
                            />
                        </form>
                    </FormProvider>
                    <p className={styles.subheader}>* Please attach any scope documents via email.</p>
                    <div>
                        <Box my={2} className={styles.thankYou}>
                            <Typography align="center" variant="h4">
                                Thanks for considering H+M!
                            </Typography>
                            <Typography align="center" variant="subtitle1">
                                Available for all your design/build, engineering and construction needs.
                            </Typography>
                        </Box>
                    </div>
                    <div className={styles.submit}>
                        <Button onClick={onSubmit} type="submit" className="save-button">
                            Send via Email
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}

import moment from 'moment';
import {useContext, useEffect, useState} from 'react';
import {scroller} from 'react-scroll';
import ChatBubble from '~/components/chat/ChatBubble';
import ChatHistoryContext from '~/components/chat/ChatHistoryContext';
import NoDataPlaceholder from '~/components/generics/NoDataPlaceholder';
import styles from '~/styles/ChatHistory.module.scss';
import {Comment} from '~/types';

interface ChatHistoryProps {
    comments: Comment[];
    onLoadedCallback: () => void;
}

export default function ChatHistory({comments, onLoadedCallback}: ChatHistoryProps): JSX.Element {
    const days = Array.from(new Set(comments.map((o) => new Date(o.created).toLocaleDateString())));
    const {target, selectedComment} = useContext(ChatHistoryContext);
    const [selectedLast, setSelectedLast] = useState(null);

    useEffect(() => {
        if (comments.length > 0) {
            scroller.scrollTo(`last-message`, {
                smooth: true,
                duration: 500,
                containerId: 'comments-root',
            });
        }
    }, [comments.length]);

    useEffect(() => {
        onLoadedCallback();
    }, [onLoadedCallback]);

    let extraPadding = '';

    if (target === 'global') {
        extraPadding = styles.paddingBottom;
        if (selectedComment?.id) {
            extraPadding = styles.selectedPaddingBottom;
        } 
    }  

    // Selecting one of the last two messages for a reaction or response will leave
    // the message hidden behind the input if we don't scroll it into view after increasing the padding
    // by changing class to selectedPaddingBottom above.
    useEffect(() => {
        if (
            comments?.length && 
            selectedComment?.id &&
            (
                selectedComment.id === comments.at(-1)?.id ||
                selectedComment.id === comments[comments.length-2]?.id
            ) && selectedComment.id !== selectedLast) {
            setSelectedLast(selectedComment.id);
            scroller.scrollTo(`last-message`, {
                smooth: true,
                duration: 500,
                containerId: 'comments-root',
            });
        } else if (selectedLast && !selectedComment) {
            setSelectedLast(null);
        }
    }, [selectedComment,comments,selectedLast,setSelectedLast]);
    
    return (
        <div id="chat-history-root" className={`${styles.root} ${extraPadding}`}>
            {days.length > 0 ? (
                days.map((day, i, arr) => {
                    const lastDay = arr.length - 1 === i;
                    return (
                        <div className={styles.dateSection} key={day}>
                            <p>{day}</p>
                            {comments
                                .filter((c) => moment(c.created).isSame(moment(new Date(day).toISOString()), 'day'))
                                .map((o, j, cArr) => {
                                    const lastMessage = lastDay && cArr.length - 1 === j;
                                    return <ChatBubble comment={o} key={o.id} name={lastMessage ? 'last-message' : `${o.id}`} />;
                                })}
                        </div>
                    );
                })
            ) : (
                <NoDataPlaceholder title="No Messages" />
            )}
        </div>
    );
}

import {useEffect} from 'react';
import NeedCard from './NeedCard';
import SwipeStack from '~/components/generics/SwipeStack';
import ActionItemCard from '~/components/subsite/summary/ActionItemCard';
import HoldCard from '~/components/subsite/summary/HoldCard';
import RFICard from '~/components/subsite/summary/RFICard';
import TDRCard from '~/components/subsite/summary/TDRCard';
import TQCard from '~/components/subsite/summary/TQCard';
import {useStoreActions, useStoreState} from '~/store/storeHooks';

interface ProjectCorrespondenceStackProps {
    projectLabel?: boolean;
}

ProjectCorrespondenceStack.defaultProps = {
    projectLabel: false,
};

export default function ProjectCorrespondenceStack({projectLabel}: ProjectCorrespondenceStackProps): JSX.Element {
    const projectSubset = useStoreState((state) => state.project.projectSubset);
    const getProjectSetUnresolvedCorrespondence = useStoreActions((actions) => actions.correspondence.getProjectSetUnresolvedCorrespondence);
    const {rfis, tqs, tdrs, actionItems, holds, needs} = useStoreState((state) => state.correspondence);

    useEffect(() => {
        if (projectSubset.length > 0) {
            getProjectSetUnresolvedCorrespondence(projectSubset.map((p) => p.id));
        } else {
            // this call is still needed to clear store if user logged out and back in as different user who doesn't have
            // same access as previous user
            getProjectSetUnresolvedCorrespondence([]);
        }
    }, [getProjectSetUnresolvedCorrespondence, projectSubset]);

    return rfis.length > 0 || tqs.length > 0 || tdrs.length > 0 || actionItems.length > 0 || holds.length > 0 || needs.length > 0 ? (
        <SwipeStack id="correspondence-stack-root">
            {[
                ...actionItems.map((a, i) => <ActionItemCard index={i} actionItem={a} key={`actionItem:${a.id}`} projectLabel={projectLabel} />),
                ...rfis.map((r, i) => <RFICard index={i + actionItems.length} rfi={r} key={`rfi:${r.id}`} projectLabel={projectLabel} />),
                ...tqs.map((t, i) => <TQCard index={i + actionItems.length + rfis.length} tq={t} key={`tq:${t.id}`} projectLabel={projectLabel} />),
                ...tdrs.map((td, i) => (
                    <TDRCard index={i + actionItems.length + rfis.length + tqs.length} tdr={td} key={`tdr:${td.id}`} projectLabel={projectLabel} />
                )),
                ...holds.map((hd, i) => (
                    <HoldCard
                        index={i + actionItems.length + rfis.length + tqs.length + tdrs.length}
                        hold={hd}
                        key={`hold:${hd.id}`}
                        projectLabel={projectLabel}
                    />
                )),
                ...needs.filter((n)=>n.status !== 'Closed').map((n, i) => (
                    <NeedCard
                        index={i + actionItems.length + rfis.length + tqs.length + tdrs.length + holds.length}
                        need={n}
                        key={`need:${n.id}`}
                        projectLabel={projectLabel}
                    />
                )),
            ]}
        </SwipeStack>
    ) : null;
}

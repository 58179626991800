import CheckIcon from "@mui/icons-material/Check";
import {TextField, Button, Typography, Table, TableRow, TableHead, TableContainer, TableCell, TableBody, TableFooter} from '@mui/material';
import parse from 'html-react-parser';
import {useCallback, useEffect, useMemo, useRef} from 'react';
import {useParams} from 'react-router';
import {useNavigate} from 'react-router-dom';
import HMLogo from '../../../../assets/img/hm-logo-dark.svg';
import { USDollar } from "~/helpers";
import { useGetStateFromPath } from "~/hooks/useGetStateFromPath";
import {useStoreState} from '~/store/storeHooks';
import styles from '~/styles/COPrint.module.scss';

export default function COPrint() {
    const {projectId, coId} = useParams<{projectId: string; coId: string}>();
    const navigate = useNavigate();
    const from = useGetStateFromPath(`/project/${projectId}`);
    const changeOrders = useStoreState((state)=> state.change.changeOrders);
    const projects = useStoreState((state) => state.project.projects);
    const project = projects.find((p)=>p.id === +projectId);
    const {pm} = project;

    const changeOrder = useMemo(() => changeOrders.find((o) => coId && o.id === +coId), [coId, changeOrders]);

    const topContainer = useRef(null);

    useEffect(() => {
        // To make sure page starts from the top
        topContainer?.current.scrollIntoView({ block: "end" });
        // turn main page scroll bars back on that dialog turned off
        document.body.style.position = '';
        document.body.style.top = '';
    }, [topContainer]);

    const {
        changeOrderNumber, // change_order_number (varchar 150?) (auto increment? ) - co
        shortDescription, // short_description (varchar 200?) - both. 
        customerPo,
        detailedDescription, // detailed_description (varchar max) - both
        classification, // Classification (Scope Change, Execution Change, Rework) (varchar 20) - both
        reasonForChange, // Reason_for_change (Client Request/Delay, Scope Add/Removal, Delay (Weather/Permit), Discovery Work, Engineering, Estimating, Fabrication, Vendor, Schedule, Construction) (varchar 50) - both
        initiatingEntity, // initiating_entity (Client, H+M, Subcontractor) (varchar 15) - both
        impactsSchedule, // impacts_schedule (true/false - bit) - both
        cOContractTerms, // CO_contract_terms (Fixed Price, T&M, Both) (varchar 12) - CO
        clientContact, // client_contact (varchar 100) - CO
        workStatus, // work_status - (Pending, Started, Finished) - varchar(10)
        status,  // status (varchar 15) - both
                            // List if CO (Pending, Approved, Revise & Resubmit, Rejected, Cancelled, Hold, In Process)  
                            // LIST if ID (Approved - Adjust Forecast $ & Budget MH, Approved - Adjust Forecast $, Rejected - Do Not Proceed w/Work, Revise & Resubmit, 
                            // Budget Transfer Required w/Approval, Hold Forecast Submitted for Mid/End Month) (varchar 75) - ID
        publishedDate, // published_date (Issued To Client or Project Controls depending on CO or ID) (datetime) - both
        engineeringLaborCost,
        constructionLaborCost,
        materialCost,
        totalCost,
        equipmentCost,
        otherCost,
        subcontractorCost,
        pMApproveFinalDate,
        approvedDate,
        clientApproveFinalDate,
        approvedBySignature,
        estimatedRomValue
    } = changeOrder ?? {};

    const handlePrevious = useCallback(() => {
        navigate(from);
    }, [navigate, from]);

    const handlePrint = () => {
        window.print();
    };

    return (
        <div className={styles.root}>
            <div ref={topContainer} style={{position: 'absolute', top: 0}}/>
            <div className={styles.controls}>
                <div>
                    <Button variant='outlined' onClick={handlePrevious}>
                        Previous
                    </Button>
                    <Button variant='outlined' onClick={handlePrint}>
                        Print
                    </Button>
                </div>
            </div>
            <div className={styles.paper}>
                <div className={styles.content}>
                    <div className={styles.sectionDivider} >
                        <Typography className={styles.headerLabel}>CO Form - {changeOrderNumber}</Typography>
                    </div>
                    <img src={HMLogo} alt='H+M Logo in blue' className={styles.logo} />
                    <Typography className={styles.header}>Change Order</Typography>
                    <div className={styles.row}>
                        <TextField value={changeOrderNumber} label="CO No." style={{width: 300}} variant='standard' />
                        <TextField value={shortDescription} label="Description of CO" style={{flexGrow: 1}} variant='standard' />
                    </div>
                    <div className={styles.row}>
                        <TextField value={changeOrder?.project?.code} label="Project" style={{width: 300}} variant='standard' />
                        <TextField value={changeOrder?.project?.title} label="Project Title" style={{flexGrow: 3}} variant='standard' />
                        <TextField value={initiatingEntity} label="Initiating Entity" style={{flexGrow: 1}} variant='standard' />
                    </div>
                    <div className={styles.row}>
                        <TextField value={changeOrder?.project?.clientName} label="Client" style={{width: 300}} variant='standard' />
                        <TextField value={clientContact} label="Client Contact" style={{flexGrow: 4}} variant='standard' />
                         <TextField value={customerPo} label="Client PO" style={{flexGrow: 2}} variant='standard' />       
                        <TextField value={publishedDate?.toLocaleDateString()} label="Date Issued" style={{width: 100}} variant='standard' />
                    </div>
                    <div className={styles.sectionDivider}>
                        <div className={styles.row}>
                            <Typography className={styles.dividerLabel}>CO Contract Terms:</Typography>
                            <div className={styles.sectionCheckMarkContainer}><div className={styles.sectionCheckMarkItem}>{cOContractTerms === 'Fixed Price' ? <CheckIcon className={styles.checkMarkIcon}/> : ''}</div> Fixed Price</div>
                            <div className={styles.sectionCheckMarkContainer}><div className={styles.sectionCheckMarkItem}>{cOContractTerms === 'T&M' ? <CheckIcon className={styles.checkMarkIcon}/> : ''}</div> T&M</div>
                            <div className={styles.sectionCheckMarkContainer}><div className={styles.sectionCheckMarkItem}>{cOContractTerms === 'Both' ? <CheckIcon className={styles.checkMarkIcon}/> : ''}</div> Both</div>
                        </div>  
                    </div>
                    <div className={styles.row}> 
                        <TextField value={classification} label="Classification" style={{flexGrow: 1}} variant='standard' />
                        <TextField value={reasonForChange} label="Reason For Change" style={{flexGrow: 1}} variant='standard'/>
                    </div>
                    <div className={styles.sectionDivider} >
                        <div className={styles.row}><Typography className={styles.dividerLabel}>Detailed Description:</Typography><Typography className={styles.dividerLabel} style={{marginLeft: 80}}>(Supporting documentation to be attached as needed</Typography></div>
                    </div>
                    <div className={styles.row} style={{flexDirection: 'column'}}>
                        <div className={styles.pcnTextArea}>{parse(detailedDescription)}</div>
                    </div>
                    <div className={styles.sectionDivider}>
                        <div className={styles.row}>
                            <Typography className={styles.dividerLabel}>Impacts Schedule?</Typography>
                            <div className={styles.sectionCheckMarkContainer}><div className={styles.sectionCheckMarkItem}>{impactsSchedule ? <CheckIcon className={styles.checkMarkIcon}/> : ''}</div> Yes</div>
                            <div className={styles.sectionCheckMarkContainer}><div className={styles.sectionCheckMarkItem}>{!impactsSchedule ? <CheckIcon className={styles.checkMarkIcon}/> : ''}</div> No</div>
                        </div> 
                        <div className={styles.row} style={{marginLeft: 160}}>
                            <Typography className={styles.dividerLabel}>Current Work Status:</Typography>
                            <div className={styles.sectionCheckMarkContainer}><div className={styles.sectionCheckMarkItem}>{workStatus === 'Pending' ? <CheckIcon className={styles.checkMarkIcon}/> : ''}</div> Pending</div>
                            <div className={styles.sectionCheckMarkContainer}><div className={styles.sectionCheckMarkItem}>{workStatus === 'Started' ? <CheckIcon className={styles.checkMarkIcon}/> : ''}</div> Started</div>
                            <div className={styles.sectionCheckMarkContainer}><div className={styles.sectionCheckMarkItem}>{workStatus === 'Finished' ? <CheckIcon className={styles.checkMarkIcon}/> : ''}</div> Finished</div>
                        </div>   
                    </div>
                    <div className={styles.row} style={{marginTop: 60}}>
                        {
                            pMApproveFinalDate
                            ?
                            <TableContainer style={{marginLeft: 80, marginBottom: 20}}>
                                <Table width={400} sx={{border: '1px solid #bdc3c7'}} size='small'>
                                    <TableHead style={{backgroundColor: '#ecf0f1'}}>
                                        <TableRow>
                                            <TableCell colSpan={2} align='center' style={{fontWeight: 700}}>
                                                Summary
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width={200}/>
                                            <TableCell align='center' width={160} style={{fontWeight: 700}}>
                                                CO Value
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                Engineering Labor
                                            </TableCell>
                                            <TableCell>
                                                <TextField value={engineeringLaborCost ? USDollar.format(engineeringLaborCost) : ''} sx={{textAlign:'right',color:'green'}} variant="standard"/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                Construction Labor
                                            </TableCell>
                                            <TableCell>
                                                <TextField value={constructionLaborCost ? USDollar.format(constructionLaborCost) : ''} variant="standard"/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                Materials
                                            </TableCell>
                                            <TableCell>
                                                <TextField value={materialCost ? USDollar.format(materialCost) : ''} variant="standard"/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                Equipment
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    value={equipmentCost ? USDollar.format(equipmentCost) : ''}
                                                    variant="standard"
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                Subcontract
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    value={subcontractorCost ? USDollar.format(subcontractorCost) : ''}
                                                    variant="standard"
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                Other
                                            </TableCell>
                                            <TableCell>
                                                <TextField value={otherCost ? USDollar.format(otherCost) : ''} variant="standard"/>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                    <TableFooter style={{backgroundColor: '#ecf0f1'}}>
                                        <TableRow>
                                            <TableCell style={{fontSize: '16px', fontWeight: 700}}>Total</TableCell>
                                            <TableCell>
                                                <TextField
                                                    variant="standard"
                                                    value={totalCost ? USDollar.format(totalCost) : ''}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                            :
                            <TableContainer style={{marginLeft: 80, marginBottom: 20}}>
                                <Table width={400} sx={{border: '1px solid #bdc3c7'}} size='small'>
                                    <TableHead style={{backgroundColor: '#ecf0f1'}}>
                                        <TableRow>
                                            <TableCell colSpan={2} align='center' style={{fontWeight: 700}}>
                                                Summary
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                Estimated ROM
                                            </TableCell>
                                            <TableCell>
                                                <TextField value={estimatedRomValue} variant='standard'/>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            
                        }
                        <div style={{width: 900, display: 'flex', alignItems: 'flex-end', flexDirection: 'column', marginRight: 40}}>
                            <Typography className={styles.listLabel}>Status:</Typography>
                            <div/>
                            <div className={styles.checkMarkContainer}>Pending&nbsp;&nbsp;<div className={styles.checkMarkItem}>{status === 'Pending' ? <CheckIcon className={styles.checkMarkIcon}/> : ''}</div></div> 
                            <div className={styles.checkMarkContainer}>Approved&nbsp;&nbsp;<div className={styles.checkMarkItem}>{status === 'Approved' || status === 'In Process' ? <CheckIcon className={styles.checkMarkIcon}/> : ''}</div></div> 
                            <div className={styles.checkMarkContainer}>Revise & Resubmit&nbsp;&nbsp;<div className={styles.checkMarkItem}>{status === 'Revise & Resubmit' ? <CheckIcon className={styles.checkMarkIcon}/> : ''}</div></div>
                            <div className={styles.checkMarkContainer}>Rejected&nbsp;&nbsp;<div className={styles.checkMarkItem}>{status === 'Rejected' ? <CheckIcon className={styles.checkMarkIcon}/> : ''}</div></div>
                            <div className={styles.checkMarkContainer}>Cancelled&nbsp;&nbsp;<div className={styles.checkMarkItem}>{status === 'Cancelled' ? <CheckIcon className={styles.checkMarkIcon}/> : ''}</div></div>
                            <div className={styles.checkMarkContainer}>Hold&nbsp;&nbsp;<div className={styles.checkMarkItem}>{status === 'Hold' ? <CheckIcon className={styles.checkMarkIcon}/> : ''}</div></div>

                        </div>
                    </div>
                    <div className={styles.signatureRow} style={{marginTop: 20}}>
                        <TextField label="H+M PM" value="" className={styles.standardLine}  variant='standard'/>
                        <TextField label="Client" value="" className={styles.standardLine} variant='standard'/>
                    </div>
                    <div className={styles.signatureRow}>
                        <div className={styles.signatureLabel}>
                            {
                                pMApproveFinalDate && pm 
                                ?
                                <div className={styles.signatureContainer}>
                                    <div className={styles.signature}>
                                        <div>{pm?.fullName}</div>
                                    </div>
                                </div>  
                                :
                                <div className={styles.signatureLabel}>(Signature)</div>
                            }
                        </div>
                        <div className={styles.signatureLabel}>
                            {
                                // eslint-disable-next-line no-nested-ternary
                                approvedBySignature
                                ?
                                <div className={styles.signatureContainer}>
                                    <div className={styles.signature}>
                                        <div style={{position: 'absolute', top:-14, left:20}}>
                                            <img src={approvedBySignature} alt="" height={40} width={180}/>
                                        </div>
                                    </div>
                                </div>
                                :
                                    clientApproveFinalDate
                                    ?
                                    <>
                                        <div className={styles.signatureContainer}>
                                            <div className={styles.signature}>
                                                <div>{clientContact?.length ? clientContact : changeOrder?.project?.clientName}</div>
                                            </div>
                                        </div>
                                    </>  
                                    :
                                    <div className={styles.signatureLabel}>(Signature)</div>
                            }
                        </div>
                    </div>
                    <div className={styles.signatureRow}>
                        <TextField label="Date" className={styles.standardLine} value={`${pMApproveFinalDate ? pMApproveFinalDate.toLocaleDateString() : ""}`} variant='standard'/>
                        <TextField label="Date" className={styles.standardLine} value={approvedDate ? `${approvedDate.toLocaleDateString()}` : `${clientApproveFinalDate ? clientApproveFinalDate.toLocaleDateString() : ""}`} variant='standard'/>
                    </div>
                </div>
            </div>
        </div>
    );
}

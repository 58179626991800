import {Button, Dialog, DialogActions, DialogContent, TextField, Zoom} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import {useSnackbar} from 'notistack';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import * as React from 'react';
import {useForm, FormProvider} from 'react-hook-form';
import { trackEvent } from '~/api';
import BusyButton from '~/components/generics/BusyButton';
import FileDropZone from '~/components/generics/FIleDropZone';
import RHFController from '~/components/generics/RHFController';
import DocumentTableContext from '~/components/subsite/documentTable/DocumentTableContext';
import {DisableMobileZoom, EnableMobileZoom, GetOptionLabel, getProjectDirectory, IsOptionEqualToValue} from '~/helpers';
import {useStoreActions, useStoreState} from '~/store/storeHooks';
import styles from '~/styles/DocumentDialog.module.scss';
import {DocumentCategory, DocumentStatus, NewDocument} from '~/types';

interface NewDocumentDialogProps {
    open: boolean;
}

let scrollPos = 0;

export default function NewDocumentDialog({open}: NewDocumentDialogProps): JSX.Element {
    const {validCategories, handleClose} = useContext(DocumentTableContext);
    const project = useStoreState((state) => state.subsite.project);
    const DEFAULT_NEW_DOCUMENT: NewDocument = useMemo(
        () => ({
            number: '',
            projectId: project?.id ?? null,
            title: '',
            category: null,
            categoryId: null,
            rev: '0',
            status: null,
            statusId: null,
        }),
        [project],
    );
    const methods = useForm<NewDocument>({
        defaultValues: DEFAULT_NEW_DOCUMENT,
    });
    const {setValue, watch, handleSubmit, reset} = methods;
    const statuses = useStoreState((state) => state.document.statuses);
    const categories = useStoreState((state) => state.document.categories);
    const [files, setFiles] = useState<File[]>([]);
    const watchCategory: DocumentCategory = watch('category');
    const {enqueueSnackbar} = useSnackbar();
    const uploadFile = useStoreActions((actions) => actions.shared.uploadFile);
    const postDocument = useStoreActions((actions) => actions.document.postDocument);
    const [busy, setBusy] = useState(false);

    const categoryOptions = useMemo((): DocumentCategory[] => {
        let options: DocumentCategory[];
        if (typeof validCategories === 'number') {
            options = categories.filter((o) => o.id === validCategories);
        } else {
            options = categories.filter((o) => validCategories.includes(o.id));
        }
        if (options.length === 1) {
            setValue('category', options[0]);
            setValue('categoryId', options[0].id);
        }
        return options;
    }, [categories, setValue, validCategories]);

    const statusOptions = useMemo((): DocumentStatus[] => {
        let options: DocumentStatus[] = statuses.filter((o) => !o.categoryId);
        if (watchCategory && watchCategory.statusList.length > 0) {
            options = watchCategory.statusList;
        }
        if (options.length === 1) {
            setValue('status', options[0]);
            setValue('statusId', options[0].id);
        }
        return options;
    }, [statuses, watchCategory, setValue]);

    // Disable zooming for this dialog, it's annoying whenever selecting any input
    useEffect(() => {
        DisableMobileZoom();
        return (): void => {
            EnableMobileZoom();
        };
    }, []);

    // Stop scrolling when the action item dialog is open
    useEffect(() => {
        if (open) {
            scrollPos = window.pageYOffset;
            document.body.style.position = 'fixed';
            document.body.style.top = `-${scrollPos}px`;
            setTimeout(()=>{document.body.style.overflow = 'visible'},0);
        } else {
            document.body.style.position = '';
            document.body.style.top = '';
            window.scrollTo(0,scrollPos);
        }
    }, [open]);

    const handleCategoryChange = useCallback(
        (e: React.ChangeEvent<{}>, value: string | DocumentCategory): void => {
            let newCategory = value;
            if (typeof newCategory === 'string') {
                newCategory = categoryOptions.find((o) => o.title === newCategory);
            }
            if (newCategory) {
                setValue('category', newCategory);
                setValue('categoryId', newCategory.id);
            }
        },
        [categoryOptions, setValue],
    );

    const handleStatusChange = useCallback(
        (e: React.ChangeEvent<{}>, value: string | DocumentStatus): void => {
            let newStatus = value;
            if (typeof newStatus === 'string') {
                newStatus = statusOptions.find((o) => o.title === newStatus);
            }
            if (newStatus) {
                setValue('status', newStatus);
                setValue('statusId', newStatus.id);
            }
        },
        [statusOptions, setValue],
    );

    const handleFileSelection = useCallback((f: File[]): void => {
        setFiles(f);
    }, []);

    const onSubmit = handleSubmit(async (data): Promise<void> => {
        if (!project) {
            return;
        }

        if (files.length > 0) {
            setBusy(true);
            trackEvent('upload new document', {number: data.number, file: files[0].name});
            const projectDirectory = getProjectDirectory(project?.projectNumber ,project?.code);
            const uploadedFile = await uploadFile({destination: `/Development/Project/${projectDirectory}/`, file: files[0]});
            const {result, message} = await postDocument({
                ...data,
                ...uploadedFile,
            });
            setBusy(false);
            enqueueSnackbar(message, {variant: result});
            if (result !== 'error') {
                reset(DEFAULT_NEW_DOCUMENT);

                handleClose();
            }
        } else {
            enqueueSnackbar('Please select a file to upload', {variant: 'error'});
        }
    });

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="md"
            fullWidth
            id="new-document-dialog-root"
            className={styles.root}
            TransitionComponent={Zoom}
        >
            <FormProvider {...methods}>
                <form onSubmit={onSubmit}>
                    <div id="new-document-dialog-title" className={styles.header}>
                        <h4>New Document</h4>
                        <p>All fields required</p>
                    </div>
                    <DialogContent id="new-document-dialog-content">
                        <RHFController
                            render={({field}): JSX.Element => <TextField {...field} fullWidth label="Number" variant="standard" required/>}
                            name="number"
                            rules={{
                                required: 'Document number is required',
                            }}
                        />
                        <RHFController
                            render={({field}): JSX.Element => <TextField {...field} fullWidth label="Title" multiline variant="standard" required/>}
                            name="title"
                            rules={{
                                required: true,
                            }}
                        />
                        <RHFController
                            render={({field}): JSX.Element => <TextField {...field} fullWidth label="Revision" variant="standard" required/>}
                            name="rev"
                            rules={{
                                required: true,
                            }}
                        />
                        <RHFController
                            render={({field}): JSX.Element => <TextField {...field} fullWidth label="Description" multiline variant="standard" required/>}
                            name="description"
                            rules={{
                                required: true,
                            }}
                        />
                        <RHFController
                            render={({field}): JSX.Element => (
                                <Autocomplete
                                    {...field}
                                    options={categoryOptions}
                                    getOptionLabel={GetOptionLabel}
                                    isOptionEqualToValue={IsOptionEqualToValue}
                                    renderInput={(params): JSX.Element => <TextField label="Category" fullWidth variant="standard" {...params} required/>}
                                    onChange={handleCategoryChange}
                                />
                            )}
                            name="category"
                            rules={{
                                required: true,
                            }}
                        />
                        {watchCategory && (
                            <RHFController
                                render={({field}): JSX.Element => (
                                    <Autocomplete
                                        {...field}
                                        options={statusOptions}
                                        getOptionLabel={GetOptionLabel}
                                        isOptionEqualToValue={IsOptionEqualToValue}
                                        renderInput={(params): JSX.Element => <TextField label="Status" fullWidth variant="standard" {...params} required/>}
                                        onChange={handleStatusChange}
                                    />
                                )}
                                name="status"
                                rules={{
                                    required: true,
                                }}
                            />
                        )}
                        <FileDropZone callback={handleFileSelection} />
                    </DialogContent>
                    <DialogActions id="new-document-dialog-actions">
                        <Button onClick={handleClose} className="close-button" id="cancel-new-document-button">
                            Cancel
                        </Button>
                        <BusyButton busy={busy} type="submit" className="save-button" id="save-new-document-button">
                            Save
                        </BusyButton>
                    </DialogActions>
                </form>
            </FormProvider>
        </Dialog>
    );
}

import { GridRowClassNameParams, GridRowParams } from '@mui/x-data-grid-pro';
import {useCallback, useEffect, useState} from 'react';
import {useParams, useSearchParams} from 'react-router-dom';
import DataGridProTable from '~/components/generics/DataGridProTable';
import TDRDialog from '~/components/subsite/correspondence/tdr/TDRDialog';
import TDRTableCols from '~/components/subsite/correspondence/tdr/TDRTableColsPro';
import LoadingComponent from '~/components/visualizations/LoadingComponent';
import {useStoreActions, useStoreState} from '~/store/storeHooks';
import styles from '~/styles/DataGridPro.module.scss';

const tableCacheVersion = 2;

export default function TDRTable(): JSX.Element {
    const {projectId} = useParams<{projectId: string}>();
    const [, setSearchParams] = useSearchParams();
    const project = useStoreState((state) => state.subsite.project);
    const getTDRs = useStoreActions((actions) => actions.correspondence.getTDRs);
    const tdrs = useStoreState((state) => state.correspondence.tdrs);
    const [busy, setBusy] = useState(false);
    const columns = TDRTableCols();

    useEffect(() => {
        if (projectId) {
            setBusy(true);
            (async function fetchData(): Promise<void> {
                await getTDRs(parseInt(projectId, 10));
            })();
            setBusy(false);
        }
        return (): void => {
            setBusy(false);
        };
    }, [getTDRs, projectId]);

    const handleRowClick = useCallback(
        (params: GridRowParams): void => {
            setSearchParams({tdr: String(params.row.id)});
        },
        [setSearchParams],
    );

    const getRowClassName = (params: GridRowClassNameParams)=> {
        const {status,answered} = params.row;
        let cn = styles.row;
        if (status === 'Open'  && !answered) {
            cn = `${cn} ${styles.actionRequired}`;
        } else if (status === 'Open' && answered) {
            cn = `${cn} ${styles.actionable}`;
        } else {
            cn = `${cn} ${styles.readOnly}`;
        }
        if (params.row.descendent) cn = `${cn} ${styles.treeBranch}`;
        return cn;
    }

    return (
        <div id="TDR-table-root">
            {busy ? (
                <LoadingComponent title="Loading TDR Data" contained/>
            ) : (
                <>
                    <DataGridProTable
                        columns={columns}
                        data={tdrs}
                        optionKey='tdrTable'
                        csvFileName={`${project?.code ?? 'Project'} TDR Log.csv`}
                        cacheVersion={tableCacheVersion}
                        handleRowClick={handleRowClick}
                        getRowClassName={getRowClassName}
                    />
                    <TDRDialog />
                </>
            )}
        </div>
    );
}

import {IconButton} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useCallback, useContext, useEffect, useState} from 'react';
import * as React from 'react';
import { trackEvent } from '~/api';
import DeleteIcon from '~/assets/img/delete-icon.svg';
import ConfirmationDialog from '~/components/generics/ConfirmationDialog';
import DocumentTableContext from '~/components/subsite/documentTable/DocumentTableContext';
import {useStoreActions} from '~/store/storeHooks';

export default function DeleteDocumentButton(): JSX.Element {
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [busy, setBusy] = useState(false);
    const {document, handleClose} = useContext(DocumentTableContext);
    const deleteDocument = useStoreActions((actions) => actions.document.deleteDocument);
    const {enqueueSnackbar} = useSnackbar();

    const handleDelete = useCallback(async () => {
        setBusy(true);
        trackEvent('delete document', {id: document.id, number: document.number})
        const {result, message} = await deleteDocument(document);
        setBusy(false);
        enqueueSnackbar(message, {variant: result});
        if (result !== 'error') {
            handleClose();
        }
    }, [deleteDocument, document, enqueueSnackbar, handleClose]);

    useEffect(
        () => (): void => {
            setBusy(false);
        },
        [],
    );

    const handleClick = (e: React.MouseEvent): void => {
        e.stopPropagation();
        setConfirmDialogOpen(true);
    };

    return (
        document?.editable && (
            <>
                <IconButton onClick={handleClick} disabled={busy} className="delete-button">
                    <img src={DeleteIcon} alt="delete-document-icon-button" />
                </IconButton>
                <ConfirmationDialog
                    open={confirmDialogOpen}
                    title="Confirm Delete Request"
                    message={`Please confirm you wish to delete ${document.number} - ${document.title}, this process cannot be undone`}
                    onConfirm={handleDelete}
                    onCancel={(): void => setConfirmDialogOpen(false)}
                />
            </>
        )
    );
}

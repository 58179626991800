import {useContext} from 'react';
import ScrollToDiv from '~/components/generics/ScrollToDiv';
import SwipeStack from '~/components/generics/SwipeStack';
import CorrespondenceStackContext from '~/components/subsite/summary/correspondenceStack/CorrespondenceStackContext';
import NoOpenItemCard from '~/components/subsite/summary/correspondenceStack/NoOpenItemCard';
import RFICard from '~/components/subsite/summary/RFICard';
import {useStoreState} from '~/store/storeHooks';

interface RFIStackProps {
    projectLabel?: boolean;
}

RFIStack.defaultProps = {
    projectLabel: false,
};

export default function RFIStack({projectLabel}: RFIStackProps): JSX.Element {
    const {rfis} = useStoreState((state) => state.correspondence);
    const {showRFI} = useContext(CorrespondenceStackContext);

    if (!showRFI) {
        return null;
    }

    return (
        <>
            <ScrollToDiv id='rfi-stack-scroll-to'/>
            {
                rfis.length === 0
                ?
                <NoOpenItemCard type="rfis" />
                :
                <SwipeStack id='rfi-stack-root'>
                    {[...rfis.filter((o)=>o.status !== 'Closed' && !o.descendent).map((r, i) => <RFICard index={i} rfi={r} key={`rfi:${r.id}`} projectLabel={projectLabel} />)]}
                </SwipeStack>
            }      
        </>
    );
}

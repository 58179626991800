import {ReactNode, useEffect, useState} from 'react';
import {Navigate, useLocation} from 'react-router';
import LoadingComponent from '../visualizations/LoadingComponent';
import {useAuth} from '~/api';
import {useStoreActions} from '~/store/storeHooks';

export function InitialDataLoader({children}: {children: ReactNode}): JSX.Element {
    const [busy, setBusy] = useState(true);
    const [initUser, getInitialSharedData] = useStoreActions((actions) => [actions.shared.initUser, actions.shared.getInitialData]);

    useEffect(() => {
        (async function dashboardInit(): Promise<void> {
            setBusy(true);
            await Promise.all([initUser(), getInitialSharedData()]);
            setBusy(false);
        })();
        return (): void => setBusy(false);
    }, [initUser, getInitialSharedData]);

    return busy ? <LoadingComponent title="Loading Initial Data" /> : <>{children}</>;
}

function RequireAuth({children}: {children: ReactNode}): JSX.Element {
    const auth = useAuth();
    const location = useLocation();

    if (!auth.isAuthenticated && !auth.pending) {
        return <Navigate to="/login" state={{from: location}} replace />;
    }

    if (auth.pending) {
        return <LoadingComponent title="Loading User" />;
    }

    return <>{children}</>;
}

export function SharedFramework({children}: {children: ReactNode}): JSX.Element {
    return (
        <RequireAuth>
            <InitialDataLoader>{children}</InitialDataLoader>
        </RequireAuth>
    );
}

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, ButtonProps, CircularProgress, Tooltip} from '@mui/material';
import styles from '~/styles/BusyButton.module.scss';

interface BusyButtonProps extends ButtonProps {
    busy: boolean; // Warning: Do not have your busy prop as useRef because it will not trigger this component to re-render, use a useState
    cpSize?: number | string;
    cpThickness?: number;
    tooltip?: string;
}

BusyButton.defaultProps = {
    cpSize: 24,
    cpThickness: 2,
    tooltip: '',
};

export default function BusyButton(props: BusyButtonProps): JSX.Element {
    const {busy, children, disabled, cpSize, cpThickness, tooltip, ...rest} = props;
    return (
        <Tooltip
            title={
                <div>
                    <FontAwesomeIcon icon={['fal', 'info-circle']} size="2x" />
                    {tooltip}
                </div>
            }
            disableHoverListener={tooltip?.length === 0}
        >
            <div>
                <Button className={styles.root} {...rest} disabled={disabled || busy} id="busy-button-root">
                    {busy ? <CircularProgress size={cpSize} thickness={cpThickness} /> : children}
                </Button>
            </div>
        </Tooltip>
    );
}

import {useMediaQuery} from '@mui/material';
import {useMemo, useState} from 'react';
import MilestoneContext from './milestone/MilestoneContext';
import ProjectSummaryStack from './ProjectSummaryStack';
import {useTrackProject, useTrackPage} from '~/api';
import ProjectContext from '~/components/dashboard/project-log/ProjectContext';
import MilestoneStack from '~/components/subsite/summary/milestone/MilestoneStack';
import ProjectCorrespondenceStack from '~/components/subsite/summary/ProjectCorrespondenceStack';
import ProjectFinancialStack from '~/components/subsite/summary/ProjectFinancialStack';
import ProjectHealthStack from '~/components/subsite/summary/ProjectHealthStack';
import ProjectSummaryDesktop from '~/components/subsite/summary/ProjectSummaryDesktop';
import ProjectTeamPanel from '~/components/subsite/summary/team/ProjectTeamPanel';
import {useStoreState} from '~/store/storeHooks';
import styles from '~/styles/SummaryPanel.module.scss';

export default function SummaryPanel(): JSX.Element {
    const mobile = useMediaQuery('(max-width: 600px)');
    const project = useStoreState((state) => state.subsite.project);
    const proectContext = useMemo(() => ({project}), [project]);
    const [showMilestones, setShowMilestones] = useState(localStorage.cppShowMilestones === 'true');

    const milestoneStackContext = useMemo(
        () => ({
            showMilestones,
            setShowMilestones,
        }),
        [showMilestones, setShowMilestones],
    );

    useTrackPage('Project Summary');
    useTrackProject(project);

    return (
        <ProjectContext.Provider value={proectContext}>
            <div id="summary-panel-root" className={styles.root}>
                {project && (
                    <>
                        {mobile ? (
                            <>
                                <ProjectFinancialStack />
                                <ProjectSummaryStack />
                                <ProjectHealthStack />
                                <ProjectCorrespondenceStack />
                                <MilestoneStack />
                            </>
                        ) : (
                            <MilestoneContext.Provider value={milestoneStackContext}>
                                <ProjectSummaryDesktop />
                            </MilestoneContext.Provider>
                        )}
                        <ProjectTeamPanel />
                    </>
                )}
            </div>
        </ProjectContext.Provider>
    );
}

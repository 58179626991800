/* eslint-disable import/no-extraneous-dependencies */
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {StylesProvider} from '@mui/styles';
import {SnackbarProvider, SnackbarProviderProps} from 'notistack';
import type {ReactNode} from 'react';
import {ProviderAction} from './SnackbarCloseButton';

import '~/styles/App.scss';
import 'katex/dist/katex.min.css';
import 'suneditor/dist/css/suneditor.min.css';
import 'swiper/swiper.scss';

const anchorOrigin: SnackbarProviderProps['anchorOrigin'] = {horizontal: 'center', vertical: 'bottom'};

export interface Props {
    children?: ReactNode;
}

AppStyle.defaultProps = {
    children: null,
};

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

const classes = {
    root: 'snack-root',
};

export default function AppStyle({children}: Props): JSX.Element {
    return (
        <StylesProvider injectFirst>
            <ThemeProvider theme={darkTheme}>
                <SnackbarProvider maxSnack={3} action={ProviderAction} anchorOrigin={anchorOrigin} classes={classes}>
                    {children}
                </SnackbarProvider>
            </ThemeProvider>
        </StylesProvider>
    );
}

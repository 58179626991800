import SettingsIcon from '@mui/icons-material/Settings';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { Button, ClickAwayListener, Grow, MenuItem, Paper, Popper, MenuList } from "@mui/material";
import { GridInitialState, useGridApiContext } from "@mui/x-data-grid-pro";
import React, { useCallback, useState, useRef, useEffect } from 'react';
import { useStoreActions } from '~/store/storeHooks';

export function DataGridProSettings ({getDefaultGridState,optionKey}: {getDefaultGridState: (reason?: string)=>GridInitialState, optionKey: string}) {
    const {setUserOptions} = useStoreActions((actions) => actions.shared);
    const apiRef = useGridApiContext();
    const anchorRef = useRef<HTMLButtonElement>(null);
    const [open, setOpen] = useState(false);

    const handleToggle = useCallback(() => {
      setOpen((prevOpen) => !prevOpen);
    },[setOpen]);

    const handleClose = useCallback((event: Event | React.SyntheticEvent) => {
      if (
        anchorRef.current &&
        anchorRef.current.contains(event.target as HTMLElement)
      ) {
        return;
      }

      setOpen(false);
    },[setOpen]);

    const handleListKeyDown = useCallback((event: React.KeyboardEvent)=> {
      if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
      } else if (event.key === 'Escape') {
        setOpen(false);
      }
    },[setOpen]);

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(open);
    useEffect(() => {
      if (prevOpen.current === true && open === false) {
        anchorRef.current!.focus();
      }

      prevOpen.current = open;
    }, [open]);

    const handleResetTableSettings = useCallback(()=> {
        const defaultState = getDefaultGridState('resetting');
        setUserOptions({ 
          option: 'genericTableOptions',
          key: optionKey, 
          value: defaultState
        }); 
        setTimeout(()=>{
          apiRef.current.restoreState(defaultState);
          // there is an open issue with restoreState not restoring columnVisibilityModel
          // have to restore it separately with setColumnVisibilityModel.  Can remove this in future
          // once they have restoreState restoring the full state of everything
          apiRef.current.setColumnVisibilityModel(defaultState?.columns?.columnVisibilityModel);
          setOpen(false);
        },0)
        
    },[setUserOptions,apiRef,getDefaultGridState,optionKey]);

    return (
      <>
        <Button key='pltb1'
                ref={anchorRef}
                id="projectLogtableSettingsButton"
                aria-controls={open ? 'projectLogTableSettings' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}>
            <SettingsIcon/>
        </Button>
        <Popper open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                sx={{zIndex:9}}
                transition
                >
                {({ TransitionProps, placement }) => (
                    <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === 'bottom-start' ? 'left top' : 'left bottom',
                    }}
                  >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList 
                                  autoFocusItem={open} 
                                  id="tableSettings" 
                                  onKeyDown={handleListKeyDown}
                                  aria-labelledby='settingsButton'
                                >
                                  <MenuItem onClick={handleResetTableSettings} key='pltm3'>
                                      <SettingsBackupRestoreIcon/>&nbsp;Restore Table Defaults
                                  </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
      </>
    );
  }
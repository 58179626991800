import {ApexOptions} from 'apexcharts';
import numeral from 'numeral';
import Chart from 'react-apexcharts';
import NoDataPlaceholder from '~/components/generics/NoDataPlaceholder';
import {useStoreState} from '~/store/storeHooks';
import styles from '~/styles/InvoiceStatusBarChart.module.scss';

interface InvoiceStatusStackedBarChartProps {
    foundationCode: string;
}

const MOBILE_HEIGHT = '180px';

export default function InvoiceStatusBarChart({foundationCode}: InvoiceStatusStackedBarChartProps): JSX.Element {
    const projectInvoiceStatus = useStoreState((state) => state.accounting.projectInvoiceStatus);
    const {amountInvoiced, amountPaid} = projectInvoiceStatus(foundationCode);
    const mobileOptions: ApexOptions = {
        chart: {
            background: 'transparent',
            toolbar: {
                show: false,
            },
            fontFamily: 'Gotham Book, Roboto, Helvetica, sans-serif',
            parentHeightOffset: 0,
        },
        dataLabels: {
            enabled: false,
        },
        xaxis: {
            categories: ['Invoiced', 'Paid'],
            labels: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            axisBorder: {
                show: false,
            },
        },
        yaxis: {
            opposite: true,
            labels: {
                style: {
                    fontSize: '8px',
                },
                formatter: (value): string => numeral(value).format(value < 1000000 ? '0,0a' : '0,0.0a'),
            },
        },
        grid: {
            borderColor: '#4e4e4e',
        },
        theme: {
            mode: 'dark',
        },
        plotOptions: {
            bar: {
                columnWidth: '50%',
                distributed: true,
            },
        },
        legend: {
            show: true,
            position: 'top',
            fontSize: '8px',
        },
        colors: ['#d1edfc', '#b7fd7a'],
    };
    return amountInvoiced > 0 ? (
        <Chart
            options={mobileOptions}
            labels={['Invoiced', 'Paid']}
            type="bar"
            series={[{name: 'Invoice Status', data: [amountInvoiced, amountPaid]}]}
            height={MOBILE_HEIGHT}
            className={styles.root}
        />
    ) : (
        <NoDataPlaceholder title="No Invoices" />
    );
}

import * as React from 'react';
import styles from '~/styles/SummaryCard.module.scss';

interface SummaryCardProps extends React.HTMLAttributes<HTMLDivElement> {
    className?: string;
}
SummaryCard.defaultProps = {
    className: '',
};
export default function SummaryCard(props: SummaryCardProps): JSX.Element {
    const {children, className} = props;
    return (
        <div id="summary-card-root" {...props} className={`${styles.root} ${className}`}>
            {children}
        </div>
    );
}

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ListItem, ListItemIcon} from '@mui/material';
import styles from '~/styles/MilestoneList.module.scss';
import {Milestone} from '~/types';

interface MilestoneDesktopListItemProps {
    milestone: Milestone;
}

export default function MilestoneListItem({milestone}: MilestoneDesktopListItemProps): JSX.Element {
    const {completed, completedDate, dueDate, description, type} = milestone;

    return (
        <ListItem className={styles.milestoneListItem} divider>
            <ListItemIcon>
                {completed ? <FontAwesomeIcon icon={['fad', 'flag']} color="lightgreen" /> : <FontAwesomeIcon icon={['far', 'flag-alt']} />}
            </ListItemIcon>
            <div>
                <p className={styles.description}>{description}</p>
                <p>{`Type | ${type}`}</p>
                <p>{`Due |  ${new Date(dueDate).toLocaleDateString()}`}</p>
                <p>{completed ? `Completed | ${new Date(completedDate).toLocaleDateString()}` : 'Not Completed'}</p>
            </div>
        </ListItem>
    );
}

import { History } from '@mui/icons-material';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableCell,
    TableHead,
    TableRow,
    Zoom,
} from '@mui/material';
import {useCallback, useState} from 'react';
import { useStoreActions } from '~/store/storeHooks';
import styles from '~/styles/ChangeOrderDialog.module.scss';
import {Attachment} from '~/types';


export default function COAttachmentHistory({attachments}: {attachments:Attachment[]}): JSX.Element {
    const [open, setOpen] = useState(false);
    const downloadFile = useStoreActions((actions) => actions.shared.downloadFile);

    const handleClose = ()=> setOpen(false);

    const handleClick = useCallback(
        async (e: React.UIEvent,attachment: Attachment) => {
            if (attachment.url.includes('hm.hosted-by-files.com')) {
                window.open(attachment.url, '_blank');
            } else {
                await downloadFile(attachment.url);
                e.stopPropagation();
            }
        },
        [downloadFile],
    );

    return (
        <>
        <History onClick={()=>setOpen(true)} sx={{cursor:'pointer', padding: 0, marginTop: -10}}/>
        <Dialog
            open={open}
            onClose={handleClose}
            id="change-order-attachment-history"
            className={styles.root}
            maxWidth='md'
            fullWidth
            TransitionComponent={Zoom}>
            <DialogTitle className={styles.header}>
                <div>
                    <h3>Change Order Attachment History</h3>
                </div>
            </DialogTitle>
            <DialogContent>
                <Table>
                    <TableHead>
                        <TableRow><TableCell>Filename</TableCell><TableCell>Created</TableCell><TableCell>Created By</TableCell><TableCell>Unpublished</TableCell></TableRow>
                    </TableHead>
                {
                    attachments?.map?.((a: Attachment)=>(
                        <TableRow><TableCell sx={{cursor: 'pointer'}} onClick={(e)=>handleClick(e,a)}>{a.fileName}</TableCell><TableCell>{a.created.toLocaleString()}</TableCell><TableCell>{a.createdBy?.fullName}</TableCell><TableCell>{a.updated.toLocaleString()}</TableCell></TableRow>
                    ))
                }
                </Table>
            </DialogContent>
            <DialogActions>
                <Button className="close-button" onClick={handleClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
        </>
    );
}

import {createContext} from 'react';
import {Project} from '~/types';

type ProjectContextType = {
    project: Project;
};

const ProjectContext = createContext<ProjectContextType>({
    project: null,
});

export default ProjectContext;

/* eslint-disable react/prop-types */

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { Divider, MenuItem } from '@mui/material';
import {
    GridColumnMenuContainer, 
    SortGridMenuItems, 
    GridFilterMenuItem, 
    HideGridColMenuItem, 
    GridColumnsMenuItem,
    GridColumnMenuProps,
    GridColumnPinningMenuItems,
    useGridApiContext
} from '@mui/x-data-grid-pro';
import React, { useCallback } from 'react';
import {useStoreState, useStoreActions} from '~/store/storeHooks';


export const ProjectLogColumnMenu = React.forwardRef<HTMLUListElement,GridColumnMenuProps>((props: GridColumnMenuProps, ref) => {
    const { hideMenu, currentColumn} = props;
    const {projectTableOptions} = useStoreState((state) => state.shared.userOptions);
    const {setUserOptions} = useStoreActions((actions) => actions.shared);
    const {projectLogTableDefaultState} = useStoreState((state) => state.project);
    const {defaultOpenInNewTab} = projectTableOptions;
    
    const apiRef = useGridApiContext();
    let custom: JSX.Element[] = null;

    const handelDefaultNewTab: () => void = useCallback(()=> {
      setUserOptions({ 
        option: 'projectTableOptions',
        key: 'defaultOpenInNewTab', 
        value: !defaultOpenInNewTab
      });
    },[setUserOptions,defaultOpenInNewTab]);


    const handleResetProjectLogTableSettings = useCallback(()=> {
      setUserOptions({ 
        option: 'projectTableOptions',
        key: 'projectLogTableInitialState',
        value: projectLogTableDefaultState
      });
      apiRef.current.restoreState(projectLogTableDefaultState);
      // there is an open issue with restoreState not restoring columnVisibilityModel
      // have to restore it separately with setColumnVisibilityModel.  Can remove this in future
      // once they have restoreState restoring the full state of everything
      apiRef.current.setColumnVisibilityModel(projectLogTableDefaultState?.columns?.columnVisibilityModel);
  },[setUserOptions,apiRef,projectLogTableDefaultState]);

    if (props.currentColumn?.field === 'title') {
      custom = [
        <MenuItem onClick={handelDefaultNewTab} key='plcm1'>
          {defaultOpenInNewTab ? <CheckBoxIcon/> : <CheckBoxOutlineBlankIcon/> }&nbsp;Default Open In New Tab
        </MenuItem>,
      ]
    }

    const restoreText: string = props.currentColumn?.field === 'title' ? 'Restore Table Defaults' : 'Reset';

    return (
      <GridColumnMenuContainer ref={ref} {...props}>
        <SortGridMenuItems onClick={hideMenu} column={currentColumn!} />
        <GridFilterMenuItem onClick={hideMenu} column={currentColumn!} />
        <HideGridColMenuItem onClick={hideMenu} column={currentColumn!} />
        <GridColumnsMenuItem onClick={hideMenu} column={currentColumn!} />
        <Divider key='plcd1'/>
        <GridColumnPinningMenuItems onClick={hideMenu} column={currentColumn!} />
        <Divider key='plcd2'/>
        {custom}
        <MenuItem onClick={handleResetProjectLogTableSettings} key='plcm2' title='Restore table defaults'>
          <SettingsBackupRestoreIcon/>&nbsp;{restoreText}
        </MenuItem>
      </GridColumnMenuContainer>
    );
});

import {action, Action, thunk, Thunk} from 'easy-peasy';
import {axios} from '~/api';
import {AxiosTryCatchBlock} from '~/helpers';
import {CloseOut, NewClientRating} from '~/types';

export const DEFAULT_CLOSEOUT: CloseOut = {
    id: -1,
    projectId: -1,
    project: null,
    type: '',
    reason: null,
    comments: null,
    featureRequested: null,
    clientRating: 10,
    clientComments: null,
    clientLessonsLearned: null,
    clientSatisfied: true,
};

const defaultTimeout = 30000;
export interface CloseOutModel {
    closeOut: CloseOut;
    setCloseOut: Action<CloseOutModel, CloseOut>;
    getSurvey: Thunk<CloseOutModel, number>;
    postSurvey: Thunk<CloseOutModel, NewClientRating>;
}

const closeOutModel: CloseOutModel = {
    closeOut: DEFAULT_CLOSEOUT,
    setCloseOut: action((draftState, closeOut) => {
        draftState.closeOut = closeOut;
    }),
    getSurvey: thunk((actions, projectId) =>
        AxiosTryCatchBlock(async () => {
            const {data} = await axios.get(`/CloseOut?projectId=${projectId}`, {
                timeout: defaultTimeout,
            });
            if (data?.id) {
                actions.setCloseOut(data);
            }
        }),
    ),
    postSurvey: thunk(async (actions, survey) => {
        try {
            const {data} = await axios.post('/PostSurvey', survey, {
                timeout: defaultTimeout,
            });
            const {closeout} = data;
            if (closeout) {
                actions.setCloseOut(closeout);
            }
            return data;
        } catch (e) {
            return {
                result: 'error',
                message: 'Error while attempting to submit close out survey - Please contact tech support',
            };
        }
    }),
};

export default closeOutModel;

import { useMemo } from 'react';
import COTable from './changeOrders/COTable';
import {useTrackPage} from '~/api';
import ScrollToDiv from '~/components/generics/ScrollToDiv';
import ChangeManagementKPI from '~/components/kpi/ChangeManagementKPI';
import DocumentCountKPI from '~/components/kpi/DocumentCountKPI';
import KPIContainer from '~/components/kpi/KPIContainer';
import DocumentTable from '~/components/subsite/documentTable/DocumentTablePro';
import {useStoreState} from '~/store/storeHooks';
import styles from '~/styles/SubsiteContentPanel.module.scss';

export default function ChangePanel(): JSX.Element {
    const documentCategoryForTitle = useStoreState((state) => state.document.documentCategoryForTitle);
    const currentUser = useStoreState((state) => state.shared.currentUser);
    const {documentSubset} = useStoreState((state) => state.document);
    const changeOrderCategory = documentCategoryForTitle('change management');

    //const isHm = currentUser?.email.includes('hm-ec.com');

    const documents = useMemo(
        () => documentSubset.filter(({categoryId}) =>  categoryId === changeOrderCategory?.id),
        [changeOrderCategory, documentSubset],
    );

    // Delete this once you have documents in the PCN Change Orders category

    useTrackPage('Change Management');

    return (
        <div id="change-panel-root" className={styles.root}>
            <KPIContainer className={styles.kpiContainer}>
                 <ChangeManagementKPI
                    title="Change Orders"
                    scrollTo="change-orders-scroll-to"
                    tooltipTitle="Navigate to Change Orders"
                    type='co'
                />
                {/* will suppress this later after we remove isHm above and release new change order table to all CPP users */}
                {/* {documents?.length && ( */}
                    <DocumentCountKPI
                        title="Documents"
                        categoryTitle="change management"
                        scrollTo="change-order-documents-scroll-to"
                        tooltipTitle="Navigate to Change Order Documents"
                        countIfaOnly
                    />
                {/* )} */}
                
            </KPIContainer>
             <div id="change-orders-root">
                <ScrollToDiv id="change-orders-scroll-to" />
                <h4 className={styles.header}>Change Orders</h4>
                <COTable />
            </div>
            {/* will suppress this later after we remove isHm above and release new change order table to all CPP users */}
                {/* {documents?.length && ( */}
                <div id="change-order-documents-root">
                    <ScrollToDiv id="change-order-documents-scroll-to" />
                    <h4 className={styles.header}>Change Order Documents</h4>
                    <DocumentTable categories={changeOrderCategory.id} />
                </div>
            {/* )} */}
        </div>
    );
}

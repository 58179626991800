import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Tooltip} from '@mui/material';
import {useContext} from 'react';
import ChatHistoryContext from '~/components/chat/ChatHistoryContext';
import {Reactions} from '~/components/chat/ChatReaction';
import styles from '~/styles/ReactionHistory.module.scss';
import {CommentReaction} from '~/types';

interface ReactionHistoryProps {
    commentReactions: CommentReaction[];
}

export default function ReactionHistory({commentReactions}: ReactionHistoryProps): JSX.Element {
    const {mobile} = useContext(ChatHistoryContext);

    return (
        commentReactions.length > 0 && (
            <div id="reaction-history-root" className={styles.root}>
                {commentReactions.map(({id, reaction, person}) => {
                    const {icon, color, action} = Reactions.find((o) => o.key === reaction);
                    return (
                        <Tooltip title={<p>{`${person} ${action} this comment`}</p>} key={id}>
                            <div className={styles.iconContainer}>
                                <FontAwesomeIcon icon={icon} color={color} className={styles.chatReaction} />
                                {mobile && (
                                    <div className={styles.reactionSource}>
                                        {person
                                            .split(' ')
                                            .map((n) => n[0])
                                            .join('')}
                                    </div>
                                )}
                            </div>
                        </Tooltip>
                    );
                })}
            </div>
        )
    );
}

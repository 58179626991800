import {useMemo} from 'react';
import ProjectContext from './ProjectContext';
import CostCard from '~/components/dashboard/project-log/CostCard';
import ProgressCard from '~/components/dashboard/project-log/ProgressCard';
import ProjectInfoCard from '~/components/dashboard/project-log/ProjectInfoCard';
import ProjectScopeCard from '~/components/dashboard/summary-stack/ProjectScopeCard';
import SwipeStack from '~/components/generics/SwipeStack';
import SwipeStackCard from '~/components/generics/SwipeStackCard';
import SummaryHSEGrade from '~/components/subsite/summary/healthStack/SummaryHSEGrade';
import SummaryScheduleGrade from '~/components/subsite/summary/healthStack/SummaryScheduleGrade';
import ProjectStackMilestoneList from '~/components/subsite/summary/milestone/ProjectStackMilestoneList';
import {Project} from '~/types';

interface ProjectCardProps {
    project: Project;
}

export default function ProjectStack({project}: ProjectCardProps): JSX.Element {
    const {id} = project;
    const projectContext = useMemo(() => ({project}), [project]);

    return (
        <ProjectContext.Provider value={projectContext}>
            <SwipeStack id={`project-${id}-stack-root`}>
                <SwipeStackCard cardIndex={0}>
                    <ProjectInfoCard />
                </SwipeStackCard>
                <SwipeStackCard cardIndex={1}>
                    <ProgressCard />
                </SwipeStackCard>
                <SwipeStackCard cardIndex={2}>
                    <CostCard />
                </SwipeStackCard>
                <SwipeStackCard cardIndex={3}>
                    <ProjectScopeCard />
                </SwipeStackCard>
                <SwipeStackCard cardIndex={4}>
                    <ProjectStackMilestoneList />
                </SwipeStackCard>
                <SwipeStackCard cardIndex={5}>
                    <SummaryHSEGrade />
                </SwipeStackCard>
                <SwipeStackCard cardIndex={6}>
                    <SummaryScheduleGrade />
                </SwipeStackCard>
                {/* {isTM && <SwipeStackCard cardIndex={7}>*/}
                {/*    <SummaryBudgetGrade/>*/}
                {/* </SwipeStackCard> : null}*/}
            </SwipeStack>
        </ProjectContext.Provider>
    );
}

import {createTheme, ThemeProvider} from '@mui/material/styles';
import {ReactNode} from 'react';
import {RequireSubsiteAccess} from './RequireSubsiteAccess';
import {SharedFramework} from './SharedFramework';

const lightTheme = createTheme({
    palette: {
        mode: 'light',
    },
});

export function PrintFramework({children}: {children: ReactNode}): JSX.Element {
    return (
        <ThemeProvider theme={lightTheme}>
            <SharedFramework>
                <RequireSubsiteAccess>{children}</RequireSubsiteAccess>
            </SharedFramework>
        </ThemeProvider>
    );
}

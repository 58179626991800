import {TextField} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useContext, useState} from 'react';
import {useLocation, useMatch, useParams, useSearchParams} from 'react-router-dom';
import {trackEvent} from '~/api';
import ChatHistoryContext from '~/components/chat/ChatHistoryContext';
import ReplyToContainer from '~/components/chat/ReplyToContainer';
import {useStoreActions, useStoreState} from '~/store/storeHooks';
import styles from '~/styles/ChatInput.module.scss';
import type {NewComment} from '~/types';

export default function ChatInput(): JSX.Element {
    const [comment, setComment] = useState('');
    const {projectId} = useParams<{projectId: string}>();
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const {postComment, addCommentBeforePost} = useStoreActions((actions) => actions.comment);
    const {currentUser} = useStoreState((state) => state.shared);
    const {selectedComment, setSelectedComment, target} = useContext(ChatHistoryContext);
    const {enqueueSnackbar} = useSnackbar();
    const match = useMatch('/project/:projectId/:page');
    const page = !match ? 'chat' : match.params.page;

    const docId = searchParams.get('doc');
    const rfiId = searchParams.get('rfi');
    const tqId = searchParams.get('tq');
    const tdrId = searchParams.get('tdr');
    const holdId = searchParams.get('hold');
    const actionId = searchParams.get('action');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setComment(e.target.value);
    };

    const handleKeyPress = async (e: React.KeyboardEvent) => {
        if (e.key === 'Enter' && comment?.length) {
            e.preventDefault();

            if (comment.length > 255) {
                enqueueSnackbar(`The comment length, ${comment.length}, is over the 255 limit`, {variant: 'error'});
                return;
            }
            
            const newComment: NewComment = {
                projectId: parseInt(projectId, 10),
                parentId: selectedComment ? selectedComment.id : null,
                comment,
                target: `client;${target}`,
                url: location.pathname,
                docId: parseInt(docId, 10),
                rfiId: parseInt(rfiId, 10),
                tqId: parseInt(tqId, 10),
                tdrId: parseInt(tdrId, 10),
                holdId: parseInt(holdId, 10),
                actionId: parseInt(actionId, 10),
                page,
            };

            setComment('');
            setSelectedComment(null);
            // this gives the use immediate feedback that their message is posting by showing the message
            // in the chat history but with a spinning wheel in front of the date until it saves.
            addCommentBeforePost({newComment, currentUser, selectedComment});
            trackEvent('add chat comment');
            postComment(newComment);
        }
    };

    return (
        <div id="chat-input-root" className={styles.root}>
            {selectedComment && <ReplyToContainer />}
            <TextField
                placeholder="Enter a message"
                value={comment}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
                fullWidth
                autoFocus
                InputProps={{
                    inputProps: {
                        autoComplete: 'off',
                        maxLength: 255,
                    },
                }}
                variant="standard"
            />
        </div>
    );
}

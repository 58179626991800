import useMediaQuery from '@mui/material/useMediaQuery';
import {GridColDef, GridRenderCellParams, getGridNumericOperators, getGridDateOperators, GridValueGetterParams} from '@mui/x-data-grid-pro';
import {useMemo} from 'react';
import { getGridStringArrayAutocompleteOperators } from '~/components/generics/MUIDataGridCustomOperators';
import {USDollar, defaultMediaSizes} from '~/helpers';
import {useStoreState} from '~/store/storeHooks';
import {ChangeOrder} from '~/types';

export default function COTableCols(): GridColDef<ChangeOrder>[] {
    const mediaSizes = defaultMediaSizes;
    const xxs = useMediaQuery(mediaSizes.xxs);
    const xs = useMediaQuery(mediaSizes.xs);
    const sm = useMediaQuery(mediaSizes.sm);
    const md = useMediaQuery(mediaSizes.md);
    const lg = useMediaQuery(mediaSizes.lg);
    const changeOrders = useStoreState((state) => state.change.changeOrders);

    return useMemo(
        (): GridColDef<ChangeOrder>[] => [
            {
                headerName: 'Number',
                field: 'changeOrderNumber',
                type: 'string',
                minWidth: 150,
                headerAlign: 'center',
                align: 'center',
            },
            {
                headerName: 'Description',
                field: 'shortDescription',
                type: 'string',
                minWidth: 250,
                headerAlign: 'center',
            },
            {
                headerName: 'Classification',
                field: 'classification',
                type: 'string',
                minWidth: 200,
                headerAlign: 'center',
                align: 'center',
                filterOperators: getGridStringArrayAutocompleteOperators(changeOrders?.length ? [ ... new Set(changeOrders.map((r)=> r.classification))] : [])
            },
            {
                headerName: 'Reason',
                field: 'reasonForChange',
                type: 'string',
                minWidth: 200,
                headerAlign: 'center',
                align: 'center',
                filterOperators: getGridStringArrayAutocompleteOperators(changeOrders?.length ? [ ... new Set(changeOrders.map((r)=> r.reasonForChange))] : [])
            },
            {
                headerName: 'Department Issuing',
                field: 'departmentIssuing',
                type: 'string',
                minWidth: 200,
                headerAlign: 'center',
                align: 'center',
                filterOperators: getGridStringArrayAutocompleteOperators(changeOrders?.length ? [ ... new Set(changeOrders.map((r)=> r.departmentIssuing))] : [])
            },
            {
                headerName: 'Initiating',
                field: 'initiatingEntity',
                type: 'string',
                minWidth: 140,
                headerAlign: 'center',
                align: 'center',
                filterOperators: getGridStringArrayAutocompleteOperators(changeOrders?.length ? [ ... new Set(changeOrders.map((r)=> r.initiatingEntity))] : [])
            },
            {
                headerName: 'Initiated By',
                field: 'initiatedByHM',
                type: 'string',
                minWidth: 200,
                headerAlign: 'center',
                align: 'center',
                valueGetter: (params: GridValueGetterParams) => params.row?.initiatingEntity === 'Client' ? params.row?.initiatedByNonHm : params.row?.initiatedByHm?.fullName
            },
            {
                headerName: 'Initiated Date',
                field: 'initiatedDate',
                type: 'date',
                minWidth: 200,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params: GridRenderCellParams): string => (params.value  ? new Date(params.value ).toLocaleDateString() : ''),
                filterOperators: getGridDateOperators(),
            },
            {
                headerName: 'Work Status',
                field: 'workStatus',
                type: 'string',
                minWidth: 200,
                headerAlign: 'center',
                align: 'center',
                filterOperators: getGridStringArrayAutocompleteOperators(changeOrders?.length ? [ ... new Set(changeOrders.map((r)=> r.workStatus))] : [])
            },
            {
                headerName: 'Approval Status',
                field: 'status',
                type: 'string',
                minWidth: 250,
                headerAlign: 'center',
                filterOperators: getGridStringArrayAutocompleteOperators(changeOrders?.length ? [ ... new Set(changeOrders.map((r)=> r.status))] : [])
            },
            {
                headerName: 'Impact Schedule',
                field: 'impactsSchedule',
                type: 'boolean',
                minWidth: 110,
                headerAlign: 'center',
                align: 'center',
                valueGetter: (params: GridValueGetterParams): string => {
                    const {value} = params;
                    return value === true ? 'Yes' : 'No';
                },
                filterOperators: getGridStringArrayAutocompleteOperators([
                    'Yes',
                    'No',
                ]),
            },
            {
                headerName: 'Total Value',
                field: 'totalCost',
                type: 'number',
                minWidth: 150,
                headerAlign: 'center',
                align: 'center',
                hide: xxs || xs || sm || md || lg,
                renderCell: (params: GridRenderCellParams) => params.value ? USDollar.format(params.value) : '-',
                filterOperators: getGridNumericOperators(),
            },
            {
                headerName: 'Modified On',
                field: 'modified',
                type: 'date',
                minWidth: 150,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params: GridRenderCellParams): string => (params.value  ? new Date(params.value ).toLocaleDateString() : ''),
                filterOperators: getGridDateOperators(),
                hide: xxs || xs || sm || md || lg,
            },
        ],
        [changeOrders, xxs, xs, sm, md, lg],
    );
}

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {GridFilterOperator, getGridSingleSelectOperators, GridFilterInputValueProps, GridValueGetterParams} from '@mui/x-data-grid-pro';

interface StringArrayAutocompleteFilterProps extends GridFilterInputValueProps {
    options: string[];
}

const StringArrayAutocompleteFilter = (props: StringArrayAutocompleteFilterProps): JSX.Element => {
    const {options, item, applyValue} = props;
    const handleSelectionChange = (newVal: string[]): void => {
        applyValue({
            ...item,
            value: newVal,
        });
    };

    return (
        <Autocomplete
            options={options}
            multiple
            onChange={(_, newVal): void => handleSelectionChange(newVal as string[])}
            renderInput={(params): JSX.Element => <TextField {...params} variant="standard" label="Options" />}
            ChipProps={{size: 'small'}}
            value={item.value ?? []}
        />
    );
};

function getGridStringArrayAutocompleteOperators(options: string[], reverseLookup?: boolean): GridFilterOperator[] {
    return getGridSingleSelectOperators().map((o) => ({
        ...o,
        getApplyFilterFn: (filterItem, column) => {
            const value = (filterItem.value ?? []) as string[];
            if (!filterItem.columnField || value.length === 0 || !filterItem.operatorValue) {
                return null;
            }
            return (params): boolean => {
                const rowValue = params.value;
                switch (filterItem.operatorValue) {
                    case 'is': 
                        if (reverseLookup) {
                            let matches = true;
                            for (let i=0; i<filterItem.value.length; i++) {
                                const testValue = filterItem.value[i];
                                if (!rowValue.includes(testValue)) {
                                    matches = false;
                                    break;
                                }
                            }
                            return matches;
                        }
                        return value.includes(rowValue);
                    case 'isAnyOf': {
                        let matches = false;
                        for (let i=0; i<filterItem.value.length; i++) {
                            const testValue = filterItem.value[i];
                            if (reverseLookup ? rowValue.includes(testValue) : testValue.includes(rowValue)) {
                                matches = true;
                                break;
                            }
                        }
                        return matches;
                    }
                    default:
                        if (reverseLookup) {
                            let matches = true;
                            for (let i=0; i<filterItem.value.length; i++) {
                                const testValue = filterItem.value[i];
                                if (!rowValue.includes(testValue)) {
                                    matches = false;
                                    break;
                                }
                            }
                            return !matches;
                        }
                        return !value.includes(rowValue);
                }
            }
                
        },
        InputComponent: (props: GridFilterInputValueProps): JSX.Element => <StringArrayAutocompleteFilter options={options} {...props} />,
    }));
}

interface ObjectArrayAutocompleteFilterProps<T extends object> extends GridFilterInputValueProps {
    options: T[];
    getOptionLabel: (option: string | T) => string;
}

const ObjectArrayAutocompleteFilter = <T extends object>(props: ObjectArrayAutocompleteFilterProps<T>): JSX.Element => {
    const {options, item, applyValue, getOptionLabel} = props;

    const handleSelectionChange = (newVal: T[]): void => {
        applyValue({
            ...item,
            value: newVal,
        });
    };

    return (
        <Autocomplete
            options={options}
            getOptionLabel={getOptionLabel}
            multiple
            onChange={(_, newVal): void => handleSelectionChange(newVal as T[])}
            renderInput={(params): JSX.Element => <TextField {...params} variant="standard" label="Options" />}
            ChipProps={{size: 'small'}}
            value={item.value ?? []}
        />
    );
};

function getGridObjectArrayAutocompleteOperators<T extends object>(
    options: T[],
    key: keyof T,
    getOptionLabel: (option: string | T) => string = (o): string => `${o}`,
): GridFilterOperator[] {
    return getGridSingleSelectOperators().map((o) => ({
        ...o,
        getApplyFilterFn: (filterItem, column) => {
            const value = (filterItem.value ?? []) as T[];

            if (!filterItem.columnField || value.length === 0 || !filterItem.operatorValue) {
                return null;
            }

            return (params): boolean => {
                const rowValue = column.valueGetter ? column.valueGetter(params as GridValueGetterParams) : params.value;
                switch (filterItem.operatorValue) {
                    case 'is': 
                        return value.some((r) => r[key] === rowValue[key]);
                    case 'isAnyOf': {
                        let matches = false;
                        for (let i=0; i<filterItem.value.length; i++) {
                            const testValue = filterItem.value[i];
                            if (testValue.some((r: any) => r[key] === rowValue[key])) {
                                matches = true;
                                break;
                            }
                        }
                        return matches;
                    }
                    default:
                        return !value.some((r) => r[key] === rowValue[key]);
                }
            };
        },
        InputComponent: (props: GridFilterInputValueProps): JSX.Element => (
            <ObjectArrayAutocompleteFilter<T> options={options} getOptionLabel={getOptionLabel} {...props} />
        ),
    }));
}

export {getGridStringArrayAutocompleteOperators, getGridObjectArrayAutocompleteOperators};

import {useMemo} from 'react';
import NoDataPlaceholder from '~/components/generics/NoDataPlaceholder';
import SummaryCard from '~/components/generics/SummaryCard';
import styles from '~/styles/ScopeAttachments.module.scss';

interface NoOpenItemCardProps {
    type: 'rfis' | 'tqs' | 'tdrs' | 'holds' | 'actionItems' | 'needs';
}

export default function NoOpenItemCard({type}: NoOpenItemCardProps): JSX.Element {
    const data = useMemo((): {title: string; message: string} => {
        switch (type) {
            case 'rfis':
                return {title: 'RFI', message: 'No Open RFI'};
            case 'tqs':
                return {title: 'TQ', message: 'No Open TQ'};
            case 'tdrs':
                return {title: 'Deviations', message: 'No Open Deviation'};
            case 'holds':
                return {title: 'Holds', message: 'No Open Holds'};
            case 'needs':
                return {title: 'Needs', message: 'No Open Needs'};
            case 'actionItems':
                return {title: 'Action Items', message: 'No Open Action Item'};
            default:
                return {title: 'Open Items', message: 'No Open Item'};
        }
    }, [type]);

    return (
        <SummaryCard>
            <div className={styles.cardRoot}>
                <h3 className={styles.title}>{data.title}</h3>
                <NoDataPlaceholder title={data.message} />
            </div>
        </SummaryCard>
    );
}

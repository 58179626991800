import {motion} from 'framer-motion';
import {useEffect, useState} from 'react';
import * as React from 'react';
import {MenuToggle} from '~/components/app/navmenu/MenuToggle';
import NavMenuOptions from '~/components/app/navmenu/NavMenuOptions';
import {drawerVariants, listVariants} from '~/styles/animations/__animationVariants';
import styles from '~/styles/NavMenu.module.scss';

let scrollPos = 0;

export default function NavMenu(): JSX.Element {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleToggle = React.useCallback((): void => {
        if (!isOpen) {
            scrollPos = window.pageYOffset;
            document.body.style.position = 'fixed';
            document.body.style.top = `-${scrollPos}px`;
            setTimeout(()=>{document.body.style.overflow = 'visible'},0);
        } else {
            document.body.style.position = '';
            document.body.style.top = '';
            window.scrollTo(0,scrollPos);
        }
        setIsOpen((prev) => !prev);
    }, [isOpen]);

    useEffect(
        () => (): void => {
            document.body.style.overflow = 'visible';
            document.body.style.position = '';
            document.body.style.top = '';
        },
        [],
    );

    return (
        <motion.nav initial={false} animate={isOpen ? 'open' : 'closed'} className={styles.nav}>
            <motion.div variants={drawerVariants}>
                {isOpen && (
                    <motion.ul variants={listVariants}>
                        <NavMenuOptions open={isOpen} handleToggle={handleToggle} />
                    </motion.ul>
                )}
            </motion.div>
            <MenuToggle toggle={handleToggle} />
        </motion.nav>
    );
}

import { Box, Table, TableBody, TableCell, TableRow, Tooltip } from "@mui/material";
import { RFI } from "~/types";

export const getUniqueDisciplines = (rfis: RFI[]): string[] => {
    let uniqueArray: string[] = [];
    rfis.forEach((r)=> {
        if (r.disciplines?.length) {
            uniqueArray = [...uniqueArray, ...r.disciplines];
        } else {
            uniqueArray = [...uniqueArray, ...['Not Specified']]
        }
    })
    return [... new Set(uniqueArray)].sort();
}

const getDisciplineTitle = (disciplines: string[]): any => (
    <Table size='small'>
        <TableBody>{disciplines.sort().map((d,i)=>(
            <TableRow>
                <TableCell>{i+1}.</TableCell><TableCell>{d}</TableCell>
            </TableRow>
        ))}
        </TableBody>
    </Table>
);

const renderDisciplineTooltip = (disciplines: string[]): any => (
    <Tooltip title={getDisciplineTitle(disciplines)} arrow color='inherit'>
        <Box style={{paddingTop:20,paddingBottom:20, paddingRight: 40}}>
            {disciplines.sort()[0]} <em style={{fontSize: 10}}>...+{disciplines.length-1} more</em>
        </Box>
    </Tooltip>
);

export const renderDiscipline = (disciplines?: string[]): any => {
    const disciplineCount = disciplines?.length;
    if (disciplineCount) {
        return disciplineCount > 1 ? renderDisciplineTooltip(disciplines) : disciplines[0];
    }
    return 'Not Specified';
}

export const getDisciplineValue = (disciplines?: string[]): string => disciplines?.length ? disciplines.join(';') : 'Not Specified'

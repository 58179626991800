import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {useSnackbar} from 'notistack';
import {useMemo, useState} from 'react';
import * as React from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import cppLogo from '../../../assets/img/cpp-logo-dark-mode.svg';
import hmLogo from '../../../assets/img/hm-logo-white.svg';
import {trackEvent} from '~/api';
import BusyButton from '~/components/generics/BusyButton';
import RHFController from '~/components/generics/RHFController';
import {useStoreActions} from '~/store/storeHooks';
import styles from '~/styles/AccessRequestPage.module.scss';
import {NewAccountRequest} from '~/types';


export default function AccessRequestPage(): JSX.Element {
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const postNewAccountRequest = useStoreActions((actions) => actions.shared.postNewAccountRequest);
    const [busy, setBusy] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    const clickHome = React.useCallback(() => {
        navigate(-1);
    }, [navigate]);

    const DEFAULT_NEW_ACCOUNT_REQUEST: NewAccountRequest = useMemo(
        () => ({
            companyName: '',
            email: '',
            firstName: '',
            lastName: '',
            title: '',
            cellPhone: '',
            workPhone: '',
        }),
        [],
    );

    const methods = useForm<NewAccountRequest>({
        defaultValues: DEFAULT_NEW_ACCOUNT_REQUEST,
    });

    const {handleSubmit, reset} = methods;

    const handleCancel = React.useCallback(() => {
        reset(DEFAULT_NEW_ACCOUNT_REQUEST);
        setConfirmDialogOpen(false);
    }, [reset, DEFAULT_NEW_ACCOUNT_REQUEST]);

    const onSubmit = handleSubmit(async (data: NewAccountRequest): Promise<void> => {
        setBusy(true);

        trackEvent('requested access', {
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            title: data.title,
        });

        const {result, message} = await postNewAccountRequest({
            ...data,
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            workPhone: data.workPhone,
            title: data.title,
        });

        setBusy(false);
        enqueueSnackbar(message, {variant: result});

        if (result !== 'error') {
            handleCancel();
        } else {
            trackEvent('request access error', {error: message});
            setConfirmDialogOpen(true);
        }
    });

    const handleConfirm = (e: React.MouseEvent): void => {
        e.stopPropagation();
        setConfirmDialogOpen(true);
    };

    return (
        <div>
            <div className={styles.logoNavBar}>
                <Button color="primary" onClick={clickHome}>
                    <img src={cppLogo} alt="cpp-logo-dark" className={styles.logo} />
                </Button>
                <Button color="primary" onClick={clickHome}>
                    <img src={hmLogo} alt="hm-logo-white" className={styles.logo} />
                </Button>
            </div>
            <div id="project-request-page-root" className={styles.root}>
                <h4 className={styles.header}>New User Account</h4>
                <p className={styles.subheader}>Please populate and submit this form to request an account</p>
                <p className={styles.required}>* required field</p>
                <div>
                    <FormProvider {...methods}>
                        <form onSubmit={onSubmit}>
                            <RHFController
                                render={({field}): JSX.Element => <TextField {...field} fullWidth label="Company *" variant="standard" />}
                                name="companyName"
                                rules={{
                                    required: true,
                                }}
                            />
                            <RHFController
                                render={({field}): JSX.Element => <TextField {...field} fullWidth label="Email *" variant="standard" />}
                                name="email"
                                rules={{
                                    required: true,
                                }}
                            />
                            <RHFController
                                render={({field}): JSX.Element => <TextField {...field} fullWidth label="First Name *" variant="standard" />}
                                name="firstName"
                                rules={{
                                    required: true,
                                }}
                            />
                            <RHFController
                                render={({field}): JSX.Element => <TextField {...field} fullWidth label="Last Name *" variant="standard" />}
                                name="lastName"
                                rules={{
                                    required: true,
                                }}
                            />
                            <RHFController
                                render={({field}): JSX.Element => <TextField {...field} fullWidth label="Title *" variant="standard" />}
                                name="title"
                                rules={{
                                    required: true,
                                }}
                            />
                            <RHFController
                                render={({field}): JSX.Element => <TextField {...field} fullWidth label="Cell Phone *" variant="standard" />}
                                name="cellPhone"
                                rules={{
                                    required: true,
                                }}
                            />
                            <RHFController
                                render={({field}): JSX.Element => <TextField {...field} fullWidth label="Work Phone" variant="standard" />}
                                name="workPhone"
                            />
                        </form>
                    </FormProvider>
                    <div>
                        <Box my={2} className={styles.thankYou}>
                            <Typography align="center" variant="h4">
                                Thanks for partnering with H+M!
                            </Typography>
                        </Box>
                    </div>
                    <div className={styles.submit}>
                        <Button onClick={handleConfirm} type="submit" className="save-button">
                            Submit
                        </Button>
                    </div>
                </div>
            </div>
            <Dialog
                open={confirmDialogOpen}
                id="confirmation-dialog-root"
                onClose={(): void => setConfirmDialogOpen(false)}
                maxWidth="md"
                fullWidth
                className={styles.root}>
                <DialogTitle>Terms of Use Agreement</DialogTitle>
                <DialogContent>
                    <div className={styles.eulaLinkContainer}>
                        <a href="https://hm.hosted-by-files.com/91A0283719E1AC62AE9D6B1C66F07CEE/cpp_eula.pdf" target="_blank" rel="noreferrer">
                            User Terms of Use
                        </a>
                        <a
                            href="https://hm.hosted-by-files.com/91A0283719E1AC62AE9D6B1C66F07CEE/cpp_privacy_policy.pdf"
                            target="_blank"
                            rel="noreferrer">
                            Privacy Policy
                        </a>
                    </div>
                    <p>
                        By requesting a Capital Projects Portal account, you acknowledge that you have read and agree with the user terms of use and
                        privacy policy as referenced above. H+M reserves the right to update the user terms of use and privacy policy at any time and
                        will provide notification of updates.
                    </p>
                </DialogContent>
                <DialogActions>
                    <Button id="confirmation-cancel-button" className="close-button" onClick={handleCancel} disabled={busy}>
                        Cancel
                    </Button>
                    <BusyButton id="confirmation-accept-button" className="save-button" onClick={onSubmit} busy={busy}>
                        Confirm
                    </BusyButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

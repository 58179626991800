import {Button, Dialog, DialogActions, DialogContent, TextField, Zoom} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import {useSnackbar} from 'notistack';
import {useCallback, useEffect, useState} from 'react';
import * as React from 'react';
import {FormProvider, Message, useForm} from 'react-hook-form';
import {useParams} from 'react-router-dom';
import { trackEvent } from '~/api';
import BusyButton from '~/components/generics/BusyButton';
import RHFController from '~/components/generics/RHFController';
import DeleteTeamMemberButton from '~/components/subsite/summary/team/DeleteTeamMemberButton';
import LoadingComponent from '~/components/visualizations/LoadingComponent';
import {DisableMobileZoom, EnableMobileZoom, GetOptionLabel, IsOptionEqualToValue} from '~/helpers';
import {useStoreActions, useStoreState} from '~/store/storeHooks';
import styles from '~/styles/ProjectTeamMemberDialog.module.scss';
import {ProjectTeamMember, Role} from '~/types';


interface EditTeamMemberDialogProps {
    open: boolean;
    handleClose: () => void;
    selectedMember: ProjectTeamMember;
    isHM: boolean;
}

export default function EditTeamMemberDialog({open, handleClose, selectedMember, isHM}: EditTeamMemberDialogProps): JSX.Element {
    const {projectId} = useParams<{projectId: string}>();
    const {poc} = selectedMember;
    let {editable} = selectedMember;
    const currentUser = useStoreState((state) => state.shared.currentUser);
    const getProjectTeamRoles = useStoreActions((actions) => actions.subsite.getProjectTeamRoles);
    const teamRoles = useStoreState((state) => state.subsite.projectTeamRoles);
    const updateTeamMember = useStoreActions((actions) => actions.subsite.updateTeamMember);

    if (!isHM) editable = true;

    const methods = useForm<ProjectTeamMember>({
        defaultValues: {
            ...selectedMember,
            person: {...selectedMember.person, nickname: selectedMember.person.nickname ? selectedMember.person.nickname : ''},
        },
    });
    const {setValue, handleSubmit} = methods;
    const {enqueueSnackbar} = useSnackbar();
    const [busy, setBusy] = useState(false);

    useEffect(() => {
        setBusy(true);
        (async function dataRequest(): Promise<void> {
            await getProjectTeamRoles();
        })();
        setBusy(false);

        return (): void => {
            setBusy(false);
        };
    }, [getProjectTeamRoles, projectId]);

    // Disable zooming for this dialog, it's annoying whenever selecting any input
    useEffect(() => {
        DisableMobileZoom();
        return (): void => {
            EnableMobileZoom();
        };
    }, []);

    const handleRoleChange = useCallback(
        (e: React.ChangeEvent<{}>, value: string | Role): void => {
            let newRole = value;
            if (typeof newRole === 'string') {
                newRole = teamRoles.find((o) => o.title === newRole);
            }
            if (newRole) {
                setValue('role', newRole);
                setValue('roleId', newRole.id);
            }
        },
        [teamRoles, setValue],
    );

    const onSubmit = handleSubmit(async (data): Promise<void> => {
        setBusy(true);
        trackEvent('update team member', {id: data.id, name: data.person?.fullName});
        const {result, message} = await updateTeamMember({
            ...data,
        });
        setBusy(false);
        enqueueSnackbar(message, {variant: result});
        if (result !== 'error') {
            handleClose();
        }
    });

    if (busy) {
        return <LoadingComponent title="Loading Team Member Data" />;
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="md"
            fullWidth
            id="edit-team-member-dialog-root"
            className={styles.root}
            TransitionComponent={Zoom}
        >
            <FormProvider {...methods}>
                <form onSubmit={onSubmit}>
                    <div id="edit-team-member-dialog-title" className={styles.header}>
                        <h4>{`${editable ? 'Edit ' : ''}Team Member Info`}</h4>
                        {editable && <p>* required field</p>}
                    </div>
                    <DialogContent id="edit-team-member-dialog-content">
                        <RHFController
                            render={({field}): JSX.Element => (
                                <Autocomplete
                                    {...field}
                                    options={teamRoles}
                                    getOptionLabel={GetOptionLabel}
                                    isOptionEqualToValue={IsOptionEqualToValue}
                                    renderInput={(params): JSX.Element => <TextField label="Role *" fullWidth {...params} variant="standard" />}
                                    onChange={handleRoleChange}
                                    disabled={!editable}
                                />
                            )}
                            name="role"
                            rules={{
                                required: true,
                            }}
                        />
                        <RHFController
                            render={({field}): JSX.Element => (
                                <TextField {...field} fullWidth label="First Name *" disabled={!editable} variant="standard" />
                            )}
                            name="person.firstName"
                            rules={{
                                required: 'First Name is required' as Message,
                            }}
                        />
                        <RHFController
                            render={({field}): JSX.Element => (
                                <TextField {...field} fullWidth label="Last Name *" disabled={!editable} variant="standard" />
                            )}
                            name="person.lastName"
                            rules={{
                                required: 'Last Name is required' as Message,
                            }}
                            defaultValue=""
                        />
                        <RHFController
                            render={({field}): JSX.Element => (
                                <TextField {...field} fullWidth label="Nick Name" disabled={!editable} variant="standard" />
                            )}
                            name="person.nickname"
                            rules={{
                                required: false,
                            }}
                            defaultValue=""
                        />
                        <RHFController
                            render={({field}): JSX.Element => (
                                <TextField {...field} fullWidth label="Title *" disabled={!editable} variant="standard" />
                            )}
                            name="title"
                            rules={{
                                required: 'Job Title is required' as Message,
                            }}
                            defaultValue=""
                        />
                        {(poc || currentUser.isAdmin || !isHM) && (
                            <>
                                <RHFController
                                    render={({field}): JSX.Element => (
                                        <TextField {...field} fullWidth label="Email *" disabled={!editable} variant="standard" />
                                    )}
                                    name="person.email"
                                    rules={{
                                        required: 'Email is required' as Message,
                                    }}
                                    defaultValue=""
                                />
                                <RHFController
                                    render={({field}): JSX.Element => (
                                        <TextField {...field} fullWidth label="Work Phone *" disabled={!editable} variant="standard" />
                                    )}
                                    name="person.workPhone"
                                    rules={{
                                        required: 'Work Phone is required' as Message,
                                    }}
                                    defaultValue=""
                                />
                            </>
                        )}
                    </DialogContent>
                    <DialogActions id="edit-team-member-dialog-actions">
                        <div className={styles.iconButtonContainer}>
                            <DeleteTeamMemberButton selectedMember={selectedMember} handleCancel={handleClose} isHM={isHM}/>
                        </div>
                        <Button onClick={handleClose} className="close-button" id="cancel-edit-team-member-button">
                            Cancel
                        </Button>
                        {editable && (
                            <BusyButton busy={busy} type="submit" className="save-button" id="save-edit-team-member-button">
                                Save
                            </BusyButton>
                        )}
                    </DialogActions>
                </form>
            </FormProvider>
        </Dialog>
    );
}

import { GridColDef, GridRenderCellParams, getGridNumericOperators, getGridDateOperators } from '@mui/x-data-grid-pro';
import round from 'lodash/round';
import {useEffect, useMemo} from 'react';
import {useParams} from 'react-router-dom';
import DataGridProTable from '~/components/generics/DataGridProTable';
import {useStoreActions, useStoreState} from '~/store/storeHooks';
import {ProgressTableRow} from '~/types';

const tableCacheVersion = 2;

export default function ProgressTable(): JSX.Element {
    const getProgressTableData = useStoreActions((actions) => actions.progress.getProgressTableData);
    const phases = useStoreState((state) => state.progress.phases);
    const progressTableRows = useStoreState((state) => state.progress.progressTableRows);

    const cols = useMemo((): GridColDef<ProgressTableRow>[] => {
        let pCols: GridColDef<ProgressTableRow>[] = [];
        phases.forEach((p) => {
            const phase = p.phase.toLowerCase();
            pCols = pCols.concat([
                {
                    headerName: `${p.phase} Plan`,
                    field: `${phase}Plan`,
                    align: 'center',
                    disableColumnMenu: true,
                    disableReorder: true,
                    resizable: false,
                    sortable: false,
                    width: 86,
                    renderHeader: ()=> (
                        <div style={{position:'relative',width:'200px',display: 'flex', justifyContent: 'center'}}>
                            <div style={{marginTop: 10,color: 'white'}}>Plan</div>
                            <div style={{position:'absolute',top:12,left:0,right:0,bottom:14,borderTop:'1px solid #fff',borderLeft:'1px solid #fff',borderRadius:"10px 0px 0px 0px"}}/>
                        </div>
                    ),
                    renderCell: (params: GridRenderCellParams): string => {
                        const {row} = params;
                        const val = round((row[`${phase}Plan`] as number) * 100, 1);
                        return val ? `${val}%` : '';
                    },
                    filterOperators: getGridNumericOperators(),
                },
                {
                    headerName: `${p.phase} Actual`,
                    field: `${phase}Actual`,
                    align: 'center',
                    disableColumnMenu: true,
                    disableReorder: true,
                    resizable: false,
                    sortable: false,
                    width: 118,
                    renderHeader: ()=> (
                        <div style={{position:'relative',width:'200px',display: 'flex', justifyContent: 'center'}}>
                            <div style={{marginTop: 10,color: 'white'}}>Actual</div>
                            <div style={{position:'absolute',top:-6,left:0,right:0,display: 'flex', justifyContent: 'center'}}>
                                {p.phase}
                            </div>
                        </div>
                    ),
                    renderCell: (params: GridRenderCellParams): string => {
                        const {row} = params;
                        const val = round((row[`${phase}Actual`] as number) * 100, 1);
                        return val ? `${val}%` : '';
                    },
                    filterOperators: getGridNumericOperators(),
                },
                {
                    headerName: `${p.phase} Forecast`,
                    field: `${phase}Forecast`,
                    align: 'center',
                    disableColumnMenu: true,
                    disableReorder: true,
                    resizable: false,
                    sortable: false,
                    width: 96,
                    renderHeader: ()=> (
                        <div style={{position:'relative',width:'200px',display: 'flex', justifyContent: 'center'}}>
                            <div style={{marginTop: 10,color: 'white'}}>Forecast</div>
                            <div style={{position:'absolute',top:12,left:0,right:0,bottom:14,borderTop:'1px solid #fff',borderRight:'1px solid #fff',borderRadius:"0px 10px 0px 0px"}}/>
                        </div>
                    ),
                    renderCell: (params: GridRenderCellParams): string => {
                        const {row} = params;
                        const val = round((row[`${phase}Forecast`] as number) * 100, 1);
                        return val ? `${val}%` : '';
                    },
                    filterOperators: getGridNumericOperators(),
                },
            ]);
        });

        return [
            {
                headerName: 'Period',
                field: 'period',
                disableColumnMenu: true,
                disableReorder: true,
                resizable: false,
                sortable: false,
                width: 69,
                align: 'center',
                pinnable: true,
                headerAlign: 'center',
                filterOperators: getGridNumericOperators(),
            },
            {
                headerName: 'Weekending',
                field: 'weekending',
                disableColumnMenu: true,
                disableReorder: true,
                resizable: false,
                sortable: false,
                width: 111,
                renderCell: (params: GridRenderCellParams): string => new Date(params.row.weekending).toLocaleDateString(),
                align: 'center',
                filterOperators: getGridDateOperators(),
            },
            ...pCols,
            {
                headerName: 'Period Plan',
                field: 'periodPlan',
                align: 'center',
                disableColumnMenu: true,
                disableReorder: true,
                resizable: false,
                sortable: false,
                width: 86,
                renderHeader: ()=> (
                    <div style={{position:'relative',width:'200px',display: 'flex', justifyContent: 'center'}}>
                        <div style={{marginTop: 10,color: 'white'}}>Plan</div>
                        <div style={{position:'absolute',top:12,left:0,right:0,bottom:14,borderTop:'1px solid #fff',borderLeft:'1px solid #fff',borderRadius:"10px 0px 0px 0px"}}/>
                    </div>
                ),
                renderCell: (params: GridRenderCellParams): string => {
                    const {row} = params;
                    const val = round(row.periodPlan * 100, 1);
                    return val ? `${val}%` : '';
                },
                filterOperators: getGridNumericOperators(),
            },
            {
                headerName: 'Period Actual',
                field: 'periodActual',
                align: 'center',
                disableColumnMenu: true,
                disableReorder: true,
                resizable: false,
                sortable: false,
                width: 90,
                renderHeader: ()=> (
                    <div style={{position:'relative',width:'200px',display: 'flex', justifyContent: 'center'}}>
                        <div style={{marginTop: 10,color: 'white'}}>Actual</div>
                        <div style={{position:'absolute',top:-6,left:0,right:0,display: 'flex', justifyContent: 'center'}}>
                            Period
                        </div>
                    </div>
                ),
                renderCell: (params: GridRenderCellParams): string => {
                    const {row} = params;
                    const val = round(row.periodActual * 100, 1);
                    return val ? `${val}%` : '';
                },
                filterOperators: getGridNumericOperators(),
            },
            {
                headerName: 'Period Forecast',
                field: 'periodForecast',
                align: 'center',
                disableColumnMenu: true,
                disableReorder: true,
                resizable: false,
                sortable: false,
                width: 94,
                renderHeader: ()=> (
                    <div style={{position:'relative',width:'200px',display: 'flex', justifyContent: 'center'}}>
                        <div style={{marginTop: 10,color: 'white'}}>Forecast</div>
                        <div style={{position:'absolute',top:12,left:0,right:0,bottom:14,borderTop:'1px solid #fff',borderRight:'1px solid #fff',borderRadius:"0px 10px 0px 0px"}}/>
                    </div>
                ),
                renderCell: (params: GridRenderCellParams): string => {
                    const {row} = params;
                    const val = round(row.periodForecast * 100, 1);
                    return val ? `${val}%` : '';
                },
                filterOperators: getGridNumericOperators(),
            },
            {
                headerName: 'Cumulative Plan',
                field: 'cumulativePlan',
                align: 'center',
                disableColumnMenu: true,
                disableReorder: true,
                resizable: false,
                sortable: false,
                width: 86,
                renderHeader: ()=> (
                    <div style={{position:'relative',width:'200px',display: 'flex', justifyContent: 'center'}}>
                        <div style={{marginTop: 10,color: 'white'}}>Plan</div>
                        <div style={{position:'absolute',top:12,left:0,right:0,bottom:14,borderTop:'1px solid #fff',borderLeft:'1px solid #fff',borderRadius:"10px 0px 0px 0px"}}/>
                    </div>
                ),
                renderCell: (params: GridRenderCellParams): string => {
                    const {row} = params;
                    const val = round(row.cumulativePlan * 100, 1);
                    return val ? `${val}%` : '';
                },
                filterOperators: getGridNumericOperators(),
            },
            {
                headerName: 'Cumulative Actual',
                field: 'cumulativeActual',
                align: 'center',
                disableColumnMenu: true,
                disableReorder: true,
                resizable: false,
                sortable: false,
                width: 116,
                renderHeader: ()=> (
                    <div style={{position:'relative',width:'200px',display: 'flex', justifyContent: 'center'}}>
                        <div style={{marginTop: 10,color: 'white'}}>Actual</div>
                        <div style={{position:'absolute',top:-6,left:0,right:0,display: 'flex', justifyContent: 'center'}}>
                            Cumulative
                        </div>
                    </div>
                ),
                renderCell: (params: GridRenderCellParams): string => {
                    const {row} = params;
                    const val = round(row.cumulativeActual * 100, 1);
                    return val ? `${val}%` : '';
                },
                filterOperators: getGridNumericOperators(),
            },
            {
                headerName: 'Cumulative Forecast',
                field: 'cumulativeForecast',
                align: 'center',
                disableColumnMenu: true,
                disableReorder: true,
                resizable: false,
                sortable: false,
                width: 94,
                renderHeader: ()=> (
                    <div style={{position:'relative',width:'200px',display: 'flex', justifyContent: 'center'}}>
                        <div style={{marginTop: 10,color: 'white'}}>Forecast</div>
                        <div style={{position:'absolute',top:12,left:0,right:0,bottom:14,borderTop:'1px solid #fff',borderRight:'1px solid #fff',borderRadius:"0px 10px 0px 0px"}}/>
                    </div>
                ),
                renderCell: (params: GridRenderCellParams): string => {
                    const {row} = params;
                    const val = round(row.cumulativeForecast * 100, 1);
                    return val ? `${val}%` : '';
                },
                filterOperators: getGridNumericOperators(),
            },
        ];
    }, [phases]);

    const {projectId} = useParams<{projectId: string}>();

    useEffect(() => {
        if (projectId) {
            (async function fetchData(): Promise<void> {
                await getProgressTableData(parseInt(projectId, 10));
            })();
        }
    }, [getProgressTableData, projectId]);

    return (
        <div id="progress-table-root">
            <DataGridProTable 
                columns={cols}
                data={progressTableRows}
                optionKey='progressTableRows'
                csvFileName='progressData'
                toolbar
                cacheVersion={tableCacheVersion}
                getRowId={(row: any)=>row.period}
                density='compact'
                pinnedColumnsLeft={['period','weekending']}
                toolbarHide={{
                    columns: true,
                    density: true,
                    settings: true,
                    filter: true
                }}
            />
        </div>
    );
}

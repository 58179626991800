import parse from 'html-react-parser';
import {useContext} from 'react';
import ProjectContext from '~/components/dashboard/project-log/ProjectContext';
import {CleanSunEditorHTML} from '~/helpers';
import styles from '~/styles/ProjectSummaryDesktop.module.scss';

export default function ProjectScopeCard(): JSX.Element {
    const {project} = useContext(ProjectContext);
    const {scope} = project;

    return (
        <div>
            <h3 className={styles.title}>Scope</h3>
            <div className={styles.projectScopeContainer}>
                <div className={styles.projectScope}>{parse(CleanSunEditorHTML(scope as string))}</div>
            </div>
        </div>
    );
}

import DateFnsUtils from '@date-io/date-fns';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {MuiPickersUtilsProvider, DatePicker} from '@material-ui/pickers';
import {Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Zoom} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useForm, FormProvider} from 'react-hook-form';
import {useSearchParams} from 'react-router-dom';
import SunEditor from 'suneditor-react';
import DeleteNeedButton from './DeleteNeedButton';
import {trackEvent} from '~/api';
import BusyButton from '~/components/generics/BusyButton';
import NoDataPlaceholder from '~/components/generics/NoDataPlaceholder';
import RHFController from '~/components/generics/RHFController';
import {CleanSunEditorHTML, DisableMobileZoom, EnableMobileZoom, GetOptionLabel, IsOptionEqualToValue} from '~/helpers';
import {useStoreActions, useStoreState} from '~/store/storeHooks';
import styles from '~/styles/CorrespondenceDialog.module.scss';
import {Need, Project} from '~/types';

export default function NeedDialog({handleClose, project}: {handleClose: () => void; project: Project}): JSX.Element {
    const getProjectTeam = useStoreActions((actions) => actions.subsite.getProjectTeam);
    const [searchParams] = useSearchParams();
    const currentUser = useStoreState((state) => state.shared.currentUser);
    const needs = useStoreState((state) => state.correspondence.needs);
    const updateNeed = useStoreActions((actions) => actions.correspondence.updateNeed);
    const projectTeam = useStoreState((state) => state.subsite.projectTeam);

    const [busy, setBusy] = useState(false);

    const needId = searchParams.get('need');

    const need = useMemo(() => needs.find((o) => needId && o.id === +needId), [needId, needs]);
    const [toClient, setToClient] = useState(need?.assignedToClient);
    const projectId = project.id;

    const methods = useForm<Need>({
        defaultValues: need ?? {},
    });
    const {reset, handleSubmit, setValue} = methods;
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        setBusy(true);
        (async function dataRequest(): Promise<void> {
            await getProjectTeam(projectId);
        })();
        setBusy(false);

        return (): void => {
            setBusy(false);
        };
    }, [getProjectTeam, projectId]);

    const hmAssigneeOptions = useMemo(() => {
        const options: {id: number; title: string}[] = [];
        projectTeam.forEach((person) => {
            if (person.client === null) {
                options.push({id: person.person.id, title: person.person.fullName});
            }
        });
        return options.sort((a, b) => (a.title > b.title ? 1 : -1));
    }, [projectTeam]);

    // Reset RHF state when need changes
    useEffect(() => {
        setToClient(need?.assignedToClient);
        reset(need);
    }, [need, reset]);

    // Disable zooming for this dialog, it's annoying whenever selecting any input
    useEffect(() => {
        DisableMobileZoom();
        return (): void => {
            EnableMobileZoom();
        };
    }, []);

    const onSubmit = handleSubmit(async (data) => {
        setBusy(true);
        trackEvent('update need', {id: data.id, number: data.number});

        const {result, message} = await updateNeed({
            ...data,
            project: projectId,
            hmAssignee: data.assignedToClient ? null : data.hmAssignee?.id ?? data.hmAssignee,
            clientAssignee: data.assignedToClient ? data.clientAssignee : null,
            status: data.statusId, // api expects to receive status as integer, not string
            requestor: data.requestor?.id,
            completedBy: data.completed && !need.completedBy ? currentUser : need.completedBy,
        });
        setBusy(false);

        enqueueSnackbar(message, {variant: result});
        if (result === 'success') {
            handleClose();
        }
    });

    const handleStatusChange = useCallback(
        (e: React.ChangeEvent<{}>, value: {id: number; title: string}): void => {
            if (value?.id) {
                setValue('status', value.title);
                setValue('statusId', value.id);
            }
        },
        [setValue],
    );

    const canEdit = need?.createdBy?.id === currentUser?.id || (need?.status !== 'Closed' && need?.assignedToClient);
    const canFullEdit = need?.createdBy?.id === currentUser?.id;

    const handleWhoAssignedToChange = useCallback(
        (e: React.ChangeEvent<{}>, value: string): void => {
            setValue('assignedToClient', value !== 'H+M');
            setToClient(value !== 'H+M');
            setValue('whoIsAssignedTo', value);
        },
        [setValue, setToClient],
    );

    const handleHmAssigneeChange = useCallback(
        (e: React.ChangeEvent<{}>, value: {id: number; title: string}): void => {
            if (value) {
                setValue('hmAssigneeName', value.title);
                setValue('hmAssignee', value.id);
            }
        },
        [setValue],
    );

    return (
        <Dialog open={needId != null} onClose={handleClose} id="need-dialog-root" className={styles.root} TransitionComponent={Zoom}>
            <DialogTitle>
                <div className={styles.title}>
                    <h3>Update Project Need</h3>
                    {!canEdit && <FontAwesomeIcon icon={['fal', 'lock-alt']} color="tomato" />}
                </div>
            </DialogTitle>
            <DialogContent>
                <FormProvider {...methods}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <form>
                            {need == null ? (
                                <NoDataPlaceholder title="No Need Data Available" />
                            ) : (
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <TextField {...field} value={field.value?.fullName} label="Requestor" disabled variant="standard" />
                                            )}
                                            name="requestor"
                                            defaultValue={need.requestor?.fullName}
                                            rules={{
                                                required: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <Autocomplete
                                                    {...field}
                                                    options={[
                                                        {id: 2, title: 'Open'},
                                                        {id: 3, title: 'Closed'},
                                                    ]}
                                                    getOptionLabel={GetOptionLabel}
                                                    isOptionEqualToValue={IsOptionEqualToValue}
                                                    renderInput={(params): JSX.Element => (
                                                        <TextField label="Status" {...params} variant="standard" required disabled={!canFullEdit} />
                                                    )}
                                                    onChange={handleStatusChange}
                                                />
                                            )}
                                            name="status"
                                            rules={{
                                                required: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <TextField
                                                    {...field}
                                                    label="Need Reference Number"
                                                    autoComplete="off"
                                                    variant="standard"
                                                    required
                                                    title="Internal Reference Number"
                                                    disabled={!canFullEdit}
                                                />
                                            )}
                                            name="number"
                                            defaultValue={need.number ?? ''}
                                            rules={{
                                                required: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <DatePicker
                                                    label="Issued"
                                                    variant="dialog"
                                                    format="MM/dd/yyyy"
                                                    autoOk
                                                    showTodayButton
                                                    clearable
                                                    onChange={field.onChange}
                                                    value={field.value}
                                                    disabled
                                                />
                                            )}
                                            name="created"
                                            defaultValue={need.created ?? null}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <Autocomplete
                                                    {...field}
                                                    options={need.disciplines ?? []}
                                                    multiple
                                                    renderInput={(params): JSX.Element => (
                                                        <TextField {...params} variant="standard" label="Disciplines" disabled />
                                                    )}
                                                    ChipProps={{size: 'small'}}
                                                    disabled
                                                />
                                            )}
                                            name="disciplines"
                                            defaultValue={need.disciplines ?? []}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <div className={styles.sunEditorContainer}>
                                                    <p>What is needed? *</p>
                                                    <input
                                                        required
                                                        value={field.value}
                                                        type="input"
                                                        tabIndex={-1}
                                                        style={{position: 'absolute', top: 22, left: 14, opacity: 0}}
                                                        onChange={field.onChange}
                                                    />
                                                    <SunEditor
                                                        disable={!canFullEdit}
                                                        height="100%"
                                                        setContents={CleanSunEditorHTML(field.value)}
                                                        autoFocus={false}
                                                        onChange={field.onChange}
                                                        hideToolbar
                                                        setOptions={{
                                                            showPathLabel: false,
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            name="description"
                                            defaultValue={need.description ?? ''}
                                            rules={{required: true}}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <Autocomplete
                                                    {...field}
                                                    options={['H+M', project?.client?.name]}
                                                    getOptionLabel={GetOptionLabel}
                                                    isOptionEqualToValue={IsOptionEqualToValue}
                                                    renderInput={(params): JSX.Element => (
                                                        <TextField
                                                            label="Need From"
                                                            {...params}
                                                            variant="standard"
                                                            required
                                                            disabled={!canFullEdit}
                                                        />
                                                    )}
                                                    onChange={handleWhoAssignedToChange}
                                                />
                                            )}
                                            defaultValue={need.assignedToClient ? project?.client?.name : 'H+M'}
                                            name="whoIsAssignedTo"
                                            rules={{
                                                required: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {toClient ? (
                                            <RHFController
                                                key="clientAssignee"
                                                render={({field}): JSX.Element => {
                                                    const fieldObj = {...field};
                                                    return (
                                                        <TextField
                                                            {...fieldObj}
                                                            autoComplete="off"
                                                            key="clientAssignee"
                                                            disabled={!canEdit}
                                                            label={`Assigned To @ ${project.client.name}`}
                                                            variant="standard"
                                                        />
                                                    );
                                                }}
                                                name="clientAssignee"
                                            />
                                        ) : (
                                            <RHFController
                                                key="hmAssigneeName"
                                                render={({field}): JSX.Element => (
                                                    <Autocomplete
                                                        {...field}
                                                        options={hmAssigneeOptions}
                                                        getOptionLabel={GetOptionLabel}
                                                        isOptionEqualToValue={IsOptionEqualToValue}
                                                        disabled={!canFullEdit}
                                                        renderInput={(params): JSX.Element => (
                                                            <TextField
                                                                label="Assigned To @ H+M"
                                                                {...params}
                                                                variant="standard"
                                                                required
                                                                key="hmAssignee"
                                                            />
                                                        )}
                                                        onChange={handleHmAssigneeChange}
                                                    />
                                                )}
                                                name="hmAssigneeName"
                                                defaultValue={need?.hmAssignee?.fullName}
                                                rules={{
                                                    required: true,
                                                }}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item xs={6}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <>
                                                    <input
                                                        required
                                                        value={field.value ? field.value : ''}
                                                        tabIndex={-1}
                                                        type="input"
                                                        style={{position: 'absolute', top: 22, left: 14, opacity: 0, zIndex: -1}}
                                                        onChange={field.onChange}
                                                    />
                                                    <DatePicker
                                                        label="Due Date"
                                                        variant="dialog"
                                                        format="MM/dd/yyyy"
                                                        autoOk
                                                        showTodayButton
                                                        clearable
                                                        onChange={field.onChange}
                                                        value={field.value}
                                                        disabled={!canFullEdit}
                                                    />
                                                </>
                                            )}
                                            name="dueDate"
                                            defaultValue={need.dueDate ?? null}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <DatePicker
                                                    label="Completed Date"
                                                    variant="dialog"
                                                    format="MM/dd/yyyy"
                                                    autoOk
                                                    showTodayButton
                                                    clearable
                                                    onChange={field.onChange}
                                                    value={field.value}
                                                    disabled={!canEdit}
                                                />
                                            )}
                                            name="completed"
                                            defaultValue={need.completed ?? null}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RHFController
                                            render={({field}): JSX.Element => (
                                                <div className={styles.sunEditorContainer}>
                                                    <p>Comments</p>
                                                    <SunEditor
                                                        disable={!canEdit}
                                                        height="100%"
                                                        setContents={CleanSunEditorHTML(field.value)}
                                                        autoFocus
                                                        hideToolbar
                                                        onChange={field.onChange}
                                                        setOptions={{
                                                            showPathLabel: false,
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            name="comments"
                                            defaultValue={need.comments ?? ''}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </form>
                    </MuiPickersUtilsProvider>
                </FormProvider>
            </DialogContent>
            <DialogActions>
                {canFullEdit ? <DeleteNeedButton need={need} handleClose={handleClose} /> : <></>}
                <Button className="close-button" onClick={handleClose}>
                    Close
                </Button>
                {canEdit ? (
                    <BusyButton className="save-button" onClick={onSubmit} busy={busy}>
                        Save
                    </BusyButton>
                ) : (
                    <></>
                )}
            </DialogActions>
        </Dialog>
    );
}

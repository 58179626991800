import {StoreProvider} from 'easy-peasy';
import type {ReactNode} from 'react';
import {store} from '~/store/store';

const StoreProviderOverride = StoreProvider as any;

export default function AppStore({children}: {children: ReactNode}): JSX.Element {
    return (
        <div className="App" data-theme="default" id="app-root">
            <StoreProviderOverride store={store}>{children}</StoreProviderOverride>
        </div>
    );
}

/* eslint-disable react-hooks/exhaustive-deps */
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { useMediaQuery } from '@mui/material';
import Chip from '@mui/material/Chip';
import {DataGridPro, getGridNumericOperators, getGridDateOperators, useGridApiRef, GridState} from '@mui/x-data-grid-pro';
import numeral from 'numeral';
import {useMemo, useCallback, useEffect, useState} from 'react';
import {ProjectLogTableToolbar} from './ProjectLogTableToolbar';
import {ProjectLogColumnMenu} from '~/components/dashboard/project-log/table/ProjectLogColumnMenu';
import ProjectTitleColumn from '~/components/dashboard/project-log/table/ProjectTitleColumn';
import {getGridObjectArrayAutocompleteOperators, getGridStringArrayAutocompleteOperators} from '~/components/generics/MUIDataGridCustomOperators';
import {CPPValidStatus, defaultMediaSizes} from '~/helpers';
import useProjectHealth from '~/hooks/useProjectHealth';
import {useStoreState, useStoreActions} from '~/store/storeHooks';
import styles from '~/styles/ProjectLogTable.module.scss';
import {Project, GridInitialStateWithDensity} from '~/types';

const DEFAULT_HEALTH = {color: '#474747', label: 'Pending'};
const HEALTH_OPTIONS = [
    DEFAULT_HEALTH,
    {color: '#83cb85', label: 'Healthy'},
    {color: '#eaba72', label: 'Concerns'},
    {color: '#d47473', label: 'Issues'},
];

interface CellParams {
    // eslint-disable-next-line react/require-default-props, react/no-unused-prop-types
    value?: {
        color: string;
        label: string;
    };
}

const fieldHeaderLookup: {[field: string]: string} = {};

const cacheVersion = 2;

let saveOptionsTimeout: ReturnType<typeof setTimeout> = null;


export default function ProjectLogTable({viewCommercial}: {viewCommercial: boolean}): JSX.Element { // passing in viewCommercial from parent so we can use it in useMemo without causing useMemo to retrigger on change
    const {projects, projectSearchText, projectLogTableDefaultState} = useStoreState((state) => state.project);
    const {userOptions} = useStoreState((state) => state.shared);
    const {setUserOptions} = useStoreActions((actions) => actions.shared);
    const {setProjectSubset, saveProjectLogHeaderLookup} = useStoreActions((actions) => actions.project);
    const {calculateHSEGrade, calculateBudgetScore, calculateScheduleScore} = useProjectHealth();
    const [tableVersion,setTableVersion] = useState(0);
    const [pinChange,setPinChange] = useState(false);
    const [resetting, setResetting] = useState(false);
    const mediaSizes = defaultMediaSizes;
    const xxs = useMediaQuery(mediaSizes.xxs);
    const xs = useMediaQuery(mediaSizes.xs);
    const sm = useMediaQuery(mediaSizes.sm);
    const md = useMediaQuery(mediaSizes.md);
    const lg = useMediaQuery(mediaSizes.lg);

    const tableSizeKey = useMemo(()=>{
        if (xxs) return 'xxSmallTable';
        if (xs) return 'extraSmallTable';
        if (sm) return 'smallTable';
        if (md) return 'mediumTable';
        if (lg) return 'largeTable';
        return 'extraLargeTable';
    },[xxs,xs,sm,md,lg])

    useEffect(()=>{
        // using tableversions as a key in the div wrapping table so screen size changes will trigger data grid table to render as a new component
        // which will pick up changes to the columns that are based on screen size
        let newTableVersion = tableVersion;
        if (lg) newTableVersion += 3;
        if (md) newTableVersion += 4;
        if (sm) newTableVersion += 5;        
        if (xs) newTableVersion += 6;
        if (xxs) newTableVersion += 7;
        if (projects?.length) {
            newTableVersion = tableVersion + projects.length + 20;
        } else {
            newTableVersion += 10;
        }
        setTableVersion(newTableVersion);
        // // eslint-disable-next-line react-hooks/exhaustive-deps
    },[xxs, xs,sm,md,lg,projects]);

    const apiRef = useGridApiRef();

    const ProjectLogColumns = useMemo(
        (): any[] => [  // issue with the conditional column playing nice with GridColDef so switched to type any for now
            {field: 'code', headerName: 'Project', width: 125, minWidth: 50},
            {field: 'clientProjectCode', headerName: 'AFE #', minWidth: 50, width: 75, headerAlign: 'center'},
            {
                field: 'title',
                headerName: 'Title',
                minWidth: 50,
                width: 260,
                // eslint-disable-next-line react/no-unused-prop-types
                renderCell: ({row}: {row: Project}): JSX.Element => {
                    const {id, title, surveyAvailable} = row;
                    return <ProjectTitleColumn id={id} title={title} surveyAvailable={surveyAvailable} />;
                },
            },
            {
                field: 'percentComplete',
                headerName: '% Complete',
                headerAlign: 'center',
                align: 'center',
                minWidth: 50,
                width: 130,
                valueGetter: (params: any): number => Math.min(params.row?.percentComplete, 100),
                valueFormatter: (params: any): string => `${params.value} %`,
                filterOperators: getGridNumericOperators(),
            },
            {
                field: 'hseGrade',
                headerName: 'HSE',
                headerAlign: 'center',
                align: 'center',
                minWidth: 50,
                width: 100,
                valueFormatter: ({value}: CellParams): string => value?.label,
                renderCell: ({value}: CellParams): JSX.Element => <FontAwesomeIcon icon={['fas', 'circle']} color={`${value?.color}`} size="lg" />,
                valueGetter: ({row}: {row: Project}): {color: string; label: string} => {
                    if (!row) {
                        return DEFAULT_HEALTH;
                    }
                    const {status, projectHealth, foundationJob, healthCriteria} = row;
                    return status?.stage === 'Pre Bid' || status?.stage === 'Bid'
                        ? DEFAULT_HEALTH
                        : calculateHSEGrade(projectHealth, foundationJob, healthCriteria).indicator;
                },
                filterOperators: getGridObjectArrayAutocompleteOperators<{color: string; label: string}>(HEALTH_OPTIONS, 'label', (o) =>
                    typeof o === 'object' ? `${o.label}` : o,
                ),
            },
            {
                field: 'budgetGrade',
                headerName: 'Budget',
                headerAlign: 'center',
                align: 'center',
                minWidth: 50,
                width: 100,
                valueFormatter: ({value}: CellParams): string => value?.label,
                renderCell: ({value}: CellParams): JSX.Element => <FontAwesomeIcon icon={['fas', 'circle']} color={`${value?.color}`} size="lg" />,
                valueGetter: ({row}: {row: Project}): {color: string; label: string} => {
                    if (!row) {
                        return DEFAULT_HEALTH;
                    }
                    const {status, foundationJob} = row;
                    return foundationJob?.tm === false || status?.stage === 'Pre Bid' || status?.stage === 'Bid'
                        ? DEFAULT_HEALTH
                        : calculateBudgetScore(row).indicator;
                },
                filterOperators: getGridObjectArrayAutocompleteOperators<{color: string; label: string}>(HEALTH_OPTIONS, 'label', (o) =>
                    typeof o === 'object' ? `${o.label}` : o,
                ),
            },
            {
                field: 'scheduleGrade',
                headerName: 'Schedule',
                headerAlign: 'center',
                align: 'center',
                minWidth: 50,
                width: 100,
                valueFormatter: ({value}: CellParams): string => value?.label,
                renderCell: ({value}: CellParams): JSX.Element => <FontAwesomeIcon icon={['fas', 'circle']} color={`${value?.color}`} size="lg" />,
                valueGetter: ({row}: {row: Project}): {color: string; label: string} => {
                    if (!row) {
                        return DEFAULT_HEALTH;
                    }
                    const {status} = row;
                    return status?.stage === 'Pre Bid' || status?.stage === 'Bid' ? DEFAULT_HEALTH : calculateScheduleScore(row).indicator;
                },
                filterOperators: getGridObjectArrayAutocompleteOperators<{color: string; label: string}>(HEALTH_OPTIONS, 'label', (o) =>
                    typeof o === 'object' ? `${o.label}` : o,
                ),
            },
            {
                field: 'status',
                headerName: 'Status',
                headerAlign: 'center',
                align: 'center',
                minWidth: 50,
                width: 170,
                valueGetter: (params: any): string => (params.row?.surveyAvailable ? 'Survey Available' : CPPValidStatus(params.row?.status.status)),
                renderCell: (params: any): JSX.Element => {
                    let background = '#424242';
                    let color = '#FFF';

                    switch (params.formattedValue) {
                        case 'Active':
                            background = '#507251';
                            break;
                        case 'Pre Bid':
                            background = '#90caf9';
                            color = '#000';
                            break;
                        case 'Proposal Submitted':
                            background = '#a5ecec';
                            color = '#000';
                            break;
                        case 'Work Complete':
                            background = '#88b778';
                            color = '#000';
                            break;
                        case 'Survey Available':
                            background = '#fff684';
                            color = '#000';
                            break;
                        default:
                            break;
                    }
                    return <Chip style={{color, background}} label={params.formattedValue} className={styles.statusChip} size="small" />;
                },
                filterOperators: getGridStringArrayAutocompleteOperators([
                    'Pre Bid',
                    'Proposal Submitted',
                    'Active',
                    'Survey Available',
                    'Work Complete',
                    'Closed',
                ]),
            },
            ...(viewCommercial ? [{
                field: 'foundationJob',
                headerName: 'Contract Value',
                headerAlign: 'left',
                minWidth: 50,
                width: 95,
                valueGetter: (params: any): string =>
                    (params.row?.foundationJob?.originalContract ?? 0) + (params.row?.foundationJob?.totalApprovedIncomeAdj ?? 0),
                valueFormatter: (params: any): string => {
                    const {value, id, api} = params;
                    return CPPValidStatus(api?.getRow(id)?.status.status) === 'Pre Bid'
                        ? 'Pending'
                        : numeral(value).format(value > 1000000 ? '$0,0.0a' : '$0,0a');
                },
                filterOperators: getGridNumericOperators(),
            }] : []),
            {
                field: 'lastModified',
                headerName: 'Last Progress Date',
                headerAlign: 'center',
                align: 'center',
                minWidth: 50,
                hide: xxs || xs || md,
                width: 115,
                valueGetter: (params: any): Date | null | undefined => params.row?.lastModified,
                valueFormatter: (params: any): string => {
                    if (!params.value) {
                        return '';
                    }
                    try {
                        return `${(params.value as Date).toLocaleDateString('en-US', {
                            day: '2-digit',
                            month: '2-digit',
                            year: '2-digit',
                        })}`;
                    } catch (e) {
                        return '';
                    }
                },
                filterOperators: getGridDateOperators(),
            },
            {
                field: 'location',
                headerName: 'Location',
                width: 175,
                minWidth: 50,
                hide: xxs || xs || md,
                headerAlign: 'left',
                valueGetter: (params: any): string => params.row?.location.siteName,
            },
            {
                field: 'pm',
                headerName: 'H+M Project Manager',
                headerAlign: 'left',
                hide: xxs || xs || md,
                width: 175,
                minWidth: 50,
                valueGetter: (params: any): string => params.row?.pm?.fullName,
            },
            {
                field: 'clientPm',
                headerName: 'Project Manager',
                headerAlign: 'left',
                hide: xxs || xs || md ,
                width: 150,
                minWidth: 50,
                valueGetter: (params: any): string => params.row?.clientPm?.person?.fullName,
            },
            {
                field: 'contract',
                headerName: 'Contract Type',
                headerAlign: 'left',
                hide: xxs || xs,
                minWidth: 50,
                width: 120,
                valueGetter: (params: any): string => (params.row?.foundationJob?.tm ? 'T&M Cost' : 'Fixed Cost'),
                filterOperators: getGridStringArrayAutocompleteOperators(['T&M Cost', 'Fixed Cost']),
            },
            {
                field: 'category',
                headerName: 'Scope of Service',
                headerAlign: 'center',
                align: 'center',
                hide: xxs || xs || md,
                minWidth: 50,
                width: 100,
                valueGetter: (params: any): string => params.row?.category?.category,
            },
        ],
        [xxs,xs,md],
    );

    const fixLeftColumn = (initState: any) => {
        if (!initState?.columns || !initState?.pinnedColumns) return initState;
        const {pinnedColumns} = initState;
        const index = 0;
        if (pinnedColumns?.left?.[index] && initState?.columns?.orderedFields?.length) {
            const updatedInitState = initState;
            const leftColumn = pinnedColumns?.left?.[index];
            updatedInitState.columns.orderedFields = initState.columns.orderedFields.filter((c: string)=>c !== leftColumn)
            updatedInitState.columns.orderedFields.splice(index,0,leftColumn);
            return updatedInitState;
        }
        return initState;
    }

    let initialState = useMemo(
        () => {
            let initState: any = projectLogTableDefaultState;
            if (resetting) return {};
            const {projectTableOptions} = userOptions;
            if (projectTableOptions && projectTableOptions?.cacheVersion === cacheVersion) {
                if (projectTableOptions.projectLogTableInitialState) {
                    initState = projectTableOptions.projectLogTableInitialState;
                }
            } 

            if (initState?.[tableSizeKey]?.columns) {
                initState.columns = initState[tableSizeKey].columns;
            }

            if (initState?.[tableSizeKey]?.pinnedColumns?.left) {
                initState.pinnedColumns = initState[tableSizeKey].pinnedColumns;
            } else if (initState.pinnedColumns) {
                initState.pinnedColumns = null;
            }
            initState = fixLeftColumn(initState);
            return initState;
        }, [userOptions,tableSizeKey]
    )

    useEffect(()=>{
        const {projectTableOptions} = userOptions;
        if (projectTableOptions && projectTableOptions?.cacheVersion !== cacheVersion) {
            setUserOptions({ 
                option: 'projectTableOptions',
                key: 'cacheVersion', 
                value: cacheVersion
            }); 
        }

    },[userOptions]);

    const getDefaultGridState: (reason?: string) => GridInitialStateWithDensity =  useCallback(
        (reason) => {
            // need to save default state for when user wants to restore table to default state
            // dataGrid pro restoreState doesn't currently support restoring to default but requires
            // explicit state passed in as argument so here we are saving the default state.
            const defaultTableState: GridInitialStateWithDensity = {
                filter: {
                    filterModel: {
                        items:[]
                    }
                },
                sorting: {
                    sortModel: []
                },
                pinnedColumns: {
                    left: [],
                    right: []
                },
                columns: {
                    orderedFields: [],
                    dimensions: {},
                    columnVisibilityModel: {}
                },
                density: 'standard'
            };
            defaultTableState.density = 'standard'; 
            // Using values from column definitions to set the default state object values
            ProjectLogColumns.forEach((col)=>{
                defaultTableState.columns.orderedFields.push(col.field);
                defaultTableState.columns.columnVisibilityModel[col.field] = !col.hide;
                const minWidth: number = col.minWidth ? col.minWidth : col.width;
                const width: number = col.width ? col.width : minWidth
                defaultTableState.columns.dimensions[col.field] = {
                    width: width !== undefined ? width : 100,
                    maxWidth: col.maxWidth ? col.maxWidth : -1,
                    minWidth: minWidth !== undefined ? minWidth : 100
                }
            })
            if (reason === 'resetting') {
                setResetting(true);
            }
            return defaultTableState;
        },
        [ProjectLogColumns,tableSizeKey]
    )

    const saveTableChange = useCallback((trigger: string): void => {
        if (resetting) return;
        if (apiRef?.current?.exportState) {
            const state = apiRef?.current?.state;
            if (!state) return;
            const lookup = state.filter.visibleRowsLookup;
            const subSet = Object.keys(lookup).length === 0 ? [...projects] : projects.filter((p) => lookup[`${p.id}`]);
            setProjectSubset(subSet);
            const exportedState: GridInitialStateWithDensity = apiRef.current.exportState();
            if (trigger === 'newTableSizeToSave') {
                const defObj = getDefaultGridState();
                exportedState.columns = defObj.columns;
                exportedState.pinnedColumns = defObj.pinnedColumns;
            }
            // known issue with exportState and columnVisibility not working right
            // so explicity set it before saving to localStorage
            if (exportedState?.columns && !exportedState?.columns?.columnVisibilityModel && apiRef.current.state?.columns?.columnVisibilityModel) {
                exportedState.columns = {
                    ...exportedState.columns,
                    columnVisibilityModel: apiRef.current.state.columns.columnVisibilityModel
                } 
            }
            exportedState.density = apiRef.current.state?.density?.value;
            if (exportedState.preferencePanel) delete exportedState.preferencePanel; 
            let value = {
                ...initialState,
                ...exportedState,
                density: apiRef?.current?.state?.density?.value,
                [tableSizeKey]: {
                    columns: exportedState.columns,
                    pinnedColumns: exportedState.pinnedColumns
                }
            }
            const headerLookup: {[field: string]: string} = {};
            if (exportedState?.filter?.filterModel?.items?.length) {
                const {items} = exportedState.filter.filterModel;
                items.forEach((item) => {
                    headerLookup[item.columnField] = fieldHeaderLookup[item.columnField];
                });
            }
            value = fixLeftColumn(value);
            initialState = value;
            if (trigger === 'newTableSizeToSave') {
                saveProjectLogHeaderLookup(headerLookup);
                setUserOptions({ 
                    option: 'projectTableOptions',
                    key: 'projectLogTableInitialState', 
                    value
                });  
            } else {
                // debounce / delay saves of state change as the change is triggered rapidly while user is dragging around a column or default settings are restored trigger 4 rapid state changes
                if (saveOptionsTimeout) clearTimeout(saveOptionsTimeout);
                saveOptionsTimeout = setTimeout(()=>{
                    saveOptionsTimeout = null;
                    saveProjectLogHeaderLookup(headerLookup);
                    setUserOptions({ 
                        option: 'projectTableOptions',
                        key: 'projectLogTableInitialState', 
                        value
                    });  
                },2000);
            }

            // debounce / delay saves of state change as the change is triggered rapidly while user is dragging around a column or default settings are restored trigger 4 rapid state changes
            if (saveOptionsTimeout) clearTimeout(saveOptionsTimeout);
            saveOptionsTimeout = setTimeout(()=>{
                saveOptionsTimeout = null;
                saveProjectLogHeaderLookup(headerLookup);
                setUserOptions({ 
                    option: 'projectTableOptions',
                    key: 'projectLogTableInitialState', 
                    value
                });  
            },2000);

            const pinnedColumnLeft = initialState?.columns?.pinnedColumns?.left?.[0];
            const leftColumn = initialState?.columns?.orderedFields?.[0];
            if (trigger === 'pinned' || (pinnedColumnLeft && pinnedColumnLeft !== leftColumn) ) {
                // pinning a column also changes its order but the order change is not caught with pinned column change and column order change is not triggered
                // so triggering another state change after the pinned change and then it will catch the new column order in a state change
                setPinChange(true);
            }
        }
    }, [initialState,tableSizeKey,apiRef]);

    const handleStateChange = useCallback(
        (state: GridState): void => {
            if (resetting) {
                setTimeout(()=>setResetting(false),0);
                return;
            }
            if (pinChange) {
                setPinChange(false);
                saveTableChange('afterPinChange');
            }
            // density doesn't have a specific state change handle
            // and doesn't get saved in exportState as of yet
            // so have to look for changes here in every state change
            if (state?.density?.value !== initialState?.density) {
                saveTableChange('densityChange');
            }
            const lookup = state.filter.visibleRowsLookup;
            const subSet = Object.keys(lookup).length === 0 ? [...projects] : projects.filter((p) => lookup[`${p.id}`]);
            setProjectSubset(subSet);
        },
        [saveTableChange, projects, setProjectSubset,pinChange,resetting],
    );

    useEffect(()=>{
        if (initialState?.columns && !initialState?.[tableSizeKey] && apiRef?.current?.exportState && !resetting) {
            // we've never saved options for this table size so save them to lock them in to current options
            saveTableChange('newTableSizeToSave');
        }
        // // eslint-disable-next-line react-hooks/exhaustive-deps
    },[tableSizeKey,initialState,resetting]);

    const searchText = projectSearchText?.toLowerCase();

    const searchProjects = (p: Project) => {
        if (!searchText) return true;
        const contractType = p?.foundationJob?.tm ? 'T&M Cost' : 'Fixed Cost';
        const status = p.surveyAvailable ? 'Survey Available' : CPPValidStatus(p?.status.status);
        return (
            p.code?.toLowerCase().indexOf(searchText) > -1 ||
            p.title?.toLowerCase().indexOf(searchText) > -1 ||
            p.location?.siteName?.toLowerCase().indexOf(searchText) > -1 ||
            p.clientPm?.person?.fullName?.toLowerCase().indexOf(searchText) > -1 ||
            p.clientProjectCode?.toLowerCase().indexOf(searchText) > -1 ||
            contractType?.toLowerCase().indexOf(searchText) > -1 ||
            p.pm?.fullName?.toLowerCase().indexOf(searchText) > -1 ||
            status?.toLowerCase().indexOf(searchText) > -1
        );
    };

    const projectRows = useMemo(() => {
        if (!searchText?.length || !projects?.length) return projects;
        return projects.filter(searchProjects);
    }, [projects, searchText]);

    return (
        <div className={styles.root} key={`v${tableVersion.toString()}`}>
            <h4 className={styles.header}>Project Log</h4>
            <DataGridPro
                initialState={initialState}
                rows={projectRows}
                columns={ProjectLogColumns}
                apiRef={apiRef}
                components={{
                    ColumnMenu: ProjectLogColumnMenu,
                    Toolbar: ProjectLogTableToolbar,
                }}
                componentsProps={{
                    toolbar: {
                        getDefaultGridState
                    }
                }}
                density={initialState?.density ?? 'standard'}
                onStateChange={handleStateChange}
                onColumnOrderChange={()=>saveTableChange('columnOrder')}
                onColumnWidthChange={()=>saveTableChange('columnWidth')}
                onSortModelChange={()=>saveTableChange('sort')}
                onFilterModelChange={()=>saveTableChange('filter')}
                onPinnedColumnsChange={()=>saveTableChange('pinned')}
                onColumnVisibilityModelChange={()=>saveTableChange('visible')}
                onPreferencePanelClose={()=>saveTableChange('panelClose')}
            />
        </div>
    );
}

import * as React from 'react';
import styles from '~/styles/KPIContainer.module.scss';

interface KPIContainerProps extends React.HTMLAttributes<HTMLDivElement> {
    className: string;
}

export default function KPIContainer(props: KPIContainerProps): JSX.Element {
    const {children, className} = props;

    return (
        <div id="kpi-container-root" {...props} className={`${styles.root} ${className}`}>
            {children}
        </div>
    );
}

interface UseSearch {
    searchArray: <T>(arr: T[], keywords: string, ignoreKeys?: string[]) => T[];
}

const useSearch = (): UseSearch => {
    function iterateObject<T>(obj: T, keywords: string, ignoreKeys?: string[]): boolean {
        let result = false;
        Object.entries(obj).forEach(([key, value]) => {
            if (result === true) {
                return;
            }
            if (ignoreKeys && ignoreKeys.includes(key)) {
                return;
            }
            if (!value || value.length === 0) {
                return;
            }
            if (typeof value === 'object') {
                result = iterateObject(value, keywords, ignoreKeys);
                return;
            }
            if (typeof value === 'string' && value.toLowerCase().includes(keywords.toLowerCase())) {
                result = true;
            }
        });
        return result;
    }

    function searchArray<T>(arr: T[], keywords: string, ignoreKeys?: string[]): T[] {
        if (!keywords || keywords.length === 0 || arr.length === 0) {
            return arr;
        }
        return arr.filter((o) => {
            if (!o) {
                return false;
            }
            return iterateObject(o, keywords, ignoreKeys);
        });
    }

    return {
        searchArray,
    };
};

export default useSearch;

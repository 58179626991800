import ScopeAttachmentList from '~/components/dashboard/summary-stack/ScopeAttachmentList';
import styles from '~/styles/ScopeAttachments.module.scss';

export default function ScopeAttachmentCard(): JSX.Element {
    return (
        <div className={styles.cardRoot}>
            <h3 className={styles.title}>Scope Attachments</h3>
            <ScopeAttachmentList />
        </div>
    );
}

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import {useContext} from 'react';
import DataGridProTable from '~/components/generics/DataGridProTable';
import HealthReportCardContext from '~/components/subsite/health/HealthReportCardContext';
import useProjectHealth from '~/hooks/useProjectHealth';
import {HealthCriteria} from '~/types';

const tableCacheVersion = 2;

const HealthCriteriaTableColumns = (): GridColDef<HealthCriteria>[] => {
    const {indicator} = useProjectHealth();

    return [
        {
            headerName: 'Grade',
            field: 'grade',
            flex: 1,
            disableColumnMenu: true,
            sortable: false,
            resizable: false,
            renderCell: (params: GridRenderCellParams): JSX.Element => {
                const {grade} = params.row;
                return <FontAwesomeIcon icon={['fas', 'circle']} color={indicator(grade).color} size="lg" />;
            },
        },
        {
            headerName: 'Score Lower Limit',
            field: 'lowerLimit',
            disableColumnMenu: true,
            sortable: false,
            resizable: false,
            flex: 1,
        },
        {
            headerName: 'Score Upper Limit',
            field: 'upperLimit',
            disableColumnMenu: true,
            sortable: false,
            resizable: false,
            flex: 1,
        },
    ];
};

const getRowId = (row: any) => row.grade

export default function HealthCriteriaTable(): JSX.Element {
    const {healthCriteria} = useContext(HealthReportCardContext);

    return (
        <div id="health-criteria-table-root">
            <DataGridProTable
                columns={HealthCriteriaTableColumns()}
                data={healthCriteria}
                toolbar={false}
                cacheVersion={tableCacheVersion}
                optionKey='healthCriteriaGradeTable'
                getRowId={getRowId}
                density='compact'
            />
        </div>
    );
}
